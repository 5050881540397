import { StatusChip, Wrapper, toHyphenCase } from '@bt-healthcare/ui-toolkit';

import { Fragment } from 'react';
import { MonitoredConditionRendererWrapper } from '../PatientList.styles';
import type { ConsultationStatusRendererProps } from '../PatientList.types';
import { ConsultationStatusTypeColorMap } from '../PatientList.util';
import { ConsultationStatusMap } from 'mappings/enums';
import { EMPTY_VALUE } from 'App.constants';

export const ConsultationStatusRenderer = ({
  items,
  id,
}: ConsultationStatusRendererProps) => (
  <MonitoredConditionRendererWrapper>
    {items.map((item) =>
      item.statusType == null ? (
        <Fragment key={item.condition}>{EMPTY_VALUE}</Fragment>
      ) : (
        <Wrapper key={item.condition}>
          <StatusChip
            id={`${id}-${toHyphenCase(item.condition)}-consultation`}
            chipColor={ConsultationStatusTypeColorMap[item.statusType]}
          >
            {ConsultationStatusMap[item.statusType]}
          </StatusChip>
        </Wrapper>
      )
    )}
  </MonitoredConditionRendererWrapper>
);
