import type { StartPatientAdmissionFormStepProps } from './types';
import { Step } from './types';
import { StartAdmissionConfirm } from 'components/StartAdmissionForm/Confirm';
import { StartAdmission } from 'components/StartAdmissionForm';

export const StartAdmissionFormStep = ({
  step,
}: StartPatientAdmissionFormStepProps) => {
  if (step === Step.CONFIRM) {
    return <StartAdmissionConfirm />;
  }
  return <StartAdmission />;
};
