import {
  BTHealthIcon,
  Card,
  Grid,
  SubHeader,
  Tooltip,
  colors,
} from '@bt-healthcare/ui-toolkit';
import { useMemo, useState } from 'react';
import { PatientOverviewDetails } from './PatientOverviewDetails';
import { CardContent, EditButtonWrapper } from './styles';
import type { PatientOverviewProps } from './types';
import { PatientTabWrapper } from 'pages/Patient/styles';
import { PatientDetailsForm } from 'components/PatientDetailsForm';
import { usePatientDispatch } from 'context/patient/PatientContext';
import { mapFormDataOnSubmit } from 'components/PatientDetailsForm/mapping';
import { patientDetailsMapping } from 'components/PatientDetailsForm/utils';
import { AdmissionStatus } from 'services/graphql';

export const PatientOverview = ({ wardPatient }: PatientOverviewProps) => {
  const patientDispatch = usePatientDispatch();

  const [isEditable, setIsEditable] = useState(false);

  const isAllowEdit = useMemo(
    () =>
      wardPatient.careSettingPatient?.attributes.admissionStatus !==
      AdmissionStatus.InvitedStepup,
    [wardPatient.careSettingPatient?.attributes.admissionStatus]
  );

  const toggleEdit = () => {
    if (!isEditable) {
      const patientDetails = patientDetailsMapping(wardPatient);
      patientDispatch({
        type: 'setPatientDetailsFormData',
        patientDetailsFormData: mapFormDataOnSubmit(patientDetails),
      });
    }
    setIsEditable(!isEditable);
  };

  return (
    <PatientTabWrapper>
      <Card id="patient-overview-card">
        <CardContent>
          <Grid
            style={{
              gridTemplateColumns: '1fr max-content',
              alignItems: 'center',
            }}
          >
            <SubHeader>Patient details</SubHeader>
            {isAllowEdit && (
              <Tooltip
                content="Edit details"
                data-testid="edit-details-tooltip"
                direction="top"
                id="edit-details-tooltip"
              >
                <EditButtonWrapper
                  onClick={() => !isEditable && toggleEdit()}
                  data-testid="edit-button"
                >
                  <BTHealthIcon
                    icon="EditDetails"
                    size={18}
                    color={
                      isEditable
                        ? colors.primaryIndigo.indigo08
                        : 'currentColor'
                    }
                  />
                </EditButtonWrapper>
              </Tooltip>
            )}
          </Grid>
          {isEditable ? (
            <PatientDetailsForm
              wardPatient={wardPatient}
              toggleEdit={toggleEdit}
            />
          ) : (
            <PatientOverviewDetails wardPatient={wardPatient} />
          )}
        </CardContent>
      </Card>
    </PatientTabWrapper>
  );
};
