import {
  BTHealthIcon,
  ButtonGroup,
  Wrapper,
  colors,
  noop,
  useClickOutside,
} from '@bt-healthcare/ui-toolkit';
import { type MutableRefObject, useRef, useState, useEffect } from 'react';
import { endOfDay, startOfDay } from 'date-fns';

import {
  observationChartDateFilters,
  observationChartDateCustomFilter,
} from './LineChart/lineChart.config';
import { ButtonGroupWrapper, CustomGraphFilterButton } from './styles';
import {
  DateFilter,
  type ObservationChartDateFilter,
  type ObservationTypeLineChartFilterProps,
} from './types';
import type { DateRange } from './LineChart/LineChart.types';
import { MultiDateRangePicker } from 'components/MultiDateRangePicker';
import { usePatientDispatch, usePatient } from 'context/patient/PatientContext';

export const ObservationTypeLineChartFilter = ({
  activeFilter,
  setActiveFilter,
  handleOptionChange,
  previousFilter,
}: ObservationTypeLineChartFilterProps) => {
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const [customSelected, setCustomSelected] = useState(false);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [appliedDateRange, setAppliedDateRange] = useState<DateRange | null>(
    null
  );

  const dispatch = usePatientDispatch();
  const { synchedChartDateRange } = usePatient();

  // Used to handle Custom filter
  useEffect(() => {
    if (synchedChartDateRange.dateFilter === DateFilter.Custom) {
      setCustomSelected(true);
      setStartDate(synchedChartDateRange.range.startDate);
      setEndDate(synchedChartDateRange.range.endDate);
      handleOptionChange(
        observationChartDateCustomFilter,
        synchedChartDateRange.range
      );
    } else {
      setCustomSelected(false);
    }
  }, [synchedChartDateRange]);

  const handleOption = (item: ObservationChartDateFilter) => {
    setCustomSelected(false);
    handleOptionChange(item);
    setStartDate(null);
    setEndDate(null);
  };

  const handleDatePicker = () => {
    if (datePickerOpen && activeFilter.value === DateFilter.Custom) {
      setDatePickerOpen(false);
    } else if (datePickerOpen) {
      setCustomSelected(false);
      setDatePickerOpen(false);
    } else {
      setCustomSelected(true);
      setDatePickerOpen(true);
    }
  };

  const handleChange = (dates: Array<Date | null>) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const handleApply = () => {
    const dateRange = {
      endDate,
      startDate,
    } as DateRange;
    const chartDateRange = {
      startDate: startOfDay(dateRange.startDate),
      endDate: endOfDay(dateRange.endDate),
    };
    setAppliedDateRange(dateRange);
    handleOptionChange(observationChartDateCustomFilter, chartDateRange);
    setDatePickerOpen(false);
    dispatch({ type: 'resetChartDateFilter' });
    dispatch({
      type: 'setChartDateRange',
      synchedDateRange: {
        range: dateRange,
        dateFilter: DateFilter.Custom,
        isNavigation: false,
      },
    });
  };

  const datePickerRef = useRef() as MutableRefObject<HTMLDivElement>;

  const handleCancelDatePicker = () => {
    if (activeFilter?.value !== DateFilter.Custom) {
      setStartDate(null);
      setEndDate(null);
      setActiveFilter(previousFilter!);
      setCustomSelected(false);
    } else {
      setStartDate(appliedDateRange?.startDate!);
      setEndDate(appliedDateRange?.endDate!);
    }

    return setDatePickerOpen(false);
  };

  useClickOutside(datePickerRef, () => handleCancelDatePicker());

  const customGraphFilterButtonRenderer = [
    {
      id: 'custom-filter',
      value: (
        <CustomGraphFilterButton>
          <BTHealthIcon
            icon="Calendar"
            size={16}
            color={customSelected ? colors.base.white : colors.grey.grey08}
          />{' '}
          Custom
        </CustomGraphFilterButton>
      ),
    },
  ];
  return (
    <Wrapper>
      <ButtonGroupWrapper>
        <ButtonGroup
          onChange={handleOption as any}
          items={observationChartDateFilters}
          activeItem={
            customSelected ? observationChartDateCustomFilter : activeFilter
          }
          id="date-filter"
          data-testid="date-filter"
        />
        <Wrapper ref={datePickerRef}>
          <Wrapper onClick={handleDatePicker}>
            <ButtonGroup
              onChange={noop}
              items={customGraphFilterButtonRenderer}
              activeItem={
                customSelected ? observationChartDateCustomFilter : activeFilter
              }
              id="custom-date-filter"
              data-testid="custom-date-filter"
            />
          </Wrapper>

          <MultiDateRangePicker
            handleChange={handleChange}
            handleApply={handleApply}
            startDate={startDate}
            endDate={endDate}
            datePickerOpen={datePickerOpen}
          />
        </Wrapper>
      </ButtonGroupWrapper>
    </Wrapper>
  );
};
