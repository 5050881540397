import {
  Wrapper,
  colors,
  device,
  fontSizes,
  spacing,
} from '@bt-healthcare/ui-toolkit';
import styled from 'styled-components';

import type { StyledButtonFilterProps } from './types';

export const ButtonFilterGroupGrid = styled.div`
  display: grid;
  grid-gap: ${spacing.s8};

  @media ${device.largeTablet} {
    grid-template-columns: 3fr 1fr 132px;
    grid-gap: ${spacing.s14};
  }
`;

export const ButtonFilterGroupLabel = styled.p`
  color: ${colors.grey.grey10};
  font-size: ${fontSizes.base};
  max-width: 200px;

  @media ${device.largeTablet} {
    max-width: unset;
    font-size: ${fontSizes.h4};
  }
`;

export const ButtonFilterWrapper = styled(Wrapper)`
  margin-top: ${spacing.s4};
  display: flex;
  gap: ${spacing.s2};
  flex-wrap: wrap;

  @media ${device.largeTablet} {
    margin-top: ${spacing.s7};
  }
`;

export const StyledButtonFilter = styled.div<StyledButtonFilterProps>`
  background: ${colors.base.white};
  color: ${({ selected }) =>
    selected ? colors.primaryIndigo.indigo08 : colors.grey.grey08};
  font-size: ${fontSizes.xs};
  padding: ${spacing.s2};
  border-radius: ${spacing.s2};
  cursor: pointer;
  border: ${({ selected }) =>
    `1px solid ${
      selected ? colors.primaryIndigo.indigo08 : colors.base.white
    }`};

  min-width: 96px;
  text-align: center;
  span {
    color: ${({ selected }) =>
      selected ? colors.primaryIndigo.indigo08 : colors.grey.grey09};
  }

  @media ${device.largeTablet} {
    &:hover {
      color: ${colors.primaryIndigo.indigo08};
      border-color: ${colors.primaryIndigo.indigo08};
      transition: border-color 0.25 ease;
      span {
        color: ${colors.primaryIndigo.indigo08};
      }
    }
    font-size: ${fontSizes.sm};
  }
`;
