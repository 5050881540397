import { Maybe } from 'purify-ts';
import { descend, prop, sortWith } from 'ramda';
import { format } from 'date-fns';

import type { PatientHeaderUI, WardPatientUI } from './types';
import {
  getSupportedObservationTypes,
  getPatientsMonitoredConditions,
  getPatientsMonitoredConditionsText,
  checkIfAssessmentStart,
} from './wardPatient.utils';
import { getBirthDate, getNhsId, getPatientName } from 'selectors/patient';
import type { WardPatient } from 'services/graphql';
import { getDate, parseDateTime } from 'utils/date.utils';
import { EMPTY_VALUE } from 'App.constants';
import { nhsIdFormatter } from 'utils/string.utils';
import { ObservationTypeMap } from 'transforms/observationTypeSearchTransform';

const getMonitoredConditions = (wardPatient: WardPatient) => {
  const monitoredConditions = wardPatient.attributes?.monitoredConditions?.map(
    ({ condition, monitoringStatus, testStatus, consultationStatus }) => ({
      monitoringStatus: monitoringStatus!,
      testStatus: testStatus!,
      consultationStatus: consultationStatus!,
      condition,
      wardPatientId: wardPatient.id,
      version: wardPatient.attributes?.audit?.version ?? 1,
    })
  );

  return monitoredConditions
    ? sortWith([descend(prop('condition'))], monitoredConditions)
    : undefined;
};

export const buildWardPatientUI = (wardPatient: WardPatient): WardPatientUI => {
  const patient = Maybe.fromNullable(wardPatient.careSettingPatient);

  const personalInformation = patient.map(
    (careSettingPatient) =>
      careSettingPatient.person?.attributes.personalInformation
  );

  const contactInformation = patient.map(
    (careSettingPatient) =>
      careSettingPatient.person?.attributes.contactInformation
  );

  const submittedObservationTypes =
    wardPatient.attributes?.submittedObservationTypes ?? [];
  const admissionStatus =
    wardPatient.careSettingPatient?.attributes.admissionStatus;

  const wardPatientCreated = Maybe.fromNullable(
    wardPatient.attributes.audit?.created
  ).chain(parseDateTime);
  const admissionDateTime = Maybe.fromNullable(
    wardPatient.attributes.admissionDateTime
  ).chain(parseDateTime);
  const lastReviewDateCreated = Maybe.fromNullable(
    wardPatient.attributes.lastReviewedDateTime
  ).chain(parseDateTime);
  const admissionStatusLastUpdated = Maybe.fromNullable(
    wardPatient.careSettingPatient?.attributes.admissionStatusUpdateDateTime
  ).chain(parseDateTime);

  const lastObservationDate = Maybe.fromNullable(
    wardPatient.attributes.lastObservationDateTime
  ).chain(parseDateTime);

  const monitoredConditions = getMonitoredConditions(wardPatient);
  return {
    id: wardPatient.id,
    name: patient.map(getPatientName).extract(),
    firstName: personalInformation.map((info) => info.firstName).extract(),
    surname: personalInformation.map((info) => info.surname).extract(),
    nhsId: patient.chain(getNhsId).extract(),
    birthDate: patient.chain(getBirthDate).extract(),
    monitoredConditions,
    supportedObservationTypes: getSupportedObservationTypes(
      submittedObservationTypes,
      getPatientsMonitoredConditions(monitoredConditions)
    ),
    lastReview: lastReviewDateCreated
      .map((date) => ({
        date,
        reviewerName: wardPatient.attributes.res_lastReviewedBy ?? EMPTY_VALUE,
      }))
      .extract(),
    admissionDate: admissionDateTime.alt(wardPatientCreated).extract(),
    admissionDateTime: wardPatient.attributes.admissionDateTime,
    lastObservationDate: lastObservationDate.extract(),
    admissionStatusLastUpdated: admissionStatusLastUpdated.extract(),
    admissionStatus: admissionStatus ?? undefined,
    phoneNumber: contactInformation
      .map((info) => info?.primaryContactNumber ?? undefined)
      .extract(),
    hasSubmittedReadings: submittedObservationTypes?.length > 0,
    personId: wardPatient.careSettingPatient?.person.id,
  };
};

export const buildPatientHeaderUI = (
  warPatientUI: WardPatientUI
): PatientHeaderUI => {
  const {
    birthDate,
    monitoredConditions,
    nhsId,
    supportedObservationTypes,
    name,
    phoneNumber,
  } = warPatientUI;
  const formatBirthDate = format(birthDate!, 'yyyy-MM-dd');
  const displayBirthDate = getDate(formatBirthDate, true);

  const conditions = getPatientsMonitoredConditionsText(monitoredConditions);

  const vitalsForMonitoring =
    supportedObservationTypes
      ?.map((item) => ObservationTypeMap[item]!.name)
      .join(', ') ?? '';

  return {
    birthDate: displayBirthDate,
    nhsNumber: nhsIdFormatter(nhsId) ?? EMPTY_VALUE,
    monitoredConditions: conditions,
    name: name ?? EMPTY_VALUE,
    vitalsForMonitoring,
    canStartAssessment: checkIfAssessmentStart(monitoredConditions),
    phoneNumber: phoneNumber ?? EMPTY_VALUE,
  };
};
