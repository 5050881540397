import type { PropsWithChildren } from 'react';
import { useReducer } from 'react';

import { PatientContext, PatientDispatchContext } from './PatientContext';
import { PatientPersistedState } from './PatientPersistedState';
import type { PatientState } from 'reducers/patient/types';
import {
  initPatientState,
  patientReducer,
} from 'reducers/patient/patientReducer';

export const PatientProvider = ({
  children,
  value = initPatientState,
}: PropsWithChildren<{ value?: PatientState }>) => {
  const persistedState = PatientPersistedState();
  const patientState = { ...value, ...persistedState };

  const [state, dispatch] = useReducer(patientReducer, patientState ?? value);
  return (
    <PatientContext.Provider value={state}>
      <PatientDispatchContext.Provider value={dispatch}>
        {children}
      </PatientDispatchContext.Provider>
    </PatientContext.Provider>
  );
};
