import { useAnalytics } from '@bt-healthcare/ui-toolkit';

import { UNKNOWN_VALUE, appName } from 'App.constants';
import { PageName } from 'config/pageNames';
import { useApp } from 'context/app/AppContext';

export const useAssessmentTracking = () => {
  const { careSetting } = useApp();
  const { trackEvent } = useAnalytics();

  const trackTappedAssessment = (buttonName: string) =>
    trackEvent('Tapped', {
      buttonName,
      pageName: `${appName} - ${PageName.PATIENT_DETAIL}`,
      careSetting: careSetting?.organisation ?? UNKNOWN_VALUE,
    });

  return {
    trackTappedAssessment,
  };
};
