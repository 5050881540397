import { differenceInYears, format, parseISO } from 'date-fns';
import { Maybe } from 'purify-ts';

import { EMPTY_VALUE } from 'App.constants';
import type { FormatDOBType } from 'types';

export const parseDateTime = (dateTimeValue: string): Maybe<Date> => {
  const date = new Date(dateTimeValue);
  if (
    date instanceof Date &&
    !Number.isNaN(date) &&
    !Number.isNaN(date.valueOf())
  ) {
    return Maybe.of(date);
  }
  return Maybe.zero();
};

export const getDate = (dateValue?: string | null, includeAge = false) => {
  if (dateValue != null) {
    const asDate = parseISO(dateValue);
    const formattedDateValue = format(asDate, 'dd/MM/yyyy');
    return includeAge
      ? `${formattedDateValue} (Age ${differenceInYears(new Date(), asDate)})`
      : formattedDateValue;
  }
  return EMPTY_VALUE;
};

export const parseDateString = (dateValue: string) => {
  const parsedDate = new Date(dateValue);
  return format(parsedDate, 'dd/MM/yyyy');
};

export const toISOStringAsDate = (date: Date) => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}T00:00:00.000Z`;
};

export const appendZeroForDate = (el: number) =>
  (el.toString().length === 1 ? '0' : '') + el.toString();

export const formatDOB = (data: FormatDOBType) =>
  `${appendZeroForDate(data.dayOfBirth)}/${appendZeroForDate(
    data.monthOfBirth
  )}/${data.yearOfBirth}`;
