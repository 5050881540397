import { ErrorCode } from 'services/graphql';

export const getAdmissionFormErrorConfig = (errorType?: ErrorCode) => {
  if (!errorType) return undefined;
  if (errorType === ErrorCode.UserAlreadyExists) {
    return {
      errorTitle: 'Email already registered',
      errorMessage:
        'The email entered is already associated with an existing account. Please use a different email address.',
    };
  }
  return {
    errorMessage:
      'The patient could not be admitted due to an error. Please review the information you entered and try again.',
    errorTitle: "We couldn't admit the patient",
  };
};
