import { Notification, toHyphenCase } from '@bt-healthcare/ui-toolkit';

import type { StatusNotificationProps } from './types';
import { BoldText } from 'components/BoldText';

export const StatusNotification = ({
  icon,
  currentStatus,
  newStatus,
  statusIntro,
}: StatusNotificationProps) => {
  if (currentStatus === newStatus) return null;
  return (
    <Notification
      icon={icon}
      type="informative"
      data-testid={`${toHyphenCase(newStatus)}-update-notification`}
    >
      <BoldText>{statusIntro}</BoldText> will update from{' '}
      <BoldText>{currentStatus}</BoldText> to <BoldText>{newStatus}.</BoldText>
    </Notification>
  );
};
