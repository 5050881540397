import { useAuth0 } from '@auth0/auth0-react';
import { SpinnerLoader } from '@bt-healthcare/ui-toolkit';
import type { PropsWithChildren } from 'react';
import { useEffect } from 'react';

export const AuthenticationTemplate = ({ children }: PropsWithChildren<{}>) => {
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();

  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      loginWithRedirect();
    }
  }, [isLoading, isAuthenticated]);

  if (isLoading || !isAuthenticated) {
    return <SpinnerLoader id="loader" data-testid="loader" />;
  }
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};
