import { StatusChip } from '@bt-healthcare/ui-toolkit';
import type { RecommendationStatusChipProps } from './types';
import { YesNoType } from 'services/graphql';

export const RecommendationStatusChip = ({
  guidelineAccepted,
}: RecommendationStatusChipProps) => {
  const status = guidelineAccepted === YesNoType.Yes ? 'Accepted' : 'Declined';
  const icon = guidelineAccepted === YesNoType.Yes ? 'Check' : 'Disabled';
  const chipColor =
    guidelineAccepted === YesNoType.Yes
      ? 'notifications.light.successLight'
      : 'grey.grey03';
  const iconColor =
    guidelineAccepted === YesNoType.Yes
      ? 'notifications.light.successDark'
      : 'grey.grey10';
  return (
    <StatusChip
      id={`${status}-recommendation-status-chip`}
      icon={icon}
      chipColor={chipColor}
      iconColor={iconColor}
    >
      {status}
    </StatusChip>
  );
};
