import styled from 'styled-components';
import { device, spacing } from '@bt-healthcare/ui-toolkit';

export const ModalHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: ${spacing.s6};
`;

export const ModalHeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100%;

  @media ${device.largeTablet} {
    width: 80%;
  }
`;

export const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: ${spacing.s4};
`;
