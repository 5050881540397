import type { Dispatch } from 'react';
import { createContext } from 'react';

import { useAppContext } from '../useAppContext';
import type { AppActions, AppState } from 'reducers/app/types';

export const AppContext = createContext<AppState | null>(null);
export const AppDispatchContext = createContext<Dispatch<AppActions> | null>(
  null
);

export const useApp = () => useAppContext(AppContext);
export const useAppDispatch = () => useAppContext(AppDispatchContext);
