import { ObservationType } from 'services/graphql';

export const OBSERVATION_TYPENAME = {
  pulse: 'Pulse',
  spo2: 'Spo2',
  bloodPressure: 'BloodPressure',
} as const;

export const READING_RANGE = {
  oxygenSaturation: { min: 0, max: 100 },
  bpm: { min: 1, max: 300 },
  systolic: { min: 50, max: 250 },
  diastolic: { min: 30, max: 200 },
};

export const SupportedObservationTypeConstant = {
  Spo2: ObservationType.Spo2,
  Pulse: ObservationType.Pulse,
  BloodPressure: ObservationType.BloodPressure,
} as const;
