import type { PropsWithChildren } from 'react';
import { Auth0Provider } from '@auth0/auth0-react';

import { getEnvVariable } from '../../env.utils';

export const AuthProvider = ({ children }: PropsWithChildren<{}>) => (
  <Auth0Provider
    domain={getEnvVariable('VITE_AUTH_AUTHORITY')}
    clientId={getEnvVariable('VITE_AUTH_CLIENT_ID')}
    redirectUri={window.location.origin}
    useRefreshTokens
  >
    {children}
  </Auth0Provider>
);
