import {
  Accordion,
  Text,
  AccordionItem,
  colors,
  fontSizes,
} from '@bt-healthcare/ui-toolkit';
import type { AccordionContentProps } from './types';

export const AccordionContent = ({
  id,
  children,
  noOfFilters,
  title,
}: AccordionContentProps) => (
  <Accordion id={id}>
    <AccordionItem
      title={
        <Text
          color={colors.grey.grey10}
          fontWeight={500}
          fontSize={fontSizes.base}
          data-testid={`accordion-item-title-${id}`}
        >
          {title}
          {noOfFilters > 0 && (
            <Text
              color={colors.grey.grey08}
              fontSize={fontSizes.base}
              data-testid={`accordion-item-no-of-filters-${id}`}
            >
              {' '}
              ({noOfFilters})
            </Text>
          )}
        </Text>
      }
      id={`${id}-items`}
    >
      {children}
    </AccordionItem>
  </Accordion>
);
