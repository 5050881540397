import { useRef, type MutableRefObject } from 'react';
import {
  DatePicker,
  Wrapper,
  Button,
  Text,
  fontSizes,
  colors,
  useClickOutside,
} from '@bt-healthcare/ui-toolkit';

import type { DateRangePickerProps } from './types';
import { DatePickerWrapper, ButtonWrapper } from './styles';
import { modifiers } from './modifiers';

export const DateRangePicker = ({
  handleChange,
  handleApply,
  startDate,
  endDate,
  datePickerOpen,
  setDatePickerOpen,
  setFilterValue,
  prevFilterValue,
  editCustomRange,
  setStartDate,
  setEndDate,
}: DateRangePickerProps) => {
  const datePickerRef = useRef() as MutableRefObject<HTMLDivElement>;

  const handleCancelDatePicker = () => {
    setStartDate(new Date());
    setEndDate(null);

    if (editCustomRange) {
      return setDatePickerOpen(false);
    }

    setFilterValue(prevFilterValue);
    return setDatePickerOpen(false);
  };

  useClickOutside(datePickerRef, () => handleCancelDatePicker());

  if (!datePickerOpen) return null;

  return (
    <DatePickerWrapper
      ref={datePickerRef}
      data-testid="custom-date-range-picker"
    >
      <DatePicker
        placeholder="Custom"
        selected={startDate}
        onChange={(dates) => handleChange(dates)}
        name="dateRangePicker"
        id="dateRangePicker"
        startDate={startDate}
        endDate={endDate}
        selectsRange
        shouldCloseOnSelect={false}
        isCalendarOpen
        customInput={<Wrapper />}
        popperModifiers={modifiers}
        maxDate={new Date()}
        showPreviousMonthsDays={false}
        disabledKeyboardNavigation
      >
        <ButtonWrapper>
          <Button
            id="apply-custom-date-range-btn"
            onClick={handleApply}
            disabled={!startDate || !endDate}
          >
            <Text fontSize={fontSizes.base} color={colors.base.white}>
              Apply
            </Text>
          </Button>
        </ButtonWrapper>
      </DatePicker>
    </DatePickerWrapper>
  );
};
