import { colors, fontSizes, spacing } from '@bt-healthcare/ui-toolkit';
import styled from 'styled-components';

export const MonitoredConditionTitle = styled.p`
  color: ${colors.grey.grey10};
  font-weight: 500;
  font-size: ${fontSizes.base};
`;

export const ConditionStatusLastUpdatedWrapper = styled.div`
  margin-top: ${spacing.s2};
  span {
    font-weight: 400;
    font-size: ${fontSizes.xs};
    color: ${colors.grey.grey09};
  }
`;

export const StatusWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${spacing.s4};
`;
