import { Checkbox, toHyphenCase } from '@bt-healthcare/ui-toolkit';
import { map, dissoc, sort, ascend, prop } from 'ramda';

import { CheckboxWrapper } from './styles';
import type { PatientStatusFiltersProps } from './types';
import { TestStatusFilterSort } from './config';
import { TestStatusMap } from 'mappings/enums';
import type { MonitoredConditionTestStatusType } from 'services/graphql';
import type { SubFilter } from 'filters/types';

export const TestStatusFilters = ({
  statusFilter,
  handleTargetChecked,
}: PatientStatusFiltersProps) => {
  const testStatuses = Object.keys(TestStatusMap).map((item) => ({
    value: item,
    type: 'TestStatus',
    sort: TestStatusFilterSort[item as MonitoredConditionTestStatusType],
  }));

  const statuses = map(
    dissoc('sort'),
    sort(ascend(prop('sort')), testStatuses)
  ) as SubFilter[];
  return (
    <CheckboxWrapper data-testid="test-status-filters">
      {statuses.map((status) => (
        <Checkbox
          id={`test-${toHyphenCase(status.value)}-filter`}
          label={
            TestStatusMap[status.value as MonitoredConditionTestStatusType]
          }
          position="right"
          key={status.value}
          className="accordion-item-checkbox"
          checked={
            statusFilter &&
            statusFilter.find(
              (item) =>
                item.value === status.value && item.type === 'TestStatus'
            ) !== undefined
          }
          onChange={({ target }) => handleTargetChecked(target, status)}
        />
      ))}
    </CheckboxWrapper>
  );
};
