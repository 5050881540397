import { Checkbox, toHyphenCase } from '@bt-healthcare/ui-toolkit';

import { useState } from 'react';
import { CheckboxWrapper, MedicalCentreFilterWrapper } from './styles';
import type { PatientStatusFiltersProps } from './types';
import { MAX_MEDICAL_CENTRE_SEARCH, medicalCentreSearchFilter } from './config';
import { useApp } from 'context/app/AppContext';
import { ClientSearch } from 'components/ClientSearch';
import { NoFilterResults } from 'components/NoFilterResults';

export const MedicalCentreFilters = ({
  statusFilter,
  handleTargetChecked,
}: PatientStatusFiltersProps) => {
  const [filter, setFilter] = useState<string[]>();
  const { careSetting } = useApp();
  const medicalCentres = careSetting.generalPractices ?? [];
  const result = (filter ?? medicalCentres).sort((a, b) => a.localeCompare(b));

  return (
    <MedicalCentreFilterWrapper>
      {medicalCentres.length > MAX_MEDICAL_CENTRE_SEARCH && (
        <ClientSearch
          data={medicalCentres}
          setSearchFilter={setFilter}
          searchFilter={medicalCentreSearchFilter}
          variant="secondary"
        />
      )}
      <CheckboxWrapper data-testid="medical-centre-filters">
        {result.map((medicalCentre) => (
          <Checkbox
            id={`medical-centre-${toHyphenCase(medicalCentre)}-filter`}
            label={medicalCentre}
            position="right"
            key={medicalCentre}
            className="accordion-item-checkbox"
            checked={
              statusFilter &&
              statusFilter.find(
                (item) =>
                  item.value === medicalCentre && item.type === 'MedicalCentre'
              ) !== undefined
            }
            onChange={({ target }) =>
              handleTargetChecked(target, {
                type: 'MedicalCentre',
                value: medicalCentre,
              })
            }
          />
        ))}
        {result.length === 0 && <NoFilterResults />}
      </CheckboxWrapper>
    </MedicalCentreFilterWrapper>
  );
};
