import type { TypedOptionType } from '@bt-healthcare/ui-toolkit';
import { DEFAULT_DATE_FILTER } from 'App.constants';
import { DateFilter } from 'components/Visualisations/types';

export const options: TypedOptionType<DateFilter>[] = [
  {
    label: 'Today',
    value: DateFilter.Today,
  },
  {
    label: 'Week',
    value: DateFilter.Week,
  },
  {
    label: '2 Weeks',
    value: DateFilter.Biweekly,
  },
  {
    label: 'Month',
    value: DateFilter.Monthly,
  },
  {
    label: 'Custom',
    value: DateFilter.Custom,
  },
];

export const defaultOption = options.find(
  (option) => option.value === DEFAULT_DATE_FILTER
) as TypedOptionType<DateFilter>;

export const customOption = options.find(
  (option) => option.value === DateFilter.Custom
) as TypedOptionType<DateFilter>;
