import { addDays, format, isToday } from 'date-fns';
import type { SyntheticEvent } from 'react';
import { Fragment, useEffect, useMemo, useState } from 'react';
import {
  Card,
  Stack,
  SubHeader,
  Timestamp,
  Notification,
  Wrapper,
} from '@bt-healthcare/ui-toolkit';

import { useWindowSize } from 'usehooks-ts';
import { LineChart } from './LineChart/LineChart';
import type {
  ObservationTypeLineChartProps,
  ObservationChartDateFilter,
  ObservationTypeData,
  NavigationDirection,
  DateFilterRange,
} from './types';
import { DateFilter } from './types';
import { observationChartDateFilters } from './LineChart/lineChart.config';
import { LineChartHeading } from './LineChart/LineChartHeading';
import {
  getChartWidth,
  getCustomFilter,
  getDateIncrement,
  getXAxisLabel,
} from './observationTypeLineChart.utils';
import type { DateRange } from './LineChart/LineChart.types';
import { ObservationTypeLineChartFilter } from './ObservationTypeLineChartFilter';
import { ObservationTypeLineChartNavigation } from './ObservationTypeLineChartNavigation';
import type { GetObservationVisualsQuery, Maybe } from 'services/graphql';
import { useGetObservationVisualsLazyQuery } from 'services/graphql';
import {
  getDefaultChartData,
  observationTypeSearchTransform,
} from 'transforms/observationTypeSearchTransform';
import { ObservationTypeLineChartSkeleton } from 'components/Skeletons/ObservationTypeLineChartSkeleton';
import { getObservationTypeSearchInputVariables } from 'pages/Patient/utils';
import { FETCH_POLICY } from 'App.constants';
import { getDateRange } from 'utils/dateFilter.utils';
import { usePatientDispatch, usePatient } from 'context/patient/PatientContext';

export const ObservationTypeLineChart = ({
  lastReview,
  personId,
  admissionDateTime,
  observationTypes,
  hasSubmittedReadings,
  title = 'Vital trends',
  showLineChartHeading = true,
}: ObservationTypeLineChartProps) => {
  const [view, setView] = useState<ObservationChartDateFilter>(
    observationChartDateFilters[0]
  );

  const dispatch = usePatientDispatch();
  const { synchedChartDateFilter } = usePatient();

  const [prevView, setPrevView] = useState<ObservationChartDateFilter>(view);

  const { width } = useWindowSize();

  const [chartData, setChartData] = useState<ObservationTypeData[]>([]);
  const [showNext, setShowNext] = useState(false);
  const [showPrev, setShowPrev] = useState(true);
  const [dateRange, setDateRange] = useState<Maybe<DateRange>>(
    getDateRange(observationChartDateFilters[0].data, admissionDateTime)
  );
  const [customFilter, setCustomFilter] = useState<
    DateFilterRange | undefined
  >();

  const [xAxisLabel, setXAxisLabel] = useState(
    format(new Date(), 'eeee do MMMM yyyy')
  );

  const [getObservationVisuals, { loading }] =
    useGetObservationVisualsLazyQuery();

  const handleCompleted = (
    data: GetObservationVisualsQuery,
    dateFilter: DateFilter = DateFilter.Today
  ) => {
    const chartTransformData = observationTypeSearchTransform(
      data,
      customFilter ? customFilter.dateFilter : dateFilter,
      observationTypes
    );
    setChartData(chartTransformData);
  };

  const chartWidth = useMemo(() => getChartWidth(width), [width]);

  const handleOptionChange = (
    item: ObservationChartDateFilter,
    customDateRange?: Maybe<DateRange>
  ) => {
    setView(item);
    setPrevView(item);
    setChartData([]);
    const chartDateRange =
      customDateRange ?? getDateRange(item.data, admissionDateTime);
    if (chartDateRange != null) {
      if (item.data === DateFilter.Custom) {
        const filter = getCustomFilter(chartDateRange);
        setCustomFilter(filter);
      } else {
        dispatch({ type: 'setChartDateFilter', synchedDateFilter: item.data });
        setCustomFilter(undefined);
      }
      const label = getXAxisLabel(item.data, chartDateRange);
      setXAxisLabel(label);
      setDateRange(chartDateRange);

      if (hasSubmittedReadings) {
        getObservationVisuals({
          fetchPolicy: FETCH_POLICY.CACHE_AND_NETWORK,
          variables: {
            input: getObservationTypeSearchInputVariables(
              chartDateRange,
              personId
            ),
          },
          onCompleted: (data) => {
            handleCompleted(data, item.data);
          },
        });
      } else {
        // If no readings submitted then set default chart data to display empty charts
        setChartData(getDefaultChartData(observationTypes));
      }
    }
  };

  const handleNavigation = (
    evt: SyntheticEvent,
    direction: NavigationDirection
  ) => {
    evt.preventDefault();
    const dateIncrement = getDateIncrement(direction, view.data);
    const { startDate, endDate } = dateRange!;
    const chartDateRange = {
      startDate: addDays(startDate, dateIncrement),
      endDate: addDays(endDate, dateIncrement),
    };
    setDateRange(chartDateRange);
    const label = getXAxisLabel(view.data, chartDateRange);
    setXAxisLabel(label);
    dispatch({
      type: 'setChartDateRange',
      synchedDateRange: {
        range: chartDateRange,
        isNavigation: true,
        dateFilter: view.data,
      },
    });
  };

  useEffect(() => {
    if (dateRange == null) {
      setChartData([]);
    } else {
      const isCustom = view.data === DateFilter.Custom;
      setShowNext(!isCustom && !isToday(dateRange.endDate));
      setShowPrev(!isCustom);
      if (hasSubmittedReadings) {
        getObservationVisuals({
          fetchPolicy: FETCH_POLICY.CACHE_AND_NETWORK,
          variables: {
            input: getObservationTypeSearchInputVariables(dateRange, personId),
          },
          onCompleted: (data) => {
            handleCompleted(data, view.data);
          },
        });
      }
    }
  }, [dateRange, view]);

  useEffect(() => {
    const synchedView = observationChartDateFilters.find(
      (item) => item.data === synchedChartDateFilter
    );
    if (synchedView) {
      handleOptionChange(synchedView);
    }
  }, [synchedChartDateFilter]);

  return (
    <Card id="observation-type-line-charts" height="100%">
      <Stack id="historical-readings" space="s4">
        <Stack
          id="historical-readings-heading"
          space="none"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <SubHeader>{title}</SubHeader>
          <ObservationTypeLineChartFilter
            activeFilter={view}
            setActiveFilter={setView}
            handleOptionChange={handleOptionChange}
            previousFilter={prevView}
          />
        </Stack>
        {lastReview && (
          <Wrapper data-testid="last-reviewed-content">
            Last reviewed:{' '}
            <Timestamp
              date={lastReview.date}
              type="dateTimeWithName"
              name={lastReview.reviewerName}
              as="span"
            />
          </Wrapper>
        )}
        {!loading && chartData.every((item) => item.hits === 0) && (
          <Notification
            type="softWarning"
            data-testid="notification-no-readings-chart"
          >
            No readings have been submitted within the selected time frame.
          </Notification>
        )}
        <>
          {(view.data === DateFilter.Monthly ||
            customFilter?.dateFilter === DateFilter.Monthly) &&
            !loading && (
              <Notification type="informative">
                When viewing <strong>over a month of trends</strong>, hovering
                over the graph will display the{' '}
                <strong>total daily averages</strong>.
              </Notification>
            )}
          {loading && (
            <ObservationTypeLineChartSkeleton
              count={observationTypes.length}
              dateFilter={customFilter?.dateFilter ?? view.data}
            />
          )}
          {!loading &&
            chartData.map((item: ObservationTypeData) => (
              <Fragment key={item.name}>
                {showLineChartHeading && (
                  <LineChartHeading
                    unit={item.unit}
                    title={item.name}
                    observationType={item.type}
                  />
                )}
                <ObservationTypeLineChartNavigation
                  title={item.name}
                  showNext={showNext}
                  showPrev={showPrev}
                  handleNavigation={handleNavigation}
                >
                  <LineChart
                    xAxisLabel={xAxisLabel}
                    view={view}
                    width={chartWidth}
                    dateRange={dateRange!}
                    chartData={item.data}
                    observationType={item.type}
                    customFilter={customFilter}
                  />
                </ObservationTypeLineChartNavigation>
              </Fragment>
            ))}
        </>
      </Stack>
    </Card>
  );
};
