import {
  Card,
  ErrorNotification,
  SubHeader,
  toHyphenCase,
} from '@bt-healthcare/ui-toolkit';
import { useEffect, useState } from 'react';
import type { PatientHealthInformationProps } from './types';
import { SubHeaderWrapper } from './styles';
import { patientHealthInformationList } from './utils';
import type { ClinicianPatientLatestInformationAttributes } from 'services/graphql';
import { useGetLatestHealthInformationLazyQuery } from 'services/graphql';
import { FETCH_POLICY } from 'App.constants';
import {
  PatientTabWrapper,
  PatientDetailsWrapper,
  PatientDetail,
  LabelText,
  ValueText,
} from 'pages/Patient/styles';
import { PatientViewSkeleton } from 'components/Skeletons/PatientViewSkeleton';

export const PatientHealthInformation = ({
  wardPatientId,
  personId,
}: PatientHealthInformationProps) => {
  const [healthInformation, setHealthInformation] = useState<any>();
  const [getLatestHealthInformation, { loading, error, refetch }] =
    useGetLatestHealthInformationLazyQuery();

  useEffect(() => {
    getLatestHealthInformation({
      variables: {
        wardPatientId,
        personId,
      },
      fetchPolicy: FETCH_POLICY.CACHE_AND_NETWORK,
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        const latestHealthInformation = patientHealthInformationList(
          data.clinicianPatientLatestInformation
            ?.attributes as ClinicianPatientLatestInformationAttributes
        );
        setHealthInformation(latestHealthInformation);
      },
    });
  }, []);

  if (loading)
    return (
      <PatientTabWrapper>
        <Card id="patient-health-information-loading-card">
          <PatientViewSkeleton />
        </Card>
      </PatientTabWrapper>
    );

  if (error)
    return (
      <ErrorNotification
        id="health-information-view"
        action="trying to load health information"
        onTryAgainClick={() => refetch()}
      />
    );

  return (
    <PatientTabWrapper>
      <Card id="patient-health-information-card">
        <SubHeaderWrapper>
          <SubHeader>Health Information</SubHeader>
        </SubHeaderWrapper>
        <PatientDetailsWrapper>
          {healthInformation?.map((item: any) => (
            <PatientDetail
              key={item.label}
              data-testid={toHyphenCase(item.label)}
            >
              <LabelText>{item.label}:</LabelText>
              <ValueText>{item.value}</ValueText>
            </PatientDetail>
          ))}
        </PatientDetailsWrapper>
      </Card>
    </PatientTabWrapper>
  );
};
