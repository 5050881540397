import { colors, Text } from '@bt-healthcare/ui-toolkit';
import { Tooltip as VisxTooltip } from '@visx/tooltip';

import { DateFilter } from '../types';
import type { TooltipProps } from './Tooltip.types';
import { tooltipStyles, TooltipText } from './Tooltip.styles';
import { getTooltipDate, getTooltipValue } from './tooltip.utils';

export const Tooltip = ({
  tooltipOpen,
  tooltipData,
  tooltipLeft,
  tooltipTop,
  dateFilter,
  observationType,
}: TooltipProps) => {
  if (!tooltipOpen) return null;
  return (
    tooltipData && (
      <VisxTooltip
        key={tooltipData.date}
        top={tooltipTop}
        left={tooltipLeft}
        offsetTop={-90}
        offsetLeft={-4}
        style={tooltipStyles}
      >
        <TooltipText primary>
          {dateFilter === DateFilter.Monthly && (
            <Text color={colors.grey.grey08} as="div">
              Daily avg.
            </Text>
          )}
          {getTooltipValue(tooltipData, observationType)}
        </TooltipText>
        <TooltipText>{getTooltipDate(tooltipData, dateFilter)}</TooltipText>
      </VisxTooltip>
    )
  );
};
