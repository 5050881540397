import type {
  ObservationType,
  ObservationsReadingsView,
} from 'services/graphql';

export type ReadingValueBarProps = {
  type: ObservationType;
  readings: ObservationsReadingsView[];
  noReadingsMessage?: string;
  hideTime?: boolean;
};

export enum ObservationTypeValueNames {
  PULSE = 'Pulse',
  SYSTOLIC = 'SystolicBloodPressure',
  DIASTOLIC = 'DiastolicBloodPressure',
  SP02 = 'OxygenSaturation',
}
