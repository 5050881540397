import type { Context } from 'react';
import { useContext } from 'react';

export const useAppContext = <T,>(ctx: Context<T | null>) => {
  const context = useContext(ctx);
  if (!context) {
    throw Error('Context but be wrapped in a Provider');
  }
  return context;
};
