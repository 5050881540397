import {
  SidePanel,
  colors,
  BTHealthIcon,
  spacing,
  Wrapper,
} from '@bt-healthcare/ui-toolkit';
import HealthyYouLogo from 'assets/healthyyou-logo.svg?react';
import { useNavigate } from 'react-router-dom';
import {
  FooterNavItems,
  IconWrapper,
  Label,
  LogoText,
  LogoWrapper,
  NavItem,
  NavItems,
} from '../../styles';
import { navItems } from '../../config';
import { Profile } from '../../Profile';
import { defaultBackgroundColor, defaultColor } from '../../styles.constants';
import type { BurgerMenuProps } from './types';
import { BurgerMenuWrapper, Spacer, ProfileWrapper } from './styles';
import { useLogout } from 'hooks/useLogout';
import { ROUTE } from 'config/routes';
import { UserSettingsFeature } from 'features/userSettings/UserSettingsFeature';

export const BurgerMenu = ({
  navPanelOpen,
  setNavPanelOpen,
  activeRoute,
}: BurgerMenuProps) => {
  const navigate = useNavigate();
  const logout = useLogout();

  const handleItemClick = (link: ROUTE) => {
    setNavPanelOpen(false);
    navigate(link);
  };

  return (
    <>
      <BurgerMenuWrapper
        onClick={() => setNavPanelOpen(true)}
        onKeyUp={() => setNavPanelOpen(true)}
        role="button"
        tabIndex={0}
      >
        <BTHealthIcon icon="Menu" color={colors.grey.grey08} size={20} />
      </BurgerMenuWrapper>

      <SidePanel
        id="navigation-panel"
        open={navPanelOpen}
        setOpen={setNavPanelOpen}
        title={
          <LogoWrapper marginBottom={spacing.none}>
            <HealthyYouLogo />
            <LogoText sideNavOpen>HealthyYou</LogoText>
          </LogoWrapper>
        }
      >
        <Spacer />
        <NavItems>
          {navItems.map((navItem) => (
            <NavItem
              sideNavOpen
              key={navItem.id}
              onClick={() => handleItemClick(navItem.link)}
              active={navItem.link === activeRoute}
            >
              <IconWrapper sideNavOpen active={navItem.link === activeRoute}>
                <BTHealthIcon icon={navItem.icon} size={24} />
              </IconWrapper>
              <Label sideNavOpen active={navItem.link === activeRoute}>
                {navItem.label}
              </Label>
            </NavItem>
          ))}
        </NavItems>

        <FooterNavItems>
          <Wrapper onClick={() => handleItemClick(ROUTE.SETTINGS)}>
            <UserSettingsFeature sideNavOpen />
          </Wrapper>
          <NavItem sideNavOpen onClick={logout}>
            <ProfileWrapper>
              <Profile color={defaultBackgroundColor} fgColor={defaultColor} />
            </ProfileWrapper>

            <Label sideNavOpen>Log out</Label>
          </NavItem>
        </FooterNavItems>
      </SidePanel>
    </>
  );
};
