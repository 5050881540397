import { SidePanel, Text, colors, fontSizes } from '@bt-healthcare/ui-toolkit';
import { AddNote } from '../AddNote';
import { NotesList } from '../NotesList';
import type { NotesPanelProps } from './types';

export const NotesPanel = ({
  wardPatientId,
  open,
  setOpen,
  data,
  error,
  onRequestClose,
}: NotesPanelProps) => (
  <SidePanel
    id="notes-panel"
    open={open}
    setOpen={setOpen}
    handleClose={onRequestClose}
    title={
      <Text color={colors.grey.grey10} fontSize={fontSizes.h4} fontWeight={500}>
        Notes
      </Text>
    }
    maxWidth="347px"
  >
    <AddNote wardPatientId={wardPatientId} />
    <NotesList data={data} error={error} />
  </SidePanel>
);
