import { Modal } from '@bt-healthcare/ui-toolkit';

import type { ReactNode } from 'react';

export type StartAdmissionModalProps = {
  isModalOpen: boolean;
  onClose: () => void;
  children: ReactNode;
  id: string;
};

export const StartAdmissionModal = ({
  children,
  ...rest
}: StartAdmissionModalProps) => (
  <Modal maxWidth="563px" {...rest}>
    {children}
  </Modal>
);
