import { DateFilter } from 'components/Visualisations/types';
import { type ModuleIdentifier } from 'services/graphql';

export const EMPTY_VALUE = '--';

export const appName = 'HealthyYou Clinician';
export const shortAppName = 'hyc';
export const basePageName = ` - ${appName}`;

export const MODULE_ID = 'HEALTHYYOU_CLINICIAN' as ModuleIdentifier;
export const POLL_INTERVAL = 10000; /** 10 seconds */
export const INCLUDING_ADMISSION_DAY = 1;
export const FETCH_POLICY = {
  CACHE_AND_NETWORK: 'cache-and-network',
  NETWORK_ONLY: 'network-only',
} as const;

export const STORAGE_KEYS = {
  PATIENT_FILTER: 'patient_filter',
  PATIENT_SORT: 'patient_sort',
  GUIDELINE_ACCEPTANCE: 'guideline_acceptance',
  FORM_HYPERTENSION_ASSESSMENT: 'form_hypertension_assessment',
  FORM_PATIENT_ADMISSION_PERSONAL: 'form_patient_admission_personal',
  FORM_PATIENT_ADMISSION_MEDICAL: 'form_patient_admission_medical',
  FORM_PATIENT_DETAILS: 'form_patient_details',
};

export const EMPTY_OPTION_VALUE = { label: EMPTY_VALUE, value: '' };

export const QRISK_VERSION = 3;

export const NICE_GUIDELINES_LINK =
  'https://www.nice.org.uk/guidance/ng136/chapter/recommendations#diagnosing-hypertension';

/* Regex matches that defined on the API */
export const REGEX_POST_CODE_UK =
  /^[A-Z]{1,2}[0-9R][0-9A-Z]? \d[ABD-HJLNP-UW-Z]{2}$/;

export const UNKNOWN_VALUE = 'Unknown';

export const DEFAULT_DATE_FILTER = DateFilter.Week;

export const MAX_GENERAL_PRACTICE_FILTERS = 10;
