import {
  differenceInCalendarDays,
  formatISO,
  getDate,
  getMonth,
  getYear,
} from 'date-fns';
import { Maybe } from 'purify-ts';
import { isEmpty, reject } from 'ramda';
import { INCLUDING_ADMISSION_DAY } from '../App.constants';
import type { PatientDetailsFormData } from 'components/PatientDetailsForm/types';
import {
  GenderIdentifiedAs,
  PersonRelationshipType,
  type CareSettingPatient,
  type Person,
  type PersonInput,
  type Scalars,
  type Ward,
  type WardPatient,
} from 'services/graphql';
import { parseDateTime } from 'utils/date.utils';
import { nhsIdFormatter } from 'utils/string.utils';

export const getPatientName = (patient: CareSettingPatient): string =>
  `${patient?.person?.attributes.personalInformation?.firstName} ${patient?.person?.attributes.personalInformation?.surname}`;

export const getPatientFirstAndLastName = (
  patient: CareSettingPatient
): string =>
  `${patient?.person?.attributes.personalInformation?.firstName} ${patient?.person?.attributes.personalInformation?.surname}`;

export const getCareSettingPatient = (patient: WardPatient) =>
  patient.careSettingPatient;

export const getPerson = (careSettingPatient: CareSettingPatient) =>
  careSettingPatient.person;

export const getPersonName = (person: Person) =>
  `${person?.attributes.personalInformation?.surname}, ${person?.attributes.personalInformation?.firstName}`;

export const getBirthDate = (patient: CareSettingPatient): Maybe<Date> => {
  const dob = patient.person?.attributes.personalInformation?.dateOfBirth;
  return Maybe.fromNullable(dob).chain(parseDateTime);
};

export const getNhsId = (patient: CareSettingPatient): Maybe<string> =>
  Maybe.fromNullable(patient.person?.attributes.personalInformation?.nhsNumber);

export const getWardGroupId = (ward: Ward) => ward.attributes.wardGroupId;
export const getWardId = (ward: Ward) => ward.id;
export const getWardPatientId = (wardPatient: WardPatient) => wardPatient.id;

export const dischargeDefaultFormData = ({
  admissionDate,
  dischargeDate = new Date(),
}: {
  admissionDate?: Date;
  dischargeDate?: Date;
}) => ({
  admissionDate: admissionDate && {
    day: getDate(admissionDate),
    month: getMonth(admissionDate) + 1,
    year: getYear(admissionDate),
  },
  dischargeDate: {
    day: getDate(dischargeDate),
    month: getMonth(dischargeDate) + 1,
    year: getYear(dischargeDate),
  },
  lengthOfStayInDays: admissionDate
    ? differenceInCalendarDays(dischargeDate, admissionDate) +
      INCLUDING_ADMISSION_DAY
    : undefined,
});

export const updatePersonInput = ({
  careSettingId,
  data,
  registeredGPSurgery,
}: {
  careSettingId: string;
  data: PatientDetailsFormData;
  registeredGPSurgery: Scalars['String'];
}): PersonInput => {
  const dob = new Date(
    data.yearOfBirth,
    data.monthOfBirth - 1,
    data.dayOfBirth
  );
  const contactParty = [
    {
      humanNames: [
        {
          family: data.nextOfKinSurname ?? '',
          given: data.nextOfKinFirstName ?? '',
        },
      ],
      primaryContactNumber: data.nextOfKinPhoneNumber ?? null,
      personRelationshipType: PersonRelationshipType.NextOfKin,
    },
  ];

  return reject(isEmpty, {
    contactInformation: reject(isEmpty, {
      primaryContactNumber: data.phoneNumber,
      primaryEmailAddress: data.email,
    }),
    personalInformation: reject(isEmpty, {
      firstName: data.firstName?.trim(),
      surname: data.surname?.trim(),
      title: 'Ind',
      nhsNumber: data.nhsNumber
        ? nhsIdFormatter(data.nhsNumber, 'unformat')
        : null,
      dateOfBirth: formatISO(dob, { representation: 'date' }),
      genderAssignedAtBirth: data.genderAssignedAtBirth?.value!,
      genderIdentifiedAs:
        data.genderIdentifiedAs?.value ?? GenderIdentifiedAs.None,
      pronouns: data.pronouns?.value ?? null,
      mobility: data.mobility?.value ?? null,
      ethnicity: data.ethnicity?.value ?? null,
      primaryAddress: reject(isEmpty, {
        line1: data.address,
        postalCode: data.postcode,
      }),
    }),
    contactParty,
    careSettingId,
    registeredGPSurgery,
  });
};
