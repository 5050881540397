import { Card, colors, Stack, toHyphenCase } from '@bt-healthcare/ui-toolkit';

import { DataListItem, CardWrapper } from './styles';
import type { DischargeSummaryProps } from './types';
import { dischargeConfirmationMapping } from './mapping';

export const DischargeSummary = ({ ...props }: DischargeSummaryProps) => {
  // TODO: Move destructured props back above once mappings are aligned and refactored
  const { backgroundColor = colors.primaryIndigo.indigo01 } = props;
  const data = dischargeConfirmationMapping(props);

  return (
    <Stack space="s4" id="confirmation" alignItems="center">
      <CardWrapper>
        <Card background={backgroundColor} id="card">
          {data.map(({ label, value }) => {
            const keyName = toHyphenCase(label);
            return (
              <DataListItem key={`discharge-${keyName}`}>
                <span data-testid={`label-${keyName}`}>{label}:</span>
                <span data-testid={`value-${keyName}`}>{value}</span>
              </DataListItem>
            );
          })}
        </Card>
      </CardWrapper>
    </Stack>
  );
};
