import { colors, fontSizes, spacing } from '@bt-healthcare/ui-toolkit';
import styled from 'styled-components';
import type { ActiveProps } from './types';

export const FilterDropdownWrapper = styled.div<ActiveProps>`
  position: relative;
  min-width: 112px;
  z-index: ${({ active }) => active && 2};

  .dropdown {
    background-color: ${colors.base.white};
    border: 1px solid ${colors.grey.grey05};
    border-radius: 8px;
  }
`;

export const CurrentValue = styled.div`
  background-color: ${colors.primaryIndigo.indigo08};
  color: ${colors.base.white};
  border: 1px solid ${colors.primaryIndigo.indigo08};
  padding: ${spacing.s2} ${spacing.s4};
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: ${spacing.s2};
  font-size: ${fontSizes.sm};
  cursor: pointer;

  span {
    text-align: center;
    width: 100%;
  }
`;

export const DropdownWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
`;

export const DropdownValue = styled.p`
  margin: auto;
  cursor: pointer;
  padding: ${spacing.s1} ${spacing.s4};
  font-size: ${fontSizes.sm};
  display: flex;
  align-items: center;
  gap: ${spacing.s2};

  &:first-child {
    padding-top: ${spacing.s2};
  }

  &:last-child {
    padding-bottom: ${spacing.s2};
  }

  &:hover {
    color: ${colors.primaryIndigo.indigo08};

    svg {
      filter: invert(16%) sepia(79%) saturate(7277%) hue-rotate(266deg)
        brightness(70%) contrast(103%);
    }

    &::after {
      display: block;
    }
  }

  &:first-child {
    border-radius: 8px 8px 0 0;
  }

  &:last-child {
    border-radius: 0 0 8px 8px;
  }
`;

export const Spacer = styled.hr`
  border: 0;
  display: block;
  margin: ${spacing.s1};
`;

export const Divider = styled.hr`
  border: 0;
  display: block;
  margin: ${spacing.s1} ${spacing.s4};
  height: 1px;
  background-color: ${colors.grey.grey05};
`;
