import type { TypedOptionType } from '@bt-healthcare/ui-toolkit';
import {
  Card,
  Col,
  GRID_BREAKPOINT,
  Grid,
  Text,
  colors,
} from '@bt-healthcare/ui-toolkit';
import type { FieldValues, UseFormWatch } from 'react-hook-form';
import { MonitoredConditionFormCardWrapper } from './styles';
import { getObservationTypesForMonitoredConditionOptions } from 'mappings/monitoredConditions.formatter';
import type { PatientDiagnosis } from 'services/graphql';
import { ObservationTypeMap } from 'transforms/observationTypeSearchTransform';

type MonitoredConditionCardProps<T extends FieldValues> = {
  watch: UseFormWatch<T>;
};

export const MonitoredConditionFormCard = <T extends FieldValues>({
  watch,
}: MonitoredConditionCardProps<T>) => {
  const watchValues = watch();
  const monitoredConditions: TypedOptionType<PatientDiagnosis>[] =
    watchValues.monitoredConditions ?? [];

  if (monitoredConditions.length === 0) {
    return null;
  }

  return (
    <MonitoredConditionFormCardWrapper>
      <Card
        id="monitored-conditions-desc-card"
        background={colors.secondaryBlue.blue01}
      >
        <Grid>
          <Col
            col={{
              [GRID_BREAKPOINT.small]: '1 / 9',
              [GRID_BREAKPOINT.large]: '1 / 8',
            }}
          >
            <Text color={colors.grey.grey10}>
              As the patient is being managed for their{' '}
              <Text color={colors.grey.grey10} fontWeight={500}>
                {monitoredConditions.map((item) => item.label).join(', ')}
              </Text>
              , the following vitals will be monitored and reviewed during the
              patients health management process, until the patient is fit to
              self monitor their condition or is discharged.
            </Text>
          </Col>
          <Col
            col={{
              [GRID_BREAKPOINT.small]: '1 / 9',
              [GRID_BREAKPOINT.large]: '9 / 13',
            }}
          >
            <Text color={colors.grey.grey10} fontWeight={500}>
              Vital(s) for monitoring:
            </Text>
            <ul>
              {getObservationTypesForMonitoredConditionOptions(
                monitoredConditions
              )?.map((item) => (
                <li key={item}>
                  <Text color={colors.grey.grey10}>
                    {ObservationTypeMap[item]?.name}
                  </Text>
                </li>
              ))}
            </ul>
          </Col>
        </Grid>
      </Card>
    </MonitoredConditionFormCardWrapper>
  );
};
