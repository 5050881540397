import type { TestStatusChipProps } from '../PatientList.types';
import { sortByCondition } from '../PatientList.util';
import { TestStatusRenderer } from './TestStatusRenderer';
import type { PatientDiagnosis } from 'services/graphql';
import { MonitoringStatusType } from 'services/graphql';
import { EMPTY_VALUE } from 'App.constants';
import { isEmptyOrNil } from 'utils/object.utils';

export const TestStatusChip = ({
  testStatusByCondition,
  monitoringStatusByCondition,
  id,
}: TestStatusChipProps) => {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  if (isEmptyOrNil(testStatusByCondition)) return <>{EMPTY_VALUE}</>;

  const monitoringConditionStatuses = sortByCondition(
    Object.entries(testStatusByCondition).map(([condition, statusType]) => ({
      condition,
      statusType:
        monitoringStatusByCondition[condition as PatientDiagnosis] ===
        MonitoringStatusType.SelfMonitoring
          ? null
          : statusType,
    }))
  );

  return <TestStatusRenderer items={monitoringConditionStatuses} id={id} />;
};
