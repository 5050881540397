import type { SettingsActions, SettingsState } from './types';
import type {
  UserAction,
  UserFormData,
  UsersType,
} from 'components/Settings/types';

export const initSettingsState = {
  user: {} as UsersType,
  userAction: {} as UserAction,
  userFormData: {} as UserFormData,
};

export const settingsReducer: (
  state: SettingsState,
  action: SettingsActions
) => SettingsState = (state, action) => {
  switch (action.type) {
    case 'setUserForm': {
      return {
        ...state,
        userFormData: action.userFormData,
      };
    }
    case 'resetUserForm': {
      return {
        ...state,
        userFormData: initSettingsState.userFormData,
      };
    }
    case 'setUser': {
      return {
        ...state,
        user: action.user,
      };
    }
    case 'resetUser': {
      return {
        ...state,
        user: initSettingsState.user,
      };
    }
    case 'setUserAction': {
      return {
        ...state,
        userAction: action.userAction,
      };
    }
    case 'resetUserAction': {
      return {
        ...state,
        userAction: initSettingsState.userAction,
      };
    }
    default: {
      return state;
    }
  }
};
