import type { MarkerType } from './types';

export const stepType = (index: number, step: number): MarkerType => {
  if (index < step) {
    return 'previous';
  }
  if (index > step) {
    return 'next';
  }
  return 'current';
};
