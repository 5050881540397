import { Text, colors } from '@bt-healthcare/ui-toolkit';
import type { HypertensionAssessmentFormData } from './types';
import { YesNoType } from 'services/graphql';

/** @deprecated use BoldText component */
export const toBold = (text: string) => (
  <Text color={colors.grey.grey10} fontWeight={500}>
    {text}
  </Text>
);

export const isSelfMonitoring = (formData: HypertensionAssessmentFormData) =>
  formData.consultationRequiredYN === YesNoType.No &&
  formData.testsRequiredYN === YesNoType.No &&
  formData.urgentClinicalInterventionRequiredYN === YesNoType.No;
