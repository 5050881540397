import {
  Text,
  colors,
  fontSizes,
  ValueBarList,
  ValueBar,
  Stack,
  toHyphenCase,
} from '@bt-healthcare/ui-toolkit';

import type { ReadingValueBarProps } from './types';
import { ReadingValueBarWrapper } from './styles';
import { valueBarListReadingsConvertor } from './util';
import { notEmpty } from 'utils/array.utils';
import { isEmptyOrNil } from 'utils/object.utils';

export const ReadingValueBar = ({
  type,
  readings,
  noReadingsMessage = 'No readings submitted today',
  hideTime = false,
}: ReadingValueBarProps) => {
  const noReadings = isEmptyOrNil(readings);
  return (
    <ReadingValueBarWrapper
      data-testid={`${toHyphenCase(type)}-reading-value-bar`}
    >
      {noReadings && (
        <Text
          data-testid={`${toHyphenCase(type)}-no-readings`}
          color={colors.grey.grey08}
          fontWeight={400}
          fontSize={fontSizes.sm}
        >
          {noReadingsMessage}
        </Text>
      )}
      <Stack id="value-bar" space="s4">
        {!noReadings &&
          readings
            .map((reading) => valueBarListReadingsConvertor(reading, type))
            .filter(notEmpty)
            .map(({ id, time, values }) => (
              <ValueBarList id={id} time={hideTime ? '' : time} key={id}>
                {values.map(({ id: valueId, min, max, value, label }) => (
                  <ValueBar
                    id={valueId}
                    min={min}
                    max={max}
                    value={value}
                    label={label}
                    key={valueId}
                  />
                ))}
              </ValueBarList>
            ))}
      </Stack>
    </ReadingValueBarWrapper>
  );
};
