import { createGlobalStyle } from 'styled-components';

//*  TODO Review the button style - seems to be only used on the Press for review CTA */

export const GlobalStyles = createGlobalStyle`
#root {
    display: flex;
    flex-direction: column;
    isolation: isolate;
}

button {
    padding: 0;
    border: none;
    background: none;
    cursor: pointer;
}`;
