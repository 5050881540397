import {
  Wrapper,
  Card,
  Checkbox,
  Notification,
} from '@bt-healthcare/ui-toolkit';

import type { UserFormGrantPermissionsProps } from './types';
import { UserFormGrantPermissionsNotificationWrapper } from './styles';
import { ConditionalFieldRenderer } from 'components/Form/ConditionalFieldRenderer';

export const UserFormGrantPermissions = ({
  register,
  control,
}: UserFormGrantPermissionsProps) => (
  <Wrapper>
    <Card id="grant-permission-container" borderRadius={8}>
      <Checkbox
        id="grant-permission"
        position="right"
        label="Grant permission to manage other users?"
        {...register('careSettingAdministrator')}
      />
    </Card>
    <ConditionalFieldRenderer
      control={control}
      fieldName="careSettingAdministrator"
      condition={(arg?: boolean) => arg === true}
    >
      <UserFormGrantPermissionsNotificationWrapper>
        <Notification type="softWarning" alignSelf="baseline">
          By selecting the field above, you are granting this user access to
          manage all user accounts, access to apps and permissions across the
          system.
        </Notification>
      </UserFormGrantPermissionsNotificationWrapper>
    </ConditionalFieldRenderer>
  </Wrapper>
);
