import type { TypedOptionType } from '@bt-healthcare/ui-toolkit';
import { YesNoType, type Maybe } from 'services/graphql';

export const isEnumValue = <T extends string | number>(
  value: T,
  arg?: string
) => arg != null && arg.toUpperCase() === value;

export const isYes = (arg?: string) => isEnumValue(YesNoType.Yes, arg);

export const isNo = (arg?: string) => isEnumValue(YesNoType.No, arg);

export const isOtherSelected = (
  arg: Maybe<TypedOptionType<string>>,
  optionValue = 'OTHER'
) => arg != null && arg.value === optionValue;

export const isOtherSelectedMulti = (
  arg: Maybe<TypedOptionType<string>[]>,
  optionValue = 'OTHER'
) => arg != null && arg.some((item) => item.value === optionValue);

export const isAllNoSelected = (args: any[]) =>
  args.every((item) => isNo(item));
