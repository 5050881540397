import type { TypedOptionType } from '@bt-healthcare/ui-toolkit';

import { MonitoredConditionMap } from './enums';
import type { Maybe } from 'services/graphql';
import { ObservationType, PatientDiagnosis } from 'services/graphql';
import { EMPTY_VALUE } from 'App.constants';
import type { SupportedObservationType } from 'types';

export const formatMonitoredConditions = (
  monitoredConditions: Maybe<Array<Maybe<PatientDiagnosis>>>
) =>
  monitoredConditions
    ?.map((item) => (item == null ? EMPTY_VALUE : MonitoredConditionMap[item]))
    .sort()
    .reverse() ?? [];

const monitoredConditionMap: Record<string, SupportedObservationType[]> = {
  [PatientDiagnosis.Copd]: [ObservationType.Spo2, ObservationType.Pulse],
  [PatientDiagnosis.Hypertension]: [
    ObservationType.BloodPressure,
    ObservationType.Pulse,
  ],
};

export const getObservationTypesForMonitoredConditionOptions = (
  monitoredConditions: TypedOptionType<PatientDiagnosis>[]
) =>
  Array.from(
    new Set(
      monitoredConditions.flatMap((item) => monitoredConditionMap[item.value])
    )
  );

export const getObservationTypesForMonitoredConditions = (
  monitoredConditions: PatientDiagnosis[]
) =>
  Array.from(
    new Set(monitoredConditions.flatMap((item) => monitoredConditionMap[item]))
  );
