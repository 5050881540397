import { format } from 'date-fns';
import { toHyphenCase } from '@bt-healthcare/ui-toolkit';

import { ObservationTypeValueNames } from './types';
import { READING_RANGE } from 'models/wardPatientObservation';
import { ObservationType } from 'services/graphql';
import type {
  Maybe,
  ObservationVisualsValues,
  ObservationsReadingsView,
} from 'services/graphql';

const getObservationValue = (
  observationValues: ObservationVisualsValues,
  observationTypeValueName: ObservationTypeValueNames
) =>
  observationValues.values?.find(
    (item) => item?.name === observationTypeValueName
  )?.value ?? 0;

const getReadingValues = (
  type: ObservationType,
  observationValues?: Maybe<ObservationVisualsValues>
) => {
  if (observationValues == null) return null;
  const formattedType = toHyphenCase(type);
  switch (type) {
    case ObservationType.Pulse:
      return [
        {
          id: `${formattedType}-bpm`,
          value: getObservationValue(
            observationValues,
            ObservationTypeValueNames.PULSE
          ),
          ...READING_RANGE.bpm,
        },
      ];
    case ObservationType.Spo2:
      return [
        {
          id: `${formattedType}-spo2`,
          value: getObservationValue(
            observationValues,
            ObservationTypeValueNames.SP02
          ),
          ...READING_RANGE.oxygenSaturation,
        },
      ];
    case ObservationType.BloodPressure:
      return [
        {
          id: `${formattedType}-systolic`,
          ...READING_RANGE.systolic,
          value: getObservationValue(
            observationValues,
            ObservationTypeValueNames.SYSTOLIC
          ),
          label: 'Systolic',
        },
        {
          id: `${formattedType}-diastolic`,
          ...READING_RANGE.diastolic,
          value: getObservationValue(
            observationValues,
            ObservationTypeValueNames.DIASTOLIC
          ),
          label: 'Diastolic',
        },
      ];
    default:
      return null;
  }
};

export const valueBarListReadingsConvertor = (
  reading: ObservationsReadingsView,
  type: ObservationType
): {
  id: string;
  time: string;
  values: {
    id: string;
    min: number;
    max: number;
    value: number;
    label?: string;
  }[];
} | null => {
  const date = reading.recordedDateTime;
  const values = reading.res_observationValues;
  const common = { id: reading.id, time: format(new Date(date), 'HH:mm') };

  return {
    ...common,
    values: getReadingValues(type, values) ?? [],
  };
};
