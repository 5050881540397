import { GlyphDot } from '@visx/glyph';
import { colors } from '@bt-healthcare/ui-toolkit';

import { Fragment } from 'react';
import { DateFilter } from '../types';
import { seriesChartConfigMap } from './lineChart.config';
import type { DataPointProps } from './LineChart.types';

export const DataPoint = ({
  dateFilter,
  chartData,
  xScale,
  yScale,
  idx,
  parseDate,
  handleTooltip,
  hideTooltip,
  setTooltipValue,
}: DataPointProps) => {
  const handleGlyphStart = (e: any, d: any) => {
    setTooltipValue(d);
    handleTooltip(e);
  };

  const handleGlyphEnd = () => {
    setTooltipValue(null);
    hideTooltip();
  };

  const opacity =
    dateFilter === DateFilter.Monthly && chartData.length > 1 ? 0 : 1;
  return (
    <>
      {chartData.map((d: any, chartIdx: number) => (
        // eslint-disable-next-line react/no-array-index-key
        <Fragment key={chartIdx}>
          <GlyphDot
            cx={xScale(parseDate(d.date))}
            cy={yScale(d.series[idx])}
            r={4}
            fill={colors.base.white}
            stroke={seriesChartConfigMap[idx].glyph}
            strokeWidth={2}
            onTouchStart={(e) => handleGlyphStart(e, d)}
            onTouchMove={(e) => handleGlyphStart(e, d)}
            onMouseOver={(e) => handleGlyphStart(e, d)}
            onMouseLeave={handleGlyphEnd}
            opacity={opacity}
          />
        </Fragment>
      ))}
    </>
  );
};
