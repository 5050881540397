import {
  MonitoredConditionConsultationStatusType,
  MonitoredConditionTestStatusType,
} from 'services/graphql';

export const ConsultationStatusFilterSort = {
  [MonitoredConditionConsultationStatusType.Required]: 2,
  [MonitoredConditionConsultationStatusType.NotRequired]: 1,
  [MonitoredConditionConsultationStatusType.Invited]: 3,
  [MonitoredConditionConsultationStatusType.Booked]: 4,
  [MonitoredConditionConsultationStatusType.Missed]: 5,
  [MonitoredConditionConsultationStatusType.Cancelled]: 6,
  [MonitoredConditionConsultationStatusType.Completed]: 7,
};

export const TestStatusFilterSort = {
  [MonitoredConditionTestStatusType.NotRequired]: 1,
  [MonitoredConditionTestStatusType.Required]: 2,
  [MonitoredConditionTestStatusType.Invited]: 3,
  [MonitoredConditionTestStatusType.Booked]: 4,
  [MonitoredConditionTestStatusType.Missed]: 5,
  [MonitoredConditionTestStatusType.Cancelled]: 6,
  [MonitoredConditionTestStatusType.AwaitingResults]: 7,
  [MonitoredConditionTestStatusType.Overdue]: 8,
  [MonitoredConditionTestStatusType.Returned]: 9,
  [MonitoredConditionTestStatusType.Reviewed]: 10,
};

export const medicalCentreSearchFilter = (data: string[], value: string) => {
  if (!data || value === '') return data;
  return data.filter((item) =>
    item.toLowerCase().includes(value.toLowerCase())
  );
};

export const MAX_MEDICAL_CENTRE_SEARCH = 5;
