import { device, spacing } from '@bt-healthcare/ui-toolkit';
import styled from 'styled-components';

export const NoResultsFoundWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column-reverse;
  width: 90%;
  max-width: 1000px;
  margin: ${spacing.s6} auto;

  svg {
    max-width: 100%;
    height: auto;
  }

  @media ${device.largeTablet} {
    flex-direction: row;
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.s4};
  text-align: center;

  @media ${device.largeTablet} {
    text-align: left;
  }
`;
