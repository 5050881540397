import type { NormalizedCacheObject } from '@apollo/client';
import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  from,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { useAuth0 } from '@auth0/auth0-react';
import type { PropsWithChildren } from 'react';
import { useRef } from 'react';

import { errorLink } from './links/error';
import { retryLink } from './links/retry';
import { typePolicies } from './typePolicies';
import { httpLink } from './links/http';
import { getHttpHeaders } from 'services/httpHeaders';

export const AuthApolloProvider = ({ children }: PropsWithChildren<{}>) => {
  const { getIdTokenClaims, logout, getAccessTokenSilently } = useAuth0();

  const client = useRef<ApolloClient<NormalizedCacheObject>>();

  const authLink = setContext(async (_, { headers, ...rest }) => {
    let token;
    try {
      await getAccessTokenSilently(); // use refreshToken if needed
      token = await getIdTokenClaims();
    } catch (error) {
      console.error(error);
    }

    const customHttpHeaders = getHttpHeaders();
    const requestHttpHeaders = { ...headers, ...customHttpHeaders };
    if (!token) return { requestHttpHeaders, ...rest };

    return {
      ...rest,
      headers: {
        ...requestHttpHeaders,
        // eslint-disable-next-line no-underscore-dangle
        authorization: `Bearer ${token.__raw}`,
      },
    };
  });

  if (!client.current) {
    client.current = new ApolloClient({
      link: from([
        errorLink({
          logout: () => {
            client.current?.clearStore();
            logout({ returnTo: window.location.origin });
          },
        }),
        retryLink,
        authLink,
        httpLink,
      ]),
      cache: new InMemoryCache({ typePolicies }),
    });
  }

  return <ApolloProvider client={client.current}>{children}</ApolloProvider>;
};
