import {
  DatePicker,
  Wrapper,
  Button,
  Text,
  colors,
  Divider,
  Input,
  fontSizes,
} from '@bt-healthcare/ui-toolkit';
import { format } from 'date-fns';
import type { DateRangePickerProps } from './types';
import { DatePickerWrapper, TextWrapper, ControlsWrapper } from './styles';

export const MultiDateRangePicker = ({
  handleChange,
  handleApply,
  startDate,
  endDate,
  datePickerOpen,
}: DateRangePickerProps) => {
  if (!datePickerOpen) return null;

  return (
    <DatePickerWrapper>
      <DatePicker
        label=""
        selected={startDate}
        startDate={startDate}
        endDate={endDate}
        name="customGraphFilterDatePicker"
        id="customGraphFilterDatePicker"
        dateFormat="dd MMMM yyyy"
        selectsRange
        shouldCloseOnSelect={false}
        isCalendarOpen
        customInput={<Wrapper />}
        monthsShown={2}
        onChange={handleChange}
        maxDate={new Date()}
        disabledKeyboardNavigation
        showPreviousMonthsDays={false}
      >
        <Wrapper>
          <Divider flexColor="grey.grey03" />
          <TextWrapper>
            <Text color={colors.grey.grey10} fontSize={fontSizes.sm}>
              Custom range
            </Text>
          </TextWrapper>
          <ControlsWrapper>
            <Input
              readOnly
              value={startDate ? format(startDate, 'dd/MM/yyyy') : ''}
              placeholder="From"
              id="startDate"
            />
            <Input
              readOnly
              value={endDate ? format(endDate, 'dd/MM/yyyy') : ''}
              placeholder="To"
              id="endDate"
            />
            <Button
              id="apply-custom-multi-date-range-btn"
              onClick={handleApply}
              disabled={!startDate || !endDate}
            >
              <Text color={colors.base.white} fontSize={fontSizes.base}>
                Apply
              </Text>
            </Button>
          </ControlsWrapper>
        </Wrapper>
      </DatePicker>
    </DatePickerWrapper>
  );
};
