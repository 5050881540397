import {
  colors,
  fontSizes,
  Spacer,
  Text,
  toHyphenCase,
} from '@bt-healthcare/ui-toolkit';
import { differenceInCalendarDays } from 'date-fns';

import { AvgValueBar } from '../AvgValueBar';
import { Content, Note } from './styles';
import type { AvgReadingContentProps } from './types';
import { AvgReadingDateRange } from './AvgReadingDateRange';
import {
  getAverageDaysText,
  getAverageText,
  isSingular,
} from './AvgReadingContent.utils';
import { isEmptyOrNil } from 'utils/object.utils';
import { DateFilter } from 'components/Visualisations/types';

export const AvgReadingContent = ({
  readingsData,
  dateRange,
  name,
  observationType,
  dateFilter,
  isNavigation = false,
}: AvgReadingContentProps) => {
  const hasNoReadings =
    isEmptyOrNil(readingsData) ||
    readingsData!.observationsAbsoluteAverage!.attributes.totalNoOfReadings ===
      0;
  const isOneDayReading =
    readingsData?.observationsAbsoluteAverage?.attributes.totalNoOfDays === 1 &&
    differenceInCalendarDays(dateRange.endDate, dateRange.startDate) <= 1;

  const isCustom = dateFilter === DateFilter.Custom || isNavigation;
  const isOneReading =
    readingsData?.observationsAbsoluteAverage?.attributes.totalNoOfReadings ===
    1;

  return (
    <>
      <AvgReadingDateRange
        isCustom={isCustom}
        isOneDayReading={
          differenceInCalendarDays(dateRange.endDate, dateRange.startDate) <= 1
        }
        dateRange={dateRange}
      />
      <Spacer size="s6" />
      <Content>
        {hasNoReadings ? (
          <Text
            data-testid={`${name}-empty-readings`}
            color={colors.grey.grey08}
            fontWeight={400}
            fontSize={fontSizes.sm}
          >
            No readings submitted over this time period
          </Text>
        ) : (
          <>
            <AvgValueBar
              observationTypeData={readingsData!}
              name={name}
              emptyMessage=""
              observationType={observationType}
            />
            <Note data-testid={`average-readings-${toHyphenCase(name)}-note`}>
              Average {name} is based on{' '}
              <Text color={colors.grey.grey08} fontWeight={500}>
                {
                  readingsData!.observationsAbsoluteAverage!.attributes
                    .totalNoOfReadings
                }{' '}
                reading{isSingular(isOneReading)}
              </Text>{' '}
              {getAverageText(isOneDayReading, dateFilter, isCustom)}{' '}
              {getAverageDaysText(
                dateFilter,
                readingsData,
                isOneDayReading,
                isNavigation
              )}{' '}
            </Note>
          </>
        )}
      </Content>
    </>
  );
};
