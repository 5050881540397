import { StatusChip, Wrapper, toHyphenCase } from '@bt-healthcare/ui-toolkit';

import { MonitoredConditionRendererWrapper } from '../PatientList.styles';
import type { MonitoringStatusRendererProps } from '../PatientList.types';
import { MonitoringStatusTypeColorMap } from '../PatientList.util';
import { MonitoringStatusMap } from 'mappings/enums';

export const MonitoringStatusRenderer = ({
  items,
  id,
}: MonitoringStatusRendererProps) => (
  <MonitoredConditionRendererWrapper>
    {items.map((item) => (
      <Wrapper key={item.condition}>
        <StatusChip
          id={`${id}-${toHyphenCase(item.condition)}-monitoring`}
          chipColor={MonitoringStatusTypeColorMap[item.statusType!]}
        >
          {MonitoringStatusMap[item.statusType!]}
        </StatusChip>
      </Wrapper>
    ))}
  </MonitoredConditionRendererWrapper>
);
