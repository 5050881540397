import * as yup from 'yup';
import type { HypertensionAssessmentFormData } from './types';
import type { FormKey } from 'components/Form/types';
import {
  isRequiredDropDownListMultiOption,
  isRequiredDropDownListOption,
  isRequiredNullable,
  isRequiredWhenNo,
  isRequiredWhenSelection,
  isRequiredWhenYes,
  isWithinNumberRange,
} from 'validation/yup/schema.helpers';
import { READING_RANGE } from 'models/wardPatientObservation';
import {
  ConsultationTimeframe,
  GuidelineDeclinedReason,
  MedicationOutcome,
} from 'services/graphql';
import { validateBloodPressureValue } from 'validation/validation.helpers';

const hypertensionAssessmentRules = {
  assessmentType: isRequiredDropDownListOption(),
  assessmentStartDate: isRequiredNullable(yup.string()),
  averageDiastolic: isWithinNumberRange(
    READING_RANGE.diastolic.max,
    READING_RANGE.diastolic.min
  ).test({
    name: 'averageSystolic',
    test(value) {
      const averageSystolicValue = this.resolve(yup.ref('averageSystolic'));
      return validateBloodPressureValue(value, averageSystolicValue, true);
    },
    message: 'Please enter a value less than the average systolic value.',
  }),
  averageSystolic: isWithinNumberRange(
    READING_RANGE.systolic.max,
    READING_RANGE.systolic.min
  ).test({
    name: 'averageDiastolic',
    test(value) {
      const averageDiastolicValue = this.resolve(yup.ref('averageDiastolic'));
      return validateBloodPressureValue(value, averageDiastolicValue);
    },
    message: 'Please enter a value greater than the average diastolic value.',
  }),
  totalDays: isWithinNumberRange(),
  totalReadings: isWithinNumberRange(),
  conditionTestTypes: isRequiredWhenYes({
    field: 'testsRequiredYN',
    fn: () => isRequiredDropDownListMultiOption(),
    yupSchema: yup.array(),
  }),
  conditionTestTypeOther: isRequiredWhenSelection({
    field: 'conditionTestTypes',
    value: 'OTHER',
  }),
  reasonForNoUrgentClinicalIntervention: isRequiredWhenNo({
    field: 'urgentClinicalInterventionTakenPlaceYN',
  }),
  consultationTimeframe: isRequiredWhenYes({
    field: 'consultationRequiredYN',
    fn: () => isRequiredDropDownListOption(),
    yupSchema: yup.object(),
  }),
  customConsultationTimeframe: isRequiredWhenSelection({
    field: 'consultationTimeframe',
    value: ConsultationTimeframe.Other,
  }),
  qriskScore: yup
    .number()
    .transform((val, orig) => (orig === '' ? null : val))
    .nullable()
    .test(
      'qriskScoreValidation',
      'Value must have 2 digits after the decimal or less and be between 1 and 100.',
      (number) => {
        if (number == null) return true;
        return (
          number != null &&
          number >= 1 &&
          number <= 100 &&
          /^\d+(\.\d{1,2})?$/.test(number.toString())
        );
      }
    ),
  stoppedMedicationOutcomeComments: isRequiredWhenSelection({
    field: 'medicationOutcome',
    value: MedicationOutcome.Stopped,
  }),
  guidelineRecommendationDecisions: yup.array().of(
    yup.object().shape({
      recommendationDeclinedReason: isRequiredWhenNo({
        field: 'guidelineAccepted',
        fn: () => isRequiredDropDownListOption(),
        yupSchema: yup.object(),
      }),
      otherRecommendationDeclinedReasonComments: isRequiredWhenSelection({
        field: 'recommendationDeclinedReason',
        value: GuidelineDeclinedReason.Other,
      }),
    })
  ),
} as { [key in FormKey<HypertensionAssessmentFormData>]: any };

export const hypertensionAssessmentSchema = yup.object(
  hypertensionAssessmentRules
);
