import { spacing, colors, fontSizes } from '@bt-healthcare/ui-toolkit';
import { css } from 'styled-components';

export const datePickerStyles = css`
  .react-datepicker {
    padding: ${spacing.s6};
  }

  .react-datepicker__day:hover {
    background-color: ${colors.primaryIndigo.indigo08};
    color: ${colors.base.white};
  }

  .react-datepicker__day--range-start,
  .react-datepicker__day--range-end {
    background-color: ${colors.primaryIndigo.indigo08};
    color: ${colors.base.white};
  }

  .react-datepicker__day--in-selecting-range:not(
      .react-datepicker__day--in-range,
      .react-datepicker__month-text--in-range,
      .react-datepicker__quarter-text--in-range,
      .react-datepicker__year-text--in-range
    ) {
    background-color: ${colors.primaryIndigo.indigo01};
    color: ${colors.primaryIndigo.indigo08};
  }

  .react-datepicker__day--selecting-range-start,
  .react-datepicker__day--selecting-range-end {
    background-color: ${colors.primaryIndigo.indigo08} !important;
    color: ${colors.base.white} !important;
  }

  .react-datepicker__navigation--previous,
  .react-datepicker__navigation--next {
    top: ${spacing.s7};
    color: ${colors.primaryIndigo.indigo08};
  }

  .react-datepicker__navigation--previous {
    left: ${spacing.s6};
  }

  .react-datepicker__navigation--next {
    right: ${spacing.s6};
  }

  .react-datepicker__header {
    > div:first-child {
      margin-bottom: ${spacing.s3};
      color: ${colors.grey.grey10};
      font-size: ${fontSizes.sm};
      font-weight: 500;
    }
  }

  .react-datepicker__header {
    background: ${colors.base.white};
    border-bottom: 0;
  }

  .react-datepicker__day-name {
    color: ${colors.grey.grey08};
  }
`;
