import type { UseFormSetValue } from 'react-hook-form';
import type { Dispatch, MutableRefObject, SetStateAction } from 'react';
import { useEffect } from 'react';

import type { TypedOptionType } from '@bt-healthcare/ui-toolkit';
import type {
  HypertensionAssessmentFormData,
  HypertensionConsultationRecommendedFormData,
} from 'components/HypertensionAssessmentForm/types';
import {
  AssessmentType,
  useGetHypertensionGuidelineApplicabilitySearchResultLazyQuery,
} from 'services/graphql';
import { FETCH_POLICY } from 'App.constants';
import { isEmptyOrNil } from 'utils/object.utils';
import { validateRecommendationFieldValues } from 'pages/Assessment/utils';
import { usePatientDispatch } from 'context/patient/PatientContext';

export const useHypertensionRecommendation = (
  assessmentType: TypedOptionType<AssessmentType>,
  consultationRecommendedFields: HypertensionConsultationRecommendedFormData,
  setValue: UseFormSetValue<HypertensionAssessmentFormData>,
  recommendationTriggeredRef: MutableRefObject<boolean>,
  setHasChanged: Dispatch<SetStateAction<boolean>>,
  hasChanged = false
) => {
  const { averageDiastolic, averageSystolic, totalDays, totalReadings } =
    consultationRecommendedFields;
  const patientDispatch = usePatientDispatch();
  const [
    getHypertensionGuidelineApplicabilitySearchResult,
    { loading, error, refetch },
  ] = useGetHypertensionGuidelineApplicabilitySearchResultLazyQuery();

  const getHypertensionGuideline = () =>
    getHypertensionGuidelineApplicabilitySearchResult({
      fetchPolicy: FETCH_POLICY.CACHE_AND_NETWORK,
      notifyOnNetworkStatusChange: true,
      variables: {
        input: {
          averageDiastolicValue: averageDiastolic,
          averageSystolicValue: averageSystolic,
          totalNoOfDays: totalDays,
          totalNoOfReadings: totalReadings,
        },
      },
      onCompleted(data) {
        setHasChanged(false);
        const recommendedGuidelinesApplied =
          data.hypertensionGuidelineApplicabilitySearchResult.attributes.guidelineApplicabilityResult?.applicableGuidelines?.filter(
            (item) => item?.guideline != null && item.guidelineState
          );
        if (!isEmptyOrNil(recommendedGuidelinesApplied)) {
          const recommendedGuidelines = recommendedGuidelinesApplied!.map(
            (item) => item!.guideline!
          );
          const guidelineAcceptances = recommendedGuidelines.map((item) => ({
            accepted: null,
            guidelineType: item,
          }));
          patientDispatch({
            type: 'setGuidelineAcceptance',
            guidelineAcceptances,
          });
        } else {
          patientDispatch({ type: 'resetGuidelineAcceptance' });
          setValue('guidelineRecommendationDecisions', []);
        }
      },
    });

  useEffect(() => {
    if (
      hasChanged ||
      (!recommendationTriggeredRef.current &&
        assessmentType?.value === AssessmentType.Consultation &&
        validateRecommendationFieldValues(
          averageDiastolic,
          averageSystolic,
          totalDays,
          totalReadings
        ))
    ) {
      // eslint-disable-next-line no-param-reassign
      recommendationTriggeredRef.current = true;
      getHypertensionGuideline();
    }
  }, [
    averageDiastolic,
    averageSystolic,
    totalDays,
    totalReadings,
    assessmentType,
    hasChanged,
  ]);
  return {
    loading,
    error,
    refetch,
  };
};
