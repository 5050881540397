import {
  ErrorNotification,
  Spacer,
  SpinnerLoader,
  useScrollToTop,
} from '@bt-healthcare/ui-toolkit';
import { useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { isEmpty } from 'ramda';

import { useTracking } from 'hooks/useTracking';
import { FETCH_POLICY } from 'App.constants';
import { useGetCareSettingModulesQuery } from 'services/graphql';
import { useApp } from 'context/app/AppContext';
import {
  useSettings,
  useSettingsDispatch,
} from 'context/settings/SettingsContext';
import { ROUTE } from 'config/routes';
import { UserForm } from 'components/Settings/UserForm';
import type { UserFormData } from 'components/Settings/types';
import { userSchema } from 'components/Settings/validation';
import {
  getDefaultValues,
  getRegistrationFormTracking,
} from 'components/Settings/settings.utils';
import { FormWrapper } from 'components/Form/styles';
import { FormFooter } from 'components/FormFooter';
import { useLeavePageModal } from 'hooks/useLeavePageModal';

export const UserManagementForm = () => {
  useScrollToTop();
  const { trackPage } = useTracking();
  const navigate = useNavigate();
  const location = useLocation();

  const { careSetting } = useApp();
  const { user, userAction, userFormData } = useSettings();
  const settingsDispatch = useSettingsDispatch();

  const isEdit = !isEmpty(user) && userAction.userAction === 'edit';
  const isReactivate = !isEmpty(user) && userAction.userAction === 'activate';

  const defaultValues = !isEmpty(userFormData)
    ? userFormData
    : getDefaultValues(isEdit, isReactivate, user);
  const tracking = getRegistrationFormTracking(isEdit, isReactivate);

  const { data, loading, error, refetch } = useGetCareSettingModulesQuery({
    variables: {
      careSettingId: careSetting.id!,
    },
    fetchPolicy: FETCH_POLICY.CACHE_AND_NETWORK,
    notifyOnNetworkStatusChange: true,
  });

  const { register, control, getValues, formState } = useForm<UserFormData>({
    defaultValues,
    mode: 'onChange',
    resolver: yupResolver(userSchema),
  });
  const { isValid, errors: formErrors, isDirty } = formState;
  const { formDirtied, setFormDirtied } = useLeavePageModal();

  useEffect(() => {
    trackPage(tracking);
  }, []);

  useEffect(() => {
    if (isDirty) {
      if (!formDirtied) {
        setFormDirtied();
      }
    }
  }, [isDirty, formDirtied]);

  if (loading)
    return <SpinnerLoader id="content-loader" data-testid="content-loader" />;

  if (error) {
    return (
      <ErrorNotification
        id="care-setting-modules"
        action="returning modules associated with the care setting"
        onTryAgainClick={() => refetch()}
      />
    );
  }
  return (
    <FormWrapper>
      <UserForm
        register={register}
        control={control}
        isEdit={isEdit || isReactivate}
        modules={data!}
        errors={formErrors}
      />
      <FormFooter
        primaryButtonClick={() => {
          const formData = getValues();
          settingsDispatch({ type: 'setUserForm', userFormData: formData });
          navigate(ROUTE.SETTINGS_USER_CONFIRMED, {
            state: { from: location },
          });
        }}
        cancelButtonClick={() => navigate(ROUTE.SETTINGS_USER)}
        primaryButtonDisabled={!isValid}
      />
      <Spacer size="l4" />
    </FormWrapper>
  );
};
