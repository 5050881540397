import type { Dispatch } from 'react';
import { createContext } from 'react';

import { useAppContext } from '../useAppContext';
import type { PatientActions, PatientState } from 'reducers/patient/types';

export const PatientContext = createContext<PatientState | null>(null);
export const PatientDispatchContext =
  createContext<Dispatch<PatientActions> | null>(null);

export const usePatient = () => useAppContext(PatientContext);
export const usePatientDispatch = () => useAppContext(PatientDispatchContext);
