import {
  BTHealthIcon,
  Text,
  colors,
  fontSizes,
} from '@bt-healthcare/ui-toolkit';
import { BackButtonWrapper } from './styles';
import type { BackButtonProps } from './types';

export const BackButton = ({ handleBackButton }: BackButtonProps) => (
  <BackButtonWrapper onClick={handleBackButton} data-testid="back-button">
    <BTHealthIcon icon="ChevronLeft" color={colors.grey.grey10} size={12} />
    <Text color={colors.grey.grey10} fontSize={fontSizes.sm}>
      Back
    </Text>
  </BackButtonWrapper>
);
