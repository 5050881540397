import type { TypedOptionType } from '@bt-healthcare/ui-toolkit';
import { Card, ErrorNotification } from '@bt-healthcare/ui-toolkit';
import { useEffect, useState } from 'react';

import { isEmpty } from 'ramda';
import { defaultOption, options } from '../options';
import type { AvgBloodPressureProps } from './types';
import { ReadingHeader } from './ReadingHeader';
import { AvgReadingContent } from './AvgReadingContent';
import { loadObservationAbsoluteAverages } from './AvgBloodPressure.utils';
import type { GetObservationAbsoluteAverageQuery } from 'services/graphql';
import {
  ObservationType,
  useGetObservationAbsoluteAverageLazyQuery,
} from 'services/graphql';
import { DateFilter } from 'components/Visualisations/types';
import { AvgBloodPressureSkeleton } from 'components/Skeletons/AvgBloodPressureSkeleton';
import type { DateRange } from 'components/Visualisations/LineChart/LineChart.types';
import { getDateRange } from 'utils/dateFilter.utils';
import { usePatient, usePatientDispatch } from 'context/patient/PatientContext';

export const AvgBloodPressure = ({
  personId,
  admissionDateTime,
  hasSubmittedReadings,
}: AvgBloodPressureProps) => {
  const patientDispatch = usePatientDispatch();
  const { synchedChartDateFilter, synchedChartDateRange } = usePatient();

  const [filter, setFilter] = useState(defaultOption);
  const [dateRange, setDateRange] = useState(
    getDateRange(defaultOption.value, admissionDateTime)
  );

  const [readingsData, setReadingsData] =
    useState<GetObservationAbsoluteAverageQuery>();

  const handleCompleted = (data: GetObservationAbsoluteAverageQuery) => {
    setReadingsData(data);
  };

  const [getObservationAbsoluteAverages, { loading, refetch, error }] =
    useGetObservationAbsoluteAverageLazyQuery();

  useEffect(() => {
    if (dateRange == null) {
      setReadingsData(undefined);
    } else if (hasSubmittedReadings) {
      loadObservationAbsoluteAverages(
        getObservationAbsoluteAverages,
        dateRange,
        personId,
        handleCompleted
      );
    }
  }, []);

  const handleDropdownItemClick = (
    option: TypedOptionType<DateFilter>,
    customDates?: DateRange
  ) => {
    setFilter(option);
    patientDispatch({ type: 'resetChartDateRange' });
    const readingsDateRange = getDateRange(option.value, admissionDateTime);
    const range = readingsDateRange || customDates;
    if (range !== undefined) {
      setDateRange(range);
      if (option.value !== DateFilter.Custom) {
        patientDispatch({
          type: 'setChartDateFilter',
          synchedDateFilter: option.value,
        });
      }

      if (hasSubmittedReadings) {
        loadObservationAbsoluteAverages(
          getObservationAbsoluteAverages,
          range,
          personId,
          handleCompleted
        );
      }
    }
  };

  useEffect(() => {
    const synchedView = options.find(
      (item) => item.value === synchedChartDateFilter
    );
    if (synchedView) {
      handleDropdownItemClick(synchedView);
    }
  }, [synchedChartDateFilter]);

  useEffect(() => {
    if (!isEmpty(synchedChartDateRange)) {
      setDateRange(synchedChartDateRange.range);
      if (hasSubmittedReadings) {
        loadObservationAbsoluteAverages(
          getObservationAbsoluteAverages,
          synchedChartDateRange.range,
          personId,
          handleCompleted
        );
      }
    }
  }, [synchedChartDateRange]);

  const filterType = 'avg-blood-pressure';

  if (error)
    return (
      <ErrorNotification
        id={filterType}
        onTryAgainClick={() => refetch()}
        action="loading average blood pressure"
      />
    );

  return (
    <Card id={`${filterType}-card`}>
      <ReadingHeader
        title="Avg. Blood Pressure"
        icon="BloodPressure"
        handleFilterDropdownClick={handleDropdownItemClick}
        filterType={filterType}
      />
      {hasSubmittedReadings && (loading || readingsData == null) ? (
        <AvgBloodPressureSkeleton />
      ) : (
        <AvgReadingContent
          name="BP"
          readingsData={readingsData ?? null}
          dateRange={dateRange!}
          observationType={ObservationType.BloodPressure}
          dateFilter={filter.value}
          isNavigation={synchedChartDateRange.isNavigation}
        />
      )}
    </Card>
  );
};
