export const validateBloodPressureValue = (
  value: any,
  averageBpValue: any,
  isLessThan = false
) => {
  if (Number.isInteger(averageBpValue) && Number.isInteger(value)) {
    return isLessThan ? value < +averageBpValue : value > +averageBpValue;
  }
  return true;
};
