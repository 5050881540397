import { Checkbox, toHyphenCase } from '@bt-healthcare/ui-toolkit';

import { map, dissoc, sort, ascend, prop } from 'ramda';
import { CheckboxWrapper } from './styles';
import type { PatientStatusFiltersProps } from './types';
import { ConsultationStatusFilterSort } from './config';
import { ConsultationStatusMap } from 'mappings/enums';
import type { MonitoredConditionConsultationStatusType } from 'services/graphql';
import type { SubFilter } from 'filters/types';

export const ConsultationStatusFilters = ({
  statusFilter,
  handleTargetChecked,
}: PatientStatusFiltersProps) => {
  const consultationStatuses = Object.keys(ConsultationStatusMap).map(
    (item) => ({
      value: item,
      type: 'ConsultationStatus',
      sort: ConsultationStatusFilterSort[
        item as MonitoredConditionConsultationStatusType
      ],
    })
  );

  const statuses = map(
    dissoc('sort'),
    sort(ascend(prop('sort')), consultationStatuses)
  ) as SubFilter[];
  return (
    <CheckboxWrapper data-testid="consultation-status-filters">
      {statuses.map((status) => (
        <Checkbox
          id={`consultation-${toHyphenCase(status.value)}-filter`}
          label={
            ConsultationStatusMap[
              status.value as MonitoredConditionConsultationStatusType
            ]
          }
          position="right"
          key={status.value}
          className="accordion-item-checkbox"
          checked={
            statusFilter &&
            statusFilter.find(
              (item) =>
                item.value === status.value &&
                item.type === 'ConsultationStatus'
            ) !== undefined
          }
          onChange={({ target }) => handleTargetChecked(target, status)}
        />
      ))}
    </CheckboxWrapper>
  );
};
