import { toHyphenCase } from '@bt-healthcare/ui-toolkit';

import { Field } from './Field';
import type { FieldRowProps } from './AdmissionConfirmation.types';

export const ListRow = ({ name, value }: FieldRowProps) => (
  <>
    <Field data-testid={`${toHyphenCase(name)}-name`}>{name}:</Field>
    <Field fieldType="value" data-testid={`${toHyphenCase(name)}-value`}>
      {value}
    </Field>
  </>
);
