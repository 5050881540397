import {
  Wrapper,
  colors,
  device,
  fontSizes,
  spacing,
} from '@bt-healthcare/ui-toolkit';
import styled from 'styled-components';

export const Title = styled.p`
  color: ${colors.grey.grey10};
  font-size: ${fontSizes.base};
  font-weight: 500;
  padding-bottom: ${spacing.s2};
`;

export const Grid = styled(Wrapper)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: ${spacing.s4};
  padding-top: ${spacing.s4};

  @media ${device.largeTablet} {
    grid-template-columns: repeat(3, 1fr);
  }
`;

export const GridItem = styled(Wrapper)`
  display: flex;
  flex-direction: column;
  gap: ${spacing.s1};

  span {
    word-break: break-all;
  }
`;
