import { Grid, Col, GRID_BREAKPOINT } from '@bt-healthcare/ui-toolkit';

import { useState } from 'react';

import { HypertensionAssessmentDeclineRecommendation } from './HypertensionAssessmentDeclineRecommendation';
import { HypertensionAssessmentRecommendationText } from './HypertensionAssessmentRecommendationText';
import { RecommendationAcceptanceNotification } from './Notifications/RecommendationAcceptanceNotification';
import type { HypertensionAssessmentRecommendationProps } from './types';
import type { GuidelineType } from 'services/graphql';
import { YesNoType } from 'services/graphql';
import { RecommendationCard } from 'components/Card/RecommendationCard';
import { DeclineRecommendation } from 'components/Modal/DeclineRecommendation';
import { useAssessmentTracking } from 'hooks/useAssessmentTracking';
import { usePatientDispatch } from 'context/patient/PatientContext';

export const HypertensionAssessmentRecommendation = ({
  hypertensionCarePlanRef,
  guidelineAcceptance,
  idx,
  formData,
  control,
  register,
  errors,
  setValue,
  resetField,
  isValid,
}: HypertensionAssessmentRecommendationProps) => {
  const { trackTappedAssessment } = useAssessmentTracking();

  const [declineRecommendationModalOpen, setDeclineRecommendationModalOpen] =
    useState(false);
  const patientDispatch = usePatientDispatch();

  const handleAcceptRecommendation = (guidelineType: GuidelineType) => {
    trackTappedAssessment('assessment/hypertension/accept_recommendation');
    setValue(
      `guidelineRecommendationDecisions.${idx}.guidelineAccepted`,
      YesNoType.Yes
    );
    setValue(
      `guidelineRecommendationDecisions.${idx}.guidelineRecommended`,
      guidelineType
    );
    patientDispatch({
      type: 'updateGuidelineAcceptance',
      guidelineAcceptance: {
        accepted: true,
        guidelineType,
      },
    });
  };

  const handleCloseRecommendation = () => {
    setValue(`guidelineRecommendationDecisions.${idx}.guidelineAccepted`, null);
    setDeclineRecommendationModalOpen(false);
    resetField(
      `guidelineRecommendationDecisions.${idx}.recommendationDeclinedReason`
    );
    resetField(
      `guidelineRecommendationDecisions.${idx}.otherRecommendationDeclinedReasonComments`
    );
  };

  const handleDecline = () => {
    trackTappedAssessment('assessment/hypertension/decline_recommendation');
    setValue(
      `guidelineRecommendationDecisions.${idx}.guidelineAccepted`,
      YesNoType.No
    );
    setDeclineRecommendationModalOpen(true);
  };

  const handleDeclineRecommendation = (guidelineType: GuidelineType) => {
    setDeclineRecommendationModalOpen(false);
    setValue(
      `guidelineRecommendationDecisions.${idx}.guidelineRecommended`,
      guidelineType
    );
    patientDispatch({
      type: 'updateGuidelineAcceptance',
      guidelineAcceptance: {
        accepted: false,
        guidelineType,
      },
    });
  };

  const guidelineAccepted = (accepted: boolean) =>
    accepted ? YesNoType.Yes : YesNoType.No;

  return (
    <>
      <Grid
        ref={hypertensionCarePlanRef}
        data-testid="hypertension-assessment-recommendation"
      >
        <Col
          col={{
            [GRID_BREAKPOINT.large]: '1 / 14',
          }}
        >
          <HypertensionAssessmentRecommendationText />
        </Col>
        <Col
          col={{
            [GRID_BREAKPOINT.large]: '1 / 14',
          }}
        >
          {guidelineAcceptance.accepted == null ? (
            <RecommendationCard
              onAccept={handleAcceptRecommendation}
              onDecline={handleDecline}
              guidelineType={guidelineAcceptance.guidelineType}
              idx={idx}
            />
          ) : (
            <RecommendationAcceptanceNotification
              key={guidelineAcceptance.guidelineType}
              guidelineAccepted={guidelineAccepted(
                guidelineAcceptance.accepted
              )}
              guidelineType={guidelineAcceptance.guidelineType}
            />
          )}
        </Col>

        {guidelineAcceptance.accepted === false && (
          <HypertensionAssessmentDeclineRecommendation
            control={control}
            register={register}
            errors={errors}
            formData={formData}
            idx={idx}
            disabled={guidelineAcceptance.accepted === false}
          />
        )}
        <Col
          col={{
            [GRID_BREAKPOINT.large]: '1 / 14',
          }}
        />
      </Grid>
      <DeclineRecommendation
        modalOpen={declineRecommendationModalOpen}
        guidelineType={guidelineAcceptance.guidelineType}
        control={control}
        errors={errors}
        formData={formData}
        idx={idx}
        onDecline={handleDeclineRecommendation}
        register={register}
        onClose={handleCloseRecommendation}
        isValid={isValid}
      />
    </>
  );
};
