import { Wrapper, spacing } from '@bt-healthcare/ui-toolkit';
import styled from 'styled-components';

export const TextWrapper = styled(Wrapper)`
  display: flex;
  flex-direction: column;
  gap: ${spacing.s2};
  text-align: center;
  margin: ${spacing.s1} ${spacing.none} ${spacing.s4} ${spacing.none};
`;
