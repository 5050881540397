import type {
  Maybe,
  PatientDiagnosis,
  MonitoringStatusType,
  MonitoredConditionConsultationStatusType,
  MonitoredConditionTestStatusType,
} from 'services/graphql';
import { GetWardPatientDocument } from 'services/graphql';

export const getRefetchQueries = (
  wardPatientId: string,
  careSettingId: Maybe<string>
) => [
  {
    query: GetWardPatientDocument,
    variables: {
      wardPatientId,
      careSettingId,
    },
  },
];

export const getMonitoringStatusInputVariables = (
  condition: PatientDiagnosis,
  monitoringStatusType: MonitoringStatusType,
  userId: string,
  version: number,
  wardPatientId: string
) => ({
  input: {
    condition,
    monitoringStatusType,
    userId,
    version,
    wardPatientId,
  },
});

export const getConsultationStatusInputVariables = (
  condition: PatientDiagnosis,
  consultationStatusType: MonitoredConditionConsultationStatusType,
  userId: string,
  version: number,
  wardPatientId: string
) => ({
  input: {
    condition,
    consultationStatusType,
    userId,
    version,
    wardPatientId,
  },
});

export const getTestStatusInputVariables = (
  condition: PatientDiagnosis,
  testStatusType: MonitoredConditionTestStatusType,
  userId: string,
  version: number,
  wardPatientId: string
) => ({
  input: {
    condition,
    testStatusType,
    userId,
    version,
    wardPatientId,
  },
});
