import type { TickFormatter, TickRendererProps } from '@visx/axis';
import { AxisBottom, AxisLeft } from '@visx/axis';
import { scaleTime, scaleLinear } from '@visx/scale';
import { Group } from '@visx/group';
import { timeFormat, utcParse } from 'd3-time-format';
import { GridColumns, GridRows } from '@visx/grid';
import { extent } from 'd3-array';
import { colors, fontSizes, toHyphenCase } from '@bt-healthcare/ui-toolkit';
import type { ScaleTime } from 'd3-scale';
import { localPoint } from '@visx/event';
import { useTooltip } from '@visx/tooltip';
import { useState } from 'react';
import { Line } from '@visx/shape';
import { isEmpty } from 'ramda';
import { Tooltip } from '../Tooltip/Tooltip';
import {
  Y_SCALE_OFFSET,
  lineChartConfigMap,
  MARGIN,
  LINE_CHART_HEIGHT,
  LINE_CHART_WIDTH,
} from './lineChart.config';
import { getTickValues } from './lineChart.utils';
import type {
  LineChartProps,
  TimeParser,
  VisxTooltipEvent,
} from './LineChart.types';
import { DataLine } from './DataLine';
import { AxisBottomTick } from './AxisBottomTick';
import { LineChartWrapper } from './LineChart.styles';
import { ObservationTypeMap } from 'transforms/observationTypeSearchTransform';
import { DATE_FILTER_MAP } from 'utils/dateFilter.utils';

export const LineChart = ({
  xAxisLabel,
  view,
  chartData,
  dateRange,
  observationType,
  customFilter,
  height = LINE_CHART_HEIGHT,
  width = LINE_CHART_WIDTH,
}: LineChartProps) => {
  const [tooltipValue, setTooltipValue] = useState<any>(null);
  const chartWidth = width - MARGIN.left - MARGIN.right;
  const chartHeight = height - MARGIN.top - MARGIN.bottom;

  const filter = customFilter?.dateFilter ?? view.data;
  const { dateFormat, labelFormat } = (lineChartConfigMap as any)[filter];
  let customLabelFormat = labelFormat;
  if (customFilter && customFilter.noOfDays > DATE_FILTER_MAP.Month!) {
    customLabelFormat = '%-d %b';
  }

  const parseDate = utcParse(dateFormat) as TimeParser;
  const tickValues = getTickValues(dateRange, filter, customFilter?.isCustom);

  const xScale: ScaleTime<any, any, never> = scaleTime()
    .domain(extent(tickValues) as [Date, Date])
    .range([0, chartWidth]);

  const formatTime: TickFormatter<any> = timeFormat(customLabelFormat);

  const seriesData: number[] = chartData.flatMap((d: any) => d.series);

  const [
    yMinValue = ObservationTypeMap[observationType]!.defaultRange.min,
    yMaxValue = ObservationTypeMap[observationType]!.defaultRange.max,
  ] = extent(seriesData) as [number, number];

  const yScale = scaleLinear({
    domain: [yMinValue - Y_SCALE_OFFSET, yMaxValue + Y_SCALE_OFFSET],
    range: [chartHeight, 0],
  });

  const renderTickComponent = (props: TickRendererProps) => (
    <AxisBottomTick {...props} />
  );

  const {
    tooltipOpen,
    showTooltip,
    tooltipData,
    tooltipLeft = 0,
    tooltipTop = 0,
    hideTooltip,
  } = useTooltip();

  const handleTooltip = (event: VisxTooltipEvent) => {
    const { x, y } = localPoint(event) || { x: 0, y: 0 };
    let adjustedX = x - MARGIN.right;
    if (adjustedX > chartWidth) adjustedX = chartWidth;
    if (adjustedX < 0) adjustedX = 0;
    showTooltip({
      tooltipLeft: adjustedX,
      tooltipTop: y,
      tooltipData: tooltipValue,
    });
  };

  return (
    <LineChartWrapper
      data-testid={`${toHyphenCase(observationType)}-line-chart`}
    >
      <svg
        width={width}
        height={height}
        onTouchStart={handleTooltip}
        onTouchMove={handleTooltip}
        onMouseMove={handleTooltip}
        onMouseLeave={hideTooltip}
      >
        <Group transform={`translate(${MARGIN.left},${MARGIN.top})`}>
          <GridRows
            scale={yScale}
            width={chartWidth}
            height={chartHeight}
            stroke={colors.grey.grey04}
            strokeDasharray="5"
            numTicks={6}
          />
          <GridColumns
            scale={xScale}
            width={chartWidth}
            height={chartHeight}
            stroke={colors.grey.grey04}
            tickValues={tickValues}
            strokeDasharray="2"
          />
          <AxisBottom
            scale={xScale}
            top={chartHeight}
            hideTicks
            tickFormat={formatTime}
            tickValues={tickValues}
            tickComponent={renderTickComponent}
            stroke={colors.grey.grey04}
            label={xAxisLabel}
            labelOffset={30}
            labelProps={{
              fontSize: fontSizes.xs,
              textAnchor: 'middle',
              fontWeight: 500,
              fill: colors.primaryIndigo.indigo08,
            }}
          />

          <AxisLeft
            scale={yScale}
            strokeDasharray="2"
            hideTicks
            numTicks={4}
            tickFormat={(series) => `${series}`}
            tickLabelProps={() => ({
              textAnchor: 'end',
              fontSize: fontSizes.xs,
              fill: colors.grey.grey08,
            })}
            stroke={colors.grey.grey02}
          />
          <Group>
            {!isEmpty(chartData) &&
              chartData[0].series.map((__: number, idx: number) => (
                <DataLine
                  // eslint-disable-next-line react/no-array-index-key
                  key={idx}
                  idx={idx}
                  chartData={chartData}
                  parseDate={parseDate}
                  dateFilter={filter}
                  xScale={xScale}
                  yScale={yScale}
                  handleTooltip={handleTooltip}
                  tooltipOpen={tooltipOpen}
                  tooltipData={tooltipData}
                  tooltipLeft={tooltipLeft}
                  tooltipTop={tooltipTop}
                  hideTooltip={hideTooltip}
                  setTooltipValue={setTooltipValue}
                  observationType={observationType}
                />
              ))}
          </Group>
          {!isEmpty(chartData) && tooltipOpen && (
            <Group>
              <Line
                from={{ x: tooltipLeft, y: 0 }}
                to={{
                  x: tooltipLeft,
                  y: LINE_CHART_HEIGHT - MARGIN.bottom - Y_SCALE_OFFSET,
                }}
                stroke={colors.primaryIndigo.indigo08}
                strokeWidth={2}
                pointerEvents="none"
              />
            </Group>
          )}
        </Group>
      </svg>
      <Tooltip
        tooltipOpen={tooltipOpen}
        tooltipData={tooltipData}
        tooltipLeft={tooltipLeft}
        tooltipTop={tooltipTop}
        dateFilter={filter}
        observationType={observationType}
      />
    </LineChartWrapper>
  );
};
