import { AccordionItem, toHyphenCase } from '@bt-healthcare/ui-toolkit';
import { AccordionTitle } from '../AccordionTitle';
import type { PatientAssessmentDetailsAccordionItemProps } from './types';
import { RecommendationAccordionItem } from './RecommendationAccordionItem/RecommendationAccordionItem';
import {
  PatientDetailsWrapper,
  PatientDetail,
  LabelText,
  ValueText,
} from 'pages/Patient/styles';
import { isFunctionType } from 'utils/object.utils';
import {
  AssessmentType,
  type GuidelineRecommendationDecisions,
} from 'services/graphql';

export const PatientAssessmentDetailsAccordionItem = ({
  assessment,
  details,
}: PatientAssessmentDetailsAccordionItemProps) => {
  const carePlanRecommendations =
    assessment.attributes.medicationAssessment
      ?.guidelineRecommendationDecisions;
  return (
    <AccordionItem
      title={<AccordionTitle assessment={assessment} />}
      id={`assessment-${assessment.id}`}
      variant="link"
    >
      <>
        <PatientDetailsWrapper>
          {details.map((detail) => (
            <PatientDetail
              key={detail.label}
              data-testid={toHyphenCase(detail.label)}
            >
              <LabelText>{detail.label}:</LabelText>
              <ValueText>
                {isFunctionType(detail.value) ? detail.value() : detail.value}
              </ValueText>
            </PatientDetail>
          ))}
        </PatientDetailsWrapper>
        {assessment.attributes.assessmentType ===
          AssessmentType.Consultation && (
          <RecommendationAccordionItem
            recommendations={
              carePlanRecommendations as GuidelineRecommendationDecisions[]
            }
            medicationAssessment={assessment.attributes.medicationAssessment}
          />
        )}
      </>
    </AccordionItem>
  );
};
