import { Button, Modal } from '@bt-healthcare/ui-toolkit';

import { ModalTitle } from '../ModalTitle';
import type { CancelUpdatingModalProps } from '../types';
import {
  ButtonWrapper,
  ModalBody,
  ModalStrapline,
  TextWrapper,
} from 'components/Modal/styles';

export const CancelUpdatingModal = ({
  isModalOpen,
  handleProceed,
  handleClose,
}: CancelUpdatingModalProps) => (
  <Modal
    onClose={handleClose}
    isModalOpen={isModalOpen}
    id="updating-cancel-modal"
    maxWidth="563px"
  >
    <ModalBody>
      <TextWrapper>
        <ModalTitle title="You have unsaved changes" />
        <ModalStrapline>
          Any changes made to the patients details will not be saved.
        </ModalStrapline>
      </TextWrapper>
      <ButtonWrapper>
        <Button
          id="cancel-cancellation"
          onClick={handleClose}
          variant="primary"
        >
          Continue editing
        </Button>
        <Button
          id="continue-cancellation"
          onClick={handleProceed}
          variant="primaryRed"
        >
          Discard changes
        </Button>
      </ButtonWrapper>
    </ModalBody>
  </Modal>
);
