import { EMPTY_VALUE } from 'App.constants';
import {
  EthnicityMap,
  GenderAssignedAtBirthMap,
  GenderIdentifiedAsMap,
  PronounsMap,
} from 'mappings/enums';
import type {
  Ethnicity,
  GenderAssignedAtBirth,
  GenderIdentifiedAs,
  Maybe,
  PersonContactPartyFields,
  Pronouns,
  WardPatient,
} from 'services/graphql';
import { getDate } from 'utils/date.utils';
import { propEmptyOr } from 'utils/object.utils';
import { nhsIdFormatter } from 'utils/string.utils';

export const getContactPartyDetails = (
  contactParty: Maybe<Maybe<PersonContactPartyFields>[]> | undefined
) =>
  contactParty?.map((contact) => {
    const { primaryContactNumber, humanNames } = contact || {
      primaryContactNumber: EMPTY_VALUE,
      humanNames: [],
    };
    const { given, family } = (humanNames?.length && humanNames[0]) || {
      given: EMPTY_VALUE,
      family: EMPTY_VALUE,
    };

    return {
      name: given,
      surname: family,
      phone: primaryContactNumber,
    };
  }) || [];

export const patientInformationUi = (wardPatient: WardPatient) => {
  const {
    contactInformation,
    personalInformation,
    contactParty,
    registeredGPSurgery,
  } = wardPatient?.careSettingPatient?.person.attributes || {};
  const { comments } = wardPatient?.attributes || {};

  const contactPartDetails = getContactPartyDetails(contactParty);

  const patientDetails = [
    {
      label: 'First name',
      value: propEmptyOr('firstName', personalInformation),
    },
    {
      label: 'Surname',
      value: propEmptyOr('surname', personalInformation),
    },
    {
      label: 'D.O.B',
      value: getDate(personalInformation?.dateOfBirth, true),
    },
    {
      label: 'Gender assigned at birth',
      value:
        GenderAssignedAtBirthMap[
          personalInformation?.genderAssignedAtBirth as GenderAssignedAtBirth
        ] || EMPTY_VALUE,
    },
    {
      label: 'Gender identified as',
      value:
        GenderIdentifiedAsMap[
          personalInformation?.genderIdentifiedAs as GenderIdentifiedAs
        ] || EMPTY_VALUE,
    },
    {
      label: 'Pronouns',
      value:
        PronounsMap[personalInformation?.pronouns as Pronouns] || EMPTY_VALUE,
    },
    {
      label: 'Ethnicity',
      value:
        EthnicityMap[personalInformation?.ethnicity as Ethnicity] ||
        EMPTY_VALUE,
    },
    {
      label: 'First line of address',
      value: propEmptyOr('line1', personalInformation?.primaryAddress),
    },
    {
      label: 'Postcode',
      value: propEmptyOr('postalCode', personalInformation?.primaryAddress),
    },
    {
      label: 'NHS no',
      value: nhsIdFormatter(personalInformation?.nhsNumber)!,
    },
    {
      label: 'Email',
      value: propEmptyOr('primaryEmailAddress', contactInformation),
    },
    {
      label: 'Phone number',
      value: propEmptyOr('primaryContactNumber', contactInformation),
    },
    {
      label: 'Next of kin first name',
      value: contactPartDetails[0]?.name || EMPTY_VALUE,
    },
    {
      label: 'Next of kin surname',
      value: contactPartDetails[0]?.surname || EMPTY_VALUE,
    },
    {
      label: 'Next of kin number',
      value: contactPartDetails[0]?.phone || EMPTY_VALUE,
    },
    {
      label: 'Medical Centre',
      value: registeredGPSurgery || EMPTY_VALUE,
    },
    {
      label: 'Admission date',
      value: getDate(wardPatient.attributes.admissionDateTime),
    },
    {
      label: 'Admitted by',
      value: wardPatient.attributes.res_admittedBy || EMPTY_VALUE,
    },
    {
      label: 'Comments',
      value: comments || EMPTY_VALUE,
    },
  ];

  return patientDetails ?? [];
};
