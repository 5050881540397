import {
  ProgressStepWrapper,
  ProgressStepMarker,
  ProgressStepText,
} from './ProgressStep.styles';
import type { ProgressStepProps } from './types';

export const ProgressStep = ({
  stepType,
  width,
  children,
}: ProgressStepProps) => (
  <ProgressStepWrapper data-testid="progress-step" width={width}>
    <ProgressStepMarker stepType={stepType} />
    <ProgressStepText stepType={stepType}>{children}</ProgressStepText>
  </ProgressStepWrapper>
);
