import { EMPTY_VALUE } from 'App.constants';
import {
  GuidelineDeclinedReasonMap,
  GuidelineMap,
  MedicationOutcomeMap,
  MedicationProtocolMap,
} from 'mappings/enums';
import {
  GuidelineDeclinedReason,
  MedicationOutcome,
  YesNoType,
} from 'services/graphql';
import type {
  MedicationAssessment,
  Maybe,
  GuidelineRecommendationDecisions,
} from 'services/graphql';
import { isEmptyOrNil } from 'utils/object.utils';
import { RecommendationStatusChip } from 'components/HypertensionAssessmentForm/RecommendationStatusChip';

const LABELS = {
  REASON_FOR_DECLINING: 'Reason for declining',
  MEDICATION_RECOMMENDATION: 'Medication recommendation',
  RECOMMENDATION_WAS: 'Recommendation was',
  OTHER_REASON_FOR_DECLINING: 'Other',
  MEDICATION_OUTCOME: 'Medication outcome',
  NEW_MEDICATION_PROTOCOL: 'New medication protocol',
  COMMENTS: 'Comments',
};

const patientRecommendationList = (
  recommendation: Maybe<GuidelineRecommendationDecisions>,
  medicationAssessment: Maybe<MedicationAssessment>
) => [
  {
    label: LABELS.MEDICATION_RECOMMENDATION,
    value: isEmptyOrNil(recommendation?.guidelineRecommended)
      ? EMPTY_VALUE
      : GuidelineMap[recommendation!.guidelineRecommended],
  },
  {
    label: LABELS.RECOMMENDATION_WAS,
    value: isEmptyOrNil(recommendation?.guidelineAccepted) ? (
      EMPTY_VALUE
    ) : (
      <RecommendationStatusChip
        guidelineAccepted={recommendation!.guidelineAccepted}
      />
    ),
  },
  {
    label: LABELS.REASON_FOR_DECLINING,
    value: isEmptyOrNil(recommendation?.recommendationDeclinedReason)
      ? EMPTY_VALUE
      : GuidelineDeclinedReasonMap[
          recommendation!.recommendationDeclinedReason!
        ],
  },
  {
    label: LABELS.OTHER_REASON_FOR_DECLINING,
    value: recommendation?.otherRecommendationDeclinedReasonComments,
  },
  {
    label: LABELS.MEDICATION_OUTCOME,
    value: () => {
      if (isEmptyOrNil(medicationAssessment?.medicationOutcome))
        return EMPTY_VALUE;
      return medicationAssessment!.medicationOutcome ===
        MedicationOutcome.Stopped
        ? medicationAssessment!.stoppedMedicationOutcomeComments
        : MedicationOutcomeMap[medicationAssessment!.medicationOutcome!];
    },
  },
  {
    label: LABELS.NEW_MEDICATION_PROTOCOL,
    value: isEmptyOrNil(medicationAssessment?.newMedicationProtocol)
      ? EMPTY_VALUE
      : MedicationProtocolMap[medicationAssessment!.newMedicationProtocol!],
  },
  {
    label: LABELS.COMMENTS,
    value: isEmptyOrNil(medicationAssessment?.comments)
      ? EMPTY_VALUE
      : medicationAssessment!.comments,
  },
];

export const getRecommendationList = (
  recommendation: Maybe<GuidelineRecommendationDecisions>,
  medicationAssessment: Maybe<MedicationAssessment>
) => {
  let recommendationDetails = patientRecommendationList(
    recommendation,
    medicationAssessment
  );
  if (recommendation === null) {
    return recommendationDetails.filter(
      (item) =>
        item.label === LABELS.COMMENTS ||
        item.label === LABELS.MEDICATION_OUTCOME ||
        item.label === LABELS.NEW_MEDICATION_PROTOCOL
    );
  }
  const isAccepted = recommendation?.guidelineAccepted === YesNoType.Yes;
  if (isAccepted)
    recommendationDetails = recommendationDetails.filter(
      (item) =>
        item.label !== LABELS.REASON_FOR_DECLINING &&
        item.label !== LABELS.OTHER_REASON_FOR_DECLINING
    );

  if (
    !isAccepted &&
    recommendation?.recommendationDeclinedReason !==
      GuidelineDeclinedReason.Other
  ) {
    recommendationDetails = recommendationDetails.filter(
      (item) => item.label !== LABELS.OTHER_REASON_FOR_DECLINING
    );
  }
  return recommendationDetails;
};
