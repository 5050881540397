import { Tooltip } from '@bt-healthcare/ui-toolkit';
import { EMPTY_VALUE } from 'App.constants';
import { SmokingStatusMap } from 'mappings/enums';
import type { ClinicianPatientLatestInformationAttributes } from 'services/graphql';
import { getDate } from 'utils/date.utils';
import { isEmptyOrNil } from 'utils/object.utils';

export const patientLifestyleList = (
  patientInfo: ClinicianPatientLatestInformationAttributes
) => [
  {
    label: 'Smoking status',
    value: isEmptyOrNil(
      patientInfo?.latestHealthInformation?.smokingInformation?.status
    ) ? (
      EMPTY_VALUE
    ) : (
      <Tooltip
        content={getDate(
          patientInfo.latestHealthInformation?.smokingInformation?.created
        )}
        id="smoking-status-tooltip"
        direction="top-start"
      >
        {
          SmokingStatusMap[
            patientInfo?.latestHealthInformation?.smokingInformation?.status!
          ]
        }
      </Tooltip>
    ),
  },
];
