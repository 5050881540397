import styled from 'styled-components';
import { colors, device, fontSizes, spacing } from '@bt-healthcare/ui-toolkit';
import type { NavigationWrapperProps } from './types';
import type { SideNavProps } from './DesktopContent/types';
import {
  activeBackgroundColor,
  activeColor,
  defaultColor,
  hoverColor,
} from './styles.constants';

const sideNavStyles = { width: { open: '252px', closed: '108px' } };

export const sideNavOpenWidth = sideNavStyles.width.open;
export const sideNavClosedWidth = sideNavStyles.width.closed;

export const iconMarginLeftCalc = '10px';

export const navTransitionValue = 'all 0.2s ease-in';
export const navPadding = spacing.s6;

export const NavigationWrapper = styled.nav<NavigationWrapperProps>`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  height: ${({ sideNavOpen }) => (sideNavOpen ? '100%' : '64px')};
  width: 100%;
  background: ${colors.base.white};
  padding: ${navPadding};
  box-shadow: ${({ scrolled }) =>
    scrolled ? '0px 4px 10px 1px rgba(112, 144, 176, 0.2)' : 'none'};
  transition: ${navTransitionValue};

  @media ${device.largeTablet} {
    background: ${colors.base.white};
    /* width is needed for smooth transition on toggle */
    width: ${({ sideNavOpen }) =>
      sideNavOpen ? sideNavOpenWidth : sideNavClosedWidth};
    height: 100%;
  }
`;

export const LogoWrapper = styled.div<{ marginBottom?: string }>`
  display: flex;
  align-items: center;
  margin-bottom: ${({ marginBottom }) => marginBottom ?? spacing.s14};

  svg {
    min-width: 60px;
    height: 60px;
  }
`;

export const NavItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.s6};
`;

export const NavItem = styled.div<SideNavProps>`
  display: flex;
  align-items: center;
  cursor: pointer;
  background: ${({ active, sideNavOpen }) =>
    sideNavOpen && active ? activeBackgroundColor : 'transparent'};
  border-radius: ${spacing.s2};
  transition-property: background, fill;
  transition-duration: 0.2s;
  transition-timing-function: ease-in;

  &:hover {
    svg path {
      fill: ${hoverColor};
    }

    span {
      color: ${hoverColor};
    }
  }
`;

/* min-width needed for transitions */
/* margin-left calc needed for positioning */
export const IconWrapper = styled.div<SideNavProps>`
  background: red;
  margin-left: ${iconMarginLeftCalc};
  min-width: 40px;
  height: 40px;
  border-radius: ${spacing.s2};
  border-radius: ${spacing.s2};
  background: ${({ active, sideNavOpen }) =>
    !sideNavOpen && active ? activeBackgroundColor : 'transparent'};

  display: flex;
  align-items: center;
  justify-content: center;

  svg path {
    fill: ${({ active }) => (active ? activeColor : defaultColor)};
  }
`;

export const Label = styled.span<SideNavProps>`
  opacity: ${({ sideNavOpen }) => (sideNavOpen ? '1' : '0')};
  min-width: ${({ sideNavOpen }) => (sideNavOpen ? '140px' : '0')};
  height: ${({ sideNavOpen }) => !sideNavOpen && '0'};
  overflow: ${({ sideNavOpen }) => !sideNavOpen && 'hidden'};
  white-space: nowrap;
  margin-left: ${({ sideNavOpen }) => sideNavOpen && spacing.s2};
  transition-property: opacity, min-width, height, overflow;
  transition-duration: 0.4s;
  transition-timing-function: ease-in;
  color: ${({ active }) => (active ? activeColor : defaultColor)};
  font-size: ${fontSizes.sm};
`;

export const FooterNavItems = styled(NavItems)`
  position: absolute;
  bottom: ${navPadding};
  left: ${navPadding};
  right: ${navPadding};
`;

export const LogoText = styled(Label)`
  color: ${colors.primaryIndigo.indigo08};
  font-size: ${fontSizes.h4};
`;
