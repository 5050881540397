import { Wrapper, device, spacing } from '@bt-healthcare/ui-toolkit';
import styled from 'styled-components';

export const ContentWrapper = styled(Wrapper)`
  display: flex;
  flex-direction: column;
  gap: ${spacing.s2};
  margin-bottom: ${spacing.s2};
  width: 100%;

  h3,
  span {
    text-align: center;
  }

  @media ${device.largeTablet} {
    gap: ${spacing.s6};
  }
`;
