import type { UsersType } from './types';
import type { GetRegisteredCareSettingCliniciansQuery } from 'services/graphql';

export const registeredCliniciansTransform = (
  data: GetRegisteredCareSettingCliniciansQuery
) => {
  let result: UsersType[] = [];
  if (
    data.registeredCareSettingClinicians &&
    data.registeredCareSettingClinicians.length === 0
  )
    return result;

  result = data.registeredCareSettingClinicians!.map((user) => ({
    firstName: user.attributes.firstName ?? '',
    surname: user.attributes.surname ?? '',
    id: user.attributes.userId,
    emailAddress: user.attributes.email ?? '',
    mobileNumber: user.attributes.res_mobileNumber ?? '',
    modules: user.attributes.careSettingClinicianModuleAvailability.flatMap(
      (module) =>
        module?.attributes.availabilityStatus === 'ACCESSIBLE'
          ? module.attributes.moduleId
          : []
    ),
    careSettingAdministrator:
      user.attributes.careSettingClinicianModuleRoles.some(
        (module) =>
          module?.attributes.moduleRoleId === 'CARE_SETTING_ADMINISTRATOR'
      ),
    userActivationState:
      (user.attributes.careSettingClinicianUser?.attributes.userState ===
      'ENABLED'
        ? 'ACTIVE'
        : 'DEACTIVATED') ?? 'NONE',
  }));

  return result;
};
