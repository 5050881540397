import {
  Wrapper,
  colors,
  device,
  fontSizes,
  spacing,
} from '@bt-healthcare/ui-toolkit';
import styled from 'styled-components';

export const MenuBarWrapper = styled(Wrapper)`
  overflow-x: scroll;
  overflow-y: hidden;

  li {
    white-space: nowrap;
  }

  @media ${device.largeTablet} {
    overflow: hidden;

    li {
      white-space: normal;
    }
  }
`;

export const PatientWrapper = styled.section<{ columns: string }>`
  display: grid;
  grid-gap: ${spacing.s4};

  @media ${device.desktop} {
    grid-gap: ${spacing.s6};
    grid-template-columns: ${({ columns }) => columns};
  }
`;

export const PatientTabWrapper = styled(Wrapper)`
  display: flex;
  flex-direction: column;
`;

export const PatientDetailsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${spacing.s4};

  @media ${device.largeTablet} {
    grid-template-columns: repeat(5, 1fr);
    padding: ${spacing.none} ${spacing.s2};
  }
`;

export const PatientDetail = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: ${spacing.s1};
  overflow-wrap: anywhere;

  @media ${device.largeTablet} {
    display: flex;
    flex-direction: column;
    gap: ${spacing.s2};
  }
`;

export const LabelText = styled.span`
  color: ${colors.grey.grey08};
  font-size: ${fontSizes.xs};

  @media ${device.largeTablet} {
    font-size: ${fontSizes.sm};
  }
`;

export const ValueText = styled.span`
  color: ${colors.grey.grey10};
  font-size: ${fontSizes.sm};

  @media ${device.largeTablet} {
    font-size: ${fontSizes.base};
  }
`;
