import flagsmith from 'flagsmith';

import type { NavigateFunction } from 'react-router-dom';
import { FeatureFlagKeys } from 'features/types';
import { ROUTE } from 'config/routes';

export const StartAdmissionFeature = (navigate: NavigateFunction) =>
  flagsmith.hasFeature(FeatureFlagKeys.REMOTE_CARE_ADMISSION)
    ? [
        {
          label: 'Patient Admission',
          action: () => navigate(ROUTE.START_ADMISSION),
        },
      ]
    : [];
