import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Modal,
  Text,
  Spacer,
  ModalBody,
  ModalFooter,
  Button,
  colors,
  fontSizes,
} from '@bt-healthcare/ui-toolkit';

import type { ConfirmDischargeProps } from '../types';
import { ModalHeader, ModalHeaderContent, ButtonsWrapper } from './styles';
import type { WardDischargeInput } from 'services/graphql';
import {
  CarePackageStatusType,
  DischargeStatusType,
  IsolationType,
  PorterStatusType,
  TtoStatusType,
  useCreateWardDischargeMutation,
  YesNoType,
  DischargeReasonType,
} from 'services/graphql';
import {
  getPatientFirstAndLastName,
  getWardGroupId,
  getWardPatientId,
} from 'selectors/patient';
import { DischargeSummary } from 'components/DischargeSummary';
import { pipeNilable } from 'selectors/utils';
import { getCareSettingPatient, getWard } from 'selectors/wardPatient';
import { ROUTE } from 'config/routes';

export const ConfirmDischarge = ({
  admissionDate,
  wardPatient,
  lengthOfStayInDays,
  modalOpen,
  formData,
  closeConfirm,
  dischargeDate,
}: ConfirmDischargeProps) => {
  const navigate = useNavigate();
  const [dischargePatient, { data }] = useCreateWardDischargeMutation();

  const wardPatientId = getWardPatientId(wardPatient);

  const wardGroupId = pipeNilable([getWard, getWardGroupId])(wardPatient);
  const wardId = wardPatient.ward?.id || '';
  const patientName = pipeNilable([
    getCareSettingPatient,
    getPatientFirstAndLastName,
  ])(wardPatient);
  const careSettingId = wardPatient?.attributes.careSettingId;

  useEffect(() => {
    if (data) {
      navigate(ROUTE.DISCHARGE_CONFIRMED, {
        state: {
          wardPatientId,
          name: patientName,
          admissionDate,
          dischargeDate,
          lengthOfStayInDays,
          reasonForDischarge: formData?.reasonForDischarge?.label,
          comments: formData?.comments,
        },
      });
      closeConfirm();
    }
  }, [data]);

  const options: { variables: { input: WardDischargeInput } } = {
    variables: {
      input: {
        wardId,
        wardGroupId,
        wardPatientId,
        careSettingId,
        carePackageStatus: CarePackageStatusType.None,
        dischargeStatus: DischargeStatusType.Completed,
        toTakeOutStatus: TtoStatusType.None,
        decisionMakerYN: YesNoType.No,
        isolationType: IsolationType.None,
        porterStatus: PorterStatusType.NotRequired,
        comments: formData?.comments || null,
        dischargeReason:
          formData?.reasonForDischarge?.value || DischargeReasonType.None,
        dischargeActualDateTime: dischargeDate.toISOString(),
        bedTransferConfirmed: null,
        decisionMakerLastVisitedDateTime: null,
        dischargeAssessmentOutcome: null,
        dischargeExpectedDateTime: null,
        dischargeStatusFailedReason: null,
        dischargeStatusFailedReasonOther: null,
        dischargeStatusFailedReasons: null,
        dischargeToAssessDateTime: null,
        isolationTypeOther: null,
        pharmacy: null,
        readyForDischargeDateTime: null,
        requireDischargeToAssess: null,
        socialCareYN: null,
        version: null,
        waitingReasonForClinicallyReadyForDischarge: null,
        waitingReasonForPotentialDischarge: null,
        waitingReasonOther: null,
        waitingReasonsForClinicallyReadyForDischarge: null,
        waitingReasonsForPotentialDischarge: null,
        wardDischargeId: null,
        bedCleaningStatus: null,
        bedCleaningMethod: null,
        clinicallyReadyForDischargeDateTime: null,
        requiresIsolationYN: YesNoType.None,
      },
    },
  };

  const handleDischarge = () => {
    dischargePatient(options);
  };

  return (
    <Modal
      isModalOpen={modalOpen}
      onClose={closeConfirm}
      id="confirm-discharge-modal"
    >
      <ModalHeader>
        <ModalHeaderContent>
          <Text
            as="h2"
            color={colors.grey.grey10}
            fontSize={fontSizes.h4}
            fontWeight={500}
          >
            Confirm discharge
          </Text>
          <Spacer size="s4" />
          <Text as="p" fontSize={fontSizes.sm} color={colors.grey.grey09}>
            Please confirm you are about to discharge this patient from the
            HealthyYou platform.
          </Text>
        </ModalHeaderContent>
      </ModalHeader>
      <ModalBody>
        <DischargeSummary
          name={patientName}
          admissionDate={admissionDate}
          dischargeDate={dischargeDate}
          lengthOfStayInDays={lengthOfStayInDays}
          reasonForDischarge={formData?.reasonForDischarge?.label}
          comments={formData?.comments}
        />
      </ModalBody>
      <ModalFooter flex>
        <ButtonsWrapper>
          <Button
            id="cancel-discharge-btn"
            onClick={closeConfirm}
            variant="secondary"
          >
            Cancel
          </Button>
          <Button
            id="confirm-discharge-btn"
            onClick={handleDischarge}
            variant="primary"
          >
            Confirm
          </Button>
        </ButtonsWrapper>
      </ModalFooter>
    </Modal>
  );
};
