import { Button, Modal, noop } from '@bt-healthcare/ui-toolkit';

import {
  ButtonWrapper,
  ModalBody,
  ModalStrapline,
  TextWrapper,
} from '../styles';
import { ModalTitle } from '../ModalTitle';
import { useApp } from 'context/app/AppContext';
import { useLeavePageModal } from 'hooks/useLeavePageModal';

export const LeavePage = () => {
  const { showLeavePageModal, onCloseHandler } = useApp();
  const { closeModal } = useLeavePageModal();

  return (
    <Modal
      onClose={noop}
      isModalOpen={showLeavePageModal}
      id="leave-page-modal"
    >
      <ModalBody>
        <TextWrapper>
          <ModalTitle title="Leave page" />
          <ModalStrapline>
            Leaving the page will delete all progress made and any information
            that you have entered so far will be lost.
          </ModalStrapline>
        </TextWrapper>
        <ButtonWrapper>
          <Button id="leave-page-continue" onClick={closeModal}>
            No, continue
          </Button>
          <Button
            id="leave-page-proceed"
            onClick={onCloseHandler}
            variant="primaryRed"
          >
            Yes, leave
          </Button>
        </ButtonWrapper>
      </ModalBody>
    </Modal>
  );
};
