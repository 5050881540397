import { colors, device } from '@bt-healthcare/ui-toolkit';
import styled from 'styled-components';

export const CardRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;

  @media ${device.tablet} {
    flex-direction: row;
  }
`;

export const PersonWrapper = styled.div`
  border-radius: 8px;
  background-color: ${colors.primaryIndigo.indigo10};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
`;

export const Seperator = styled.div`
  width: 80%;
  height: 2px;
  border-bottom: 2px dotted ${colors.primaryIndigo.indigo08};
  margin: 8px 0;

  @media ${device.tablet} {
    width: 1px;
    border-bottom: none;
    height: 40px;
    border-right: 2px dotted ${colors.primaryIndigo.indigo08};
    margin: 0 16px;
  }
`;

export const FieldRow = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 8px;

  @media ${device.tablet} {
    flex-direction: row;
  }
`;

export const FieldWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;
