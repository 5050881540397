import { toHyphenCase } from '@bt-healthcare/ui-toolkit';
import {
  LineChartHeadingWrapper,
  TitleWrapper,
  UnitsWrapper,
} from './LineChart.styles';
import type { LineChartHeadingProps } from './LineChart.types';

export const LineChartHeading = ({
  title,
  unit,
  observationType,
  ariaLevel = 4,
}: LineChartHeadingProps) => (
  <LineChartHeadingWrapper role="heading" ariaLevel={ariaLevel}>
    <TitleWrapper
      data-testid={`${toHyphenCase(observationType)}-line-chart-header`}
    >
      {title}
    </TitleWrapper>
    {unit && <UnitsWrapper> ({unit})</UnitsWrapper>}
  </LineChartHeadingWrapper>
);
