import { CardButton, Stack, Divider } from '@bt-healthcare/ui-toolkit';

import type { SideMenuProps } from './types';
import { CardButtonWrapper } from './styles';
import { PatientAdmissionCard } from 'components/Card/PatientAdmissionCard';
import { AdmissionStatus } from 'services/graphql';
import { MonitoredConditionCard } from 'components/Card/MonitoredConditionCard/MonitoredConditionCard';

export const SideMenu = ({
  admissionStatus,
  admissionStatusLastUpdated,
  setShowNotesPanel,
  monitoredConditions,
}: SideMenuProps) => {
  const readingsNotSubmitted =
    admissionStatus === AdmissionStatus.Invited ||
    admissionStatus === AdmissionStatus.Accepted ||
    admissionStatus === AdmissionStatus.InvitedStepup;

  return (
    <Stack space="s2" id="side-menu-wrapper">
      {readingsNotSubmitted ? (
        <PatientAdmissionCard
          admissionStatus={admissionStatus}
          admissionStatusLastUpdated={admissionStatusLastUpdated}
        />
      ) : (
        monitoredConditions?.map((item) => (
          <MonitoredConditionCard
            key={item.condition}
            {...item}
            monitoringStatus={item.monitoringStatus ?? undefined}
            consultationStatus={item.consultationStatus ?? undefined}
            testStatus={item.testStatus ?? undefined}
          />
        ))
      )}
      <Divider lineType="dotted" />

      <CardButtonWrapper
        onClick={() => setShowNotesPanel(true)}
        role="button"
        data-testid="notes-button"
      >
        <CardButton id="notes-card" iconSize={12}>
          Notes
        </CardButton>
      </CardButtonWrapper>
    </Stack>
  );
};
