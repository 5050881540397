import { Wrapper, device, spacing } from '@bt-healthcare/ui-toolkit';
import styled from 'styled-components';

export const SubHeaderWrapper = styled(Wrapper)`
  margin-bottom: ${spacing.s4};

  @media ${device.largeTablet} {
    padding: ${spacing.none} ${spacing.s2};
    margin-bottom: ${spacing.s6};
  }
`;
