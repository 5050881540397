import { useAnalytics } from '@bt-healthcare/ui-toolkit';

import { UNKNOWN_VALUE, appName } from 'App.constants';
import { PageName } from 'config/pageNames';
import { useApp } from 'context/app/AppContext';

export const useStatusChangeTracking = () => {
  const { careSetting } = useApp();
  const { trackEvent } = useAnalytics();

  const trackStatusChange = (
    monitoredCondition: string,
    statusType: string,
    selectionFrom: string,
    selectionTo: string
  ) =>
    trackEvent('Drop-down selected', {
      dropDownName: `${monitoredCondition}${statusType}`,
      pageName: `${appName} - ${PageName.PATIENT_DETAIL}`,
      careSetting: careSetting?.organisation ?? UNKNOWN_VALUE,
      monitoredCondition,
      selectionFrom,
      selectionTo,
    });

  return {
    trackStatusChange,
  };
};
