import { Wrapper, colors, device, spacing } from '@bt-healthcare/ui-toolkit';
import styled from 'styled-components';
import { MAX_FORM_WIDTH, MAX_PAGE_WIDTH } from 'styles/constants.styles';

export const mobileFooterHeight = `calc(120px + ${spacing.s2} + ${spacing.s2})`;
export const desktopFooterHeight = '120px';

export const FormFooterWapper = styled(Wrapper)`
  height: ${mobileFooterHeight};
  position: fixed;
  right: 0;
  bottom: 0;
  background: ${colors.base.light};
  box-shadow: 0px 4px 30px 1px rgba(112, 144, 176, 0.15);

  @media ${device.largeTablet} {
    height: ${desktopFooterHeight};
  }
`;

export const ButtonWrapper = styled(Wrapper)<{ isFormWidth: boolean }>`
  display: flex;
  flex-direction: column;
  padding: ${spacing.s4} ${spacing.none};
  gap: ${spacing.s2};

  @media ${device.largeTablet} {
    padding: ${spacing.s8} ${spacing.none};
    flex-direction: row;
    gap: ${spacing.s10};

    max-width: ${({ isFormWidth }) =>
      isFormWidth ? MAX_FORM_WIDTH : MAX_PAGE_WIDTH};
    margin: 0 auto;
    button {
      height: max-content;
    }

    #primary-btn {
      width: 371px;
    }

    #cancel-btn {
      width: fit-content;
      padding: ${spacing.none};
    }
  }
`;
