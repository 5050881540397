/** Conversion from imperial to metric units for BMI */
export const POUNDS_TO_KG_CONVERSION_FACTOR = 0.45359237;
export const INCHES_TO_CM_CONVERSION_FACTOR = 2.54;

export const UNIT_SYSTEM = 'http://unitsofmeasure.org'; // nosonar

export const UnitCode = {
  perMinute: { system: UNIT_SYSTEM, unit: '/min', code: '/min' },
  saturation: { system: UNIT_SYSTEM, unit: '%', code: '%' },
  mmhg: { system: UNIT_SYSTEM, unit: 'mmHg', code: 'mm[Hg]' },
  lb: { system: UNIT_SYSTEM, unit: 'lb', code: '[lb_av]' },
  kg: { system: UNIT_SYSTEM, unit: 'kg', code: 'kg' },
  celsius: { system: UNIT_SYSTEM, unit: 'Cel', code: 'Cel' },
  cm: { system: UNIT_SYSTEM, unit: 'cm', code: 'cm' },
  in: { system: UNIT_SYSTEM, unit: 'inch', code: '[in_i]' },
  millimolePerLiter: {
    system: UNIT_SYSTEM,
    unit: 'mmol/L',
    code: '[mmol]/L',
  },
  milligramPerDeciliter: {
    system: UNIT_SYSTEM,
    unit: 'mg/dL',
    code: 'mg/dL',
  },
};
