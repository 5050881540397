import { shortAppName } from 'App.constants';

export const set = <T>(key: string, value: T) => {
  localStorage.setItem(`${shortAppName}_${key}`, JSON.stringify(value));
};

export const get = <T>(key: string) => {
  const value = localStorage.getItem(`${shortAppName}_${key}`);
  return value != null ? (JSON.parse(value) as T) : null;
};

export const remove = (key: string) =>
  localStorage.removeItem(`${shortAppName}_${key}`);

export const clear = () => localStorage.clear();
