import { zoneDateTimeToDateTimeConvertor } from '@bt-healthcare/ui-toolkit';
import { descend, pathOr, sortWith } from 'ramda';
import { EMPTY_VALUE } from 'App.constants';
import {
  AssessmentTypeMap,
  ConsultationTimeframeMap,
  MedicationProtocolMap,
  MonitoredConditionMap,
  TestsRequiredMap,
  YesNoMap,
} from 'mappings/enums';
import { TestType, ConsultationTimeframe } from 'services/graphql';
import type {
  YesNoType,
  MedicationProtocol,
  WardPatientConditionAssessmentAttribute,
  WardPatientConditionAssessment,
} from 'services/graphql';
import { isEmptyOrNil } from 'utils/object.utils';
import {
  bloodPressureFormatter,
  bloodPressureReadingsFormatter,
} from 'utils/string.utils';

const getTestsRequested = (
  assessment: WardPatientConditionAssessmentAttribute
) =>
  isEmptyOrNil(assessment.hypertensionAssessment?.conditionTestTypes)
    ? EMPTY_VALUE
    : assessment.hypertensionAssessment?.conditionTestTypes
        ?.map((item) =>
          item === TestType.Other
            ? assessment.hypertensionAssessment?.conditionTestTypeOther
            : TestsRequiredMap[item as TestType]
        )
        .join(', ');

const getReviewedDate = (assessment: WardPatientConditionAssessmentAttribute) =>
  (zoneDateTimeToDateTimeConvertor(
    assessment.assessmentStartDateZoned,
    'dd/MM/yyyy'
  ) as string) ?? EMPTY_VALUE;

const getConsultationTakePlace = (
  assessment: WardPatientConditionAssessmentAttribute
) => {
  const value = assessment.hypertensionAssessment?.consultationTimeframe!;
  return value === ConsultationTimeframe.Other
    ? assessment.hypertensionAssessment?.customConsultationTimeframe!
    : ConsultationTimeframeMap[value] ?? EMPTY_VALUE;
};

export const sortWardPatientConditionAssessments = (
  assessment: WardPatientConditionAssessment[]
) =>
  sortWith([
    descend(pathOr('', ['attributes', 'assessmentStartDateZoned'])),
    descend(pathOr('', ['attributes', 'audit', 'created'])),
  ])(assessment);

export const patientConditionAssessmentList = (
  assessment: WardPatientConditionAssessmentAttribute
) => [
  {
    label: 'Condition assessed',
    value: MonitoredConditionMap[assessment.monitoredCondition],
  },
  {
    label: 'Type of assessment',
    value: AssessmentTypeMap[assessment.assessmentType],
  },
  {
    label: 'Review date',
    value: getReviewedDate(assessment),
  },
  {
    label: 'Average BP during review',
    value: bloodPressureFormatter(
      assessment.hypertensionAssessment?.averageDiastolic!,
      assessment.hypertensionAssessment?.averageSystolic!
    ),
  },
  {
    label: 'Average based on',
    value: bloodPressureReadingsFormatter(
      assessment.hypertensionAssessment?.totalReadings!,
      assessment.hypertensionAssessment?.totalDays!
    ),
  },
  {
    label: 'Tests required',
    value: isEmptyOrNil(assessment.hypertensionAssessment?.testsRequiredYN)
      ? EMPTY_VALUE
      : YesNoMap[
          assessment.hypertensionAssessment?.testsRequiredYN as YesNoType
        ],
  },
  {
    label: 'Tests requested',
    value: getTestsRequested(assessment),
  },
  {
    label: 'Urgent clinical intervention',
    value: isEmptyOrNil(
      assessment.hypertensionAssessment?.urgentClinicalInterventionRequiredYN
    )
      ? EMPTY_VALUE
      : YesNoMap[
          assessment.hypertensionAssessment
            ?.urgentClinicalInterventionRequiredYN as YesNoType
        ],
  },
  {
    label: 'Urg. clinical intervention done',
    value: isEmptyOrNil(
      assessment.hypertensionAssessment?.urgentClinicalInterventionTakenPlaceYN
    )
      ? EMPTY_VALUE
      : YesNoMap[
          assessment.hypertensionAssessment
            ?.urgentClinicalInterventionTakenPlaceYN as YesNoType
        ],
  },
  {
    label: 'Consultation required',
    value: isEmptyOrNil(
      assessment.hypertensionAssessment?.consultationRequiredYN
    )
      ? EMPTY_VALUE
      : YesNoMap[
          assessment.hypertensionAssessment?.consultationRequiredYN as YesNoType
        ],
  },
  {
    label: 'Consultation to take place',
    value: () => getConsultationTakePlace(assessment),
  },
  {
    label: 'QRisk3',
    value: isEmptyOrNil(assessment.hypertensionAssessment?.qriskScore)
      ? EMPTY_VALUE
      : `${assessment.hypertensionAssessment?.qriskScore}%`,
  },
  {
    label: 'Comments',
    value: isEmptyOrNil(assessment.assessmentComments)
      ? EMPTY_VALUE
      : assessment.assessmentComments,
  },
  {
    label: 'BP medication',
    value: isEmptyOrNil(
      assessment.medicationAssessment?.isTakingBloodPressureMedicationYN
    )
      ? EMPTY_VALUE
      : YesNoMap[
          assessment.medicationAssessment
            ?.isTakingBloodPressureMedicationYN as YesNoType
        ],
  },
  {
    label: 'BP medication protocol',
    value:
      MedicationProtocolMap[
        assessment.medicationAssessment
          ?.medicationProtocol as MedicationProtocol
      ] ?? EMPTY_VALUE,
  },
  {
    label: 'Assessment created by',
    value: assessment.res_createdBy ?? EMPTY_VALUE,
  },
];
