import {
  Card,
  Divider,
  Text,
  colors,
  fontSizes,
} from '@bt-healthcare/ui-toolkit';

import type { HypertensionAssessmentSummaryCardProps } from './types';
import { Title, Grid, GridItem } from './styles';
import type { ContentMap } from 'types';
import { isFunctionType } from 'utils/object.utils';

export const HypertensionAssessmentSummaryCard = ({
  title,
  confirmationDetails,
}: HypertensionAssessmentSummaryCardProps) => (
  <Card id="hypertension-assessment-summary-card">
    <Title>{title}:</Title>
    <Divider flexColor="grey.grey02" />
    <Grid>
      {confirmationDetails.map(({ name, value }: ContentMap) => (
        <GridItem key={name}>
          <Text color={colors.grey.grey08} fontSize={fontSizes.sm}>
            {name}:
          </Text>
          <Text color={colors.grey.grey10} fontSize={fontSizes.base}>
            {isFunctionType(value) ? value() : value}
          </Text>
        </GridItem>
      ))}
    </Grid>
  </Card>
);
