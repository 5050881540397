import { Text, colors, fontSizes } from '@bt-healthcare/ui-toolkit';

import type { ModalTitleProps } from './types';

export const ModalTitle = ({ title }: ModalTitleProps) => (
  <Text
    as="h3"
    fontSize={fontSizes.h3}
    fontWeight={500}
    color={colors.grey.grey10}
    style={{ textAlign: 'center' }}
  >
    {title}
  </Text>
);
