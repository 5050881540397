import { type MutableRefObject, useRef, useState, useEffect } from 'react';
import type { TypedOptionType } from '@bt-healthcare/ui-toolkit';
import {
  BTHealthIcon,
  colors,
  Text,
  toHyphenCase,
  useClickOutside,
  Wrapper,
} from '@bt-healthcare/ui-toolkit';
import { isEmpty } from 'ramda';
import { options, defaultOption, customOption } from '../options';
import type { FilterDropdownProps } from './types';
import {
  FilterDropdownWrapper,
  CurrentValue,
  DropdownWrapper,
  DropdownValue,
  Spacer,
  Divider,
} from './styles';
import { getPrevFilterValue } from './utils';
import { DateFilter } from 'components/Visualisations/types';
import type { DateRange } from 'components/Visualisations/LineChart/LineChart.types';
import { DateRangePicker } from 'components/DateRangePicker';
import { usePatient, usePatientDispatch } from 'context/patient/PatientContext';

export const FilterDropdown = ({
  handleItemClick,
  filterType,
}: FilterDropdownProps) => {
  const { synchedChartDateFilter, synchedChartDateRange } = usePatient();
  const patientDispatch = usePatientDispatch();
  const [filterValue, setFilterValue] = useState(defaultOption);
  const [prevFilterValue, setPrevFilterValue] = useState(filterValue);
  const [displayOptions, setDisplayOptions] = useState(
    options.filter((option) => option !== filterValue)
  );

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [editCustomRange, setEditCustomRange] = useState(false);

  const [active, setActive] = useState(false);
  const [dropdownMenuOpen, setDropdownMenuOpen] = useState(false);
  const [datePickerOpen, setDatePickerOpen] = useState(false);

  const handleClick = (
    clickedOption: TypedOptionType<DateFilter>,
    customDates?: DateRange
  ) => {
    setFilterValue(clickedOption);
    setPrevFilterValue(getPrevFilterValue(clickedOption, prevFilterValue));

    if (clickedOption.value === DateFilter.Custom && !customDates) {
      return setDatePickerOpen(true);
    }
    setActive(false);
    setDropdownMenuOpen(false);
    setDisplayOptions(options.filter((item) => item !== clickedOption));
    handleItemClick(clickedOption, customDates);

    return setDatePickerOpen(false);
  };

  const handleChange = (dates: Array<Date | null>) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const handleApply = () => {
    const dateRange = {
      endDate,
      startDate,
    } as DateRange;

    setEditCustomRange(false);
    handleClick(customOption, dateRange);
    patientDispatch({ type: 'resetChartDateFilter' });
    patientDispatch({
      type: 'setChartDateRange',
      synchedDateRange: {
        range: dateRange,
        dateFilter: DateFilter.Custom,
        isNavigation: false,
      },
    });
    // Resets the calendar control to default values
    setStartDate(new Date());
    setEndDate(null);
  };

  const handleEdit = () => {
    setEditCustomRange(true);
    handleClick(customOption);
  };

  const handleButtonClick = () => {
    setActive(!active);
    setDropdownMenuOpen(!dropdownMenuOpen);
  };

  const buttonRef = useRef() as MutableRefObject<HTMLDivElement>;
  useClickOutside(buttonRef, () => {
    setActive(false);
    setDropdownMenuOpen(false);
  });

  // Used to handle default filters
  useEffect(() => {
    if (synchedChartDateFilter !== DateFilter.Custom) {
      const option = options.find(
        (item) => item.value === synchedChartDateFilter
      );
      if (option) {
        setFilterValue(option);
        setDisplayOptions(options.filter((item) => item !== option));
        setStartDate(new Date());
        setEndDate(null);
      }
    }
  }, [synchedChartDateFilter]);

  // Used to handle custom filters
  useEffect(() => {
    if (
      !isEmpty(synchedChartDateFilter) &&
      synchedChartDateRange.dateFilter === DateFilter.Custom
    ) {
      const option = options.find(
        (item) => item.value === synchedChartDateRange.dateFilter
      );
      if (option) {
        setFilterValue(option);
        setDisplayOptions(options.filter((item) => item !== option));
        setStartDate(synchedChartDateRange.range.startDate);
        setEndDate(synchedChartDateRange.range.endDate);
      }
    }
  }, [synchedChartDateRange]);

  return (
    <FilterDropdownWrapper
      ref={buttonRef}
      active={active}
      data-testid={`${toHyphenCase(filterType)}-filter`}
    >
      <CurrentValue
        onClick={handleButtonClick}
        data-testid={`${toHyphenCase(filterType)}-value`}
      >
        {filterValue.value === DateFilter.Custom && (
          <BTHealthIcon icon="Calendar" color={colors.base.white} size={14} />
        )}
        <Text color={colors.base.white}>{filterValue.label}</Text>
      </CurrentValue>

      {dropdownMenuOpen && (
        <DropdownWrapper>
          <Spacer />
          <Wrapper className="dropdown">
            {filterValue.label === DateFilter.Custom && (
              <>
                <DropdownValue
                  onClick={handleEdit}
                  data-testid={`${toHyphenCase(filterType)}-option-edit`}
                >
                  <BTHealthIcon
                    icon="Calendar"
                    color={colors.grey.grey09}
                    size={14}
                  />
                  Edit
                </DropdownValue>
                <Divider />
              </>
            )}
            {displayOptions.map((option) => (
              <DropdownValue
                key={option.label}
                onClick={() => handleClick(option)}
                data-testid={`${toHyphenCase(filterType)}-option-${toHyphenCase(
                  option.label
                )}`}
              >
                {option.label}
              </DropdownValue>
            ))}
          </Wrapper>
          <DateRangePicker
            handleChange={handleChange}
            handleApply={handleApply}
            startDate={startDate}
            endDate={endDate}
            datePickerOpen={datePickerOpen}
            setFilterValue={setFilterValue}
            prevFilterValue={prevFilterValue}
            setDatePickerOpen={setDatePickerOpen}
            editCustomRange={editCustomRange}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
          />
        </DropdownWrapper>
      )}
    </FilterDropdownWrapper>
  );
};
