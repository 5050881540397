import * as yup from 'yup';
import { differenceInYears, isBefore, isValid, parse } from 'date-fns';
import type { UseFormSetError, UseFormSetFocus } from 'react-hook-form';

import type { PatientAdmissionPersonalFormData } from '../types';
import {
  firstNameRule,
  requiredPhoneNumber,
  requiredDropdownRule,
  surnameRule,
  requiredNumber,
  requiredYear,
  REQUIRED_TEXT,
  DOB_REQUIRED_TEXT,
} from 'components/Form/validationHelper';
import type { ValidationResponse } from 'components/Form/types';

export const INVALID_DATE_MESSAGE = 'Date is invalid.';
export const FUTURE_DATE_MESSAGE = 'Date must be earlier than todays date.';
export const INVALID_PATIENT_AGE =
  'Patient cannot be admitted as they are under 18.';

const NHS_NUMBER_VALIDATION_MESSAGE =
  'Incorrect format. Please enter as a 10 digit number or in the format XXX XXX XXXX.';

export const validateDateOfBirth = (
  data: Pick<
    PatientAdmissionPersonalFormData,
    'dayOfBirth' | 'yearOfBirth' | 'monthOfBirth'
  >
) => {
  const { dayOfBirth, yearOfBirth, monthOfBirth } = data;
  const response: ValidationResponse = { isValid: true };
  const parsedDate = parse(
    `${dayOfBirth}/${monthOfBirth}/${yearOfBirth}`,
    'dd/MM/yyyy',
    new Date()
  );

  if (isValid(parsedDate)) {
    if (isBefore(new Date(), parsedDate)) {
      return { ...response, isValid: false, message: FUTURE_DATE_MESSAGE };
    }
    const age = differenceInYears(new Date(), parsedDate);
    return age < 18
      ? { ...response, isValid: false, message: INVALID_PATIENT_AGE }
      : response;
  }
  return { ...response, isValid: false, message: INVALID_DATE_MESSAGE };
};

const patientAdmissionFormRules = {
  firstName: firstNameRule(
    'First name must be between 1-50 characters in length.'
  ),
  surname: surnameRule('Surname must be between 1-50 characters in length.'),
  address: yup.string().required(REQUIRED_TEXT),
  postcode: yup
    .string()
    .matches(
      /^(([A-Z]{1,2}\d[A-Z\d]?|ASCN|STHL|TDCU|BBND|[BFS]IQQ|PCRN|TKCA) ?\d[A-Z]{2}|BFPO ?\d{1,4}|(KY\d|MSR|VG|AI)[ -]?\d{4}|[A-Z]{2} ?\d{2}|GE ?CX|GIR ?0A{2}|SAN ?TA1)$/i, // nosonar
      'Postcode must be alphanumeric and may contain one space. No special characters allowed.'
    )
    .required(REQUIRED_TEXT),
  genderAssignedAtBirth: yup.string().required(REQUIRED_TEXT),
  ethnicity: requiredDropdownRule,
  nhsNumber: yup
    .string()
    .required(REQUIRED_TEXT)
    .matches(/^(?:\d{3} \d{3} \d{4}|\d{10})$/, NHS_NUMBER_VALIDATION_MESSAGE),
  email: yup
    .string()
    .matches(
      /^([a-zA-Z0-9_\-\\.+]+)@([a-zA-Z0-9_\-\\.]+)\.([a-zA-Z]{2,15})$/,
      'Please enter a valid email address.'
    )
    .required(REQUIRED_TEXT),
  phoneNumber: requiredPhoneNumber,
  nextOfKinFirstName: firstNameRule(
    'Name must be between 1-50 characters in length.'
  ).notRequired(),
  nextOfKinSurname: surnameRule(
    'Name must be between 1-50 characters in length.'
  ).notRequired(),
  dayOfBirth: requiredNumber(DOB_REQUIRED_TEXT),
  monthOfBirth: requiredNumber(DOB_REQUIRED_TEXT),
  yearOfBirth: requiredYear(),
  nextOfKinPhoneNumber: yup.string().when(' ', (val) => {
    if (val?.nextOfKinPhoneNumber?.length > 0) {
      return yup
        .string()
        .required(REQUIRED_TEXT)
        .nullable()
        .matches(
          /^\d*$/,
          'Phone number must be a whole number, no spaces or special characters allowed.'
        )
        .min(10, 'Phone number must be at least 10 digits.')
        .max(15, 'Phone number cannot be more than 15 digits.');
    }
    return yup.string().notRequired().nullable();
  }),
} as { [key in Partial<keyof PatientAdmissionPersonalFormData>]: any };

export const patientAdmissionSchema = yup.object(patientAdmissionFormRules);

export const invalidDateOfBirthResponseHandler = (
  message: string,
  setError: UseFormSetError<PatientAdmissionPersonalFormData>,
  setFocus: UseFormSetFocus<PatientAdmissionPersonalFormData>
) => {
  setError('dateOfBirth', {
    type: 'custom',
    message,
  });

  const messageToFieldMap: Record<
    string,
    keyof PatientAdmissionPersonalFormData
  > = {
    [INVALID_DATE_MESSAGE]: 'dayOfBirth',
    [INVALID_PATIENT_AGE]: 'yearOfBirth',
    default: 'monthOfBirth',
  };

  const formField = messageToFieldMap[message] ?? messageToFieldMap.default;
  setFocus(formField);
};
