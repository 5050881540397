import { Tooltip } from '@bt-healthcare/ui-toolkit';
import type { ConversionFunction, UnitCodeValue } from './utils.types';
import {
  POUNDS_TO_KG_CONVERSION_FACTOR,
  INCHES_TO_CM_CONVERSION_FACTOR,
  UnitCode,
} from './utils.constants';
import { EMPTY_VALUE } from 'App.constants';
import {
  CvdFamilyInformationMap,
  DiabetesStatusMap,
  YesNoMap,
} from 'mappings/enums';
import { type ClinicianPatientLatestInformationAttributes } from 'services/graphql';
import { isEmptyOrNil } from 'utils/object.utils';
import { getDate } from 'utils/date.utils';

const poundsToKilograms: ConversionFunction = (pounds) =>
  pounds * POUNDS_TO_KG_CONVERSION_FACTOR;

const inchesToCentimetres: ConversionFunction = (inches) =>
  inches * INCHES_TO_CM_CONVERSION_FACTOR;

const unitConversionFns: Record<string, ConversionFunction> = {
  [UnitCode.lb.unit]: poundsToKilograms,
  [UnitCode.in.unit]: inchesToCentimetres,
};

export const unitConvertor = (
  value: number,
  unit: UnitCodeValue['unit']
): number => {
  const unitConversionFn = unitConversionFns[unit] ?? ((v) => v);
  return unitConversionFn(value);
};

export const getYesNo = (isTrue: boolean) => (isTrue ? 'YES' : 'NO');

export const getHealthInfoValue = (value: any) =>
  isEmptyOrNil(value) ? EMPTY_VALUE : value;

export const patientHealthInformationList = (
  patientInfo: ClinicianPatientLatestInformationAttributes
) => {
  const patientWeight = patientInfo.latestObservationTypes?.weightInformation;
  const convertedPatientWeight = isEmptyOrNil(patientWeight)
    ? null
    : unitConvertor(patientWeight?.value!, patientWeight?.unit!).toFixed(1);

  const patientHeight = patientInfo.latestHealthInformation?.heightInformation;
  const convertedPatientHeight = isEmptyOrNil(patientHeight)
    ? null
    : unitConvertor(patientHeight?.value!, patientHeight?.unit!);
  const {
    totalCholesterolInformation,
    hdlCholesterolInformation,
    fastingBloodGlucoseInformation,
  } = patientInfo.latestObservationTypes || {};
  const { diabetesInformation, ckdInformation, cvdInformation } =
    patientInfo.latestHealthInformation || {};

  return [
    {
      label: 'Weight',
      value: isEmptyOrNil(patientWeight) ? (
        EMPTY_VALUE
      ) : (
        <Tooltip
          content={getDate(patientWeight?.created)}
          id="patient-weight-tooltip"
          direction="top-start"
        >
          {convertedPatientWeight} kg
        </Tooltip>
      ),
    },
    {
      label: 'Height',
      value: isEmptyOrNil(patientHeight) ? (
        EMPTY_VALUE
      ) : (
        <Tooltip
          content={getDate(patientHeight?.created)}
          id="patient-height-tooltip"
          direction="top-start"
        >
          {convertedPatientHeight} cm
        </Tooltip>
      ),
    },
    {
      label: 'BMI',
      value: getHealthInfoValue(patientInfo.res_bmiValue),
    },
    {
      label: 'Total cholesterol',
      value: isEmptyOrNil(totalCholesterolInformation?.value) ? (
        EMPTY_VALUE
      ) : (
        <Tooltip
          content={getDate(totalCholesterolInformation?.created)}
          id="patient-total-cholesterol-tooltip"
          direction="top-start"
        >
          {totalCholesterolInformation?.value}{' '}
          {totalCholesterolInformation?.unit}
        </Tooltip>
      ),
    },
    {
      label: 'HDL cholesterol',
      value: isEmptyOrNil(hdlCholesterolInformation?.value) ? (
        EMPTY_VALUE
      ) : (
        <Tooltip
          content={getDate(hdlCholesterolInformation?.created)}
          id="patient-hdl-cholesterol-tooltip"
          direction="top-start"
        >
          {hdlCholesterolInformation?.value} {hdlCholesterolInformation?.unit}
        </Tooltip>
      ),
    },
    {
      label: 'Fasting blood glucose',
      value: isEmptyOrNil(fastingBloodGlucoseInformation?.value) ? (
        EMPTY_VALUE
      ) : (
        <Tooltip
          content={getDate(fastingBloodGlucoseInformation?.created)}
          id="patient-fasting-blood-glucose-tooltip"
          direction="top-start"
        >
          {fastingBloodGlucoseInformation?.value}{' '}
          {fastingBloodGlucoseInformation?.unit}
        </Tooltip>
      ),
    },
    {
      label: 'Diabetes status',
      value: isEmptyOrNil(diabetesInformation?.diabetesStatus) ? (
        EMPTY_VALUE
      ) : (
        <Tooltip
          content={getDate(diabetesInformation?.created)}
          id="patient-diabetes-status-tooltip"
          direction="top-start"
        >
          {DiabetesStatusMap[diabetesInformation?.diabetesStatus!]}
        </Tooltip>
      ),
    },
    {
      label: 'Chronic kidney disease status',
      value: isEmptyOrNil(ckdInformation?.ckdStatus) ? (
        EMPTY_VALUE
      ) : (
        <Tooltip
          content={getDate(ckdInformation?.created)}
          id="patient-chronic-kidney-disease-status-tooltip"
          direction="top-start"
        >
          {YesNoMap[getYesNo(ckdInformation?.ckdStatus!)]}
        </Tooltip>
      ),
    },
    {
      label: 'Cardiovascular disease status',
      value: isEmptyOrNil(cvdInformation?.hasCvd) ? (
        EMPTY_VALUE
      ) : (
        <Tooltip
          content={getDate(cvdInformation?.created)}
          id="patient-cardiovascular-disease-status-tooltip"
          direction="top-start"
        >
          {cvdInformation?.hasCvd ? 'Yes' : 'None'} <br />{' '}
          {CvdFamilyInformationMap[cvdInformation?.familyHadCvd!]}
        </Tooltip>
      ),
    },
  ];
};
