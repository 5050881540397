import { device, spacing } from '@bt-healthcare/ui-toolkit';
import styled from 'styled-components';

export const TextWrapper = styled.div`
  margin-bottom: ${spacing.s3};
  display: flex;
  justify-content: space-between;

  .clear-all {
    cursor: pointer;
    display: none;

    @media ${device.largeTablet} {
      display: block;
    }
  }
`;

export const AccordionWrapper = styled.div`
  margin: ${spacing.s8} ${spacing.none};
  display: flex;
  flex-direction: column;
`;
