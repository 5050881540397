import { Button, Modal } from '@bt-healthcare/ui-toolkit';

import { ModalTitle } from '../ModalTitle';
import type { CancelAdmissionModalProps } from '../types';
import {
  ButtonWrapper,
  ModalBody,
  ModalStrapline,
  TextWrapper,
} from 'components/Modal/styles';

export const CancelAdmissionModal = ({
  isModalOpen,
  handleProceed,
  handleClose,
}: CancelAdmissionModalProps) => (
  <Modal
    onClose={handleClose}
    isModalOpen={isModalOpen}
    id="admission-cancel-modal"
  >
    <ModalBody>
      <TextWrapper>
        <ModalTitle title="Cancel admission" />
        <ModalStrapline>
          Are you sure you want to cancel? All progress and any information that
          you have entered will be lost.
        </ModalStrapline>
      </TextWrapper>
      <ButtonWrapper>
        <Button
          id="cancel-cancellation"
          onClick={handleClose}
          variant="primary"
        >
          No, continue
        </Button>
        <Button
          id="continue-cancellation"
          onClick={handleProceed}
          variant="primaryRed"
        >
          Yes, cancel
        </Button>
      </ButtonWrapper>
    </ModalBody>
  </Modal>
);
