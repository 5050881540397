import {
  Accordion,
  AccordionItem,
  Card,
  ErrorNotification,
  SubHeader,
  colors,
  fontSizes,
  toHyphenCase,
  Text,
} from '@bt-healthcare/ui-toolkit';
import { useEffect, useState } from 'react';
import type { PatientLifestyleProps } from './types';
import { SubHeaderWrapper } from './styles';
import { patientLifestyleList } from './util';
import type { ClinicianPatientLatestInformationAttributes } from 'services/graphql';
import { useGetLatestLifestyleInformationLazyQuery } from 'services/graphql';
import { FETCH_POLICY } from 'App.constants';
import {
  PatientTabWrapper,
  PatientDetailsWrapper,
  PatientDetail,
  LabelText,
  ValueText,
} from 'pages/Patient/styles';
import { PatientViewSkeleton } from 'components/Skeletons/PatientViewSkeleton';

export const PatientLifestyle = ({
  wardPatientId,
  personId,
}: PatientLifestyleProps) => {
  const [healthInformation, setHealthInformation] = useState<any>();
  const [getLatestHealthInformation, { loading, error, refetch }] =
    useGetLatestLifestyleInformationLazyQuery();

  useEffect(() => {
    getLatestHealthInformation({
      variables: {
        wardPatientId,
        personId,
      },
      fetchPolicy: FETCH_POLICY.CACHE_AND_NETWORK,
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        const latestHealthInformation = patientLifestyleList(
          data.clinicianPatientLatestInformation
            ?.attributes as ClinicianPatientLatestInformationAttributes
        );
        setHealthInformation(latestHealthInformation);
      },
    });
  }, []);

  if (loading)
    return (
      <PatientTabWrapper>
        <Card id="patient-lifestyle-loading-card">
          <PatientViewSkeleton />
        </Card>
      </PatientTabWrapper>
    );

  if (error)
    return (
      <ErrorNotification
        id="lifestyle-view"
        action="trying to load lifestyle information"
        onTryAgainClick={() => refetch()}
      />
    );

  return (
    <PatientTabWrapper>
      <Card id="patient-lifestyle-card">
        <SubHeaderWrapper>
          <SubHeader>Lifestyle</SubHeader>
        </SubHeaderWrapper>
        <Accordion
          id="patient-lifestyle"
          data-testid="patient-lifestyle-accordion"
        >
          <AccordionItem
            title={
              <Text
                color={colors.secondaryBlue.blue09}
                fontSize={fontSizes.base}
              >
                Smoking habits
              </Text>
            }
            id="smoking-habits"
            variant="secondary"
            expanded
          >
            <PatientDetailsWrapper>
              {healthInformation?.map((item: any) => (
                <PatientDetail
                  key={item.label}
                  data-testid={toHyphenCase(item.label)}
                >
                  <LabelText>{item.label}:</LabelText>
                  <ValueText>{item.value}</ValueText>
                </PatientDetail>
              ))}
            </PatientDetailsWrapper>
          </AccordionItem>
        </Accordion>
      </Card>
    </PatientTabWrapper>
  );
};
