import { colors, spacing, fontSizes } from '@bt-healthcare/ui-toolkit';

export const filterButtonStyles = () => ({
  background: colors.base.white,
  border: `1px solid ${colors.grey.grey06}`,
  padding: `${spacing.s2}`,
  borderRadius: spacing.s2,
  display: 'flex',
  alignItems: 'center',
  gap: spacing.s2,
  fontSize: fontSizes.sm,
  cursor: 'pointer',
});

export const selectedStyles = () => ({
  border: `1px solid ${colors.primaryIndigo.indigo08}`,
  background: colors.primaryIndigo.indigo01,
  color: colors.primaryIndigo.indigo08,
});

export const activeStyles = () => ({
  border: `1px solid ${colors.primaryIndigo.indigo08}`,
  background: 'transparent',
  color: colors.primaryIndigo.indigo08,
});
