import { Skeleton, Spacer } from '@bt-healthcare/ui-toolkit';

import { SkeletonWrapper } from './SkeletonWrapper';
import type { ObservationTypeLineChartSkeletonProps } from './types';
import { DateFilter } from 'components/Visualisations/types';

export const ObservationTypeLineChartSkeleton = ({
  dateFilter,
  count = 1,
}: ObservationTypeLineChartSkeletonProps) => (
  <>
    {dateFilter === DateFilter.Monthly ||
      (dateFilter === DateFilter.Biweekly && (
        <Skeleton
          id="notification-skeleton"
          data-testid="notification-skeleton"
          variant="rectangular"
          height={50}
          rounded
        />
      ))}
    <SkeletonWrapper count={count}>
      <Skeleton
        id="line-chart-header-skeleton"
        data-testid="line-chart-header-skeleton"
        variant="text"
        fontSize="h4"
        width="30%"
        rounded
      />
      <Skeleton
        id="line-chart-skeleton"
        data-testid="line-chart-skeleton"
        variant="rectangular"
        height={300}
        rounded
      />
      <Spacer size="s12" />
    </SkeletonWrapper>
  </>
);
