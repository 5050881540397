import { useAuth0 } from '@auth0/auth0-react';

import { getCareSetting, getProfile, getUserRoles } from './auth.utils';
import { useGetUserProfileBySafIdQuery } from 'services/graphql';
import { setSentryUser } from 'sentry';

export const useAuthUserProfile = () => {
  const { user, isAuthenticated, isLoading } = useAuth0();
  const { data, error, loading } = useGetUserProfileBySafIdQuery({
    variables: user?.sub ? { safId: user.sub } : undefined,
    skip: !isAuthenticated || !user,
  });

  let userId;

  if (data?.userProfile) {
    userId = data.userProfile.id;
    setSentryUser(userId);
  }

  const careSetting = getCareSetting(
    data?.userProfile?.attributes.defaultWardId ?? null,
    data?.userProfile?.attributes.defaultCareSettingId ?? null,
    data?.userProfile?.careSetting?.attributes
  );

  const profile = getProfile(careSetting, user);
  const userRoles = getUserRoles(data);
  return {
    data,
    loading: isLoading || loading,
    error,
    careSetting,
    profile,
    userId,
    userRoles,
  };
};
