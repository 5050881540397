import { spacing, device } from '@bt-healthcare/ui-toolkit';
import styled from 'styled-components';

export const FilterStatusChipWrapper = styled.div`
  display: flex;
  grid-gap: ${spacing.s2};
  padding: ${spacing.s1};
  svg {
    &:hover {
      cursor: pointer;
      opacity: 0.75;
    }
  }
`;

export const FilterStatusChipGroupWrapper = styled.div`
  display: none;
  @media ${device.largeTablet} {
    display: flex;
    grid-gap: ${spacing.s2};
    padding-top: ${spacing.s4};
    flex-wrap: wrap;
  }
`;
