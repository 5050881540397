import { Notification } from '@bt-healthcare/ui-toolkit';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { isEmpty } from 'ramda';

import type { UserListProps } from './types';
import { UserListRenderer } from './UserListRenderer';
import { UserState, useUpdateUserStateMutation } from 'services/graphql';
import { ROUTE } from 'config/routes';
import { UserDeactivationModal } from 'components/Modal/UserDeactivation';
import {
  useSettings,
  useSettingsDispatch,
} from 'context/settings/SettingsContext';

export const UserList = ({ data, refetch, loading }: UserListProps) => {
  const [deactivationModalOpen, setDeactivationModalOpen] = useState(false);
  const navigate = useNavigate();

  const [updateUserState, { loading: userUpdateLoading }] =
    useUpdateUserStateMutation();

  const dispatch = useSettingsDispatch();
  const { user, userAction } = useSettings();

  const handleClose = () => {
    dispatch({ type: 'resetUser' });
    dispatch({ type: 'resetUserAction' });
    setDeactivationModalOpen(false);
  };

  const handleDeactivate = (userId: string) => {
    updateUserState({
      variables: {
        input: {
          userId,
          userState: UserState.Disabled,
        },
      },
      onCompleted: () => {
        if (refetch) refetch();
        handleClose();
      },
    });
  };

  const handleEdit = () => {
    if (userAction.userAction === 'activate') {
      return navigate(ROUTE.SETTINGS_USER_REACTIVATE);
    }
    return navigate(ROUTE.SETTINGS_USER_EDIT);
  };

  useEffect(() => {
    if (!isEmpty(userAction)) {
      const selectedUser = data.find((item) => item.id === userAction.userId);
      if (selectedUser) {
        dispatch({ type: 'setUser', user: selectedUser });
        switch (userAction.userAction) {
          case 'edit':
            handleEdit();
            break;
          case 'deactivate':
            setDeactivationModalOpen(true);
            break;
          case 'activate':
            handleEdit();
            break;
          default: {
            break;
          }
        }
      }
    }
  }, [userAction]);

  return (
    <>
      {!isEmpty(user) && (
        <UserDeactivationModal
          modalOpen={deactivationModalOpen}
          handleClose={handleClose}
          handleDeactivate={() => handleDeactivate(user.id!)}
        />
      )}
      {!loading && data.length === 0 ? (
        <Notification
          type="informative"
          data-testid="clinician-users-search-results-notification"
        >
          Search returned no results. Please try again.
        </Notification>
      ) : (
        <UserListRenderer data={data} loading={loading || userUpdateLoading} />
      )}
    </>
  );
};
