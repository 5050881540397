import Config from './config.json';

type EnvVars = keyof Pick<
  ImportMetaEnv,
  | 'VITE_ENV'
  | 'VITE_AUTH_CLIENT_ID'
  | 'VITE_AUTH_AUTHORITY'
  | 'VITE_SERVICE_GRAPHQL'
  | 'VITE_MIXPANEL_ANALYTICS_TOKEN'
  | 'VITE_SENTRY_DSN'
  | 'VITE_FLAGSMITH_ENV_KEY'
>;

export const getEnvVariable = (variable: EnvVars) =>
  Config[variable] && !Config[variable].includes('$VITE_')
    ? Config[variable]
    : import.meta.env[variable]!;
