import * as AppStorage from 'storage';

import { STORAGE_KEYS } from 'App.constants';
import type { HypertensionAssessmentFormData } from 'components/HypertensionAssessmentForm/types';
import type {
  PatientAdmissionMedicalFormData,
  PatientAdmissionPersonalFormData,
} from 'components/PatientAdmissionForm/types';
import type { FilterState, GuidelineAcceptance } from 'reducers/patient/types';
import { initialFilterState } from 'reducers/patient/patientReducer';

export const PatientPersistedState = () => {
  const hypertensionAssessmentFormData =
    AppStorage.get<HypertensionAssessmentFormData>(
      STORAGE_KEYS.FORM_HYPERTENSION_ASSESSMENT
    );
  const admissionFormData = {
    personal: AppStorage.get<PatientAdmissionPersonalFormData>(
      STORAGE_KEYS.FORM_PATIENT_ADMISSION_PERSONAL
    ),
    medical: AppStorage.get<PatientAdmissionMedicalFormData>(
      STORAGE_KEYS.FORM_PATIENT_ADMISSION_MEDICAL
    ),
  };
  const guidelineAcceptance = AppStorage.get<GuidelineAcceptance[]>(
    STORAGE_KEYS.GUIDELINE_ACCEPTANCE
  );
  const filters = AppStorage.get<FilterState>(STORAGE_KEYS.PATIENT_FILTER);

  return {
    filters: filters === null ? initialFilterState : filters,
    hypertensionAssessmentFormData:
      hypertensionAssessmentFormData == null
        ? ({} as HypertensionAssessmentFormData)
        : hypertensionAssessmentFormData,
    admissionFormData:
      admissionFormData == null
        ? ({
            personal: {} as PatientAdmissionPersonalFormData,
            medical: {} as PatientAdmissionMedicalFormData,
          } as any)
        : admissionFormData,
    guidelineAcceptance:
      guidelineAcceptance == null
        ? ([] as GuidelineAcceptance[])
        : guidelineAcceptance,
  };
};
