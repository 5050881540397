import { Route, Routes } from 'react-router-dom';
import { PageNotFound, Unauthorised } from '@bt-healthcare/ui-toolkit';

import { ProtectedRoute } from './ProtectedRoute';
import { PatientLayout } from './PatientLayout';
import { SettingsLayout } from './SettingsLayout';
import { RBACRoute } from './RBACRoute';
import { RoutePageWrapper } from './styles';
import { PatientAdmissionForm } from 'pages/PatientAdmission/PatientAdmissionForm';
import { PatientList } from 'pages/PatientList/PatientList';
import { ROUTE } from 'config/routes';
import { Logout } from 'pages/Logout';
import { Discharge } from 'pages/PatientDischarge/PatientDischarge';
import { DischargeConfirmed } from 'pages/PatientDischarge/PatientDischargeConfirmed';
import { Patient } from 'pages/Patient';
import { HypertensionAssessmentConfirm } from 'pages/Assessment/HypertensionAssessmentConfirm';
import { HypertensionAssessment } from 'pages/Assessment/HypertensionAssessment';
import { UserConfirmationFeatureRoute } from 'features/userSettings/UserConfirmationFeatureRoute';
import { RBACAction } from 'auth/useHasAccess';
import { Settings } from 'pages/Settings/Settings';
import { UserManagementForm } from 'pages/Settings/UserManagementForm';
import { Users } from 'pages/Settings/Users';
import { StartAdmissionFeatureRoute } from 'pages/StartAdmission/StartAdmissionFeatureRoute';

export const AppRoutes = () => (
  <RoutePageWrapper>
    <Routes>
      <Route path={ROUTE.HOME} element={<ProtectedRoute />}>
        <Route element={<PatientLayout />}>
          <Route path={ROUTE.HOME} element={<PatientList />} />
          <Route path={ROUTE.PATIENT_DETAIL} element={<Patient />} />
          <Route path={ROUTE.ADMISSION} element={<PatientAdmissionForm />} />
          <Route
            path={ROUTE.START_ADMISSION}
            element={<StartAdmissionFeatureRoute />}
          />
          <Route path={ROUTE.DISCHARGE} element={<Discharge />} />
          <Route
            path={ROUTE.ASSESSMENT_HYPERTENSION}
            element={<HypertensionAssessment />}
          />
          <Route
            path={ROUTE.ASSESSMENT_HYPERTENSION_CONFIRM}
            element={<HypertensionAssessmentConfirm />}
          />
          <Route
            path={ROUTE.DISCHARGE_CONFIRMED}
            element={<DischargeConfirmed />}
          />
        </Route>
        <Route element={<SettingsLayout />}>
          <Route
            element={
              <RBACRoute
                redirectPath={ROUTE.UNAUTHORISED}
                actions={[RBACAction.ADMINISTER_USERS]}
              />
            }
          >
            <Route path={ROUTE.SETTINGS} element={<Settings />} />
            <Route path={ROUTE.SETTINGS_USER} element={<Users />} />
            <Route
              path={ROUTE.SETTINGS_USER_CREATE}
              element={<UserManagementForm />}
            />
            <Route
              path={ROUTE.SETTINGS_USER_EDIT}
              element={<UserManagementForm />}
            />
            <Route
              path={ROUTE.SETTINGS_USER_REACTIVATE}
              element={<UserManagementForm />}
            />
            <Route
              path={ROUTE.SETTINGS_USER_CONFIRMED}
              element={<UserConfirmationFeatureRoute />}
            />
          </Route>
        </Route>
      </Route>
      <Route path={ROUTE.LOGOUT} element={<Logout />} />
      <Route path={ROUTE.UNAUTHORISED} element={<Unauthorised hideButton />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  </RoutePageWrapper>
);
