import {
  BTHealthIcon,
  Button,
  Card,
  Col,
  GRID_BREAKPOINT,
  Grid,
  Spacer,
  Text,
  colors,
  fontSizes,
} from '@bt-healthcare/ui-toolkit';
import { createSearchParams, useNavigate } from 'react-router-dom';

import { useEffect } from 'react';
import {
  CardRow,
  FieldRow,
  FieldWrapper,
  PersonWrapper,
  Seperator,
} from './styles';
import { ROUTE } from 'config/routes';
import { Step } from 'pages/StartAdmission/types';
import { useAdmissionForm } from 'context/admission/AdmissionContext';
import { returnCheckOrCross } from 'pages/StartAdmission/utils';
import { useGetWardPatientsLazyQuery } from 'services/graphql';
import { FETCH_POLICY } from 'App.constants';
import { useAuthUserProfile } from 'auth/useAuthUserProfile';

export const StartAdmissionConfirm = () => {
  const navigate = useNavigate();
  const { admission, isStandalone, matchedData, person } = useAdmissionForm();
  const { data: userProfileData } = useAuthUserProfile();
  const wardId = userProfileData?.userProfile?.attributes.defaultWardId;

  const [getWardPatient, { data }] = useGetWardPatientsLazyQuery({
    errorPolicy: 'all',
    fetchPolicy: FETCH_POLICY.CACHE_AND_NETWORK,
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (data) {
      const wardPatient = data.wardPatientsSearch.searchResults.find(
        (x) => x.personId === person.personId
      );
      navigate(ROUTE.PATIENT_DETAIL, {
        state: {
          wardPatientId: wardPatient?.id,
        },
      });
    }
  }, [data]);

  const onContinue = () => {
    navigate({
      pathname: ROUTE.ADMISSION,
    });
  };

  const onCancel = () => {
    navigate({
      pathname: ROUTE.START_ADMISSION,
      search: createSearchParams({ step: Step.FORM }).toString(),
    });
  };

  const onPatientProfile = () => {
    getWardPatient({
      variables: {
        wardId: wardId ?? '',
        ascending: null,
        sortKey: null,
        from: 0,
        limit: 30,
        search: `${admission.firstName} ${admission.surname}`,
        admissionStatuses: [],
        monitoringStatuses: [],
        monitoredConditions: [],
        registeredGPSurgeries: null,
        testStatuses: null,
        consultationStatuses: null,
      },
    });
  };

  return (
    <div data-testid="start-admission-confirm">
      <Spacer size="s10" />
      <Grid>
        <Col
          col={{
            [GRID_BREAKPOINT.small]: '1 / 13',
            [GRID_BREAKPOINT.large]: '1 / 13',
          }}
        >
          <Card id="admission-confirm-card">
            <CardRow>
              <PersonWrapper>
                <BTHealthIcon
                  icon="Person"
                  color={colors.base.white}
                  size={24}
                />
              </PersonWrapper>
              <Text color={colors.grey.grey10} as="h4" fontSize={fontSizes.h4}>
                {admission.firstName} {admission.surname}
              </Text>
              <Seperator />
              <FieldRow>
                <FieldWrapper>
                  <Text color={colors.grey.grey08}>Name:</Text>
                  <Text color={colors.grey.grey10}>{admission.firstName}</Text>
                  {returnCheckOrCross(matchedData?.firstName)}
                </FieldWrapper>

                <FieldWrapper>
                  <Text color={colors.grey.grey08}>Surname:</Text>
                  <Text color={colors.grey.grey10}>{admission.surname}</Text>
                  {returnCheckOrCross(matchedData?.surname)}
                </FieldWrapper>
                <FieldWrapper>
                  <Text color={colors.grey.grey08}>D.O.B:</Text>
                  <Text color={colors.grey.grey10}>
                    {admission.dateOfBirth}
                  </Text>
                  {returnCheckOrCross(matchedData?.dateOfBirth)}
                </FieldWrapper>
              </FieldRow>
            </CardRow>
          </Card>
        </Col>
        {isStandalone && (
          <Col
            col={{
              [GRID_BREAKPOINT.small]: '1 / 12',
              [GRID_BREAKPOINT.large]: '1 / 12',
            }}
          >
            <Text color={colors.grey.grey10} as="div" fontSize={fontSizes.base}>
              By completing this action the patient will receive a request to
              allow access to their historical data.
            </Text>
          </Col>
        )}
        <Col
          col={{
            [GRID_BREAKPOINT.small]: '1 / 12',
            [GRID_BREAKPOINT.large]: '1 / 6',
          }}
        >
          {isStandalone ? (
            <Button id="start-admission-continue-btn" onClick={onContinue}>
              Step up to remote care
            </Button>
          ) : (
            <Button
              id="start-admission-patient-profile-btn"
              onClick={onPatientProfile}
            >
              See patient profile
            </Button>
          )}
        </Col>
        <Col
          col={{
            [GRID_BREAKPOINT.small]: '1 / 12',
            [GRID_BREAKPOINT.large]: '6 / 11',
          }}
        >
          <Button
            id="start-admission-cancel-btn"
            onClick={onCancel}
            variant="secondary"
            transparent
          >
            {isStandalone ? 'Cancel' : 'Back to admissions'}
          </Button>
        </Col>
      </Grid>
    </div>
  );
};
