import { colors, device, spacing } from '@bt-healthcare/ui-toolkit';
import styled from 'styled-components';

export const ButtonGroupWrapper = styled.div`
  position: relative;
  display: flex;
  gap: ${spacing.s8};

  > div:first-child {
    width: 288px;
  }

  > div:last-child {
    display: none;

    @media ${device.largeTablet} {
      display: block;
      width: 107px;
    }
  }
`;

export const ObservationTypeLineChartWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`;

export const NavigationWrapper = styled.div<{ disabled?: boolean }>`
  width: 24px;
  height: 24px;
  text-align: center;
  background: ${colors.grey.grey02};
  opacity: ${({ disabled }) => (disabled ? 0.2 : 1)};
  border-radius: 50%;
  transition: 0.24s;
  &:hover {
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
    background: ${({ disabled }) =>
      disabled ? colors.grey.grey02 : colors.grey.grey04};
  }
`;

export const CustomGraphFilterButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${spacing.s2};
`;
