import { SubHeader, Wrapper } from '@bt-healthcare/ui-toolkit';
import { useLocation, useNavigate } from 'react-router-dom';

import { Breadcrumbs } from '../Breadcrumbs';
import { HeaderWrapper, AppMenuHeader, LeftContent } from './styles';
import type { HeaderProps } from './types';
import { ROUTE } from 'config/routes';
import { useApp } from 'context/app/AppContext';
import { MenuActionButton } from 'components/MenuActionButton';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { useTracking } from 'hooks/useTracking';
import { PageName } from 'config/pageNames';

export const Header = ({ sideNavOpen }: HeaderProps) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { isRemoteCareAdmissionFeatureFlagEnabled } = useFeatureFlags();
  const { trackTapped } = useTracking();

  const { careSetting } = useApp();

  const homeRoute = pathname === ROUTE.HOME;
  const settingsRoute = pathname === ROUTE.SETTINGS;
  const userSettingsRoute = pathname === ROUTE.SETTINGS_USER;

  const navigateAdmitPatient = () => {
    trackTapped(
      'Admit CTA',
      isRemoteCareAdmissionFeatureFlagEnabled
        ? PageName.START_ADMISSION
        : PageName.ADMISSION
    );

    return navigate(
      isRemoteCareAdmissionFeatureFlagEnabled
        ? ROUTE.START_ADMISSION
        : ROUTE.ADMISSION
    );
  };

  return (
    <HeaderWrapper
      id="patient-list-tab-item"
      data-testid="patient-list-tab-item"
      aria-label="Header"
      sideNavOpen={sideNavOpen}
    >
      <AppMenuHeader sideNavOpen={sideNavOpen}>
        <LeftContent>
          {homeRoute && <SubHeader>{careSetting.organisation}</SubHeader>}
          {settingsRoute && <SubHeader>Settings</SubHeader>}
          <Breadcrumbs />
        </LeftContent>
        {homeRoute && (
          <Wrapper>
            <MenuActionButton
              id="admit-patient-btn"
              onClick={navigateAdmitPatient}
              value="Admit patient"
            />
          </Wrapper>
        )}
        {userSettingsRoute && (
          <Wrapper>
            <MenuActionButton
              id="add-user-btn"
              onClick={() => navigate(ROUTE.SETTINGS_USER_CREATE)}
              value="Add new user"
            />
          </Wrapper>
        )}
      </AppMenuHeader>
    </HeaderWrapper>
  );
};
