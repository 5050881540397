import styled from 'styled-components';

import { spacing, device } from '@bt-healthcare/ui-toolkit';

import DischargeConfirmed from 'assets/discharge-confirmed.svg?react';

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.s4};
  text-align: center;
`;

export const DischargeConfirmedSVG = styled(DischargeConfirmed)`
  width: 202px;
  height: 180px;

  @media ${device.tablet} {
    width: 327px;
    height: 301px;
  }

  @media ${device.largeTablet} {
    width: 423px;
    height: 389px;
  }
`;
