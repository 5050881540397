import type { MonitoringStatusChipProps } from '../PatientList.types';
import { sortByCondition } from '../PatientList.util';
import { MonitoringStatusRenderer } from './MonitoringStatusRenderer';
import { EMPTY_VALUE } from 'App.constants';
import { isEmptyOrNil } from 'utils/object.utils';

export const MonitoringStatusChip = ({
  monitoringStatusByCondition,
  id,
}: MonitoringStatusChipProps) => {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  if (isEmptyOrNil(monitoringStatusByCondition)) return <>{EMPTY_VALUE}</>;

  const monitoringConditionStatuses = sortByCondition(
    Object.entries(monitoringStatusByCondition).map(
      ([condition, statusType]) => ({
        condition,
        statusType,
      })
    )
  );

  return (
    <MonitoringStatusRenderer items={monitoringConditionStatuses} id={id} />
  );
};
