import { AuthenticationTemplate } from './auth/AuthTemplate';
import { Layout } from 'components/Page/Layout';
import { AppProvider } from 'context/app/AppProvider';
import { AppRoutes } from 'routes';

const App = () => (
  <AuthenticationTemplate>
    <AppProvider>
      <Layout>
        <AppRoutes />
      </Layout>
    </AppProvider>
  </AuthenticationTemplate>
);

export default App;
