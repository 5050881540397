import type { TypedOptionType } from '@bt-healthcare/ui-toolkit';
import { getColorValue, colors } from '@bt-healthcare/ui-toolkit';
import { useState } from 'react';

import { TestStatusOptions } from 'mappings/enums';
import type {
  MonitoredConditionTestStatus,
  MonitoredConditionTestStatusType,
} from 'services/graphql';
import { useUpdateWardPatientTestStatusMutation } from 'services/graphql';
import { TestStatusTypeColorMap } from 'pages/PatientList/PatientList.util';

export const useTestStatusUpdate = (
  testStatus?: MonitoredConditionTestStatus
) => {
  const initTestStatus = TestStatusOptions.find(
    (item) => item.value === testStatus?.status
  );
  const initTestStatusColor = testStatus?.status
    ? getColorValue(TestStatusTypeColorMap[testStatus.status])
    : colors.grey.grey03;

  const [currentColor, setCurrentColor] = useState(initTestStatusColor);

  const [values, setValues] = useState(TestStatusOptions);
  const [current, setCurrent] = useState<
    TypedOptionType<MonitoredConditionTestStatusType> | undefined
  >(initTestStatus);

  const [updateWardPatientTestStatus, { error, loading }] =
    useUpdateWardPatientTestStatusMutation();

  return {
    testStatusMutation: {
      mutation: updateWardPatientTestStatus,
      error,
      loading,
    },
    testStatusConfig: {
      current,
      setCurrent,
      options: { values, setValues },
      color: {
        currentColor,
        setCurrentColor,
      },
    },
  };
};
