import { Wrapper, device, spacing } from '@bt-healthcare/ui-toolkit';
import styled from 'styled-components';

export const BackButtonWrapper = styled(Wrapper)`
  display: none;

  @media ${device.largeTablet} {
    display: flex;
    align-items: center;
    gap: ${spacing.s1};
    cursor: pointer;
    width: fit-content;
  }
`;
