import { GRID_BREAKPOINT } from '@bt-healthcare/ui-toolkit';
import { pick } from 'ramda';
import { Grid } from '../styles';
import type {
  PatientAdmissionFormFieldsProps,
  PatientAdmissionPersonalFormData,
} from '../types';
import { FormFields } from 'components/Form/FormFields';
import { usePatient } from 'context/patient/PatientContext';
import { useAdmissionForm } from 'context/admission/AdmissionContext';

const colProps: Partial<
  Record<keyof PatientAdmissionPersonalFormData, Record<string, any>>
> = {
  dateOfBirth: {
    [GRID_BREAKPOINT.large]: '1 / 5',
  },
  genderAssignedAtBirth: {
    [GRID_BREAKPOINT.large]: '1 / 9',
  },
  genderIdentifiedAs: {
    [GRID_BREAKPOINT.large]: '1 / 5',
  },
  ethnicity: {
    [GRID_BREAKPOINT.large]: '1 / 9',
  },
  nhsNumber: {
    [GRID_BREAKPOINT.large]: '1 / 9',
  },
  mobility: {
    [GRID_BREAKPOINT.large]: '1 / 9',
  },
  nextOfKinPhoneNumber: {
    [GRID_BREAKPOINT.large]: '1 / 9',
  },
};

const inputProps: Partial<
  Record<keyof PatientAdmissionPersonalFormData, Record<string, any>>
> = {
  genderIdentifiedAs: {
    optional: true,
  },
  pronouns: {
    optional: true,
  },
  mobility: {
    optional: true,
  },
  nextOfKinFirstName: {
    optional: true,
  },
  nextOfKinSurname: {
    optional: true,
  },
  nextOfKinPhoneNumber: {
    optional: true,
  },
};

const disabledProps: Partial<
  Record<keyof PatientAdmissionPersonalFormData, Record<string, any>>
> = {
  firstName: {
    disabled: true,
  },
  surname: {
    disabled: true,
  },
  dateOfBirth: {
    disabled: true,
  },
  email: {
    disabled: true,
  },
};

const fieldNames: (keyof PatientAdmissionPersonalFormData)[] = [
  'firstName',
  'surname',
  'address',
  'dateOfBirth',
  'pronouns',
  'postcode',
  'email',
  'ethnicity',
  'genderAssignedAtBirth',
  'genderIdentifiedAs',
  'nextOfKinFirstName',
  'nextOfKinSurname',
  'nextOfKinPhoneNumber',
  'nhsNumber',
  'phoneNumber',
  'mobility',
];

export const PersonalAdmissionFormFields = ({
  register,
  control,
  errors,
  clearErrors,
}: PatientAdmissionFormFieldsProps) => {
  const {
    admissionFormData: { personal },
  } = usePatient();
  const { admission, isStandalone } = useAdmissionForm();
  const defaultValues = {
    ...pick(['dayOfBirth', 'monthOfBirth', 'yearOfBirth'], admission),
    ...personal,
  } as PatientAdmissionPersonalFormData;

  return (
    <Grid>
      <FormFields
        defaultValues={defaultValues}
        register={register}
        control={control}
        errors={errors}
        clearErrors={clearErrors}
        customColProps={colProps}
        customInputProps={{
          ...inputProps,
          ...(isStandalone ? disabledProps : {}),
        }}
        fieldNames={fieldNames}
      />
    </Grid>
  );
};
