import { Wrapper } from '@bt-healthcare/ui-toolkit';

import { AvgBloodPressureWrapper, VitalTrendsWrapper } from './styles';
import type { PatientHealthVitalsProps } from './types';
import { ObservationTypeLineChart } from 'components/Visualisations/ObservationTypeLineChart';
import { TodayReadings, AvgBloodPressure } from 'components/Readings';

export const PatientHealthVitals = ({
  wardPatientUI,
  hasBloodPressureMonitoring,
}: PatientHealthVitalsProps) => (
  <>
    <Wrapper>
      <TodayReadings
        personId={wardPatientUI.personId!}
        observationTypes={wardPatientUI.supportedObservationTypes}
        hasSubmittedReadings={wardPatientUI.hasSubmittedReadings}
      />
      {hasBloodPressureMonitoring && (
        <AvgBloodPressureWrapper>
          <AvgBloodPressure
            personId={wardPatientUI.personId!}
            admissionDateTime={wardPatientUI.admissionDateTime}
            hasSubmittedReadings={wardPatientUI.hasSubmittedReadings}
          />
        </AvgBloodPressureWrapper>
      )}
    </Wrapper>
    <VitalTrendsWrapper>
      <ObservationTypeLineChart
        lastReview={wardPatientUI.lastReview}
        personId={wardPatientUI.personId!}
        observationTypes={wardPatientUI.supportedObservationTypes}
        admissionDateTime={wardPatientUI.admissionDateTime}
        hasSubmittedReadings={wardPatientUI.hasSubmittedReadings}
      />
    </VitalTrendsWrapper>
  </>
);
