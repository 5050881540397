import type { NavItem } from './types';
import { ROUTE } from 'config/routes';

export const navItems: NavItem[] = [
  {
    id: 1,
    icon: 'Patient',
    label: 'Patient list',
    link: ROUTE.HOME,
  },
];
