import { useEffect } from 'react';
import type { ConfirmationDialogProps } from './types';

export const ConfirmationDialog = (props: ConfirmationDialogProps) => {
  const { onCancel, onOk, showConfirmation, message } = props;

  useEffect(() => {
    if (showConfirmation) {
      // eslint-disable-next-line no-alert
      if (window.confirm(message)) {
        onOk();
      } else {
        onCancel();
      }
    }
  }, [showConfirmation]);

  return null;
};
