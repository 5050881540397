import {
  colors,
  fontSizes,
  Spacer,
  Text,
  BTHealthIcon,
} from '@bt-healthcare/ui-toolkit';

import { ReadingValueBar } from '../ReadingValueBar';

import { ReadingTitle, Header, Wrapper } from './styles';
import type { ReadingItemProps } from './types';

export const ReadingItem = ({
  name,
  icon,
  isLast,
  type,
  observations,
}: ReadingItemProps) => {
  const { average, readings } = observations;
  return (
    <Wrapper isLast={isLast}>
      <Header>
        <ReadingTitle>
          <BTHealthIcon
            icon={icon}
            color={colors.primaryIndigo.indigo08}
            size={16}
          />
          <Text
            data-testid={`${type}-title`}
            color={colors.primaryIndigo.indigo08}
            fontWeight={500}
          >
            {name}
          </Text>
        </ReadingTitle>
        <Text
          data-testid={`${type}-daily-avg`}
          color={colors.grey.grey08}
          fontWeight={400}
          fontSize={fontSizes.sm}
        >
          Daily average: {average}
        </Text>
      </Header>
      <Spacer size="s6" />
      <ReadingValueBar type={type} readings={readings} />
    </Wrapper>
  );
};
