import type { Dispatch } from 'react';
import { createContext } from 'react';

import { useAppContext } from '../useAppContext';
import type {
  AdmissionActions,
  AdmissionState,
} from 'reducers/admission/types';

export const AdmissionContext = createContext<AdmissionState | null>(null);
export const AdmissionDispatchContext =
  createContext<Dispatch<AdmissionActions> | null>(null);

export const useAdmissionForm = () => useAppContext(AdmissionContext);
export const useAdmissionFormDispatch = () =>
  useAppContext(AdmissionDispatchContext);
