import {
  spacing,
  device,
  Text as ToolkitText,
} from '@bt-healthcare/ui-toolkit';
import styled from 'styled-components';

export const List = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: max-content;
  gap: ${spacing.s4};
`;

export const AdmissionConfirmationGrid = styled.div`
  display: grid;
  grid-template-rows: min-content;

  gap: ${spacing.s4};
  grid-template-columns: repeat(1, 1fr);

  @media ${device.tablet} {
    gap: ${spacing.s4};
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const Text = styled(ToolkitText)`
  word-break: break-word;
`;
