import type { PropsWithChildren } from 'react';
import { useReducer } from 'react';

import { SettingsContext, SettingsDispatchContext } from './SettingsContext';
import type { SettingsState } from 'reducers/settings/types';
import {
  initSettingsState,
  settingsReducer,
} from 'reducers/settings/settingsReducer';

export const SettingsProvider = ({
  children,
  value = initSettingsState,
}: PropsWithChildren<{ value?: SettingsState }>) => {
  const [state, dispatch] = useReducer(settingsReducer, value);
  return (
    <SettingsContext.Provider value={state}>
      <SettingsDispatchContext.Provider value={dispatch}>
        {children}
      </SettingsDispatchContext.Provider>
    </SettingsContext.Provider>
  );
};
