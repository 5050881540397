import { Skeleton, Spacer, Stack } from '@bt-healthcare/ui-toolkit';

import { SkeletonWrapper } from './SkeletonWrapper';

export const PatientViewSkeleton = () => (
  <SkeletonWrapper count={4}>
    <Stack id="patient-view-skeleton-stack">
      <Skeleton
        id="patient-view-skeleton"
        data-testid="patient-view-skeleton"
        variant="text"
        fontSize="h1"
        width="100%"
        rounded
      />
      <Spacer size="s4" />
    </Stack>
  </SkeletonWrapper>
);
