import type { SyntheticEvent } from 'react';
import { BTHealthIcon, colors, noop } from '@bt-healthcare/ui-toolkit';

import type { ObservationTypeLineChartNavigationProps } from './types';
import { NavigationWrapper, ObservationTypeLineChartWrapper } from './styles';

export const ObservationTypeLineChartNavigation = ({
  children,
  handleNavigation,
  showNext,
  showPrev,
  title,
}: ObservationTypeLineChartNavigationProps) => (
  <ObservationTypeLineChartWrapper>
    <NavigationWrapper
      onClick={(evt: SyntheticEvent) =>
        showPrev ? handleNavigation(evt, 'prev') : noop()
      }
      role="button"
      disabled={!showPrev}
    >
      <BTHealthIcon
        icon="ChevronLeft"
        size={10}
        color={colors.grey.grey08}
        title={showPrev ? `View previous ${title} trends` : ''}
      />
    </NavigationWrapper>
    {children}
    <NavigationWrapper
      onClick={(evt: SyntheticEvent) =>
        showNext ? handleNavigation(evt, 'next') : noop()
      }
      role="button"
      disabled={!showNext}
    >
      <BTHealthIcon
        icon="ChevronRight"
        title={showNext ? `View next ${title} trends` : ''}
        size={10}
        color={colors.grey.grey08}
      />
    </NavigationWrapper>
  </ObservationTypeLineChartWrapper>
);
