import { RetryLink } from '@apollo/client/link/retry';
import { logNetworkError } from 'sentry';

import { isMandatoryHeaderError } from 'services/errors/server';

/** See https://www.apollographql.com/docs/react/api/link/apollo-link-retry/ */
export const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: Infinity,
    jitter: true,
  },
  attempts: {
    max: 5,
    retryIf: (error, operation) => {
      /* Retry exclusions */
      if (isMandatoryHeaderError(error)) {
        logNetworkError(error, operation);
        return false;
      }
      logNetworkError(error, operation, true);
      return !!error;
    },
  },
});
