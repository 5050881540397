import { useRef, useState } from 'react';
import { BTHealthIcon, colors, Timestamp } from '@bt-healthcare/ui-toolkit';

import { useTextClamping } from '../useTextClamping';

import { Wrapper, Body, IconContainer, Title, TitleContainer } from './styles';

import type { WardPatientComments } from 'services/graphql';
import { CommentTypeMap } from 'mappings/enums';
import { EMPTY_VALUE } from 'App.constants';

export const NoteItem = ({ data }: { data: WardPatientComments }) => {
  const {
    attributes: { comment, commentType, recordedDateTime, audit },
    id,
  } = data;

  const [clamped, setClamped] = useState(true);
  const [showButton, setShowButton] = useState(true);
  const containerRef = useRef<HTMLInputElement>(null);
  const handleClick = () => setClamped(!clamped);

  useTextClamping(containerRef, setShowButton);

  return (
    <>
      <style>{`
        .clamp {
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          display: -webkit-box;
          overflow: hidden;
          text-overflow: ellipsis;
          overflow-wrap: break-word;
        }
      `}</style>
      <Wrapper data-testid={`comment-${id}`}>
        <TitleContainer
          role="button"
          tabIndex={0}
          onClick={handleClick}
          showPointer={showButton}
        >
          <Title data-testid={`title-${id}`}>
            {CommentTypeMap[commentType]}
          </Title>
          {showButton && (
            <IconContainer
              rotateIcon={!clamped}
              data-testid={`hide-show-icon-${id}`}
            >
              <BTHealthIcon
                icon="ChevronDown"
                color={
                  !clamped ? colors.primaryIndigo.indigo06 : colors.grey.grey06
                }
                size={16}
              />
            </IconContainer>
          )}
        </TitleContainer>
        <Body
          ref={containerRef}
          data-testid={`comment-text-${id}`}
          className={clamped ? 'clamp' : ''}
        >
          {comment}
        </Body>
        <Timestamp
          color="grey.grey08"
          date={recordedDateTime}
          type="dateTimeWithName"
          data-testid={`comment-timestamp-${id}`}
          name={
            audit?.createdByUser?.careSettingClinician?.attributes
              .displayName || EMPTY_VALUE
          }
          variant="small"
        />
      </Wrapper>
    </>
  );
};
