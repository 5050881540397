import { useAnalytics } from '@bt-healthcare/ui-toolkit';

import { UNKNOWN_VALUE } from 'App.constants';
import { useApp } from 'context/app/AppContext';

export const useAdmissionTracking = () => {
  const { careSetting } = useApp();
  const { trackEvent } = useAnalytics();

  const trackAdmissionType = (admissionType: string) => {
    trackEvent('Patient Admission', {
      admissionType,
      careSetting: careSetting?.organisation ?? UNKNOWN_VALUE,
    });
  };

  return {
    trackAdmissionType,
  };
};
