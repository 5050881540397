import { Col, Grid, Input, Row } from '@bt-healthcare/ui-toolkit';

import type { UserFormProps } from './types';
import { UserFormGrantPermissions } from './UserFormGrantPermissions';

export const UserForm = ({
  register,
  control,
  isEdit = false,
  errors,
}: UserFormProps) => (
  <Grid>
    <Row>
      <Col from={1}>
        <Input
          id="firstName"
          label="First name"
          {...register('firstName')}
          errorText={errors?.firstName?.message ? errors.firstName.message : ''}
        />
      </Col>
    </Row>
    <Row>
      <Col from={1}>
        <Input
          id="surname"
          label="Surname"
          {...register('surname')}
          errorText={errors?.surname?.message ? errors.surname.message : ''}
        />
      </Col>
    </Row>
    <Row>
      <Col from={1}>
        <Input
          id="email"
          label="Email address"
          {...register('emailAddress')}
          disabled={isEdit}
          helperText={isEdit ? "This field can't be updated" : ''}
          errorText={
            errors?.emailAddress?.message ? errors.emailAddress.message : ''
          }
        />
      </Col>
    </Row>
    <Row>
      <Col from={1}>
        <Input
          id="mobileNumber"
          label="Mobile number"
          {...register('mobileNumber')}
          errorText={
            errors?.mobileNumber?.message ? errors.mobileNumber.message : ''
          }
        />
      </Col>
    </Row>
    <Row>
      <Col from={1}>
        <UserFormGrantPermissions register={register} control={control} />
      </Col>
    </Row>
  </Grid>
);
