import { spacing } from '@bt-healthcare/ui-toolkit';
import styled from 'styled-components';
import { datePickerStyles } from 'styles/datePicker.styles';

export const DatePickerWrapper = styled.div`
  position: absolute;
  top: 0;

  ${datePickerStyles}
`;

export const ButtonWrapper = styled.div`
  padding: ${spacing.s6} ${spacing.s3} ${spacing.none} ${spacing.s3};
`;
