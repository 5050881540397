import * as yup from 'yup';

import {
  requiredDropdownRule,
  requiredMultiDropdownRule,
} from '../../Form/validationHelper';
import type { PatientAdmissionMedicalFormData } from '../types';

const patientAdmissionFormRules = {
  monitoredConditions: requiredMultiDropdownRule,
  gpSurgery: requiredDropdownRule,
} as {
  [key in Partial<keyof PatientAdmissionMedicalFormData>]: any;
};

export const medicalValidationSchema = yup.object(patientAdmissionFormRules);
