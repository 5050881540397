import type { LazyQueryExecFunction } from '@apollo/client';

import { FETCH_POLICY } from 'App.constants';
import type { DateRange } from 'components/Visualisations/LineChart/LineChart.types';
import { getObservationAbsoluteAverageInputVariables } from 'pages/Patient/utils';
import type {
  Exact,
  GetObservationAbsoluteAverageQuery,
  ObservationsAverageInput,
} from 'services/graphql';
import { ObservationType } from 'services/graphql';

export const loadObservationAbsoluteAverages = (
  getObservationAbsoluteAverages: LazyQueryExecFunction<
    GetObservationAbsoluteAverageQuery,
    Exact<{
      input: ObservationsAverageInput;
    }>
  >,
  dateRange: DateRange,
  personId: string,
  callback: Function
) => {
  getObservationAbsoluteAverages({
    fetchPolicy: FETCH_POLICY.CACHE_AND_NETWORK,
    notifyOnNetworkStatusChange: true,
    variables: {
      input: getObservationAbsoluteAverageInputVariables(
        dateRange,
        personId,
        ObservationType.BloodPressure
      ),
    },
    onCompleted: (data) => {
      callback(data);
    },
  });
};
