import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { ROUTE } from 'config/routes';

// All keys used in state should be added here and to locationState
type StateKeys = 'wardPatientId' | 'patient' | 'isNavigateBack';

type LocationState = {
  wardPatientId?: string;
  patient?: string;
  isNavigateBack?: boolean;
};

export const useRedirectAndReturnState = <T = string>(
  stateKey: StateKeys,
  shouldRedirectIfNull = true
) => {
  const navigate = useNavigate();
  const location = useLocation();
  const value = location.state && (location.state as LocationState)[stateKey];

  useEffect(() => {
    if (value == null && shouldRedirectIfNull) {
      navigate(ROUTE.HOME);
    }
  }, [value, navigate]);

  return value as T;
};
