import {
  Wrapper,
  colors,
  device,
  fontCalcMixin,
  fontSizes,
  spacing,
} from '@bt-healthcare/ui-toolkit';
import styled from 'styled-components';
import {
  desktopFooterHeight,
  mobileFooterHeight,
} from 'components/FormFooter/styles';

export const HypertensionOverlayWrapper = styled(Wrapper)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  pointer-events: none;

  @media ${device.largeTablet} {
    background: linear-gradient(
      44deg,
      rgba(85, 20, 180, 0.1) 0%,
      rgba(71, 46, 205, 0.1) 30.2%,
      rgba(68, 138, 255, 0.1) 76.7%
    );
    height: 236px;
  }
`;

export const HypertensionAssessmentHeaderWrapper = styled(Wrapper)`
  display: grid;
  grid-template-columns: 1fr 20px 1fr;
  padding: ${spacing.s4};
  min-height: 236px;

  @media ${device.largeTablet} {
    padding: ${spacing.s10};
  }
`;

export const BackButtonWrapper = styled(Wrapper)`
  @media ${device.largeTablet} {
    margin-bottom: ${spacing.s6};
  }
`;

export const SubHeader = styled.h3`
  color: ${colors.primaryIndigo.indigo08};
  font-weight: 500;
  ${fontCalcMixin(fontSizes.h4)};

  @media ${device.tablet} {
    ${fontCalcMixin(fontSizes.h3)};
  }
`;

export const HypertensionAssessmentContentLayout = styled(Wrapper)`
  display: grid;
  gap: ${spacing.s4};
  margin-bottom: ${mobileFooterHeight};

  @media ${device.largeTablet} {
    padding: ${spacing.s8};
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: ${desktopFooterHeight};
  }
`;

export const HypertensionAssessmentContentWrapper = styled(Wrapper)`
  display: flex;
  flex-direction: column;
  gap: ${spacing.s4};
  margin-top: ${spacing.s4};
`;

export const HypertensionAssessmentConfirmHeaderWrapper = styled(Wrapper)`
  padding: ${spacing.s4};

  @media ${device.largeTablet} {
    padding: ${spacing.s8};
  }
`;

export const HypertensionAssessmentConfirmContentLayout = styled(Wrapper)`
  display: grid;
  gap: ${spacing.s4};
  margin-bottom: ${mobileFooterHeight};

  @media ${device.largeTablet} {
    padding: ${spacing.none} ${spacing.s8} ${spacing.s8};
    grid-template-columns: 2fr 1fr;
    margin-bottom: ${desktopFooterHeight};
  }
`;

export const HypertensionAssessmentCarePlanWrapper = styled(Wrapper)`
  margin-top: ${spacing.s4};

  @media ${device.largeTablet} {
    margin-top: ${spacing.s14};
  }
`;

export const CardWrapper = styled(Wrapper)`
  display: flex;
  flex-direction: column;
  gap: ${spacing.s4};

  @media ${device.largeTablet} {
    gap: ${spacing.s6};
  }
`;
