import { useLocation, useNavigate } from 'react-router-dom';
import { menuItems } from 'config/menuItemData';
import { getMenuHeader } from 'config/routes.utils';

export const useMenu = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const menuHeader = getMenuHeader(pathname);
  const items = menuItems.map((item) => ({
    ...item,
    active: pathname === item.path,
  }));
  const onClick = (lbl: string) => {
    const menuItem = menuItems.find(({ label }) => lbl === label);
    if (menuItem?.path) {
      navigate(menuItem.path);
    }
  };
  // Potentially can remove menuItems and onClick
  return { menuItems: items, onClick, menuHeader };
};
