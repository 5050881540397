import {
  BTHealthIcon,
  Button,
  Card,
  Text,
  colors,
  fontSizes,
} from '@bt-healthcare/ui-toolkit';

import type { RecommendationCardProps } from './types';
import {
  CardHeader,
  CardContent,
  ButtonWrapper,
  RecommendationList,
  RecommendationListItem,
} from './styles';
import { getRecommendedReasons } from './utils';
import { GuidelineMap } from 'mappings/enums';

export const RecommendationCard = ({
  idx,
  onAccept,
  onDecline,
  guidelineType,
}: RecommendationCardProps) => {
  const recommendationReasons = getRecommendedReasons(guidelineType);
  return (
    <Card
      id="recommendation-card"
      background={colors.base.light}
      data-testid="recommendation-card"
    >
      <CardHeader>
        <Text color={colors.primaryIndigo.indigo08}>
          <BTHealthIcon
            icon="Bright"
            size={16}
            color={colors.primaryIndigo.indigo08}
          />{' '}
          Recommendation
        </Text>
      </CardHeader>
      <CardContent>
        <Text
          color={colors.grey.grey10}
          fontSize={fontSizes.h4}
          as="p"
          key={guidelineType}
        >
          {GuidelineMap[guidelineType]}
        </Text>
        <Text color={colors.grey.grey10} as="p">
          Recommendation based on:
        </Text>
        <RecommendationList>
          {recommendationReasons.map((item) => (
            <RecommendationListItem key={item.id}>
              {item.reason}
            </RecommendationListItem>
          ))}
        </RecommendationList>
        <Text color={colors.grey.grey10}>
          Accept or decline in order to proceed:
        </Text>
      </CardContent>

      <ButtonWrapper>
        <Button
          id="accept-recommendation-btn"
          onClick={() => onAccept(guidelineType, idx)}
        >
          Accept
        </Button>
        <Button
          variant="primaryRed"
          id="decline-recommendation-btn"
          onClick={() => onDecline(guidelineType, idx)}
        >
          Decline
        </Button>
      </ButtonWrapper>
    </Card>
  );
};
