import { timeHour, timeDay } from 'd3-time';
import { addDays, differenceInDays } from 'date-fns';

import { DateFilter } from '../types';
import type { DateRange } from './LineChart.types';
import { DATE_FILTER_MAP } from 'utils/dateFilter.utils';

const MAX_TICKS = 6;

const evenlyDistributeTicks = (startDate: Date, endDate: Date) => {
  const timeRange = endDate.getTime() - startDate.getTime();
  const tickInterval = timeRange / (MAX_TICKS - 1);
  return Array.from(
    { length: MAX_TICKS },
    (_, i) => new Date(startDate.getTime() + i * tickInterval)
  );
};

export const getTickValues = (
  dateRange: DateRange,
  filter: DateFilter = DateFilter.Today,
  isCustom = false
) => {
  const startDate = new Date(dateRange.startDate);
  const endDate = new Date(dateRange.endDate);
  startDate.setHours(0, 0, 0, 0);
  endDate.setHours(23, 59, 59, 999);

  if (filter === DateFilter.Today) {
    const tickValues = timeHour.range(startDate, endDate, 2);
    tickValues.push(new Date(new Date(endDate).setHours(23, 59, 59, 999)));
    return tickValues;
  }
  const noOfDays = differenceInDays(endDate, startDate);
  if (
    (noOfDays >= DATE_FILTER_MAP.Week! && isCustom) ||
    filter === DateFilter.Monthly ||
    filter === DateFilter.Biweekly
  ) {
    return evenlyDistributeTicks(startDate, addDays(endDate, 1));
  }
  return timeDay.range(startDate, addDays(endDate, 1), 1);
};
