import { useApolloClient } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';

import { clearSentryUser } from 'sentry';
import * as AppStorage from 'storage';

export const useLogout = () => {
  const client = useApolloClient();
  const { logout } = useAuth0();

  return () => {
    clearSentryUser();
    client.clearStore();
    AppStorage.clear();
    logout({ returnTo: window.location.origin });
  };
};
