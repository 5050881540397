import type { PropsWithChildren } from 'react';
import { useReducer } from 'react';

import { AdmissionContext, AdmissionDispatchContext } from './AdmissionContext';
import type { AdmissionState } from 'reducers/admission/types';
import {
  admissionReducer,
  initAdmissionState,
} from 'reducers/admission/admissionReducer';

export const AdmissionProvider = ({
  children,
  value = initAdmissionState,
}: PropsWithChildren<{ value?: AdmissionState }>) => {
  const [state, dispatch] = useReducer(admissionReducer, value);
  return (
    <AdmissionContext.Provider value={state}>
      <AdmissionDispatchContext.Provider value={dispatch}>
        {children}
      </AdmissionDispatchContext.Provider>
    </AdmissionContext.Provider>
  );
};
