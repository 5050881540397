import {
  ProgressIndicatorWrapper,
  Background,
  Indicator,
  Steps,
} from './styles';
import { ProgressStep } from './ProgressStep';
import { stepType } from './utils';
import type { ProgressIndicatorProps } from './types';

export const ProgressIndicator = ({
  steps = [],
  currentStep = 0,
}: ProgressIndicatorProps) => {
  let showContent = true;

  if (steps.length === 0 || !currentStep || currentStep > steps.length) {
    showContent = false;
  }

  const indicatorWidth = 100 / (steps.length / currentStep);
  const stepWidth = 100 / steps.length;

  return (
    <ProgressIndicatorWrapper data-testid="progress-indicator">
      <Background data-testid="progress-indicator-background" />
      {showContent && (
        <>
          <Indicator
            data-testid="progress-indicator-indicator"
            width={indicatorWidth}
          />
          <Steps>
            {steps.map((step, index) => (
              <ProgressStep
                key={step}
                width={stepWidth}
                stepType={stepType(index + 1, currentStep)}
              >
                {step}
              </ProgressStep>
            ))}
          </Steps>
        </>
      )}
    </ProgressIndicatorWrapper>
  );
};
