import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Spacer } from '@bt-healthcare/ui-toolkit';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { Grid, PatientAdmissionFormWrapper } from '../styles';
import type {
  PatientAdmissionMedicalFormData,
  PatientAdmissionMedicalFormFieldsProps,
} from '../types';
import { medicalValidationSchema } from './validation';

import { ROUTE } from 'config/routes';
import { Step } from 'pages/PatientAdmission/types';
import { useApp } from 'context/app/AppContext';
import { FormFooter } from 'components/FormFooter';
import { CancelAdmissionModal } from 'components/Modal/CancelAdmission';
import { usePatient, usePatientDispatch } from 'context/patient/PatientContext';
import { FormFields } from 'components/Form/FormFields';

const PatientAdmissionMedicalFormFields = ({
  register,
  control,
  errors,
  generalPractices,
  watch,
}: PatientAdmissionMedicalFormFieldsProps) => (
  <Grid>
    <FormFields
      register={register}
      control={control}
      errors={errors}
      generalPractices={generalPractices}
      customInputProps={{
        monitoredConditions: {
          isMulti: true,
        },
        comments: {
          optional: true,
        },
      }}
      fieldNames={['gpSurgery', 'monitoredConditions', 'comments']}
      watch={watch}
    />
  </Grid>
);

export const MedicalAdmission = () => {
  const { careSetting } = useApp();

  const {
    admissionFormData: { medical },
  } = usePatient();
  const patientDispatch = usePatientDispatch();

  const {
    register,
    control,
    handleSubmit,
    watch,
    formState: { isValid, errors },
  } = useForm<PatientAdmissionMedicalFormData>({
    mode: 'onChange',
    defaultValues: medical,
    resolver: yupResolver(medicalValidationSchema),
  });

  const navigate = useNavigate();
  const onContinue = (data: PatientAdmissionMedicalFormData) => {
    patientDispatch({
      type: 'setMedicalFormData',
      medicalFormData: data,
    });
    navigate({
      pathname: ROUTE.ADMISSION,
      search: createSearchParams({ step: Step.CONFIRM }).toString(),
    });
  };

  const [cancelModalOpen, setCancelModalOpen] = useState(false);

  return (
    <PatientAdmissionFormWrapper data-testid="medical-admission-form">
      <PatientAdmissionMedicalFormFields
        register={register}
        control={control}
        errors={errors}
        generalPractices={careSetting.generalPractices ?? []}
        watch={watch}
      />
      <Spacer size="s4" />
      <FormFooter
        primaryButtonText="Continue"
        primaryButtonDisabled={!isValid}
        primaryButtonClick={handleSubmit(onContinue)}
        cancelButtonClick={() => setCancelModalOpen(true)}
      />
      <CancelAdmissionModal
        isModalOpen={cancelModalOpen}
        handleClose={() => setCancelModalOpen(false)}
        handleProceed={() => {
          setCancelModalOpen(false);
          patientDispatch({ type: 'resetPatientAdmissionFormData' });
          navigate(ROUTE.HOME);
        }}
      />
    </PatientAdmissionFormWrapper>
  );
};
