import { spacing } from '@bt-healthcare/ui-toolkit';
import styled from 'styled-components';

export const CardHeader = styled.div`
  background: linear-gradient(
    63deg,
    rgba(68, 138, 255, 0.1) -0.36%,
    rgba(71, 46, 205, 0.1) 54.21%,
    rgba(85, 20, 180, 0.1) 100.81%
  );
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  border-radius: ${spacing.s4} ${spacing.s4} ${spacing.none} ${spacing.none};
  padding: ${spacing.s4} ${spacing.s6};
`;

export const CardContent = styled.div`
  padding-top: ${spacing.s14};
  display: flex;
  flex-direction: column;
  gap: ${spacing.s4};
`;

export const RecommendationList = styled.ul``;

export const RecommendationListItem = styled.li`
  margin-left: ${spacing.s6};
`;

export const ButtonWrapper = styled.div`
  display: flex;
  gap: ${spacing.s2};
  margin-top: ${spacing.s4};
  max-width: 400px;
`;
