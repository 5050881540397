import styled from 'styled-components';
import { Wrapper, device } from '@bt-healthcare/ui-toolkit';

import { MAX_FORM_WIDTH } from 'styles/constants.styles';

export const FormWrapper = styled(Wrapper)`
  max-width: ${MAX_FORM_WIDTH};
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  grid-column: 1 / 5;

  @media ${device.tablet} {
    grid-column: 1 / 9;
  }

  @media ${device.largeTablet} {
    grid-column: 3 / 11;
  }
`;

export const MonitoredConditionFormCardWrapper = styled.div`
  margin-top: 20px;
`;
