import type {
  SynchedDateRange,
  PatientState,
  PatientActions,
  FilterState,
  AdmissionFormData,
  GuidelineAcceptance,
} from './types';
import { STORAGE_KEYS } from 'App.constants';
import type { HypertensionAssessmentFormData } from 'components/HypertensionAssessmentForm/types';
import type {
  PatientAdmissionPersonalFormData,
  PatientAdmissionMedicalFormData,
} from 'components/PatientAdmissionForm/types';
import type { PatientDetailsFormData } from 'components/PatientDetailsForm/types';
import { getConditionFilters, getPatientStatusFilters } from 'filters';
import type { Notes } from 'models/types';
import * as AppStorage from 'storage';

export const initialFilterState = {
  Condition: getConditionFilters(),
  PatientStatus: getPatientStatusFilters(),
  statusFilters: [],
} as FilterState;

export const initPatientState: PatientState = {
  synchedChartDateFilter: undefined,
  synchedChartDateRange: {} as SynchedDateRange,
  reloadPatient: false,
  filters: initialFilterState,
  hypertensionAssessmentFormData: {} as HypertensionAssessmentFormData,
  admissionFormData: {
    personal: {} as PatientAdmissionPersonalFormData,
    medical: {} as PatientAdmissionMedicalFormData,
  } as AdmissionFormData,
  patientDetailsFormData: {} as PatientDetailsFormData,
  notes: { isDirty: false } as Notes,
  guidelineAcceptance: [] as GuidelineAcceptance[],
};

export const patientReducer: (
  state: PatientState,
  action: PatientActions
) => PatientState = (state, action) => {
  switch (action.type) {
    case 'setChartDateFilter': {
      return {
        ...state,
        synchedChartDateFilter: action.synchedDateFilter,
      };
    }
    case 'setChartDateRange': {
      return {
        ...state,
        synchedChartDateRange: action.synchedDateRange,
      };
    }
    case 'resetChartDateFilter': {
      return {
        ...state,
        synchedChartDateFilter: initPatientState.synchedChartDateFilter,
      };
    }
    case 'resetChartDateRange': {
      return {
        ...state,
        synchedChartDateRange: initPatientState.synchedChartDateRange,
      };
    }
    case 'reloadPatient': {
      return {
        ...state,
        reloadPatient: action.reload,
      };
    }
    case 'addStatusFilters': {
      const newState = {
        ...state,
        filters: {
          ...state.filters,
          statusFilters: action.statusFilters,
        },
      };
      AppStorage.set(STORAGE_KEYS.PATIENT_FILTER, newState.filters);
      return newState;
    }
    case 'removeStatusFilter': {
      const newState = {
        ...state,
        filters: {
          ...state.filters,
          statusFilters: state.filters.statusFilters.filter(
            (item: any) =>
              !(
                item.value === action.statusFilter.value &&
                item.type === action.statusFilter.type
              )
          ),
        },
      };
      AppStorage.set(STORAGE_KEYS.PATIENT_FILTER, newState.filters);
      return newState;
    }
    case 'updateFilters': {
      const newState = {
        ...state,
        filters: action.filterState,
      };
      AppStorage.set(STORAGE_KEYS.PATIENT_FILTER, newState.filters);
      return newState;
    }
    case 'resetFilters': {
      AppStorage.remove(STORAGE_KEYS.PATIENT_FILTER);
      return {
        ...state,
        filters: initialFilterState,
      };
    }
    case 'setMedicalFormData': {
      const newState = {
        ...state,
        admissionFormData: {
          ...state.admissionFormData,
          medical: action.medicalFormData,
        },
      };
      AppStorage.set(
        STORAGE_KEYS.FORM_PATIENT_ADMISSION_MEDICAL,
        newState.admissionFormData.medical
      );
      return newState;
    }
    case 'setPersonalFormData': {
      const newState = {
        ...state,
        admissionFormData: {
          ...state.admissionFormData,
          personal: action.personalFormData,
        },
      };
      AppStorage.set(
        STORAGE_KEYS.FORM_PATIENT_ADMISSION_PERSONAL,
        newState.admissionFormData.personal
      );
      return newState;
    }
    case 'setPatientDetailsFormData': {
      const newState = {
        ...state,
        patientDetailsFormData: {
          ...state.patientDetailsFormData,
          ...action.patientDetailsFormData,
        },
      };
      AppStorage.set(
        STORAGE_KEYS.FORM_PATIENT_DETAILS,
        newState.patientDetailsFormData
      );
      return newState;
    }
    case 'resetPatientDetailsFormData': {
      AppStorage.remove(STORAGE_KEYS.FORM_PATIENT_DETAILS);
      return {
        ...state,
        patientDetailsFormData: initPatientState.patientDetailsFormData,
      };
    }
    case 'resetPatientAdmissionFormData': {
      AppStorage.remove(STORAGE_KEYS.FORM_PATIENT_ADMISSION_MEDICAL);
      AppStorage.remove(STORAGE_KEYS.FORM_PATIENT_ADMISSION_PERSONAL);
      return {
        ...state,
        admissionFormData: initPatientState.admissionFormData,
      };
    }
    case 'setHypertensionAssessmentFormData': {
      const newState = {
        ...state,
        hypertensionAssessmentFormData: action.hypertensionAssessmentFormData,
      };
      AppStorage.set(
        STORAGE_KEYS.FORM_HYPERTENSION_ASSESSMENT,
        newState.hypertensionAssessmentFormData
      );
      return newState;
    }
    case 'resetHypertensionAssessmentFormData': {
      AppStorage.remove(STORAGE_KEYS.FORM_HYPERTENSION_ASSESSMENT);
      return {
        ...state,
        hypertensionAssessmentFormData:
          initPatientState.hypertensionAssessmentFormData,
      };
    }
    case 'updateGuidelineAcceptance': {
      const newState = {
        ...state,
        guidelineAcceptance: state.guidelineAcceptance.map((item: any) => {
          if (item.guidelineType === action.guidelineAcceptance.guidelineType) {
            return action.guidelineAcceptance;
          }
          return item;
        }),
      };
      AppStorage.set(
        STORAGE_KEYS.GUIDELINE_ACCEPTANCE,
        newState.guidelineAcceptance
      );
      return newState;
    }
    case 'setGuidelineAcceptance': {
      const newState = {
        ...state,
        guidelineAcceptance: action.guidelineAcceptances,
      };
      AppStorage.set(
        STORAGE_KEYS.GUIDELINE_ACCEPTANCE,
        newState.guidelineAcceptance
      );
      return newState;
    }
    case 'resetGuidelineAcceptance': {
      AppStorage.remove(STORAGE_KEYS.GUIDELINE_ACCEPTANCE);
      return {
        ...state,
        guidelineAcceptance: initPatientState.guidelineAcceptance,
      };
    }
    case 'setNoteIsDirty': {
      return { ...state, notes: { isDirty: action.noteIsDirty } };
    }
    default: {
      return state;
    }
  }
};
