import {
  differenceInDays,
  endOfDay,
  isAfter,
  startOfDay,
  subDays,
} from 'date-fns';
import type { DateRange } from 'components/Visualisations/LineChart/LineChart.types';
import { DateFilter } from 'components/Visualisations/types';

import type { Maybe } from 'services/graphql';

export const DATE_FILTER_MAP: Partial<Record<DateFilter, number>> = {
  Month: 30,
  Biweekly: 14,
  Week: 7,
  Today: 1,
};

export const getDateRange = (
  dateFilter: DateFilter,
  admissionDateTime: Maybe<string>,
  startDate?: string,
  endDate?: string
): Maybe<DateRange> => {
  if (admissionDateTime == null) return null;
  const currentDate = Date.now();
  const toDateAdmissionDateTime = new Date(admissionDateTime);
  if (isAfter(toDateAdmissionDateTime, currentDate)) return null;
  if (dateFilter === DateFilter.Today) {
    return {
      startDate: startOfDay(currentDate),
      endDate: endOfDay(currentDate),
    };
  }
  if (dateFilter !== DateFilter.Custom) {
    const sub = DATE_FILTER_MAP[dateFilter]!;
    return {
      startDate: startOfDay(subDays(currentDate, sub - 1)),
      endDate: endOfDay(currentDate),
    };
  }
  if (dateFilter === DateFilter.Custom && startDate && endDate)
    return {
      startDate: startOfDay(new Date(startDate)),
      endDate: endOfDay(new Date(endDate)),
    };

  return null;
};

export const getDateFilterForDateRange = (
  dateFilter: DateFilter,
  chartDateRange: Maybe<DateRange>
) => ({
  dateFilterRange: {
    dateFilter,
    isCustom: dateFilter === DateFilter.Custom,
    noOfDays: chartDateRange
      ? differenceInDays(chartDateRange.endDate, chartDateRange.startDate)
      : 0,
  },
  dateRange: chartDateRange,
});

export const getDateFilter = (
  dateFilter: DateFilter,
  admissionDateTime: Maybe<string>,
  startDate?: string,
  endDate?: string
) => {
  const chartDateRange = getDateRange(
    dateFilter,
    admissionDateTime,
    startDate,
    endDate
  );
  return getDateFilterForDateRange(dateFilter, chartDateRange);
};
