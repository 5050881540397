import {
  Button,
  colors,
  fontSizes,
  Text,
  Wrapper,
} from '@bt-healthcare/ui-toolkit';

import type { PatientHeaderProps } from './types';
import { PatientHeaderWrapper, PatientDetailWrapper } from './styles';
import { PatientDetail } from 'components/PatientDetail';
import { HeaderCard } from 'components/Card/HeaderCard';

export const PatientHeader = ({
  name,
  vitalsForMonitoring,
  monitoredConditions,
  nhsNumber,
  birthDate,
  canStartAssessment,
  handleHypertensionAssessmentClick,
}: PatientHeaderProps) => (
  <HeaderCard id="patient-details" data-testid="patient-header-card">
    <PatientHeaderWrapper>
      <Wrapper>
        <Text
          color={colors.primaryIndigo.indigo08}
          fontWeight={500}
          fontSize={fontSizes.h3}
          data-testid="patient-name"
        >
          {name}
        </Text>
        <PatientDetailWrapper>
          <PatientDetail id="date-of-birth" label="D.O.B">
            {birthDate}
          </PatientDetail>
          <PatientDetail id="patient-nhsid" label="NHS No">
            {nhsNumber}
          </PatientDetail>
          <PatientDetail id="monitored-condition-text" label="Condition(s)">
            {monitoredConditions}
          </PatientDetail>
          <PatientDetail
            id="vitals-for-monitoring-text"
            label="Vitals for monitoring"
          >
            {vitalsForMonitoring}
          </PatientDetail>
        </PatientDetailWrapper>
      </Wrapper>
      <Wrapper>
        {canStartAssessment && (
          <Button
            id="start-hypertension-assessment-btn"
            onClick={handleHypertensionAssessmentClick}
            type="button"
          >
            Start new assessment
          </Button>
        )}
      </Wrapper>
    </PatientHeaderWrapper>
  </HeaderCard>
);
