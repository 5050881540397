import { PageNotFound } from '@bt-healthcare/ui-toolkit';

import { StartAdmissionForm } from './StartAdmissionForm';
import { useFeatureFlags } from 'hooks/useFeatureFlags';

export const StartAdmissionFeatureRoute = () => {
  const { isRemoteCareAdmissionFeatureFlagEnabled } = useFeatureFlags();
  return isRemoteCareAdmissionFeatureFlagEnabled ? (
    <StartAdmissionForm />
  ) : (
    <PageNotFound />
  );
};
