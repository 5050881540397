import { ModuleIdentifierMap } from './settings.mappings';
import type { UsersType } from './types';
import type { ModuleIdentifier } from 'services/graphql';
import { ErrorCode } from 'services/graphql';

export const getRegistrationFormTracking = (
  isEdit: boolean,
  isReactivate: boolean
) => {
  let tracking = 'add new user';
  if (isEdit || isReactivate) {
    tracking = `${isEdit ? 'edit' : 'reactivate'} user`;
  }
  return tracking;
};

export const getConfirmationConfig = (
  isEdit: boolean,
  isReactivate: boolean,
  errorType?: ErrorCode
) => {
  let config = {
    confirmationText: 'add the new user',
    actionText: 'added',
    summaryText:
      'The users account registration is complete. They can now log in and start using the platform.',
    errorMessage:
      'The user could not be added. Please review the information you entered and try again.',
    errorTitle: "We couldn't add the user",
    tracking: 'add new user confirmation',
  };
  if (isEdit || isReactivate) {
    config = {
      ...config,
      confirmationText: isEdit ? 'edit the user' : 'reactivate the user',
      actionText: isEdit ? 'updated' : 'reactivated',
      summaryText: isEdit
        ? 'The user was successfully updated. Any changes will be updated when they next login or refresh their details.'
        : 'The user was successfully reactivated. They can now log in and start using the platform.',
      errorMessage: `The user could not be ${
        isEdit ? 'updated' : 'reactivated'
      }. Please review the information you entered and try again.`,
      errorTitle: `We couldn't ${isEdit ? 'edit' : 'reactivate'} the user`,
      tracking: isEdit
        ? 'edit user confirmation'
        : 'reactivate user confirmation',
    };
  }

  if (errorType === ErrorCode.UserAlreadyExists) {
    return {
      ...config,
      errorTitle: 'Email already registered',
      errorMessage:
        'The email entered is already associated with an existing account. Please use a different email address.',
    };
  }
  return config;
};

export const getDefaultValues = (
  isEdit: boolean,
  isReactivate: boolean,
  user?: UsersType
) => {
  const defaultValues = {
    firstName: '',
    surname: '',
    emailAddress: '',
    mobileNumber: '',
    modules: null,
    careSettingAdministrator: false,
  };
  return isEdit || isReactivate ? { ...user } : defaultValues;
};

export const moduleIdentifierTransform = (modules?: ModuleIdentifier[]) => {
  if (!modules || typeof modules === 'string') return null;
  const transform = modules.map((app) => ModuleIdentifierMap[app]);
  return transform.join(', ');
};

export const userFilter = (data: UsersType[], value: string) => {
  if (!data || value === '') return data;

  return data.filter(
    (item) =>
      item.firstName?.toLowerCase().includes(value.toLowerCase()) ||
      item.surname?.toLowerCase().includes(value.toLowerCase()) ||
      item.emailAddress?.toLowerCase().includes(value.toLowerCase())
  );
};
