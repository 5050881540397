import { device, spacing } from '@bt-healthcare/ui-toolkit';
import styled from 'styled-components';
import { datePickerStyles } from 'styles/datePicker.styles';

export const DatePickerWrapper = styled.div`
  display: none;

  @media ${device.largeTablet} {
    display: block;
    position: absolute;
    right: 0;
    top: 26px;

    ${datePickerStyles}

    .react-datepicker-popper {
      position: relative !important;
      inset: none !important;
      transform: none !important;
    }

    .react-datepicker__month-container {
      float: none;
      display: table-cell;
    }
  }
`;

export const TextWrapper = styled.div`
  margin: ${spacing.s4} ${spacing.none};
`;
export const ControlsWrapper = styled.div`
  display: flex;
  gap: ${spacing.s6};
`;
