import styled from 'styled-components';
import { colors, spacing, fontSizes } from '@bt-healthcare/ui-toolkit';

export const ViewAllButton = styled.button`
  width: 100%;
  background: none;
  font-size: ${fontSizes.base};
  font-weight: 500;
  border: none;
  color: ${colors.primaryIndigo.indigo09};
  margin-top: ${spacing.s5};
  cursor: pointer;
`;
