import { Text, colors, fontSizes } from '@bt-healthcare/ui-toolkit';
import NoResultsFoundIllustration from 'assets/no-results-found.svg?react';
import { NoResultsFoundWrapper, TextWrapper } from './styles';

export const NoResultsFound = () => (
  <NoResultsFoundWrapper data-testid="no-results-found">
    <TextWrapper>
      <Text color={colors.grey.grey09} fontWeight={500} fontSize={fontSizes.h3}>
        We couldn&apos;t find any results matching your search.
      </Text>
      <Text color={colors.grey.grey08} fontSize={fontSizes.h4}>
        Please try using different keywords or broadening your search criteria
        for more results.
      </Text>
    </TextWrapper>
    <NoResultsFoundIllustration />
  </NoResultsFoundWrapper>
);
