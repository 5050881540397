import * as yup from 'yup';

import type { UserFormData } from './types';
import type { FormKey } from 'components/Form/types';
import { isRequired } from 'validation/yup/schema.helpers';
import {
  REQUIRED_TEXT,
  requiredPhoneNumber,
} from 'components/Form/validationHelper';

const userFormRules = {
  firstName: isRequired(),
  surname: isRequired(),
  emailAddress: yup
    .string()
    .matches(
      /^([a-zA-Z0-9_\-\\.+]+)@([a-zA-Z0-9_\-\\.]+)\.([a-zA-Z]{2,15})$/,
      'Please enter a valid email address.'
    )
    .required(REQUIRED_TEXT),
  mobileNumber: requiredPhoneNumber,
} as { [key in FormKey<UserFormData>]: any };

export const userSchema = yup.object(userFormRules);
