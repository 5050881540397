import { Wrapper, colors, spacing } from '@bt-healthcare/ui-toolkit';
import styled from 'styled-components';
import type {
  UserListSubMenuDropdownListItemProps,
  UserListSubMenuWrapperProps,
} from './types';

export const UserListSubMenuWrapper = styled(Wrapper)`
  position: relative;
`;

export const UserListSubMenuButton = styled.button<UserListSubMenuWrapperProps>`
  height: 24px;
  width: 24px;
  border: 0;
  padding: 0;
  background-color: ${({ open }) =>
    open ? colors.primaryIndigo.indigo01 : colors.base.white};
  color: ${colors.grey.grey08};
  cursor: pointer;
  outline: 0;
  border-radius: ${spacing.s1};

  p {
    height: 24px;
  }

  &:hover {
    background-color: ${colors.primaryIndigo.indigo01};
  }
`;

export const UserListSubMenuDropdown = styled.div`
  position: absolute;
  top: calc(100% + ${spacing.s1});
  right: 0;
  z-index: 2;
  box-shadow: 0px 8px 30px rgba(112, 144, 176, 0.2);
  border-radius: ${spacing.s1};
  background: ${colors.base.white};
  width: 125px;

  ul {
    list-style: none;
    padding: ${spacing.s4} ${spacing.s3};
    margin: ${spacing.none};
  }

  li {
    cursor: pointer;
  }
`;

export const UserListSubMenuDropdownListItem = styled.li<UserListSubMenuDropdownListItemProps>`
  margin-bottom: ${spacing.s4};
  color: ${({ warning }) => warning && colors.notifications.light.infoDark};

  &:last-child {
    margin-bottom: ${spacing.none};
  }
`;

export const UserFormGrantPermissionsNotificationWrapper = styled(Wrapper)`
  margin-top: ${spacing.s4};
`;
