import type { BTHealthIconProp } from '@bt-healthcare/ui-toolkit';

import type { ObservationReadingsAverage } from './types';
import { SupportedObservationTypeConstant } from 'models/wardPatientObservation';
import type {
  Maybe,
  ObservationTypeReadings,
  ObservationsReadingsView,
} from 'services/graphql';
import type { SupportedObservationType } from 'types';
import { isEmptyOrNil } from 'utils/object.utils';

const AVERAGE_DEFAULT = 'n/a';

export const mapping = {
  [SupportedObservationTypeConstant.Pulse]: {
    name: 'Pulse (bpm)',
    icon: 'Pulse' as BTHealthIconProp,
    type: SupportedObservationTypeConstant.Pulse,
    sort: 3,
  },
  [SupportedObservationTypeConstant.Spo2]: {
    name: 'SpO2 (%)',
    icon: 'SpO2' as BTHealthIconProp,
    type: SupportedObservationTypeConstant.Spo2,
    sort: 2,
  },
  [SupportedObservationTypeConstant.BloodPressure]: {
    name: 'Blood Pressure (mmHg)',
    icon: 'BloodPressure' as BTHealthIconProp,
    type: SupportedObservationTypeConstant.BloodPressure,
    sort: 1,
  },
};

export const getObservationReadings = (
  supportedObservationType: SupportedObservationType,
  observationTypeReadings?: Maybe<ObservationTypeReadings>[]
): ObservationReadingsAverage => {
  if (isEmptyOrNil(observationTypeReadings))
    return { readings: [], average: AVERAGE_DEFAULT };

  const result = observationTypeReadings!.find(
    (item) => item?.observationType === supportedObservationType
  );

  return result && !isEmptyOrNil(result.observationReadings)
    ? {
        readings: result.observationReadings as ObservationsReadingsView[],
        average:
          result.res_dailyAverage == null
            ? AVERAGE_DEFAULT
            : result.res_dailyAverage,
      }
    : { readings: [] as ObservationsReadingsView[], average: AVERAGE_DEFAULT };
};
