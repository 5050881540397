import {
  Col,
  Grid,
  Row,
  SubHeader,
  Notification,
  ErrorNotification,
} from '@bt-healthcare/ui-toolkit';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useTracking } from 'hooks/useTracking';
import { FETCH_POLICY } from 'App.constants';
import { useGetRegisteredCareSettingCliniciansQuery } from 'services/graphql';
import { UserList } from 'components/Settings/UserList';
import type { UsersType } from 'components/Settings/types';
import { registeredCliniciansTransform } from 'components/Settings/settings.transforms';
import { PageName } from 'config/pageNames';
import { useApp } from 'context/app/AppContext';
import { useSettingsDispatch } from 'context/settings/SettingsContext';
import { MenuActionButton } from 'components/MenuActionButton';
import { ROUTE } from 'config/routes';
import { ClientSearch } from 'components/ClientSearch';
import { userFilter } from 'components/Settings/settings.utils';

export const Users = () => {
  const { trackPage } = useTracking();
  const navigate = useNavigate();
  const [users, setUsers] = useState<UsersType[]>([]);
  const [filter, setFilter] = useState<UsersType[]>();

  const { careSetting } = useApp();
  const settingsDispatch = useSettingsDispatch();

  const { loading, error, refetch } =
    useGetRegisteredCareSettingCliniciansQuery({
      variables: {
        careSettingId: careSetting.id!,
      },
      fetchPolicy: FETCH_POLICY.CACHE_AND_NETWORK,
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        const mappedRegisteredUsers = registeredCliniciansTransform(data);
        setUsers(mappedRegisteredUsers);
      },
    });

  useEffect(() => {
    settingsDispatch({ type: 'resetUser' });
    settingsDispatch({ type: 'resetUserAction' });
    settingsDispatch({ type: 'resetUserForm' });
    trackPage(PageName.SETTINGS_USER);
  }, []);

  return (
    <Grid>
      <Row>
        <Col xs={12} alignSelf="center" display={{ xs: 'block', lg: 'none' }}>
          <MenuActionButton
            id="add-user-mobile-btn"
            onClick={() => navigate(ROUTE.SETTINGS_USER_CREATE)}
            value="Add new user"
          />
        </Col>
      </Row>
      <Row>
        <Col xs={8} alignSelf="center" display={{ xs: 'none', lg: 'block' }}>
          <SubHeader color="indigo08">Users</SubHeader>
        </Col>
        <Col lg={4} xs={12} alignSelf="right">
          <ClientSearch
            data={users}
            setSearchFilter={setFilter}
            searchFilter={userFilter}
          />
        </Col>
      </Row>
      {error ? (
        <Row>
          <Col from={1}>
            <ErrorNotification
              id="clinician-users-list"
              action="trying to display users"
              onTryAgainClick={() => refetch()}
            />
          </Col>
        </Row>
      ) : (
        <Row>
          <Col from={1}>
            {!loading && users.length === 0 ? (
              <Notification
                type="informative"
                data-testid="clinician-users-empty-notification"
              >
                Currently no users have been assigned.
              </Notification>
            ) : (
              <UserList
                loading={loading}
                refetch={refetch}
                data={filter ?? users}
              />
            )}
          </Col>
        </Row>
      )}
    </Grid>
  );
};
