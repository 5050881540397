import { Modal, Button, ModalBody } from '@bt-healthcare/ui-toolkit';

import type { UserDeactivationModalProps } from '../types';
import { ButtonWrapper, ModalStrapline, TextWrapper } from '../styles';
import { ModalTitle } from '../ModalTitle';

export const UserDeactivationModal = ({
  modalOpen,
  handleClose,
  handleDeactivate,
}: UserDeactivationModalProps) => (
  <Modal
    onClose={handleClose}
    isModalOpen={modalOpen}
    id="user-deactivation-modal"
    inPortal
  >
    <ModalBody>
      <TextWrapper>
        <ModalTitle title="Deactivate user" />
        <ModalStrapline>
          You are about to deactivate this user and they will no longer have
          access to any apps.
        </ModalStrapline>
      </TextWrapper>
      <ButtonWrapper>
        <Button
          aria-label="Cancel modal"
          id="cancel-btn"
          onClick={handleClose}
          variant="primary"
        >
          No, cancel
        </Button>
        <Button
          id="deactivate-btn"
          onClick={handleDeactivate}
          variant="primaryRed"
        >
          Yes, deactivate
        </Button>
      </ButtonWrapper>
    </ModalBody>
  </Modal>
);
