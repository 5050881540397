import { colors, spacing } from '@bt-healthcare/ui-toolkit';
import styled from 'styled-components';
import type { MenuOpenProps } from './types';

export const FilterMenuOverlay = styled.div<MenuOpenProps>`
  display: ${({ menuOpen }) => (menuOpen ? 'block' : 'none')};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(26, 26, 26, 0.7);
  z-index: 2;
`;

export const FilterMenuWrapper = styled.div<MenuOpenProps>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: ${spacing.s6};
  background-color: ${colors.base.white};
  z-index: 2;
  padding: ${spacing.s4} ${spacing.s2};
  transform: ${({ menuOpen }) =>
    menuOpen ? `translateX(0)` : `translateX(100%)`};
  transition: transform 0.25s ease-in;
`;

export const FilterMenuTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${spacing.s4};
`;

export const FilterMenuButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.s2};
  position: absolute;
  left: ${spacing.s6};
  right: ${spacing.s6};
  bottom: ${spacing.s6};
  z-index: 2;
`;
