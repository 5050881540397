export enum ROUTE {
  HOME = '/',
  PATIENT_DETAIL = '/patient',
  ADMISSION = '/patient/admission/form',
  START_ADMISSION = '/patient/admission',
  ASSESSMENT_HYPERTENSION = '/assessment/hypertension',
  ASSESSMENT_HYPERTENSION_CONFIRM = '/assessment/hypertension/confirm',
  LOGOUT = '/logout',
  DISCHARGE = '/patient/discharge',
  DISCHARGE_CONFIRMED = '/patient/discharge/confirm',
  SETTINGS = '/settings',
  SETTINGS_USER = '/settings/user',
  SETTINGS_USER_CREATE = '/settings/user/manage',
  SETTINGS_USER_EDIT = '/settings/user/edit',
  SETTINGS_USER_REACTIVATE = '/settings/user/reactivate',
  SETTINGS_USER_CONFIRMED = '/settings/user/confirm',
  UNAUTHORISED = '/unauthorised',
}
