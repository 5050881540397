import { useEffect, useState } from 'react';

import { ConfirmationDialog } from '../ConfirmationDialog';
import type { NotesProps } from './Notes.types';
import { NotesPanel } from '.';
import { FETCH_POLICY, POLL_INTERVAL } from 'App.constants';
import { useGetWardPatientCommentsQuery } from 'services/graphql';
import { usePatientDispatch, usePatient } from 'context/patient/PatientContext';

const confirmationMessage =
  'Note is not submitted\nIf you close the note section now your note will not be saved or submitted. Are you sure you want to close this section?';

export const Notes = ({
  wardPatientId,
  showNotesPanel,
  setShowNotesPanel,
}: NotesProps) => {
  const [showNotesConfirmation, setShowNotesConfirmation] = useState(false);

  const { data, error, startPolling, stopPolling } =
    useGetWardPatientCommentsQuery({
      variables: {
        wardPatientId,
      },
      fetchPolicy: FETCH_POLICY.CACHE_AND_NETWORK,
    });

  useEffect(() => {
    if (showNotesPanel) startPolling(POLL_INTERVAL);
  }, [showNotesPanel]);

  const patientDispatch = usePatientDispatch();
  const {
    notes: { isDirty },
  } = usePatient();

  const onModalClose = () => {
    stopPolling();
    if (isDirty) {
      return setShowNotesConfirmation(true);
    }
    return setShowNotesPanel(false);
  };

  const continueEditingNote = () => {
    setShowNotesConfirmation(false);
    startPolling(POLL_INTERVAL);
  };

  const closeAndDismissNote = () => {
    stopPolling();
    setShowNotesConfirmation(false);
    setShowNotesPanel(false);
    patientDispatch({
      type: 'setNoteIsDirty',
      noteIsDirty: false,
    });
  };
  return (
    <>
      <NotesPanel
        wardPatientId={wardPatientId}
        open={showNotesPanel}
        setOpen={setShowNotesPanel}
        onRequestClose={onModalClose}
        data={data}
        error={error}
      />
      <ConfirmationDialog
        onCancel={continueEditingNote}
        onOk={closeAndDismissNote}
        showConfirmation={showNotesConfirmation}
        message={confirmationMessage}
      />
    </>
  );
};
