import { StatusCodes } from 'http-status-codes';
import { ErrorCode } from '../graphql';

const clientErrorCodeValidator = (
  errors: any,
  errorCode: ErrorCode,
  statusCode: StatusCodes
) =>
  errors[0]?.extensions?.response.status === statusCode &&
  errors[0]?.extensions?.code === errorCode;

export const userAlreadyExistsError = (error: any) =>
  clientErrorCodeValidator(
    error,
    ErrorCode.UserAlreadyExists,
    StatusCodes.UNPROCESSABLE_ENTITY
  );

export const versionMismatchError = (error: any) =>
  clientErrorCodeValidator(
    error,
    ErrorCode.VersionMismatch,
    StatusCodes.CONFLICT
  );
