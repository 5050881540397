import { Notification, Text, colors } from '@bt-healthcare/ui-toolkit';

import type { RecommendationAvailableNotificationProps } from '../types';
import { NotificationWrapper, RecommendationAvailableText } from '../styles';

export const RecommendationAvailableNotification = ({
  hypertensionCarePlanRef,
}: RecommendationAvailableNotificationProps) => {
  const handleScrollToCarePlan = () => {
    if (hypertensionCarePlanRef.current) {
      hypertensionCarePlanRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  return (
    <NotificationWrapper onClick={handleScrollToCarePlan} role="link">
      <Notification
        icon="Bright"
        type="informative"
        iconColor={colors.secondaryBlue.blue07}
        data-testid="recommendation-available-notification"
      >
        <RecommendationAvailableText data-test-id="recommendation-available-link">
          Recommendation available.
        </RecommendationAvailableText>{' '}
        <Text color={colors.grey.grey10}>
          See patient care plan for more details.
        </Text>
      </Notification>
    </NotificationWrapper>
  );
};
