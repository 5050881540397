import { Button, Modal, ModalBody } from '@bt-healthcare/ui-toolkit';

import type { CancelDischargeProps } from '../types';
import { ButtonWrapper, ModalStrapline, TextWrapper } from '../styles';
import { ModalTitle } from '../ModalTitle';

export const CancelDischarge = ({
  modalOpen,
  handleCancelDischarge,
  handleContinueDischarge,
}: CancelDischargeProps) => (
  <Modal
    isModalOpen={modalOpen}
    onClose={handleContinueDischarge}
    id="cancel-discharge-modal"
  >
    <ModalBody>
      <TextWrapper>
        <ModalTitle title="Cancel discharge" />
        <ModalStrapline>
          Are you sure you want to cancel? All progress and any information that
          you have entered will be lost.
        </ModalStrapline>
      </TextWrapper>
      <ButtonWrapper>
        <Button
          id="cancel-discharge"
          onClick={handleCancelDischarge}
          variant="primaryRed"
        >
          Yes, cancel
        </Button>
        <Button
          id="continue-discharge"
          onClick={handleContinueDischarge}
          variant="primary"
        >
          No, continue
        </Button>
      </ButtonWrapper>
    </ModalBody>
  </Modal>
);
