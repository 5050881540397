import {
  Button,
  colors,
  fontSizes,
  Modal,
  Text,
} from '@bt-healthcare/ui-toolkit';

import type { DeclineRecommendationProps } from '../types';
import { ButtonWrapper, ModalBody } from '../styles';
import { ModalTitle } from '../ModalTitle';
import { ContentWrapper } from './styles';
import { HypertensionAssessmentDeclineRecommendation } from 'components/HypertensionAssessmentForm/HypertensionAssessmentDeclineRecommendation';
import { GuidelineMap } from 'mappings/enums';

export const DeclineRecommendation = ({
  modalOpen,
  guidelineType,
  idx,
  control,
  errors,
  register,
  formData,
  onDecline,
  onClose,
  isValid = true,
}: DeclineRecommendationProps) => (
  <Modal
    onClose={onClose}
    isModalOpen={modalOpen}
    id="decline-recommendation-modal"
  >
    <ModalBody>
      <ContentWrapper>
        <ModalTitle title="Recommendation declined" />
        <Text
          fontWeight={500}
          color={colors.primaryIndigo.indigo08}
          fontSize={fontSizes.h4}
          data-testid="decline-recommendation-guideline-type-text"
        >
          {GuidelineMap[guidelineType]}
        </Text>
        <HypertensionAssessmentDeclineRecommendation
          control={control}
          register={register}
          errors={errors}
          formData={formData}
          idx={idx}
        />
      </ContentWrapper>
      <ButtonWrapper>
        <Button id="continue-hypertension-assessment" onClick={onClose}>
          No, cancel
        </Button>
        <Button
          id="leave-hypertension-assessment"
          onClick={() => onDecline(guidelineType, idx)}
          variant="primaryRed"
          disabled={!isValid}
        >
          Yes, decline
        </Button>
      </ButtonWrapper>
    </ModalBody>
  </Modal>
);
