import {
  Card,
  Accordion,
  AccordionItem,
  Text,
  colors,
  Col,
  GRID_BREAKPOINT,
  MultipleChoiceButton,
  Grid,
  Spacer,
  Stack,
} from '@bt-healthcare/ui-toolkit';
import type { HypertensionAssessmentFormProps } from './types';
import { medicationProtocolOptions, yesNoOptions } from 'mappings/enums';
import { ConditionalFieldRenderer } from 'components/Form/ConditionalFieldRenderer';
import { isYes } from 'components/Form/conditionalRules';
import { RHFDropDown } from 'components/Form/RHFDropDown';
import { MedicationProtocol } from 'services/graphql';

export const HypertensionAssessmentMedicalForm = ({
  register,
  control,
  formData,
  resetField,
  fieldDisabled = false,
}: HypertensionAssessmentFormProps) => (
  <Card id="hypertension-assessment-medical-form-card">
    <Accordion id="hypertension-assessment-medical-form-accordion">
      <AccordionItem
        title={
          <Text color={colors.secondaryBlue.blue10} fontSize="20px">
            Medication
          </Text>
        }
        id="hypertension-assessment-medical-accordion-item"
        expanded
      >
        <Grid>
          <Col
            col={{
              [GRID_BREAKPOINT.large]: '1 / 14',
            }}
          >
            <MultipleChoiceButton
              label="Is the patient taking any medication for their blood pressure?"
              options={yesNoOptions}
              {...register('isTakingBloodPressureMedicationYN', {
                onChange: () => {
                  resetField('medicationProtocol', { defaultValue: null });
                  resetField('hasEverTakenBloodPressureMedicationYN', {
                    defaultValue: null,
                  });
                },
              })}
              border
              defaultValue={
                formData.isTakingBloodPressureMedicationYN ?? undefined
              }
            />
          </Col>
          <ConditionalFieldRenderer
            control={control}
            fieldName="isTakingBloodPressureMedicationYN"
            condition={(arg) => isYes(arg)}
            defaultValue={formData.isTakingBloodPressureMedicationYN}
          >
            <Col
              col={{
                [GRID_BREAKPOINT.large]: '1 / 14',
              }}
            >
              <RHFDropDown
                label="Medication protocol"
                fieldName="medicationProtocol"
                control={control}
                options={medicationProtocolOptions.filter(
                  (item) => item.value !== MedicationProtocol.None
                )}
                defaultValue={formData.medicationProtocol ?? undefined}
              />
            </Col>
          </ConditionalFieldRenderer>
          <Col
            col={{
              [GRID_BREAKPOINT.large]: '1 / 14',
            }}
          >
            <MultipleChoiceButton
              label="Has the patient ever taken any medication for their blood pressure?"
              options={yesNoOptions}
              {...register('hasEverTakenBloodPressureMedicationYN')}
              border
              disabled={fieldDisabled}
              defaultValue={
                formData.hasEverTakenBloodPressureMedicationYN ?? undefined
              }
            />
            {fieldDisabled && (
              <Stack id="medication-text">
                <Spacer size="s2" />
                <Text color={colors.grey.grey08} fontSize="12px" as="p">
                  This is automatically selected due to your previous answer.
                </Text>
              </Stack>
            )}
          </Col>
        </Grid>
      </AccordionItem>
    </Accordion>
  </Card>
);
