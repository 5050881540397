import { Button, Modal } from '@bt-healthcare/ui-toolkit';

import Hero from 'assets/patient-admitted-success.svg?react';
import type { PatientUpdatedModalProps } from '../types';
import {
  ButtonWrapper,
  ModalBody,
  ModalStrapline,
  TextWrapper,
} from '../styles';
import { ModalTitle } from '../ModalTitle';

export const PatientUpdatedModal = ({
  isModalOpen,
  handleClose,
  handleBack,
}: PatientUpdatedModalProps) => (
  <Modal
    onClose={handleClose}
    isModalOpen={isModalOpen}
    id="updating-success-modal"
    maxWidth="563px"
    data-testid="updating-success-modal"
  >
    <ModalBody>
      <Hero />
      <TextWrapper>
        <ModalTitle title="Update successful" />
        <ModalStrapline>
          You can view these changes on the patient`s profile. To view all
          patients, go back to the patient list.
        </ModalStrapline>
      </TextWrapper>
      <ButtonWrapper>
        <Button
          id="view-patient-list"
          onClick={handleBack}
          variant="secondary"
          data-testid="view-patient-list-btn"
        >
          Back to patient list
        </Button>
        <Button
          id="view-patient-profile"
          onClick={handleClose}
          variant="primary"
          data-testid="view-patient-profile-btn"
        >
          View patient profile
        </Button>
      </ButtonWrapper>
    </ModalBody>
  </Modal>
);
