import { Text, colors } from '@bt-healthcare/ui-toolkit';

import { FieldRow, FieldWrapper, ModalTitle, TextWrapper } from '../styles';
import { ModalButtons } from '../ModalButtons';
import { StartAdmissionModal } from '..';
import { ModalBody, ModalStrapline } from 'components/Modal/styles';
import type { AdmissionDataMatches } from 'components/StartAdmissionForm/types';
import { returnCheckOrCross } from 'pages/StartAdmission/utils';

export type PartialMatchModalProps = {
  isModalOpen: boolean;
  handleClose: () => void;
  handleProceed: () => void;
  matches?: AdmissionDataMatches;
};

export const PartialMatchModal = ({
  isModalOpen,
  handleProceed,
  handleClose,
  matches,
}: PartialMatchModalProps) => (
  <StartAdmissionModal
    onClose={handleClose}
    isModalOpen={isModalOpen}
    id="partial-match-modal"
  >
    <ModalBody>
      <TextWrapper>
        <ModalTitle title="Patient details do not match" />
        <ModalStrapline>
          All details must match to complete admission. Review the information
          provided and try again. If updates are required please contact
          support.
        </ModalStrapline>
        <FieldWrapper>
          <FieldRow>
            <Text color={colors.grey.grey10}>Name</Text>
            {returnCheckOrCross(matches?.firstName!)}
          </FieldRow>
          <FieldRow>
            <Text color={colors.grey.grey10}>Surname</Text>
            {returnCheckOrCross(matches?.surname!)}
          </FieldRow>
          <FieldRow>
            <Text color={colors.grey.grey10}>D.O.B</Text>
            {returnCheckOrCross(matches?.dateOfBirth!)}
          </FieldRow>
        </FieldWrapper>
      </TextWrapper>
      <ModalButtons handleClose={handleClose} handleProceed={handleProceed} />
    </ModalBody>
  </StartAdmissionModal>
);
