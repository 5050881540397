import type { TypedOptionType } from '@bt-healthcare/ui-toolkit';
import {
  Accordion,
  AccordionItem,
  Card,
  Divider,
  Spacer,
  Text,
  colors,
  getColorValue,
  toHyphenCase,
} from '@bt-healthcare/ui-toolkit';
import { useState, type Dispatch, type SetStateAction } from 'react';

import { MonitoredConditionStatusUpdate } from './MonitoredConditionStatusUpdate';
import { MonitoredConditionTitle, StatusWrapper } from './styles';
import type { MonitoredConditionCardProps } from './types';
import {
  getMonitoringStatusInputVariables,
  getConsultationStatusInputVariables,
  getRefetchQueries,
  getTestStatusInputVariables,
} from './utils';
import { useMonitoringStatusUpdate } from './useMonitoringStatusUpdate';
import { useConsultationStatusUpdate } from './useConsultationStatusUpdate';
import { useTestStatusUpdate } from './useTestStatusUpdate';
import { MonitoringStatus } from 'components/Modal';
import {
  MonitoredConditionMap,
  MonitoringStatusOptions,
  SelfMonitoringOptions,
} from 'mappings/enums';
import type {
  MonitoredConditionConsultationStatusType,
  MonitoredConditionTestStatusType,
} from 'services/graphql';
import { AdmissionStatus, MonitoringStatusType } from 'services/graphql';
import { ReviewDateTime } from 'components/ReviewDateTime';
import { useApp } from 'context/app/AppContext';
import {
  ConsultationStatusTypeColorMap,
  MonitoringStatusTypeColorMap,
  TestStatusTypeColorMap,
} from 'pages/PatientList/PatientList.util';
import { useStatusChangeTracking } from 'hooks/useStatusChangeTracking';
import { usePrevious } from 'hooks/usePrevious';
import { EMPTY_VALUE } from 'App.constants';
import { usePatientDispatch } from 'context/patient/PatientContext';

export const MonitoredConditionCard = ({
  condition,
  version,
  wardPatientId,
  monitoringStatus,
  consultationStatus,
  testStatus,
}: MonitoredConditionCardProps) => {
  const { trackStatusChange } = useStatusChangeTracking();
  const { userId, careSetting } = useApp();
  const patientDispatch = usePatientDispatch();
  const title = MonitoredConditionMap[condition];
  const [isSelfMonitoring, setIsSelfMonitoring] = useState(
    monitoringStatus?.status === MonitoringStatusType.SelfMonitoring
  );

  const handleReload = () => {
    patientDispatch({ type: 'reloadPatient', reload: true });
  };

  const {
    monitoringStatusMutation,
    monitoringStatusModal,
    monitoringStatusConfig,
  } = useMonitoringStatusUpdate(monitoringStatus);

  const { consultationStatusConfig, consultationStatusMutation } =
    useConsultationStatusUpdate(consultationStatus);

  const { testStatusConfig, testStatusMutation } =
    useTestStatusUpdate(testStatus);

  const previousMonitoringStatus = usePrevious(
    monitoringStatusConfig.current?.value
  );
  const previousConsultationStatus = usePrevious(
    consultationStatusConfig.current?.value
  );
  const previousTestStatus = usePrevious(testStatusConfig.current?.value!);

  const handleConfirmModal = (
    monitoringStatusType: MonitoringStatusType,
    stateFn: Dispatch<SetStateAction<boolean>>
  ) => {
    monitoringStatusConfig.options.setValues(
      monitoringStatusType === MonitoringStatusType.SelfMonitoring
        ? SelfMonitoringOptions
        : MonitoringStatusOptions
    );
    monitoringStatusConfig.color.setCurrentColor(
      getColorValue(MonitoringStatusTypeColorMap[monitoringStatusType])
    );
    monitoringStatusConfig.setCurrent(
      MonitoringStatusOptions.find(
        (item) => item.value === monitoringStatusType
      )
    );
    monitoringStatusMutation.mutation({
      variables: getMonitoringStatusInputVariables(
        condition,
        monitoringStatusType,
        userId,
        version,
        wardPatientId
      ),
      refetchQueries: getRefetchQueries(wardPatientId, careSetting.id ?? null),
      onCompleted: () => {
        setIsSelfMonitoring(
          monitoringStatusType === MonitoringStatusType.SelfMonitoring
        );
      },
    });
    stateFn(false);
  };

  const handleCloseModal = (
    stateFn: Dispatch<SetStateAction<boolean>>,
    isSelfMonitored = false
  ) => {
    if (isSelfMonitored) {
      monitoringStatusConfig.options.setValues(MonitoringStatusOptions);
      setIsSelfMonitoring(false);
    }
    monitoringStatusConfig.setCurrent(monitoringStatusConfig.previous);
    monitoringStatusConfig.color.setCurrentColor(
      monitoringStatusConfig.color.previousColor ?? ''
    );
    stateFn(false);
  };

  const handleMonitoringStatusChange = ({
    value,
    label,
  }: TypedOptionType<MonitoringStatusType | AdmissionStatus.Monitoring>) => {
    if (value === monitoringStatusConfig.current?.value) return;
    trackStatusChange(
      title,
      'Condition',
      previousMonitoringStatus ?? EMPTY_VALUE,
      value
    );
    if (value === MonitoringStatusType.SelfMonitoring)
      setIsSelfMonitoring(true);
    monitoringStatusConfig.setCurrent({ value, label });
    if (value === AdmissionStatus.Monitoring) {
      monitoringStatusModal.setPatientMonitoringOpen(true);
      monitoringStatusConfig.color.setCurrentColor('');
    } else {
      monitoringStatusConfig.color.setCurrentColor(
        getColorValue(MonitoringStatusTypeColorMap[value])
      );
      switch (value) {
        case MonitoringStatusType.SelfMonitoring:
          monitoringStatusModal.setPatientSelfMonitoringOpen(true);
          break;
        case MonitoringStatusType.Reviewed:
          monitoringStatusModal.setMonitoredConditionReviewedOpen(true);
          break;
        case MonitoringStatusType.UrgentClinicalInterventionRequired:
          monitoringStatusModal.setUrgentClinicalIntervention(true);
          break;
        default:
          monitoringStatusMutation.mutation({
            variables: getMonitoringStatusInputVariables(
              condition,
              value,
              userId,
              version,
              wardPatientId
            ),
            refetchQueries: getRefetchQueries(
              wardPatientId,
              careSetting.id ?? null
            ),
          });
          break;
      }
    }
  };

  const handleConsultationStatusChange = ({
    value,
    label,
  }: TypedOptionType<MonitoredConditionConsultationStatusType>) => {
    if (value === consultationStatusConfig.current?.value) return;
    trackStatusChange(
      title,
      'Consultation',
      previousConsultationStatus ?? EMPTY_VALUE,
      value
    );
    consultationStatusMutation.mutation({
      variables: getConsultationStatusInputVariables(
        condition,
        value,
        userId,
        version,
        wardPatientId
      ),
      refetchQueries: getRefetchQueries(wardPatientId, careSetting.id ?? null),
      onCompleted: () => {
        consultationStatusConfig.setCurrent({ value, label });
        consultationStatusConfig.color.setCurrentColor(
          getColorValue(ConsultationStatusTypeColorMap[value])
        );
      },
    });
  };

  const handleTestStatusChange = ({
    value,
    label,
  }: TypedOptionType<MonitoredConditionTestStatusType>) => {
    if (value === testStatusConfig.current?.value) return;
    trackStatusChange(title, 'Test', previousTestStatus ?? EMPTY_VALUE, value);
    testStatusMutation.mutation({
      variables: getTestStatusInputVariables(
        condition,
        value,
        userId,
        version,
        wardPatientId
      ),
      refetchQueries: getRefetchQueries(wardPatientId, careSetting.id ?? null),
      onCompleted: () => {
        testStatusConfig.setCurrent({ value, label });
        testStatusConfig.color.setCurrentColor(
          getColorValue(TestStatusTypeColorMap[value])
        );
      },
    });
  };

  return (
    <>
      <Card
        id={`${toHyphenCase(title)}-card`}
        data-testid={`${toHyphenCase(title)}-card`}
      >
        <Accordion
          id={`${toHyphenCase(title)}-accordion`}
          data-testid={`${toHyphenCase(title)}-accordion`}
        >
          <AccordionItem
            title={<MonitoredConditionTitle>{title}</MonitoredConditionTitle>}
            id={`${toHyphenCase(title)}-accordion-item`}
            expanded
          >
            <Divider flexColor="grey.grey02" marginPadding="none" />
            <StatusWrapper>
              <MonitoredConditionStatusUpdate
                loading={monitoringStatusMutation.loading}
                label="Condition Status"
                id={`${title.toLowerCase()}-condition-status`}
                statusOptions={monitoringStatusConfig.options.values}
                currentStatus={monitoringStatusConfig.current}
                handleChange={handleMonitoringStatusChange}
                valueContainerColor={monitoringStatusConfig.color.currentColor}
                error={monitoringStatusMutation.error}
                status={monitoringStatus}
                condition={condition}
                handleReload={handleReload}
              />
              {!isSelfMonitoring && (
                <>
                  <Spacer size="s4" />
                  <MonitoredConditionStatusUpdate
                    loading={consultationStatusMutation.loading}
                    label="Consultation"
                    id={`${title.toLowerCase()}-consultation-status`}
                    statusOptions={consultationStatusConfig.options.values}
                    currentStatus={consultationStatusConfig.current}
                    handleChange={handleConsultationStatusChange}
                    valueContainerColor={
                      consultationStatusConfig.color.currentColor
                    }
                    error={consultationStatusMutation.error}
                    status={consultationStatus}
                    condition={condition}
                    handleReload={handleReload}
                  />
                  <Spacer size="s4" />
                  <MonitoredConditionStatusUpdate
                    loading={testStatusMutation.loading}
                    label="Tests"
                    id={`${title.toLowerCase()}-test-status`}
                    statusOptions={testStatusConfig.options.values}
                    currentStatus={testStatusConfig.current}
                    handleChange={handleTestStatusChange}
                    valueContainerColor={testStatusConfig.color.currentColor}
                    error={testStatusMutation.error}
                    status={testStatus}
                    condition={condition}
                    handleReload={handleReload}
                  />
                </>
              )}
            </StatusWrapper>
          </AccordionItem>
        </Accordion>
      </Card>

      <MonitoringStatus
        id="patient-self-monitored"
        isModalOpen={monitoringStatusModal.patientSelfMonitoringOpen}
        handleClose={() => {
          handleCloseModal(
            monitoringStatusModal.setPatientSelfMonitoringOpen,
            true
          );
        }}
        handleConfirm={() =>
          handleConfirmModal(
            MonitoringStatusType.SelfMonitoring,
            monitoringStatusModal.setPatientSelfMonitoringOpen
          )
        }
        title="Patient to self-monitor"
        text={
          <>
            The patient will no longer be actively monitored for their &nbsp;
            {title}, meaning the remote care team will not be reviewing their
            condition moving forwards.
            <br />
            <br />
            Please ensure you inform the patient separately, as they will not
            automatically be notified of this status change.
          </>
        }
        checkboxText={
          <Text color={colors.grey.grey10}>
            I have informed the patient and can confirm they are stable to
            self-monitor their condition from <ReviewDateTime />
          </Text>
        }
      />
      <MonitoringStatus
        id="patient-monitored"
        isModalOpen={monitoringStatusModal.patientMonitoringOpen}
        handleClose={() => {
          handleCloseModal(monitoringStatusModal.setPatientMonitoringOpen);
        }}
        handleConfirm={() =>
          handleConfirmModal(
            MonitoringStatusType.AwaitingReadings,
            monitoringStatusModal.setPatientMonitoringOpen
          )
        }
        title="Patient set to monitored"
        text={
          <>
            The patient will be actively monitored for their {title}, meaning
            the remote care team will be reviewing their condition moving
            forwards.
            <br />
            <br />
            Please ensure you inform the patient separately, as they will not
            automatically be notified of this status change.
          </>
        }
        checkboxText={
          <Text color={colors.grey.grey10}>
            I confirm the patient should be set to monitoring for their
            condition from <ReviewDateTime />
          </Text>
        }
      />
      <MonitoringStatus
        id="monitored-condition-reviewed"
        isModalOpen={monitoringStatusModal.monitoredConditionReviewedOpen}
        handleClose={() => {
          handleCloseModal(
            monitoringStatusModal.setMonitoredConditionReviewedOpen
          );
        }}
        handleConfirm={() =>
          handleConfirmModal(
            MonitoringStatusType.Reviewed,
            monitoringStatusModal.setMonitoredConditionReviewedOpen
          )
        }
        title={`${title} reviewed`}
        text={`Please ensure you have reviewed all readings and completed the patients initial ${title} review. Once confirmed, you can add consultation and/or test requirements if needed.`}
        checkboxColor={colors.notifications.light.successLight}
        checkboxText={
          <Text color={colors.grey.grey10}>
            I have reviewed all of the patient&apos;s readings and completed
            their initial review on <ReviewDateTime />
          </Text>
        }
      />
      <MonitoringStatus
        id="urgent-clinical-intervention"
        isModalOpen={monitoringStatusModal.urgentClinicalIntervention}
        handleClose={() => {
          handleCloseModal(monitoringStatusModal.setUrgentClinicalIntervention);
        }}
        handleConfirm={() =>
          handleConfirmModal(
            MonitoringStatusType.UrgentClinicalInterventionRequired,
            monitoringStatusModal.setUrgentClinicalIntervention
          )
        }
        title="Urgent clinical intervention"
        text="The clinician will not automatically be notified of this status change. Please ensure you have escalated the patient’s circumstances to the appropriate clinician."
        checkboxColor={colors.notifications.light.successLight}
        checkboxText={
          <Text color={colors.grey.grey10}>
            I have informed the clinician of the patient&apos;s escalation on{' '}
            <ReviewDateTime />
          </Text>
        }
      />
    </>
  );
};
