import { Button, Modal } from '@bt-healthcare/ui-toolkit';

import Hero from 'assets/patient-admitted-success.svg?react';
import type { ModalProps } from '../types';
import {
  ButtonWrapper,
  ModalBody,
  ModalStrapline,
  TextWrapper,
} from '../styles';
import { ModalTitle } from '../ModalTitle';

type PatientAdmittedModalProps = ModalProps & {
  isStandalone: boolean;
};

export const PatientAdmittedModal = ({
  modalOpen,
  handleClose,
  isStandalone,
}: PatientAdmittedModalProps) => (
  <Modal
    onClose={handleClose}
    isModalOpen={modalOpen}
    id="admission-success-modal"
  >
    <ModalBody>
      <Hero />
      <TextWrapper>
        <ModalTitle
          title={isStandalone ? 'Remote care invite sent' : 'Patient admitted'}
        />
        <ModalStrapline>
          {isStandalone
            ? 'You can now find them on the patient list. Once the invitation is accepted, you will have access to their historical data.'
            : 'You can now find them on the patient list.'}
        </ModalStrapline>
      </TextWrapper>
      <ButtonWrapper>
        <Button id="view-patient-list" onClick={handleClose} variant="primary">
          View patient list
        </Button>
      </ButtonWrapper>
    </ModalBody>
  </Modal>
);
