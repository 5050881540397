import styled from 'styled-components';
import { Wrapper } from '@bt-healthcare/ui-toolkit';

import { MAX_FORM_WIDTH } from 'styles/constants.styles';

export const FormWrapper = styled(Wrapper)`
  max-width: ${MAX_FORM_WIDTH};
  margin: 10% auto;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
