import type { ColorType } from '@bt-healthcare/ui-toolkit';
import {
  Card,
  Divider,
  StatusChip,
  Text,
  colors,
  fontSizes,
  toHyphenCase,
} from '@bt-healthcare/ui-toolkit';

import { Fragment } from 'react';
import { Grid, Title } from './styles';
import type { HypertensionAssessmentStatusCardProps } from './types';
import { getHypertensionStatusValue } from './utils';

export const HypertensionAssessmentStatusCard = ({
  assessmentStatuses,
}: HypertensionAssessmentStatusCardProps) => (
  <Card
    id="hypertension-assessment-status-card"
    data-testid="hypertension-assessment-status-card"
  >
    <Title role="heading">Hypertension status updates:</Title>
    <Divider flexColor="grey.grey02" />
    <Grid>
      {assessmentStatuses.map(({ status, label }) => (
        <Fragment key={label}>
          <Text color={colors.grey.grey10} fontSize={fontSizes.base}>
            {label}
          </Text>
          <StatusChip
            id={`${toHyphenCase(label)}-hypertension-assessment-status-chip`}
            data-testid={`${toHyphenCase(
              label
            )}-hypertension-assessment-status-chip`}
            chipColor={
              getHypertensionStatusValue(status, 'colour') as ColorType
            }
          >
            {getHypertensionStatusValue(status, 'status')}
          </StatusChip>
        </Fragment>
      ))}
    </Grid>
  </Card>
);
