import styled from 'styled-components';

import { device, fontSizes, spacing, colors } from '@bt-healthcare/ui-toolkit';

export const DataListItem = styled.span`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: ${spacing.s8};
  font-size: ${fontSizes.sm};
  margin-bottom: ${spacing.s4};
  color: ${colors.grey.grey10};

  span:first-child {
    font-weight: 500;
  }

  span:last-child {
    font-weight: 400;
    margin-bottom: ${spacing.none};
  }

  @media ${device.largeTablet} {
    font-size: ${fontSizes.base};
  }
`;

export const CardWrapper = styled.div`
  width: 100%;

  @media ${device.largeTablet} {
    width: 80%;
  }
`;

export const CommentsWrapper = styled.span`
  word-break: break-all;
`;
