import {
  Card,
  SubHeader,
  Notification,
  Divider,
} from '@bt-healthcare/ui-toolkit';
import { startOfDay, endOfDay } from 'date-fns';
import { Fragment } from 'react';

import { ReadingItem } from '../ReadingItem';
import { getObservationReadings, mapping } from './mapping';
import type { TodayReadingsProps } from './types';
import { FETCH_POLICY } from 'App.constants';
import { useGetObservationsForTodaysReadingsQuery } from 'services/graphql';

export const TodayReadings = ({
  personId,
  observationTypes,
  hasSubmittedReadings,
}: TodayReadingsProps) => {
  const { data, error } = useGetObservationsForTodaysReadingsQuery({
    variables: {
      input: {
        personId,
        observationTypes,
        from: startOfDay(new Date()).toISOString(),
        to: endOfDay(new Date()).toISOString(),
      },
    },
    skip: !hasSubmittedReadings,
    fetchPolicy: FETCH_POLICY.CACHE_AND_NETWORK,
    notifyOnNetworkStatusChange: true,
  });

  return (
    <Card id="today-readings" height="fit-content">
      <SubHeader>Today&apos;s readings</SubHeader>
      {error && (
        <Notification type="softWarning">
          Please refresh the page to try and reload.
        </Notification>
      )}

      {observationTypes
        .map((type) => mapping[type])
        .map(({ type, name, icon }, idx) => (
          <Fragment key={`${type}-key`}>
            <ReadingItem
              type={type}
              name={name}
              icon={icon}
              isLast={idx === observationTypes.length - 1}
              observations={getObservationReadings(
                type,
                data?.observationsForTodaysReadings?.observationTypeReadings
              )}
            />
            {idx < observationTypes.length - 1 && <Divider />}
          </Fragment>
        ))}
    </Card>
  );
};
