import { useAnalytics } from '@bt-healthcare/ui-toolkit';

import { UNKNOWN_VALUE, appName, basePageName } from 'App.constants';
import { useApp } from 'context/app/AppContext';

export const useTracking = () => {
  const { careSetting } = useApp();
  const { trackPage: track, trackEvent } = useAnalytics();

  const trackPage = (pageName: string) => {
    const trackingProperties = {
      appName,
      careSetting: careSetting?.organisation ?? UNKNOWN_VALUE,
    };
    track(
      `${pageName}${basePageName}`,
      window.location.href,
      trackingProperties
    );
  };

  const trackTapped = (buttonName: string, pageName: string) =>
    trackEvent('Tapped', {
      buttonName,
      pageName: `${appName} - ${pageName}`,
      careSetting: careSetting?.organisation ?? UNKNOWN_VALUE,
    });

  const trackDropDown = (
    dropDownName: string,
    pageName: string,
    selectionFrom: string,
    selectionTo: string
  ) =>
    trackEvent('Drop-down selected', {
      dropDownName,
      pageName: `${appName} - ${pageName}`,
      selectionFrom,
      selectionTo,
    });

  const trackLink = (url: string, pageName: string) =>
    trackEvent('Link selected', {
      url,
      pageName: `${appName} - ${pageName}`,
    });

  return {
    trackPage,
    trackEvent,
    trackTapped,
    trackDropDown,
    trackLink,
  };
};
