import { useNavigate } from 'react-router-dom';
import {
  SpinnerLoader,
  useScrollToTop,
  Text,
  colors,
  fontSizes,
  Grid,
  ErrorModal,
  Col,
  Row,
  Spacer,
} from '@bt-healthcare/ui-toolkit';
import { useEffect, useState } from 'react';

import { useTracking } from 'hooks/useTracking';
import { ROUTE } from 'config/routes';
import { getConfirmationConfig } from 'components/Settings/settings.utils';
import {
  useSettings,
  useSettingsDispatch,
} from 'context/settings/SettingsContext';
import {
  userAdmissionFormMapping,
  userConfirmationMapping,
  userFormMapping,
} from 'components/Settings/settings.mappings';
import { UserSubmittedModal } from 'components/Modal/UserSubmitted';
import { FormFooter } from 'components/FormFooter';
import { DataListCard } from 'components/Card/DataListCard';
import { useRegistrationFormSubmission } from 'hooks/useRegistrationFormSubmission';
import { useApp } from 'context/app/AppContext';
import { userAlreadyExistsError } from 'services/errors/client';
import { ErrorCode } from 'services/graphql';
import { FormWrapper } from 'components/Form/styles';

export const UserConfirmation = () => {
  useScrollToTop();

  const { userAction, userFormData, user } = useSettings();
  const { careSetting } = useApp();
  const settingsDispatch = useSettingsDispatch();

  const [modalOpen, setModalOpen] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [errorType, setErrorType] = useState<ErrorCode | undefined>();

  const isEdit = userAction.userAction === 'edit';
  const isReactivate = userAction.userAction === 'activate';
  const data = userAdmissionFormMapping(userFormData);

  const navigate = useNavigate();
  const { trackPage } = useTracking();

  const mappedUserAdmissionData = userFormMapping(userFormData);

  const onCompleted = () => {
    setModalOpen(true);
  };

  const { loading, onSubmit, error } = useRegistrationFormSubmission(
    careSetting.id!,
    mappedUserAdmissionData,
    onCompleted,
    user.id!,
    isEdit,
    isReactivate,
    careSetting.defaultWardId!
  );

  useEffect(() => {
    if (error) {
      if (userAlreadyExistsError(error.graphQLErrors)) {
        setErrorType(ErrorCode.UserAlreadyExists);
      } else {
        setErrorType(ErrorCode.Generic);
      }
      setErrorModalOpen(true);
    }
  }, [error]);

  const {
    confirmationText,
    actionText,
    summaryText,
    errorTitle,
    errorMessage,
    tracking,
  } = getConfirmationConfig(isEdit, isReactivate, errorType);

  useEffect(() => {
    trackPage(tracking);
  }, []);

  return (
    <FormWrapper>
      <Grid>
        {loading && (
          <SpinnerLoader
            id="loader"
            data-testid="loader"
            asModal
            text="Submitting user"
          />
        )}
        <Row>
          <Col from={1} display={{ lg: 'block', md: 'none' }}>
            <Text
              color={colors.primaryIndigo.indigo08}
              as="h4"
              fontWeight={500}
              fontSize={fontSizes.h4}
            >
              Confirm user details
            </Text>
          </Col>
        </Row>
        <Row>
          <Col from={1}>
            <Text
              color={colors.grey.grey09}
              fontWeight={400}
              fontSize={fontSizes.base}
            >
              Please review the information below, once you&apos;re happy with
              it, press confirm to {confirmationText}.
            </Text>
          </Col>
        </Row>
        <Row>
          <Col from={1} width="600px">
            <DataListCard data={userConfirmationMapping(data)} />
          </Col>
        </Row>
        <FormFooter
          primaryButtonText="Confirm"
          primaryButtonClick={() => {
            onSubmit();
          }}
          cancelButtonClick={() => {
            navigate(ROUTE.SETTINGS_USER);
          }}
        />
        <UserSubmittedModal
          modalOpen={modalOpen}
          handleClose={() => {
            setModalOpen(false);
            settingsDispatch({ type: 'resetUserForm' });
            navigate(ROUTE.SETTINGS_USER);
          }}
          actionText={actionText}
          summaryText={summaryText}
        />
        {errorModalOpen && (
          <ErrorModal
            isModalOpen={errorModalOpen}
            secondaryButtonText="Cancel"
            onSecondaryAction={() => {
              setErrorModalOpen(false);
              setErrorType(undefined);
            }}
            onPrimaryAction={() => {
              setErrorModalOpen(false);
              if (errorType === ErrorCode.Generic) onSubmit();
              setErrorType(undefined);
            }}
            primaryButtonText="Try again"
            title={errorTitle}
          >
            {errorMessage} If the problem persists, please contact us for
            assistance.
          </ErrorModal>
        )}
      </Grid>
      <Spacer size="l4" />
    </FormWrapper>
  );
};
