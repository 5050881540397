import {
  Wrapper,
  colors,
  device,
  fontSizes,
  spacing,
} from '@bt-healthcare/ui-toolkit';
import styled from 'styled-components';

export const CardContent = styled.div`
  @media ${device.largeTablet} {
    padding: ${spacing.s2};
  }
`;

export const EditButtonWrapper = styled(Wrapper)`
  width: max-content;
  cursor: pointer;
  color: ${colors.grey.grey10};
  &:hover {
    color: ${colors.primaryIndigo.indigo08};
  }
  svg {
    pointer-events: none;
  }
`;

export const PatientOverviewDetailsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: ${spacing.s4};
  padding: ${spacing.s2} ${spacing.none};

  @media ${device.largeTablet} {
    grid-template-columns: repeat(5, 1fr);
    padding: ${spacing.s6} ${spacing.none};
  }
`;

export const PatientOverviewDetail = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.s1};
  overflow-wrap: anywhere;

  @media ${device.largeTablet} {
    gap: ${spacing.s2};
  }
`;

export const DischargeCardContent = styled(CardContent)`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: ${spacing.s4};

  @media ${device.largeTablet} {
    flex-direction: row;
    align-items: center;
    gap: ${spacing.s6};
  }
`;

export const DischargeContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.s4};

  @media ${device.largeTablet} {
    max-width: 715px;
    gap: ${spacing.s2};
  }
`;

export const DischargeText = styled.span`
  color: ${colors.grey.grey08};
  font-size: ${fontSizes.sm};

  @media ${device.largeTablet} {
    font-size: ${fontSizes.base};
  }
`;
