import styled from 'styled-components';
import { colors, spacing } from '@bt-healthcare/ui-toolkit';

export const NoteWrapper = styled.div`
  margin: ${spacing.s4} 0;
`;
export const NoteMessageWrapper = styled.div`
  margin-top: ${spacing.s4};
`;

export const Notification = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  font-size: 14px;
  color: ${colors.notifications.light.errorDark};
  > :first-child {
    margin-right: 9px;
  }
`;
