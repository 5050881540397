import type { RecommendedReasons } from './types';
import { BoldText } from 'components/BoldText';
import { GuidelineType } from 'services/graphql';

export const getRecommendedReasons = (guidelineType: GuidelineType) => {
  const offerMedicationReasons: RecommendedReasons[] = [
    {
      id: 1,
      reason: (
        <>
          Average blood pressure is greater than or equal to{' '}
          <BoldText>150/95</BoldText>
        </>
      ),
    },

    {
      id: 2,
      reason: `Average based on a minimum of 8 readings over at least 4 days and maximum of 28 days.`,
    },
  ];
  if (guidelineType === GuidelineType.OfferMedication)
    return offerMedicationReasons;

  return [];
};
