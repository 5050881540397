import { colors } from '@bt-healthcare/ui-toolkit';
import styled from 'styled-components';

export const NumOfFiltersWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: ${colors.primaryIndigo.indigo08};
`;
