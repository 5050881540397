import { Col, Grid, Row, RequestCard, colors } from '@bt-healthcare/ui-toolkit';
import { useEffect } from 'react';

import { Link } from 'react-router-dom';

import { useTracking } from 'hooks/useTracking';
import { PageName } from 'config/pageNames';
import { ROUTE } from 'config/routes';

export const Settings = () => {
  const { trackPage } = useTracking();
  useEffect(() => {
    trackPage(PageName.SETTINGS);
  }, []);

  return (
    <Grid>
      <Row>
        <Col xs={4} alignSelf="center">
          <Link to={ROUTE.SETTINGS_USER}>
            <RequestCard
              cardTitle="Users"
              data-testid="users-request-card"
              icon="ChevronRight"
              iconColor={colors.grey.grey09}
              id="users-request-card"
            />
          </Link>
        </Col>
      </Row>
    </Grid>
  );
};
