import { Button, Modal } from '@bt-healthcare/ui-toolkit';

import Hero from 'assets/patient-admitted-success.svg?react';
import type { UserSubmittedModalProps } from '../types';
import {
  ButtonWrapper,
  ModalBody,
  ModalStrapline,
  TextWrapper,
} from '../styles';
import { ModalTitle } from '../ModalTitle';

export const UserSubmittedModal = ({
  modalOpen,
  handleClose,
  actionText,
  summaryText,
}: UserSubmittedModalProps) => (
  <Modal
    onClose={handleClose}
    isModalOpen={modalOpen}
    id="user-submitted-modal"
  >
    <ModalBody>
      <Hero />
      <TextWrapper>
        <ModalTitle title={`User successfully ${actionText}`} />
        <ModalStrapline>{summaryText}</ModalStrapline>
      </TextWrapper>
      <ButtonWrapper>
        <Button id="view-users" onClick={handleClose} variant="primary">
          View users
        </Button>
      </ButtonWrapper>
    </ModalBody>
  </Modal>
);
