import type { ColorType } from '@bt-healthcare/ui-toolkit';
import { sortWith, prop, descend } from 'ramda';
import type { FilterType } from 'filters/types';

import {
  MonitoredConditionTestStatusType,
  MonitoredConditionConsultationStatusType,
  AdmissionStatus,
  MonitoringStatusType,
} from 'services/graphql';
import { isEmptyOrNil } from 'utils/object.utils';
import type { FilterState } from 'reducers/patient/types';

export const AdmissionStatusColorMap: { [Key in AdmissionStatus]: ColorType } =
  {
    [AdmissionStatus.Accepted]: 'rag.light.lightYellow',
    [AdmissionStatus.Invited]: 'secondaryBlue.blue01',
    [AdmissionStatus.Monitoring]: 'notifications.light.successLight',
    [AdmissionStatus.InvitedStepup]: 'rag.light.lightYellow',
  };

export const MonitoringStatusTypeColorMap: {
  [Key in MonitoringStatusType]: ColorType;
} = {
  [MonitoringStatusType.Reviewed]: 'notifications.light.successLight',
  [MonitoringStatusType.SelfMonitoring]: 'secondaryBlue.blue01',
  [MonitoringStatusType.AwaitingReadings]: 'rag.light.lightYellow',
  [MonitoringStatusType.ReadingsAvailable]: 'secondaryBlue.blue01',
  [MonitoringStatusType.ReadyForReview]: 'notifications.light.successLight',
  [MonitoringStatusType.UrgentClinicalInterventionRequired]:
    'notifications.light.errorLight',
};

export const ConsultationStatusTypeColorMap: {
  [Key in MonitoredConditionConsultationStatusType]: ColorType;
} = {
  [MonitoredConditionConsultationStatusType.Required]: 'rag.light.lightYellow',
  [MonitoredConditionConsultationStatusType.NotRequired]: 'grey.grey03',
  [MonitoredConditionConsultationStatusType.Invited]: 'secondaryBlue.blue01',
  [MonitoredConditionConsultationStatusType.Booked]: 'secondaryBlue.blue01',
  [MonitoredConditionConsultationStatusType.Missed]:
    'notifications.light.errorLight',
  [MonitoredConditionConsultationStatusType.Cancelled]:
    'notifications.light.errorLight',
  [MonitoredConditionConsultationStatusType.Completed]:
    'notifications.light.successLight',
};

export const TestStatusTypeColorMap: {
  [Key in MonitoredConditionTestStatusType]: ColorType;
} = {
  [MonitoredConditionTestStatusType.Required]: 'rag.light.lightYellow',
  [MonitoredConditionTestStatusType.NotRequired]: 'grey.grey03',
  [MonitoredConditionTestStatusType.Invited]: 'secondaryBlue.blue01',
  [MonitoredConditionTestStatusType.Booked]: 'secondaryBlue.blue01',
  [MonitoredConditionTestStatusType.Missed]: 'notifications.light.errorLight',
  [MonitoredConditionTestStatusType.Cancelled]:
    'notifications.light.errorLight',
  [MonitoredConditionTestStatusType.AwaitingResults]: 'secondaryBlue.blue01',
  [MonitoredConditionTestStatusType.Overdue]: 'notifications.light.errorLight',
  [MonitoredConditionTestStatusType.Returned]: 'rag.light.lightYellow',
  [MonitoredConditionTestStatusType.Reviewed]:
    'notifications.light.successLight',
};

export const getFilterByType = (filters: FilterState, filterType: FilterType) =>
  filters.statusFilters?.filter((item) => item.type === filterType);

export const getFilters = (filters: FilterState) => {
  const testStatusFilters = getFilterByType(filters, 'TestStatus');
  const consultationStatusFilters = getFilterByType(
    filters,
    'ConsultationStatus'
  );
  const medicalCentreFilters = getFilterByType(filters, 'MedicalCentre');

  return {
    admissionStatuses: isEmptyOrNil(filters.PatientStatus)
      ? []
      : filters.PatientStatus.filter(
          (item) =>
            item.active &&
            (item.filter === AdmissionStatus.Accepted ||
              item.filter === AdmissionStatus.Invited ||
              item.filter === AdmissionStatus.InvitedStepup)
        ).map((item) => item.filter),
    monitoringStatuses: isEmptyOrNil(filters.PatientStatus)
      ? []
      : filters.PatientStatus.filter(
          (item) =>
            item.active &&
            item.filter !== AdmissionStatus.Accepted &&
            item.filter !== AdmissionStatus.Invited &&
            item.filter !== AdmissionStatus.InvitedStepup
        ).map((item) => item.filter),
    monitoredConditions: isEmptyOrNil(filters.Condition)
      ? []
      : filters.Condition.filter((item) => item.active).map(
          (item) => item.filter
        ),
    registeredGPSurgeries: isEmptyOrNil(medicalCentreFilters)
      ? null
      : medicalCentreFilters.map((item) => item.value),
    testStatuses: isEmptyOrNil(testStatusFilters)
      ? null
      : testStatusFilters.map(
          (item) => item.value as MonitoredConditionTestStatusType
        ),
    consultationStatuses: isEmptyOrNil(consultationStatusFilters)
      ? null
      : consultationStatusFilters.map(
          (item) => item.value as MonitoredConditionConsultationStatusType
        ),
  };
};

export const sortByCondition = sortWith([
  descend(prop<any>('condition')),
]) as any;
