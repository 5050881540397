import { useEffect } from 'react';
import {
  Text,
  colors,
  fontSizes,
  Spacer,
  useScrollToTop,
} from '@bt-healthcare/ui-toolkit';
import { useSearchParams } from 'react-router-dom';

import { ProgressIndicatorWrapper } from './styles';
import { Step } from './types';
import { PatientAdmissionFormStep } from './PatientAdmissionFormStep';
import { ProgressIndicator } from 'components/ProgressIndicator/ProgressIndicator';
import { enumFromStringValue } from 'mappings/enums';
import { capitalize } from 'utils/string.utils';
import { useTracking } from 'hooks/useTracking';
import { PageName } from 'config/pageNames';
import { FormWrapper } from 'components/Form/styles';

const stepToIndex = (step: Step): number => {
  switch (step) {
    case Step.CONFIRM:
      return 3;
    case Step.MEDICAL:
      return 2;
    default:
      return 1;
  }
};

const stepsOrder = [Step.PERSONAL, Step.MEDICAL, Step.CONFIRM];

export const PatientAdmissionForm = () => {
  useScrollToTop();
  const { trackPage } = useTracking();
  const [searchParams] = useSearchParams();
  const step: Step =
    enumFromStringValue(Step, searchParams.get('step') || Step.PERSONAL) ||
    Step.PERSONAL;

  useEffect(() => {
    switch (step) {
      case Step.CONFIRM:
        return trackPage(`${PageName.ADMISSION} - Confirm`);
      case Step.MEDICAL:
        return trackPage(`${PageName.ADMISSION} - Medical`);
      default:
        return trackPage(PageName.ADMISSION);
    }
  }, [step]);

  return (
    <FormWrapper>
      <ProgressIndicatorWrapper>
        <ProgressIndicator
          steps={stepsOrder.map(capitalize)}
          currentStep={stepToIndex(step)}
        />
      </ProgressIndicatorWrapper>
      {step === Step.CONFIRM && (
        <Text
          color={colors.primaryIndigo.indigo08}
          as="h4"
          fontWeight={500}
          fontSize={fontSizes.h4}
        >
          Confirm admission
        </Text>
      )}
      <Spacer size="s4" />
      <PatientAdmissionFormStep step={step} />
      <Spacer size="l4" />
    </FormWrapper>
  );
};
