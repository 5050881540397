import type { AnySchema } from 'yup';
import { object, string } from 'yup';
import type { OptionalObjectSchema } from 'yup/lib/object';

import type { DischargeFormData } from './PatientDischarge.types';

export const schema: OptionalObjectSchema<
  Record<keyof DischargeFormData, AnySchema>
> = object({
  reasonForDischarge: object().nullable().required('This is a required field.'),
  comments: string(),
});
