import { Button, Modal, noop } from '@bt-healthcare/ui-toolkit';

import { useEffect, useRef } from 'react';
import type { ResetRecommendationProps } from '../types';
import { ButtonWrapper, ModalBody, ModalStrapline } from '../styles';
import { ModalTitle } from '../ModalTitle';
import { TextWrapper } from './styles';

export const ResetRecommendation = ({
  modalOpen,
  onCancel,
  onContinue,
}: ResetRecommendationProps) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  useEffect(() => {
    if (modalOpen && buttonRef.current) {
      buttonRef.current.focus();
    }
  }, [modalOpen]);
  return (
    <Modal
      onClose={noop}
      isModalOpen={modalOpen}
      id="reset-recommendation-modal"
    >
      <ModalBody>
        <TextWrapper>
          <ModalTitle title="Recommendation will be reset or removed" />
          <ModalStrapline>
            Changing the values will reset or remove the current recommendation.
            Click cancel to keep it or continue to update. Please note that
            updating it may result in no recommendation.
          </ModalStrapline>
        </TextWrapper>
        <ButtonWrapper>
          <Button id="cancel-btn" onClick={onCancel}>
            Yes, cancel
          </Button>
          <Button
            id="continue-btn"
            onClick={onContinue}
            variant="primaryRed"
            ref={buttonRef}
          >
            No, continue
          </Button>
        </ButtonWrapper>
      </ModalBody>
    </Modal>
  );
};
