/**
 * Usage
 * To use this hook you will need:
 * showButton: {useState}: required as a callback from this component
 * clamped: {useState}: required to add/remove class from component
 * class file containing:
 * .clamp {
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            display: -webkit-box;
            overflow: hidden;
            text-overflow: ellipsis;
            overflow-wrap: break-word;
          }


 implementation:
 1. On the text field that you want to clamp:
 <div ref={containerRef} className={clamped ? 'clamp' : ''}>
 the text
 </div>

 3. a reference for the text field:
 const containerRef = useRef<HTMLInputElement>(null);

 2. A button to show/hide clamped text:
 <Button onClick={handleClick}>show/hide text</Button>

 3. A function to handle state toggle change of clamped
 const handleClick = () => setClamped(!clamped);
 */

import type { RefObject, SetStateAction } from 'react';
import { useEffect } from 'react';
import debounce from 'lodash.debounce';

export const useTextClamping = (
  containerRef: RefObject<HTMLInputElement>,
  setState: SetStateAction<any>
) => {
  useEffect(() => {
    const hasClamping = (el: HTMLElement) => {
      const { clientHeight, scrollHeight } = el;
      return clientHeight !== scrollHeight;
    };

    const checkButtonAvailability = () => {
      if (containerRef.current) {
        // Save current state to reapply later if necessary.
        const hadClampClass = containerRef.current.classList.contains('clamp');
        // Make sure that CSS clamping is applied if applicable.
        if (!hadClampClass) containerRef.current.classList.add('clamp');
        // Check for clamping and show or hide button accordingly.
        setState(hasClamping(containerRef.current));
        // Sync clamping with local state.
        if (!hadClampClass) containerRef.current.classList.remove('clamp');
      }
    };

    const debouncedCheck = debounce(checkButtonAvailability, 50);

    checkButtonAvailability();
    window.addEventListener('resize', debouncedCheck);

    return () => {
      window.removeEventListener('resize', debouncedCheck);
    };
  }, [containerRef]);
};
