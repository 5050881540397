/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  ErrorNotification,
  Link,
  toHyphenCase,
} from '@bt-healthcare/ui-toolkit';

import type { MonitoredConditionStatusErrorProps } from './types';
import { versionMismatchError } from 'services/errors/client';

export const MonitoredConditionStatusError = ({
  condition,
  error = [],
  handleReload,
}: MonitoredConditionStatusErrorProps) => {
  const isVersionMismatch = error.length >= 1 && versionMismatchError(error);
  const messageDetail = isVersionMismatch
    ? ' The status may have been already updated.'
    : '';

  return (
    <ErrorNotification id={`${toHyphenCase(condition)}-status`}>
      We&apos;re sorry, there&apos;s been an issue when updating the status.
      {messageDetail} Please click{' '}
      <Link
        onClick={handleReload}
        variant="button"
        color="base.dark"
        data-testid={`${toHyphenCase(condition)}-error-notification-link`}
      >
        reload
      </Link>{' '}
      and try again.
    </ErrorNotification>
  );
};
