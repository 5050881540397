import { ModalTitle } from '../styles';
import { ModalButtons } from '../ModalButtons';
import { StartAdmissionModal } from '..';
import {
  ModalBody,
  ModalStrapline,
  TextWrapper,
} from 'components/Modal/styles';

export type DifferentCareSettingModalProps = {
  isModalOpen: boolean;
  handleClose: () => void;
  handleProceed: () => void;
};

export const DifferentCareSettingModal = ({
  isModalOpen,
  handleProceed,
  handleClose,
}: DifferentCareSettingModalProps) => (
  <StartAdmissionModal
    onClose={handleClose}
    isModalOpen={isModalOpen}
    id="different-care-setting-modal"
  >
    <ModalBody>
      <TextWrapper>
        <ModalTitle title="Patient managed by another service provider" />
        <ModalStrapline>
          Patient cannot be admitted to this service. You can advise the patient
          to contact their service provider if they need any support.
        </ModalStrapline>
      </TextWrapper>
      <ModalButtons handleClose={handleClose} handleProceed={handleProceed} />
    </ModalBody>
  </StartAdmissionModal>
);
