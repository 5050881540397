import type { IFlagsmithFeature } from 'flagsmith/types';

export type Flags = {
  [key: string]: IFlagsmithFeature;
};

export enum FeatureFlagKeys {
  REORGANISE_CARE_SETTINGS = 'web_hyc_reorganise_care_settings',
  HEALTH_INFORMATION_VIEW = 'web_hyc_health_information_view',
  LIFESTYLE_VIEW = 'web_hyc_lifestyle_view',
  USER_SETTINGS = 'web_hyc_user_settings',
  REMOTE_CARE_ADMISSION = 'web_hyc_remote_care_admission',
}

export type FeatureFlags = {
  isReorganiseCareSettingsFeatureFlagEnabled: boolean;
  isHealthInformationViewFeatureFlagEnabled: boolean;
  isLifestyleViewFeatureFlagEnabled: boolean;
  isUserSettingsFeatureFlagEnabled: boolean;
  isRemoteCareAdmissionFeatureFlagEnabled: boolean;
};

export const featureFlagKeys = [
  FeatureFlagKeys.REORGANISE_CARE_SETTINGS,
  FeatureFlagKeys.HEALTH_INFORMATION_VIEW,
  FeatureFlagKeys.LIFESTYLE_VIEW,
  FeatureFlagKeys.USER_SETTINGS,
  FeatureFlagKeys.REMOTE_CARE_ADMISSION,
];

export type FeatureFlagsTypes = { [Key in FeatureFlagKeys]?: boolean };
