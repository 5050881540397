import type {
  PatientAdmissionPersonalFormData,
  PatientAdmissionMedicalFormData,
} from 'components/PatientAdmissionForm/types';
import { GenderAssignedAtBirthMap } from 'mappings/enums';
import { getObservationTypesForMonitoredConditionOptions } from 'mappings/monitoredConditions.formatter';
import { ObservationTypeMap } from 'transforms/observationTypeSearchTransform';

import { propEmptyOr } from 'utils/object.utils';
import { nhsIdFormatter } from 'utils/string.utils';

export const personalMapping = (data: PatientAdmissionPersonalFormData) => [
  { name: 'Patient first name', value: data.firstName },
  { name: 'Patient surname', value: data.surname },
  {
    name: 'DOB',
    value: `${data.dayOfBirth}/${data.monthOfBirth}/${data.yearOfBirth}`,
  },
  {
    name: 'Gender assigned at birth',
    value: GenderAssignedAtBirthMap[data.genderAssignedAtBirth],
  },
  {
    name: 'Gender identifies as',
    value: propEmptyOr('label', data.genderIdentifiedAs),
  },
  { name: 'Pronouns', value: propEmptyOr('label', data.pronouns) },
  {
    name: 'Ethnicity',
    value: propEmptyOr('label', data.ethnicity),
  },
  { name: 'First line of address', value: data.address },
  { name: 'Postcode', value: data.postcode },
  { name: 'NHS no', value: nhsIdFormatter(data.nhsNumber)! },
  { name: 'Email', value: data.email },
  { name: 'Phone number', value: data.phoneNumber },
  { name: 'Mobility', value: propEmptyOr('label', data.mobility) },
  {
    name: 'Next of kin first name',
    value: propEmptyOr('nextOfKinFirstName', data),
  },
  {
    name: 'Next of kin surname',
    value: propEmptyOr('nextOfKinSurname', data),
  },
  {
    name: 'Next of kin number',
    value: propEmptyOr('nextOfKinPhoneNumber', data),
  },
];

export const medicalMapping = (data: PatientAdmissionMedicalFormData) => [
  {
    name: 'Condition(s) to monitor',
    value: data.monitoredConditions.map(({ label }) => label).join(', '),
  },
  { name: 'Medical Centre', value: data.gpSurgery.label },
  {
    name: 'Vitals for monitoring',
    value: getObservationTypesForMonitoredConditionOptions(
      data.monitoredConditions
    )
      ?.map((item) => ObservationTypeMap[item]?.name)
      .join(', '),
  },
  { name: 'Comments', value: propEmptyOr('comments', data) },
];
