import { device, spacing } from '@bt-healthcare/ui-toolkit';
import styled from 'styled-components';

export const PatientHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;

  > div {
    grid-gap: ${spacing.s2};

    &:last-child {
      display: none;
    }
  }

  @media ${device.largeTablet} {
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    > div {
      display: flex;
      flex-direction: column;
      &:last-child {
        display: block;
      }
    }
  }
`;

export const PatientAssessmentHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex-direction: column;
  padding-left: ${spacing.s4};

  @media ${device.largeTablet} {
    flex-direction: row;
  }
`;

export const PatientDetailWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${spacing.s2};
  margin-top: ${spacing.s4};
`;
