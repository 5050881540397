import * as yup from 'yup';

export const REQUIRED_TEXT = 'This field is required.';
export const DOB_REQUIRED_TEXT = 'Please enter a valid date of birth.';
export const REQUIRED_NUMBER = 'Please enter a valid number.';
export const MAX_LIFE_SPAN_YEAR = new Date().getFullYear() - 200;

export const firstNameRule = (msg: string) =>
  yup
    .string()
    .max(50, msg)
    .matches(
      /^(?![-].*)(?!.*[-]$)[a-zA-Z-\s]*$/,
      'Name can only contain alphabetical characters, hyphens and spaces.'
    )
    .required(REQUIRED_TEXT);

export const surnameRule = (msg: string) =>
  yup
    .string()
    .max(50, msg)
    .matches(
      /^(?![-'].*)(?!.*[-']$)[a-zA-Z-'\s]*$/,
      'Name can only contain alphabetical characters, apostrophes, hyphens and spaces.'
    )
    .required(REQUIRED_TEXT);

export const requiredPhoneNumber = yup
  .string()
  .required(REQUIRED_TEXT)
  .nullable()
  .matches(
    /^\d*$/,
    'Mobile number must be a whole number, no spaces or special characters allowed.'
  )
  .min(10, 'Mobile number must be at least 10 digits.')
  .max(15, 'Mobile number cannot be more than 15 digits.');

export const requiredDropdownRule = yup
  .object()
  .nullable()
  .required(REQUIRED_TEXT);

export const requiredMultiDropdownRule = yup
  .array()
  .min(1, 'Patient must have at least one condition for monitoring.')
  .nullable()
  .required(REQUIRED_TEXT);

export const requiredNumber = (message = REQUIRED_NUMBER) =>
  yup.number().nullable().typeError(REQUIRED_TEXT).min(1, message);

export const requiredYear = (min = MAX_LIFE_SPAN_YEAR) =>
  yup.number().nullable().typeError(REQUIRED_TEXT).min(min, DOB_REQUIRED_TEXT);

export const required = (
  isNullable = false,
  yupSchema = yup.string(),
  message = REQUIRED_TEXT
) =>
  isNullable
    ? yupSchema.nullable().required(message)
    : yupSchema.required(message);
