import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { HashRouter as Router } from 'react-router-dom';
import flagsmith from 'flagsmith';
import { FlagsmithProvider } from 'flagsmith/react';
import type { EnvTypes} from '@bt-healthcare/ui-toolkit';
import { ErrorBoundary } from '@bt-healthcare/ui-toolkit';

import App from './App';
import { AuthProvider } from './auth/AuthProvider';
import { AuthApolloProvider } from './auth/AuthApolloProvider';
import { getEnvVariable } from './env.utils';
import { initializeAnalytics } from './services/analytics';
import { initializeSentry } from 'sentry';

initializeSentry(
  getEnvVariable('VITE_SENTRY_DSN'),
  getEnvVariable('VITE_ENV') as EnvTypes
);
initializeAnalytics();

flagsmith.init({
  environmentID: getEnvVariable('VITE_FLAGSMITH_ENV_KEY'),
});

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <StrictMode>
    <ErrorBoundary>
      <AuthProvider>
        <AuthApolloProvider>
          <Router>
            <FlagsmithProvider flagsmith={flagsmith}>
              <App />
            </FlagsmithProvider>
          </Router>
        </AuthApolloProvider>
      </AuthProvider>
    </ErrorBoundary>
  </StrictMode>
);
