import styled from 'styled-components';
import { device, spacing } from '@bt-healthcare/ui-toolkit';

import { sideNavOpenWidth, sideNavClosedWidth } from './Navigation/styles';
import type { WrapperProps } from './types';
import { MAX_PAGE_WIDTH } from 'styles/constants.styles';

const desktopPaddingMap = ({ size }: WrapperProps) => {
  const sideMargin = `calc(${sideNavClosedWidth} + ${spacing.s8})`;
  const maxContentWidth = MAX_PAGE_WIDTH;
  if (size === 'normal') {
    return `0 ${sideMargin} ${spacing.s6} ${sideMargin}`;
  }
  const stretchedRightPadding = `calc(100vw - ${sideMargin} - ${maxContentWidth})`;
  return `0 min(max(${spacing.s6}, ${stretchedRightPadding}), ${sideMargin}) ${spacing.s6} ${sideMargin}`;
};

const sideNavOpenPaddingMap = ({ size }: WrapperProps) => {
  const sideMargin = `calc(${sideNavOpenWidth} + ${spacing.s8})`;

  if (size === 'normal') {
    return desktopPaddingMap;
  }

  return `0 ${spacing.s6} ${spacing.s6} ${sideMargin}`;
};

export const PageWrapper = styled.main<WrapperProps>`
  position: relative;
  padding: 68px ${spacing.s4} ${spacing.s6};
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  height: 100%;

  @media ${device.largeTablet} {
    /* Review these padding utils */
    padding: ${({ sideNavOpen }) =>
      sideNavOpen ? sideNavOpenPaddingMap : desktopPaddingMap};
    transition: padding 0.2s ease-in;
  }
`;

export const PageContent = styled.div<WrapperProps>`
  margin: 0 auto;
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;

  /* sticky-footer can be used to create fixed/absolute components that are fluid with the navigation open/close  */
  #sticky-footer {
    left: 0;
    padding: ${spacing.none} ${spacing.s4};
  }

  @media ${device.largeTablet} {
    max-width: ${MAX_PAGE_WIDTH};

    #sticky-footer {
      /* Review this */
      padding: ${({ sideNavOpen }) =>
        sideNavOpen ? sideNavOpenPaddingMap : desktopPaddingMap};
      transition: padding 0.2s ease-in;
    }
  }
`;
