import type { PropsWithChildren } from 'react';
import { useEffect, useState } from 'react';
import {
  ErrorBoundary,
  SpinnerLoader,
  GlobalStyles as ToolkitGlobalStyles,
} from '@bt-healthcare/ui-toolkit';
import { useNavigate, useLocation } from 'react-router-dom';

import { GlobalStyles } from '../global.styles';
import { Navigation } from './Navigation';
import { PageContent, PageWrapper } from './styles';
import { Header } from './Header';
import { useAuthUserProfile } from 'auth/useAuthUserProfile';
import { useMenu } from 'hooks/useMenu';
import { useAppDispatch } from 'context/app/AppContext';
import { mapBackRoute } from 'config/routes.utils';
import { LeavePage } from 'components/Modal';

export const Layout = ({ children }: PropsWithChildren) => {
  const navigate = useNavigate();
  const { pathname, state: locationState } = useLocation();
  const dispatch = useAppDispatch();
  const { loading, careSetting, userId } = useAuthUserProfile();

  const { menuHeader } = useMenu();
  const [sideNavOpen, setSideNavOpen] = useState(false);

  useEffect(() => {
    dispatch({
      type: 'setCareSetting',
      careSetting,
    });
  }, [careSetting, dispatch]);

  useEffect(() => {
    if (userId) {
      dispatch({
        type: 'setUserId',
        userId,
      });
    }
  }, [userId, dispatch]);

  if (loading) {
    return <SpinnerLoader id="loader" data-testid="loader" />;
  }

  return (
    <>
      <ToolkitGlobalStyles />
      <Header sideNavOpen={sideNavOpen} />
      <ErrorBoundary>
        {/* Review usage of all these nested elements */}
        <PageWrapper sideNavOpen={sideNavOpen} size="wide" id="page-wrapper">
          <PageContent id="Content" size="wide" sideNavOpen={sideNavOpen}>
            <GlobalStyles />
            {children}
          </PageContent>
        </PageWrapper>
      </ErrorBoundary>
      <Navigation
        onCollapse={() => setSideNavOpen(!sideNavOpen)}
        sideNavOpen={sideNavOpen}
        menuHeader={menuHeader}
        onBackClick={mapBackRoute(
          pathname,
          navigate,
          (locationState as any)?.wardPatientId
        )}
      />
      <LeavePage />
    </>
  );
};
//
