import { colors, GRID_BREAKPOINT } from '@bt-healthcare/ui-toolkit';
import { Grid } from './style';
import type {
  PatientDetailsFormData,
  PatientDetailsFormFieldsProps,
} from './types';
import { FormFields } from 'components/Form/FormFields';
import { usePatient } from 'context/patient/PatientContext';

const colProps: Partial<
  Record<keyof PatientDetailsFormData, Record<string, any>>
> = {
  pronouns: {
    [GRID_BREAKPOINT.large]: '9 / 13',
  },
  address: {
    [GRID_BREAKPOINT.large]: '5 / 9',
  },
  postcode: {
    [GRID_BREAKPOINT.large]: '9 / 13',
  },
  email: {
    [GRID_BREAKPOINT.large]: '5 / 9',
  },
  phoneNumber: {
    [GRID_BREAKPOINT.large]: '9 / 13',
  },
  gpSurgery: {
    [GRID_BREAKPOINT.large]: '1 / 5',
  },
  comments: {
    [GRID_BREAKPOINT.large]: '5 / 9',
  },
};

const inputProps: Partial<
  Record<keyof PatientDetailsFormData, Record<string, any>>
> = {
  email: {
    label: 'Email',
    disabled: true,
  },
  genderAssignedAtBirth: {
    isDropdown: true,
  },
  genderIdentifiedAs: {
    optional: true,
  },
  pronouns: {
    optional: true,
  },
  nextOfKinFirstName: {
    optional: true,
  },
  nextOfKinSurname: {
    optional: true,
  },
  nextOfKinPhoneNumber: {
    optional: true,
  },
  comments: {
    optional: true,
    rows: 1,
    color: colors.primaryIndigo.indigo08,
  },
};

const fieldNames: (keyof PatientDetailsFormData)[] = [
  'firstName',
  'surname',
  'address',
  'comments',
  'dateOfBirth',
  'pronouns',
  'postcode',
  'email',
  'ethnicity',
  'genderAssignedAtBirth',
  'genderIdentifiedAs',
  'gpSurgery',
  'nextOfKinFirstName',
  'nextOfKinSurname',
  'nextOfKinPhoneNumber',
  'nhsNumber',
  'phoneNumber',
];

export const PatientDetailsFormFields = ({
  register,
  control,
  errors,
  clearErrors,
  generalPractices,
}: PatientDetailsFormFieldsProps) => {
  const { patientDetailsFormData } = usePatient();

  return (
    <Grid>
      <FormFields
        defaultValues={patientDetailsFormData}
        register={register}
        control={control}
        errors={errors}
        clearErrors={clearErrors}
        generalPractices={generalPractices ?? []}
        customColProps={colProps}
        customInputProps={inputProps}
        fieldNames={fieldNames}
      />
    </Grid>
  );
};
