import { Button } from '@bt-healthcare/ui-toolkit';

import { ButtonWrapper } from 'components/Modal/styles';

export type ModalButtonsProps = {
  handleClose: () => void;
  handleProceed: () => void;
};

export const ModalButtons = ({
  handleProceed,
  handleClose,
}: ModalButtonsProps) => (
  <ButtonWrapper>
    <Button
      id="start-admission-modal-proceed"
      onClick={handleProceed}
      variant="secondary"
      transparent
    >
      View patient list
    </Button>
    <Button
      id="start-admission-modal-cancel"
      onClick={handleClose}
      variant="primary"
    >
      Back to admissions
    </Button>
  </ButtonWrapper>
);
