import { colors, Text } from '@bt-healthcare/ui-toolkit';
import { NoFilterResultsWrapper } from './styles';

export const NoFilterResults = () => (
  <NoFilterResultsWrapper data-testid="no-filter-results">
    <Text
      color={colors.grey.grey08}
      data-testid="no-filter-results-summary-text"
    >
      Your search did not return any results.
    </Text>
    <Text
      color={colors.grey.grey08}
      as="div"
      data-testid="no-filter-results-suggestions-text"
      id="no-filter-results-suggestions-text"
    >
      Suggestions:
    </Text>
    <ul aria-labelledby="no-filter-results-suggestions-text">
      <li>Check for spelling mistakes</li>
      <li>Try different keywords</li>
    </ul>
  </NoFilterResultsWrapper>
);
