import { Wrapper, device, spacing } from '@bt-healthcare/ui-toolkit';
import styled from 'styled-components';

export const RoutePageWrapper = styled(Wrapper)`
  padding: ${spacing.s4} 0;

  @media ${device.largeTablet} {
    padding-top: ${spacing.none};
  }
`;
