import { device, spacing } from '@bt-healthcare/ui-toolkit';
import styled from 'styled-components';

export const VitalTrendsWrapper = styled.div`
  display: none;
  @media ${device.largeMobile} {
    display: block;
  }
`;

export const AvgBloodPressureWrapper = styled.div`
  margin-top: ${spacing.s6};
`;
