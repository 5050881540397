import { Outlet, useNavigate } from 'react-router-dom';

import { useEffect } from 'react';

import type { RBACRouteProps } from './types';
import { useHasAccess } from 'auth/useHasAccess';
import { useAuthUserProfile } from 'auth/useAuthUserProfile';

export const RBACRoute = ({ actions, redirectPath }: RBACRouteProps) => {
  const { userRoles } = useAuthUserProfile();
  const navigate = useNavigate();
  const hasAccess = useHasAccess(actions, userRoles);

  useEffect(() => {
    if (!hasAccess) {
      navigate(redirectPath);
    }
  }, [userRoles]);

  return <Outlet />;
};
