import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Col,
  ErrorModal,
  GRID_BREAKPOINT,
  Grid,
  Spacer,
  SpinnerLoader,
} from '@bt-healthcare/ui-toolkit';
import { createSearchParams, useNavigate } from 'react-router-dom';

import { useEffect, useState } from 'react';
import { StartAdmissionFormWrapper } from './styles';
import type { StartAdmissionFormData } from './types';
import {
  startAdmissionSchema,
  validateAllNotMatch,
  validatePartialMatch,
} from './validation';
import { StartAdmissionFormFields } from './StartAdmissionFormFields';
import { ROUTE } from 'config/routes';
import { useGetSearchPersonLazyQuery } from 'services/graphql';
import { PartialMatchModal } from 'components/Modal/StartAdmission/PartialMatch';
import {
  useAdmissionForm,
  useAdmissionFormDispatch,
} from 'context/admission/AdmissionContext';

import { FETCH_POLICY } from 'App.constants';
import { useAuthUserProfile } from 'auth/useAuthUserProfile';
import { Step } from 'pages/StartAdmission/types';
import { EmailAlreadyExistModal } from 'components/Modal/StartAdmission/EmailAlreadyExist';
import { DifferentCareSettingModal } from 'components/Modal/StartAdmission/DifferentCareSetting';
import { formatDOB } from 'utils/date.utils';
import { useAdmissionTracking } from 'hooks/useAdmissionTracking';
import { useLeavePageModal } from 'hooks/useLeavePageModal';

export const StartAdmission = () => {
  const navigate = useNavigate();
  const { data: userData } = useAuthUserProfile();
  const careSettingId = userData?.userProfile?.attributes.defaultCareSettingId;
  const { admission, matchedData } = useAdmissionForm();
  const admissionDispatch = useAdmissionFormDispatch();

  const { register, control, clearErrors, formState, handleSubmit } =
    useForm<StartAdmissionFormData>({
      mode: 'onChange',
      reValidateMode: 'onChange',
      resolver: yupResolver(startAdmissionSchema),
    });
  const { isValid, errors, isDirty } = formState;
  const [getSearchPerson, { data: searchPersonData, error, loading }] =
    useGetSearchPersonLazyQuery({
      fetchPolicy: FETCH_POLICY.NETWORK_ONLY,
      notifyOnNetworkStatusChange: true,
      errorPolicy: 'all',
    });
  const [isDifferentCareSetting, setIsDifferentCareSetting] = useState(false);
  const [isEmailAlreadyExist, setIsEmailAlreadyExist] = useState(false);
  const [isPartialMatch, setIsPartialMatch] = useState(false);
  const { trackAdmissionType } = useAdmissionTracking();
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const { formDirtied, setFormDirtied } = useLeavePageModal();

  const navigateToConfirmStep = () => {
    const { searchPerson } = searchPersonData || {};

    admissionDispatch({
      type: 'setAdmission',
      admission: {
        ...admission,
        firstName:
          searchPerson?.personalInformation.firstName ?? admission.firstName,
        surname: searchPerson?.personalInformation.surname ?? admission.surname,
        email:
          searchPerson?.contactInformation?.primaryEmailAddress ??
          admission.email,
      },
    });

    navigate({
      pathname: ROUTE.START_ADMISSION,
      search: createSearchParams({ step: Step.CONFIRM }).toString(),
    });
  };

  const isValidStandalonePatient = (
    isPartial: boolean,
    isStandalone: boolean
  ) => !isPartial && isStandalone;

  const isValidSameCareSetting = (personCareSettingId: string) =>
    personCareSettingId === careSettingId;

  const isValidDifferentCareSetting = (personCareSettingId: string) =>
    personCareSettingId !== careSettingId;

  const isValidSearchPersonData = () => searchPersonData && !error;

  const checkPatientCareSetting = (personCareSettingId: string) => {
    // S3: Patient managed within different care setting
    if (isValidDifferentCareSetting(personCareSettingId)) {
      trackAdmissionType('Managed within different care setting');
      setIsDifferentCareSetting(true);
      return;
    }

    // S4: Patient managed within same care setting
    if (isValidSameCareSetting(personCareSettingId)) {
      trackAdmissionType('Managed within same care setting');
      navigateToConfirmStep();
    }
  };

  useEffect(() => {
    if (isDirty) {
      if (!formDirtied) {
        setFormDirtied();
      }
    }
  }, [isDirty, formDirtied]);

  useEffect(() => {
    if (error) {
      setErrorModalOpen(true);
    }
  }, [error]);

  useEffect(() => {
    if (isValidSearchPersonData()) {
      const { searchPerson } = searchPersonData!;

      // S5: Patient does not have an existing account
      if (searchPerson === null) {
        navigate({
          pathname: ROUTE.ADMISSION,
        });
        return;
      }

      admissionDispatch({
        type: 'setPerson',
        person: searchPerson,
      });
      admissionDispatch({
        type: 'setIsStandalone',
        isStandalone: searchPerson?.isStandalonePatient,
      });

      const result = validatePartialMatch(
        admission,
        searchPerson?.personalInformation
      );

      admissionDispatch({
        type: 'setMatchedData',
        matchedData: result.matches,
      });

      // S1: Patient matches existing Standlone account
      if (
        isValidStandalonePatient(
          result.isPartial,
          searchPerson?.isStandalonePatient
        )
      ) {
        navigateToConfirmStep();
        return;
      }

      if (
        isValidStandalonePatient(
          result.isPartial,
          !searchPerson?.isStandalonePatient
        )
      ) {
        checkPatientCareSetting(searchPerson?.careSettingId!);
        return;
      }

      // S6: Patient does not match existing account - email matches
      if (validateAllNotMatch(result.matches)) {
        trackAdmissionType('Does not match existing account');
        setIsEmailAlreadyExist(true);
        return;
      }

      // S2: Patient partial matches existing Standalone account
      setIsPartialMatch(result.isPartial);
      trackAdmissionType('Partial match');
    }
  }, [searchPersonData, admission, error]);

  const navigateBackHome = () => {
    navigate({
      pathname: ROUTE.HOME,
    });
  };

  const onContinue = (data: StartAdmissionFormData) => {
    admissionDispatch({
      type: 'setAdmission',
      admission: {
        ...data,
        dateOfBirth: formatDOB(data),
      },
    });
    getSearchPerson({
      variables: { input: { primaryEmailAddress: data?.email } },
    });
  };

  return (
    <>
      {loading && (
        <SpinnerLoader
          id="loader"
          data-testid="loader"
          asModal
          text="Checking details"
        />
      )}

      <StartAdmissionFormWrapper data-testid="start-admission-wrapper">
        <StartAdmissionFormFields
          register={register}
          control={control}
          errors={errors}
          clearErrors={clearErrors}
        />
        <Spacer size="s10" />
        <Grid>
          <Col
            col={{
              [GRID_BREAKPOINT.small]: '1 / 14',
              [GRID_BREAKPOINT.large]: '1 / 6',
            }}
          >
            <Button
              id="start-admission-continue-btn"
              onClick={handleSubmit(onContinue)}
              disabled={!isValid}
            >
              Continue
            </Button>
          </Col>
        </Grid>
        <PartialMatchModal
          isModalOpen={!loading && isPartialMatch}
          handleClose={() => setIsPartialMatch(false)}
          handleProceed={navigateBackHome}
          matches={matchedData}
        />
        <EmailAlreadyExistModal
          isModalOpen={!loading && isEmailAlreadyExist}
          handleClose={() => setIsEmailAlreadyExist(false)}
          handleProceed={navigateBackHome}
        />
        <DifferentCareSettingModal
          isModalOpen={!loading && isDifferentCareSetting}
          handleClose={() => setIsDifferentCareSetting(false)}
          handleProceed={navigateBackHome}
        />
        <ErrorModal
          isModalOpen={!loading && errorModalOpen}
          secondaryButtonText="Cancel"
          onSecondaryAction={() => {
            setErrorModalOpen(false);
          }}
          onPrimaryAction={() => {
            setErrorModalOpen(false);
            getSearchPerson({
              variables: { input: { primaryEmailAddress: admission?.email } },
            });
          }}
          primaryButtonText="Try again"
          title="We could not retrieve patient info"
        >
          Patient info could not be retrieved. Please review the information you
          entered and try again. If the problem persists, please contact us for
          assistance.
        </ErrorModal>
      </StartAdmissionFormWrapper>
    </>
  );
};
