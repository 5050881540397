import type {
  BTHealthIconProp,
  NotificationType,
} from '@bt-healthcare/ui-toolkit';
import {
  BTHealthIcon,
  Notification,
  Text,
  colors,
} from '@bt-healthcare/ui-toolkit';
import type { RecommendationAcceptanceNotificationProps } from '../types';
import { IconWrapper } from './styles';
import { GuidelineMap } from 'mappings/enums';
import { YesNoType } from 'services/graphql';
import { BoldText } from 'components/BoldText';

const RecommendationMap = {
  [YesNoType.Yes]: {
    notificationType: 'positive',
    icon: 'Check',
    iconColor: colors.rag.light.green,
    text: 'accepted',
  },
  [YesNoType.No]: {
    notificationType: 'disabled',
    icon: 'Disabled',
    iconColor: colors.grey.grey10,
    text: 'declined',
  },
};

export const RecommendationAcceptanceNotification = ({
  guidelineAccepted,
  guidelineType,
}: RecommendationAcceptanceNotificationProps) => {
  const { notificationType, icon, iconColor, text } =
    RecommendationMap[guidelineAccepted!];
  return (
    <Notification
      icon="Bright"
      iconColor={iconColor}
      type={notificationType as NotificationType}
      data-testid="recommendation-acceptance-notification"
    >
      <Text color={colors.grey.grey10}>
        <BoldText>Recommendation {text}:</BoldText>{' '}
        {GuidelineMap[guidelineType]}
      </Text>
      <IconWrapper>
        <BTHealthIcon
          icon={icon as BTHealthIconProp}
          title={`Recommendation ${text}`}
          color={iconColor}
          size={24}
        />
      </IconWrapper>
    </Notification>
  );
};
