import { Wrapper, device, spacing } from '@bt-healthcare/ui-toolkit';
import styled from 'styled-components';

export const AccordionTitleWrapper = styled(Wrapper)`
  display: flex;
  flex-direction: column;
  gap: ${spacing.s1};
  padding: ${spacing.s2};
  width: 100%;

  @media ${device.largeTablet} {
    gap: ${spacing.s2};
    padding: ${spacing.s4};
  }
`;
