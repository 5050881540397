import { colors, spacing, fontSizes } from '@bt-healthcare/ui-toolkit';
import styled, { css } from 'styled-components';

import type { MarkerType } from './types';

export const ProgressStepWrapper = styled.div<{ width: number }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: ${(props) => props.width}%;
`;

const markerVariant = (stepType = 'current') =>
  ({
    previous: css`
      width: 6px;
      height: 6px;
      border-radius: 3px;
      margin-top: 3px;
      background-color: ${colors.secondaryBlue.blue04};
    `,
    current: css`
      width: 10px;
      height: 10px;
      border-radius: 5px;
      margin-top: 1px;
      background-color: ${colors.secondaryBlue.blue02};
    `,
    next: css`
      width: 6px;
      height: 6px;
      border-radius: 3px;
      margin-top: 3px;
      background-color: ${colors.grey.grey08};
    `,
  }[stepType]);

type Props = { stepType: MarkerType };

export const ProgressStepMarker = styled.div<Props>`
  display: flex;
  ${(props) => markerVariant(props.stepType)}
`;

export const ProgressStepText = styled.div<Props>`
  display: flex;
  position: absolute;
  font-size: ${fontSizes.sm};
  color: ${(props) =>
    props.stepType === 'current' ? colors.grey.grey10 : colors.grey.grey08};
  margin-top: ${spacing.s6};
`;
