import { spacing } from '@bt-healthcare/ui-toolkit';
import styled from 'styled-components';

export const Wrapper = styled.section<{ isLast: boolean }>`
  display: flex;
  flex-direction: column;
  margin: ${spacing.s8} 0 ${(props) => (props.isLast ? 0 : spacing.s6)} 0;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

export const ReadingTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;

  & :first-child {
    margin-right: ${spacing.s1};
  }
`;
