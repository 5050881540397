import type { ErrorCode } from 'services/graphql';

export const getPatientDetailsFormErrorConfig = (errorType?: ErrorCode) => {
  if (!errorType) return undefined;
  return {
    errorTitle: "We couldn't update the patient",
    errorMessage:
      'The patient could not be updated due to an error. Please review the information you entered and try again.',
  };
};
