/* eslint-disable no-console */
/**
 *
 * @param str
 * input: 'WARD_PATIENT'
 * output: 'Ward patient'
 */
export const prettifyEnum = (str: string): string =>
  str.charAt(0).toUpperCase() +
  str.slice(1).toLowerCase().replace(/([_])/g, ' ');

/**
 *
 * @param value
 * @param maxChars
 * input: value: 'sometext', maxChars: 1000
 * output: '992 / 1000'
 */
export const characterCount = (value: string, maxChars: number): string =>
  value ? `${maxChars - value.length} / ${maxChars}` : '';
