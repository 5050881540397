import { BTHealthIcon, colors } from '@bt-healthcare/ui-toolkit';
import { useState } from 'react';
import { BackNavItem, MenuHeader } from './styles';
import { BurgerMenu } from './BurgerMenu';
import type { MobileContentProps } from './types';

export const MobileContent = ({
  onBackClick,
  menuHeader,
  activeRoute,
}: MobileContentProps) => {
  const [navPanelOpen, setNavPanelOpen] = useState(false);

  return (
    <>
      {onBackClick && (
        <BackNavItem
          onClick={onBackClick}
          tabIndex={0}
          role="button"
          navPanelOpen={navPanelOpen}
        >
          <BTHealthIcon
            icon="BackArrow"
            size={14}
            color={colors.grey.grey08}
            title="Go back"
          />
        </BackNavItem>
      )}

      {menuHeader && <MenuHeader>{menuHeader}</MenuHeader>}

      <BurgerMenu
        navPanelOpen={navPanelOpen}
        setNavPanelOpen={setNavPanelOpen}
        activeRoute={activeRoute}
      />
    </>
  );
};
