import { Fragment } from 'react';
import type { SkeletonWrapperProps } from './types';

export const SkeletonWrapper = ({
  children,
  count = 1,
}: SkeletonWrapperProps) => (
  <>
    {Array.from({ length: count }, (_, index) => (
      <Fragment key={index}>{children}</Fragment>
    ))}
  </>
);
