import {
  Col,
  DateInput,
  GRID_BREAKPOINT,
  Input,
  InputMessage,
  MultipleChoiceButton,
  TextArea,
} from '@bt-healthcare/ui-toolkit';
import { useRef } from 'react';
import type { ChangeEvent } from 'react';
import type { FieldErrors, FieldValues, Path } from 'react-hook-form';
import type { FormFieldsProps } from './types';
import { MonitoredConditionFormCard } from './MonitoredConditionFormCard';
import { RHFDropDown } from 'components/Form/RHFDropDown';
import {
  EthnicityOptions,
  GenderAssignedAtBirthOptions,
  GenderIdentifiedAsOptions,
  MobilityOptions,
  MonitoredConditionOptions,
  PronounsOptions,
} from 'mappings/enums';

const validationMessage = <T extends FieldValues>(
  errors: FieldErrors<T>,
  name: keyof T
) => {
  const error = errors[name];
  return {
    errorText: Array.isArray(error)
      ? error[0].message
      : error?.message?.toString(),
  };
};

const extractProps = (props?: Record<string, any>) => {
  if (props) return props;
  return {};
};

export const FormFields = <T extends FieldValues>({
  defaultValues,
  register,
  control,
  errors,
  clearErrors,
  generalPractices = [],
  customColProps,
  customInputProps,
  fieldNames = [],
  watch,
}: FormFieldsProps<T>) => {
  const dateInputRef = useRef<any>();
  const { ref: dayRef, ...dayRest } = register('dayOfBirth' as Path<T>);
  const { ref: monthRef, ...monthRest } = register('monthOfBirth' as Path<T>);
  const { ref: yearRef, ...yearRest } = register('yearOfBirth' as Path<T>);

  dateInputRef.current = { dayRef, monthRef, yearRef };
  const inputParams = {
    dayInput: {
      id: 'dayOfBirth',
      'data-testid': 'day-of-birth',
      'aria-label': 'Day of birth',
      defaultValue: defaultValues?.dayOfBirth,
      ...dayRest,
      ...validationMessage(errors, 'dayOfBirth'),
      onChange: (e: ChangeEvent) => {
        clearErrors?.('dateOfBirth' as Path<T>);
        dayRest.onChange(e);
      },
    },
    monthInput: {
      id: 'monthOfBirth',
      'data-testid': 'month-of-birth',
      'aria-label': 'Month of birth',
      defaultValue: defaultValues?.monthOfBirth,
      ...monthRest,
      ...validationMessage(errors, 'monthOfBirth'),
      onChange: (e: ChangeEvent) => {
        clearErrors?.('dateOfBirth' as Path<T>);
        monthRest.onChange(e);
      },
    },
    yearInput: {
      id: 'yearOfBirth',
      'data-testid': 'year-of-birth',
      'aria-label': 'year of birth',
      defaultValue: defaultValues?.yearOfBirth,
      ...yearRest,
      ...validationMessage(errors, 'yearOfBirth'),
      onChange: (e: ChangeEvent) => {
        clearErrors?.('dateOfBirth' as Path<T>);
        yearRest.onChange(e);
      },
    },
  };

  return (
    <>
      {fieldNames.includes('firstName') && (
        <Col
          col={{
            [GRID_BREAKPOINT.small]: '1 / 9',
            [GRID_BREAKPOINT.large]: '1 / 5',
            ...extractProps(customColProps?.firstName),
          }}
        >
          <Input
            id="firstName"
            label="Patient first name"
            maxLength={50}
            {...extractProps(customInputProps?.firstName)}
            {...register('firstName' as Path<T>)}
            {...validationMessage(errors, 'firstName')}
          />
        </Col>
      )}
      {fieldNames.includes('surname') && (
        <Col
          col={{
            [GRID_BREAKPOINT.small]: '1 / 9',
            [GRID_BREAKPOINT.large]: '5 / 9',
            ...extractProps(customColProps?.surname),
          }}
        >
          <Input
            id="surname"
            label="Patient surname"
            maxLength={50}
            {...extractProps(customInputProps?.surname)}
            {...register('surname' as Path<T>)}
            {...validationMessage(errors, 'surname')}
          />
        </Col>
      )}
      {fieldNames.includes('dateOfBirth') && (
        <Col
          col={{
            [GRID_BREAKPOINT.small]: '1 / 9',
            [GRID_BREAKPOINT.large]: '9 / 13',
            ...extractProps(customColProps?.dateOfBirth),
          }}
        >
          <DateInput
            inputParams={inputParams}
            ref={dateInputRef}
            id="dateOfBirth"
            label="Date of birth"
            {...extractProps(customInputProps?.dateOfBirth)}
          />
          {errors.dateOfBirth && (
            <InputMessage
              id="error-dateOfBirth"
              hasError
              errorText={errors.dateOfBirth?.message as string}
            />
          )}
        </Col>
      )}
      {fieldNames.includes('genderAssignedAtBirth') && (
        <Col
          col={{
            [GRID_BREAKPOINT.small]: '1 / 9',
            [GRID_BREAKPOINT.large]: '1 / 5',
            ...extractProps(customColProps?.genderAssignedAtBirth),
          }}
        >
          {customInputProps?.genderAssignedAtBirth?.isDropdown && (
            <RHFDropDown
              label="Gender assigned at birth"
              fieldName={'genderAssignedAtBirth' as Path<T>}
              control={control}
              options={GenderAssignedAtBirthOptions}
              {...extractProps(customInputProps?.genderAssignedAtBirth)}
              {...validationMessage(errors, 'genderAssignedAtBirth')}
            />
          )}
          {!customInputProps?.genderAssignedAtBirth?.isDropdown && (
            <MultipleChoiceButton
              size="lg"
              label="Gender assigned at birth"
              options={GenderAssignedAtBirthOptions}
              {...extractProps(customInputProps?.genderAssignedAtBirth)}
              {...register('genderAssignedAtBirth' as Path<T>)}
              {...validationMessage(errors, 'genderAssignedAtBirth')}
            />
          )}
        </Col>
      )}
      {fieldNames.includes('genderIdentifiedAs') && (
        <Col
          col={{
            [GRID_BREAKPOINT.small]: '1 / 9',
            [GRID_BREAKPOINT.large]: '5 / 9',
            ...extractProps(customColProps?.genderIdentifiedAs),
          }}
        >
          <RHFDropDown
            label="Gender identifies as"
            fieldName={'genderIdentifiedAs' as Path<T>}
            control={control}
            options={GenderIdentifiedAsOptions}
            optional
            {...extractProps(customInputProps?.genderIdentifiedAs)}
          />
        </Col>
      )}
      {fieldNames.includes('pronouns') && (
        <Col
          col={{
            [GRID_BREAKPOINT.small]: '1 / 9',
            [GRID_BREAKPOINT.large]: '5 / 9',
            ...extractProps(customColProps?.pronouns),
          }}
        >
          <RHFDropDown
            label="Pronouns"
            fieldName={'pronouns' as Path<T>}
            control={control}
            options={PronounsOptions}
            optional
            {...extractProps(customInputProps?.pronouns)}
          />
        </Col>
      )}
      {fieldNames.includes('ethnicity') && (
        <Col
          col={{
            [GRID_BREAKPOINT.small]: '1 / 9',
            [GRID_BREAKPOINT.large]: '1 / 5',
            ...extractProps(customColProps?.ethnicity),
          }}
        >
          <RHFDropDown
            label="Ethnicity"
            fieldName={'ethnicity' as Path<T>}
            control={control}
            options={EthnicityOptions}
            {...extractProps(customInputProps?.ethnicity)}
            {...validationMessage(errors, 'ethnicity')}
          />
        </Col>
      )}
      {fieldNames.includes('address') && (
        <Col
          col={{
            [GRID_BREAKPOINT.small]: '1 / 9',
            [GRID_BREAKPOINT.large]: '1 / 5',
            ...extractProps(customColProps?.address),
          }}
        >
          <Input
            id="address"
            label="First line of address"
            {...extractProps(customInputProps?.address)}
            {...register('address' as Path<T>)}
            {...validationMessage(errors, 'address')}
          />
        </Col>
      )}
      {fieldNames.includes('postcode') && (
        <Col
          col={{
            [GRID_BREAKPOINT.small]: '1 / 9',
            [GRID_BREAKPOINT.large]: '5 / 9',
            ...extractProps(customColProps?.postcode),
          }}
        >
          <Input
            id="postcode"
            label="Postcode"
            maxLength={10}
            {...extractProps(customInputProps?.postcode)}
            {...register('postcode' as Path<T>)}
            {...validationMessage(errors, 'postcode')}
            style={{ textTransform: 'uppercase' }}
          />
        </Col>
      )}
      {fieldNames.includes('nhsNumber') && (
        <Col
          col={{
            [GRID_BREAKPOINT.small]: '1 / 9',
            [GRID_BREAKPOINT.large]: '1 / 5',
            ...extractProps(customColProps?.nhsNumber),
          }}
        >
          <Input
            id="nhsNumber"
            label="NHS no"
            maxLength={12}
            {...extractProps(customInputProps?.nhsNumber)}
            {...register('nhsNumber' as Path<T>)}
            {...validationMessage(errors, 'nhsNumber')}
          />
        </Col>
      )}
      {fieldNames.includes('email') && (
        <Col
          col={{
            [GRID_BREAKPOINT.small]: '1 / 9',
            [GRID_BREAKPOINT.large]: '1 / 5',
            ...extractProps(customColProps?.email),
          }}
        >
          <Input
            id="email"
            label="Email address"
            data-testid="email-input"
            {...extractProps(customInputProps?.email)}
            {...register('email' as Path<T>)}
            {...validationMessage(errors, 'email')}
          />
        </Col>
      )}
      {fieldNames.includes('phoneNumber') && (
        <Col
          col={{
            [GRID_BREAKPOINT.small]: '1 / 9',
            [GRID_BREAKPOINT.large]: '5 / 9',
            ...extractProps(customColProps?.phoneNumber),
          }}
        >
          <Input
            id="phoneNumber"
            label="Mobile number"
            {...extractProps(customInputProps?.phoneNumber)}
            {...register('phoneNumber' as Path<T>)}
            {...validationMessage(errors, 'phoneNumber')}
          />
        </Col>
      )}
      {fieldNames.includes('mobility') && (
        <Col
          col={{
            [GRID_BREAKPOINT.small]: '1 / 9',
            [GRID_BREAKPOINT.large]: '1 / 5',
            ...extractProps(customColProps?.mobility),
          }}
        >
          <RHFDropDown
            label="Mobility"
            fieldName={'mobility' as Path<T>}
            control={control}
            options={MobilityOptions}
            {...extractProps(customInputProps?.mobility)}
            {...validationMessage(errors, 'mobility')}
          />
        </Col>
      )}
      {fieldNames.includes('nextOfKinFirstName') && (
        <Col
          col={{
            [GRID_BREAKPOINT.small]: '1 / 9',
            [GRID_BREAKPOINT.large]: '1 / 5',
            ...extractProps(customColProps?.nextOfKinFirstName),
          }}
        >
          <Input
            id="nextOfKinFirstName"
            label="Next of kin first name"
            maxLength={50}
            {...extractProps(customInputProps?.nextOfKinFirstName)}
            {...register('nextOfKinFirstName' as Path<T>)}
            {...validationMessage(errors, 'nextOfKinFirstName')}
          />
        </Col>
      )}
      {fieldNames.includes('nextOfKinSurname') && (
        <Col
          col={{
            [GRID_BREAKPOINT.small]: '1 / 9',
            [GRID_BREAKPOINT.large]: '5 / 9',
            ...extractProps(customColProps?.nextOfKinSurname),
          }}
        >
          <Input
            id="nextOfKinSurname"
            label="Next of kin surname"
            maxLength={50}
            {...extractProps(customInputProps?.nextOfKinSurname)}
            {...register('nextOfKinSurname' as Path<T>)}
            {...validationMessage(errors, 'nextOfKinSurname')}
          />
        </Col>
      )}
      {fieldNames.includes('nextOfKinPhoneNumber') && (
        <Col
          col={{
            [GRID_BREAKPOINT.small]: '1 / 9',
            [GRID_BREAKPOINT.large]: '9 / 13',
            ...extractProps(customColProps?.nextOfKinPhoneNumber),
          }}
        >
          <Input
            id="nextOfKinPhoneNumber"
            label="Next of kin number"
            {...extractProps(customInputProps?.nextOfKinPhoneNumber)}
            {...register('nextOfKinPhoneNumber' as Path<T>)}
            {...validationMessage(errors, 'nextOfKinPhoneNumber')}
          />
        </Col>
      )}
      {fieldNames.includes('gpSurgery') && (
        <Col
          col={{
            [GRID_BREAKPOINT.small]: '1 / 9',
            [GRID_BREAKPOINT.large]: '1 / 9',
            ...extractProps(customColProps?.gpSurgery),
          }}
        >
          <RHFDropDown
            label="Medical Centre"
            fieldName={'gpSurgery' as Path<T>}
            control={control}
            options={
              generalPractices?.length > 0
                ? [...generalPractices].sort((a, b) => a.localeCompare(b))
                : []
            }
            {...extractProps(customInputProps?.gpSurgery)}
            {...validationMessage(errors, 'gpSurgery')}
          />
        </Col>
      )}
      {fieldNames.includes('monitoredConditions') && (
        <Col
          col={{
            [GRID_BREAKPOINT.small]: '1 / 9',
            [GRID_BREAKPOINT.large]: '1 / 9',
            ...extractProps(customColProps?.monitoredConditions),
          }}
        >
          <RHFDropDown
            label="Condition(s)"
            fieldName={'monitoredConditions' as Path<T>}
            control={control}
            options={MonitoredConditionOptions}
            {...extractProps(customInputProps?.monitoredConditions)}
            {...validationMessage(errors, 'monitoredConditions')}
          />
          {watch && <MonitoredConditionFormCard watch={watch} />}
        </Col>
      )}
      {fieldNames.includes('comments') && (
        <Col
          col={{
            [GRID_BREAKPOINT.small]: '1 / 9',
            [GRID_BREAKPOINT.large]: '1 / 9',
            ...extractProps(customColProps?.comments),
          }}
        >
          <TextArea
            id="comments"
            label="Comments"
            maxLength={1000}
            {...extractProps(customInputProps?.comments)}
            {...register('comments' as Path<T>)}
          />
        </Col>
      )}
    </>
  );
};
