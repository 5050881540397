import type { TypedOptionType } from '@bt-healthcare/ui-toolkit';
import {
  Button,
  Wrapper,
  DropDown,
  TextArea,
  Spacer,
  colors,
  BTHealthIcon,
} from '@bt-healthcare/ui-toolkit';
import { formatISO } from 'date-fns';
import { yupResolver } from '@hookform/resolvers/yup';
import type { FieldError } from 'react-hook-form';
import { useForm, Controller } from 'react-hook-form';
import { useEffect } from 'react';

import { schema } from '../validationSchema';
import type { AddNoteProps, NotesFormData } from '../Notes.types';
import { NoteLandmarkWrapper } from '../Notes.styles';
import { characterCount } from './AddNote.utils';
import { NoteWrapper, NoteMessageWrapper, Notification } from './styles';
import { CommentTypeMap } from 'mappings/enums';
import {
  CommentTypes,
  useAddWardPatientCommentMutation,
  GetWardPatientCommentsDocument,
} from 'services/graphql';
import { usePatientDispatch } from 'context/patient/PatientContext';

const MIN_CHARS = 1;
const MAX_CHARS = 1000;

export const noteCategories = Object.values(CommentTypes)
  .map((val) => ({
    value: val,
    label: CommentTypeMap[val],
  }))
  .sort((curr, prev) => curr.label.localeCompare(prev.label));

export const AddNote = ({ wardPatientId }: AddNoteProps) => {
  const patientDispatch = usePatientDispatch();
  const [
    addWardPatientCommentMutation,
    { data: addData, loading: addLoading, error: addError },
  ] = useAddWardPatientCommentMutation();

  const onSubmit = (data: NotesFormData) => {
    if (!data.category) return;
    addWardPatientCommentMutation({
      variables: {
        input: {
          wardPatientId,
          recordedDateTime: formatISO(new Date()),
          comment: data.notevalue,
          commentType: data.category.value,
        },
      },
      refetchQueries: [
        {
          query: GetWardPatientCommentsDocument,
          variables: {
            wardPatientId,
          },
        },
      ],
    });
  };

  const {
    control,
    handleSubmit,
    register,
    watch,
    reset,
    formState: { errors, isDirty, isValid },
  } = useForm<NotesFormData>({
    mode: 'onChange',
    resolver: yupResolver(schema(MIN_CHARS, MAX_CHARS)),
    defaultValues: {
      category: null,
      notevalue: '',
    },
  });

  useEffect(() => {
    patientDispatch({
      type: 'setNoteIsDirty',
      noteIsDirty: isDirty,
    });
  }, [isDirty]);

  useEffect(() => {
    if (addData) {
      reset({
        category: null,
        notevalue: '',
      });
    }
  }, [addData]);

  const noteFieldValue = watch('notevalue');

  const noteFieldErrorText = (e: FieldError) => {
    const isMaxError = e.type === 'max';
    return isMaxError ? characterCount(noteFieldValue, MAX_CHARS) : e.message;
  };

  return (
    <NoteLandmarkWrapper id="add-note" data-testid="add-note">
      <Controller
        name="category"
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <DropDown
            id="add-note-category"
            name="category"
            label="Category"
            options={noteCategories}
            value={value as TypedOptionType<CommentTypes>}
            onBlur={onBlur}
            onChange={onChange}
            errorText={errors.category?.message?.toString()}
            data-testid="test"
          />
        )}
      />
      <Spacer size="s8" />

      <NoteWrapper>
        <TextArea
          id="add-note-notevalue"
          label="Add note"
          helperText={characterCount(noteFieldValue, MAX_CHARS)}
          rows={7}
          errorText={
            errors.notevalue?.message && noteFieldErrorText(errors?.notevalue)
          }
          {...register('notevalue')}
        />
      </NoteWrapper>

      <Button
        id="add-note-button"
        variant="primary"
        type="button"
        onClick={handleSubmit(onSubmit)}
        disabled={!isDirty || !isValid}
      >
        Submit
      </Button>
      <NoteMessageWrapper>
        {addLoading && <div data-testid="loading">sending note...</div>}
        {addError && (
          <Notification data-testid="notification">
            <BTHealthIcon
              icon="Warning"
              color={colors.notifications.light.errorDark}
              size={12}
            />
            Failed to submit, please try again.
          </Notification>
        )}
        {addData && <Wrapper>Note was successfully added</Wrapper>}
      </NoteMessageWrapper>
    </NoteLandmarkWrapper>
  );
};
