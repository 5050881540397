import {
  Button,
  Card,
  Checkbox,
  Portal,
  colors,
  Modal,
  ModalBody,
  ModalFooter,
} from '@bt-healthcare/ui-toolkit';
import { useState } from 'react';
import type { MonitoringStatusProps } from '../types';
import { Header, Paragraph } from '../styles';

export const MonitoringStatus = ({
  isModalOpen,
  handleClose,
  handleConfirm,
  id,
  title,
  text,
  checkboxColor = colors.secondaryBlue.blue01,
  checkboxText,
}: MonitoringStatusProps) => {
  const [checked, setChecked] = useState(false);

  const resetModal = () => setChecked(false);

  return (
    <Portal>
      <Modal onClose={handleClose} isModalOpen={isModalOpen} id={`${id}-modal`}>
        <ModalBody>
          <Header>{title}</Header>
          <Paragraph>{text}</Paragraph>
          <Card id="checkbox-card" background={checkboxColor}>
            <Checkbox
              id="confirm-checkbox"
              onChange={({ target }) => setChecked(target.checked)}
              label={checkboxText}
              position="leftTop"
            />
          </Card>
        </ModalBody>
        <ModalFooter flex>
          <Button
            id="cancel"
            onClick={() => {
              resetModal();
              handleClose();
            }}
            variant="secondary"
          >
            Cancel
          </Button>
          <Button
            id="confirm"
            onClick={() => {
              resetModal();
              handleConfirm();
            }}
            variant="primary"
            disabled={!checked}
          >
            Confirm
          </Button>
        </ModalFooter>
      </Modal>
    </Portal>
  );
};
