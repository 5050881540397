import {
  Divider,
  ErrorNotification,
  SpinnerLoader,
  Wrapper,
} from '@bt-healthcare/ui-toolkit';
import { useForm } from 'react-hook-form';
import type { ChangeEvent } from 'react';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  HypertensionAssessmentHeaderWrapper,
  BackButtonWrapper,
  HypertensionOverlayWrapper,
  HypertensionAssessmentContentWrapper,
  HypertensionAssessmentContentLayout,
  HypertensionAssessmentCarePlanWrapper,
  SubHeader,
} from './styles';
import {
  getCurrentHypertensionStatus,
  handleNavigateBack,
  setPreviousConsultationRecommendedValues,
} from './utils';
import type { HypertensionAssessmentFormData } from 'components/HypertensionAssessmentForm/types';
import { DEFAULT_DATE_FILTER, FETCH_POLICY } from 'App.constants';
import { useTracking } from 'hooks/useTracking';
import type {
  MonitoredConditionConsultationStatusType,
  MonitoredConditionTestStatusType,
  MonitoringStatusType,
  WardPatient,
} from 'services/graphql';
import {
  AssessmentType,
  YesNoType,
  useGetWardPatientQuery,
} from 'services/graphql';
import { useApp } from 'context/app/AppContext';
import { useRedirectAndReturnState } from 'hooks/useRedirectAndReturnState';
import {
  buildPatientHeaderUI,
  buildWardPatientUI,
} from 'models/wardPatient/wardPatient.view';
import { PatientAssessmentHeader } from 'components/PatientHeader/PatientAssessmentHeader';
import { HypertensionAssessmentHealthVitals } from 'components/HypertensionAssessmentHealthVitals';
import { HypertensionAssessmentReviewForm } from 'components/HypertensionAssessmentForm/HypertensionAssessmentReviewForm';
import { HypertensionAssessmentMedicalForm } from 'components/HypertensionAssessmentForm/HypertensionAssessmentMedicalForm';
import { FormFooter } from 'components/FormFooter';
import { BackButton } from 'components/BackButton';
import { ROUTE } from 'config/routes';
import { CancelAssessment, ResetRecommendation } from 'components/Modal';
import { hypertensionAssessmentSchema } from 'components/HypertensionAssessmentForm/validationSchema';
import { PageName } from 'config/pageNames';
import { HypertensionAssessmentMedicationOutcomeForm } from 'components/HypertensionAssessmentForm/HypertensionAssessmentMedicationOutcomeForm';
import { useHypertensionRecommendation } from 'hooks/useHypertensionRecommendation';
import { AssessmentTypeSelector } from 'components/HypertensionAssessmentForm/AssessmentTypeSelector';
import { usePreviousHypertensionConsultationRecommendation } from 'hooks/usePreviousHypertensionConsultationRecommendation';
import { HypertensionAssessmentFormHeader } from 'components/HypertensionAssessmentForm/HypertensionAssessmentFormHeader';
import { useDebounceFormValue } from 'hooks/useDebounceFormValue';
import { isEmptyOrNil } from 'utils/object.utils';
import { useAssessmentTracking } from 'hooks/useAssessmentTracking';
import { usePatient, usePatientDispatch } from 'context/patient/PatientContext';
import { useLeavePageModal } from 'hooks/useLeavePageModal';

export const HypertensionAssessment = () => {
  const wardPatientId = useRedirectAndReturnState('wardPatientId');
  const patient = useRedirectAndReturnState('patient');
  const isNavigateBack = useRedirectAndReturnState('isNavigateBack', false);
  const navigate = useNavigate();
  const patientDispatch = usePatientDispatch();
  const hypertensionCarePlanRef = useRef<HTMLDivElement>(null);
  const { careSetting } = useApp();

  const { hypertensionAssessmentFormData, guidelineAcceptance } = usePatient();
  const { trackPage } = useTracking();
  const { trackTappedAssessment } = useAssessmentTracking();

  const { formDirtied, setFormDirtied, checkCanLeavePage } =
    useLeavePageModal();

  useEffect(() => {
    trackPage(PageName.ASSESSMENT_HYPERTENSION);
    patientDispatch({
      type: 'setChartDateFilter',
      synchedDateFilter: DEFAULT_DATE_FILTER,
    });
    patientDispatch({ type: 'resetChartDateRange' });
  }, []);

  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [resetRecommendationModalOpen, setResetRecommendationModalOpen] =
    useState(false);

  const [selfMonitoringAccepted, setSelfMonitoringAccepted] = useState(false);

  /** Debounced fields */
  const [totalDays, setTotalDays] = useState(0);
  const [totalReadings, setTotalReadings] = useState(0);
  const [averageDiastolic, setAverageDiastolic] = useState(0);
  const [averageSystolic, setAverageSystolic] = useState(0);

  const [everTakenMedicationDisabled, setEverTakenMedicationDisabled] =
    useState(false);
  const [hasChanged, setHasChanged] = useState(false);
  const recommendationTriggeredRef = useRef(false);

  const { data, loading, error, refetch } = useGetWardPatientQuery({
    variables: { wardPatientId, careSettingId: careSetting.id ?? null },
    fetchPolicy: FETCH_POLICY.CACHE_AND_NETWORK,
    notifyOnNetworkStatusChange: true,
  });
  const {
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    resetField,
    getValues,
    formState,
  } = useForm<HypertensionAssessmentFormData>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      guidelineRecommendationDecisions:
        hypertensionAssessmentFormData.guidelineRecommendationDecisions,
      averageDiastolic: hypertensionAssessmentFormData.averageDiastolic,
      averageSystolic: hypertensionAssessmentFormData.averageSystolic,
      totalDays: hypertensionAssessmentFormData.totalDays,
      totalReadings: hypertensionAssessmentFormData.totalReadings,
    },
    resolver: yupResolver(hypertensionAssessmentSchema),
  });

  const { errors, isValid, isDirty } = formState;
  const { assessmentType } = getValues();

  useEffect(() => {
    if (isDirty) {
      if (!formDirtied) {
        setFormDirtied();
      }
    }
  }, [isDirty, formDirtied]);

  const totalDaysDebouncing = useDebounceFormValue<
    HypertensionAssessmentFormData,
    number
  >(control, 'totalDays', totalDays, setTotalDays);
  const totalReadingsDebouncing = useDebounceFormValue<
    HypertensionAssessmentFormData,
    number
  >(control, 'totalReadings', totalReadings, setTotalReadings);
  const averageDiastolicDebouncing = useDebounceFormValue<
    HypertensionAssessmentFormData,
    number
  >(control, 'averageDiastolic', averageDiastolic, setAverageDiastolic);
  const averageSystolicDebouncing = useDebounceFormValue<
    HypertensionAssessmentFormData,
    number
  >(control, 'averageSystolic', averageSystolic, setAverageSystolic);

  const isDebouncing =
    totalDaysDebouncing ||
    totalReadingsDebouncing ||
    averageDiastolicDebouncing ||
    averageSystolicDebouncing;

  const showResetRecommendationModal = () => {
    setResetRecommendationModalOpen(true);
  };

  const showHasFieldChanged = () => {
    setHasChanged(true);
  };

  const hasRecommendation = !isEmptyOrNil(guidelineAcceptance);
  const previousRecommendationRef =
    usePreviousHypertensionConsultationRecommendation(
      assessmentType,
      averageDiastolic,
      averageSystolic,
      totalDays,
      totalReadings,
      hasRecommendation ? showResetRecommendationModal : showHasFieldChanged
    );

  const { loading: recommendationLoading } = useHypertensionRecommendation(
    assessmentType,
    { averageDiastolic, averageSystolic, totalDays, totalReadings },
    setValue,
    recommendationTriggeredRef,
    setHasChanged,
    hasChanged
  );

  useEffect(() => {
    if (hasChanged) {
      previousRecommendationRef.current = {
        averageDiastolic,
        averageSystolic,
        totalDays,
        totalReadings,
      };
    }
  }, [hasChanged]);

  useEffect(() => {
    if (isNavigateBack) {
      handleNavigateBack(
        previousRecommendationRef,
        hypertensionAssessmentFormData,
        recommendationTriggeredRef
      );
    }
  }, [isNavigateBack]);

  const handleContinue = (formData: HypertensionAssessmentFormData) => {
    patientDispatch({
      type: 'setHypertensionAssessmentFormData',
      hypertensionAssessmentFormData: formData,
    });
    navigate(ROUTE.ASSESSMENT_HYPERTENSION_CONFIRM, {
      state: { wardPatientId, patient },
    });
  };

  const handleBackButton = () => {
    checkCanLeavePage(() => {
      trackTappedAssessment('assessment/hypertension/leave_assessment');
      patientDispatch({ type: 'resetHypertensionAssessmentFormData' });
      navigate(ROUTE.PATIENT_DETAIL, { state: { wardPatientId } });
    });
  };

  const handleCancel = () => {
    setCancelModalOpen(true);
  };

  const handleRetry = () => refetch();

  const handleCancelResetRecommendation = () => {
    setPreviousConsultationRecommendedValues(
      setValue,
      previousRecommendationRef
    );
    setResetRecommendationModalOpen(false);
    setHasChanged(false);
  };

  const handleContinueResetRecommendation = () => {
    trackTappedAssessment('assessment/hypertension/reset_recommendation');
    setHasChanged(true);
    setResetRecommendationModalOpen(false);
  };

  const handleSelfMonitoringConfirmationChange = ({
    target,
  }: ChangeEvent<HTMLInputElement>) => {
    setSelfMonitoringAccepted(target.checked);
  };

  const watchFitForSelfMonitoringYN = watch('fitToSelfMonitorYN');
  const watchIsTakingBloodPressureMedicationYN = watch(
    'isTakingBloodPressureMedicationYN'
  );

  useEffect(() => {
    if (watchFitForSelfMonitoringYN === YesNoType.No) {
      setSelfMonitoringAccepted(false);
    }
  }, [watchFitForSelfMonitoringYN]);

  window.onpopstate = () => {
    navigate(ROUTE.ASSESSMENT_HYPERTENSION, {
      state: { wardPatientId, patient },
    });
    handleBackButton();
  };

  useEffect(() => {
    if (watchIsTakingBloodPressureMedicationYN === YesNoType.Yes) {
      setValue('hasEverTakenBloodPressureMedicationYN', YesNoType.Yes);
      setEverTakenMedicationDisabled(true);
    } else {
      setValue(
        'hasEverTakenBloodPressureMedicationYN',
        hypertensionAssessmentFormData.hasEverTakenBloodPressureMedicationYN
      );
      setEverTakenMedicationDisabled(false);
    }
  }, [watchIsTakingBloodPressureMedicationYN]);

  const isContinueDisabled =
    !isValid ||
    (watchFitForSelfMonitoringYN === YesNoType.Yes &&
      !selfMonitoringAccepted) ||
    (guidelineAcceptance.length > 0 &&
      guidelineAcceptance.some((item) => item.accepted == null)) ||
    recommendationLoading ||
    isDebouncing ||
    resetRecommendationModalOpen;

  if (loading) {
    return (
      <SpinnerLoader
        id="ward-patient-loader"
        data-testid="ward-patient-loader"
      />
    );
  }

  if (error || !data?.wardPatient) {
    return (
      <ErrorNotification
        id="patient"
        action="when retrieving the patient"
        onTryAgainClick={handleRetry}
      />
    );
  }

  const wardPatientUI = buildWardPatientUI(data.wardPatient as WardPatient);
  const patientDetailsHeaderUI = buildPatientHeaderUI(wardPatientUI);

  return (
    <>
      <HypertensionOverlayWrapper />
      <HypertensionAssessmentHeaderWrapper>
        <Wrapper>
          <BackButtonWrapper>
            <BackButton handleBackButton={handleBackButton} />
          </BackButtonWrapper>
          <SubHeader>Hypertension assessment</SubHeader>
          {recommendationLoading && (
            <SpinnerLoader
              asModal
              id="recommendation-loader"
              data-testid="recommendation-loader"
              text="Checking for recommendations"
            />
          )}
          <HypertensionAssessmentFormHeader
            control={control}
            errors={errors}
            resetField={resetField}
            assessmentType={assessmentType}
            hypertensionAssessmentFormData={hypertensionAssessmentFormData}
            recommendationTriggeredRef={recommendationTriggeredRef}
          />
        </Wrapper>
        <Divider
          lineType="dotted"
          variant="vertical"
          flexColor="primaryIndigo.indigo08"
        />
        <Wrapper>
          <PatientAssessmentHeader {...patientDetailsHeaderUI} />
        </Wrapper>
      </HypertensionAssessmentHeaderWrapper>

      {!assessmentType ? (
        <AssessmentTypeSelector />
      ) : (
        <HypertensionAssessmentContentLayout>
          <Wrapper>
            <SubHeader color="indigo08">Assessment</SubHeader>
            <HypertensionAssessmentContentWrapper>
              <HypertensionAssessmentReviewForm
                register={register}
                control={control}
                errors={errors}
                handleSelfMonitoringConfirmationChange={
                  handleSelfMonitoringConfirmationChange
                }
                formData={hypertensionAssessmentFormData}
                resetField={resetField}
                monitoringStatus={
                  getCurrentHypertensionStatus(
                    'monitoringStatus',
                    wardPatientUI.monitoredConditions
                  ) as MonitoringStatusType
                }
                consultationStatus={
                  getCurrentHypertensionStatus(
                    'consultationStatus',
                    wardPatientUI.monitoredConditions
                  ) as MonitoredConditionConsultationStatusType
                }
                testStatus={
                  getCurrentHypertensionStatus(
                    'testStatus',
                    wardPatientUI.monitoredConditions
                  ) as MonitoredConditionTestStatusType
                }
                hypertensionCarePlanRef={hypertensionCarePlanRef}
              />
              <HypertensionAssessmentMedicalForm
                register={register}
                control={control}
                errors={errors}
                formData={hypertensionAssessmentFormData}
                fieldDisabled={everTakenMedicationDisabled}
                resetField={resetField}
              />
            </HypertensionAssessmentContentWrapper>
          </Wrapper>
          <Wrapper>
            <SubHeader color="indigo08">
              Hypertension monitored vitals
            </SubHeader>
            <HypertensionAssessmentContentWrapper>
              <HypertensionAssessmentHealthVitals
                wardPatientUI={wardPatientUI}
              />
            </HypertensionAssessmentContentWrapper>
            {assessmentType.value === AssessmentType.Consultation && (
              <HypertensionAssessmentCarePlanWrapper>
                <SubHeader color="indigo08">Hypertension Care Plan</SubHeader>
                <HypertensionAssessmentContentWrapper>
                  <HypertensionAssessmentMedicationOutcomeForm
                    register={register}
                    control={control}
                    errors={errors}
                    formData={hypertensionAssessmentFormData}
                    setValue={setValue}
                    fieldDisabled={everTakenMedicationDisabled}
                    resetField={resetField}
                    hypertensionCarePlanRef={hypertensionCarePlanRef}
                    isValid={isValid}
                  />
                </HypertensionAssessmentContentWrapper>
              </HypertensionAssessmentCarePlanWrapper>
            )}
          </Wrapper>
        </HypertensionAssessmentContentLayout>
      )}
      <FormFooter
        primaryButtonClick={handleSubmit(handleContinue)}
        cancelButtonClick={handleCancel}
        primaryButtonDisabled={isContinueDisabled}
        isFormWidth={false}
      />
      <CancelAssessment
        modalOpen={cancelModalOpen}
        setModalOpen={setCancelModalOpen}
        wardPatientId={wardPatientId}
      />
      <ResetRecommendation
        modalOpen={resetRecommendationModalOpen}
        onCancel={handleCancelResetRecommendation}
        onContinue={handleContinueResetRecommendation}
      />
    </>
  );
};
