import styled from 'styled-components';

export const Wrapper = styled.div`
  flex-direction: column;
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: center;
`;

export const TextWrapper = styled.div`
  max-width: 640px;
  text-align: center;
`;
