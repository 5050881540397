import { BTHealthIcon } from '@bt-healthcare/ui-toolkit';
import { useLocation, useNavigate } from 'react-router-dom';

import type { UserSettingsFeatureProps } from './types';
import {
  NavItem,
  Label,
  IconWrapper,
} from 'components/Page/Layout/Navigation/styles';
import { ROUTE } from 'config/routes';
import { useAuthUserProfile } from 'auth/useAuthUserProfile';
import { RBACAction, useHasAccess } from 'auth/useHasAccess';

export const UserSettingsFeature = ({
  sideNavOpen,
}: UserSettingsFeatureProps) => {
  const { userRoles } = useAuthUserProfile();
  const navigate = useNavigate();
  const hasAccess = useHasAccess([RBACAction.ADMINISTER_USERS], userRoles);
  const { pathname } = useLocation();

  const isActive = pathname.includes(ROUTE.SETTINGS);
  if (!hasAccess) return null;

  return (
    <NavItem
      sideNavOpen={sideNavOpen}
      onClick={() => navigate(ROUTE.SETTINGS)}
      active={isActive}
    >
      <IconWrapper sideNavOpen={sideNavOpen} active={isActive}>
        <BTHealthIcon icon="Settings" size={24} />
      </IconWrapper>
      <Label sideNavOpen={sideNavOpen} active={isActive}>
        Settings
      </Label>
    </NavItem>
  );
};
