import styled from 'styled-components';
import {
  Wrapper,
  colors,
  device,
  fontSizes,
  spacing,
} from '@bt-healthcare/ui-toolkit';

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  margin: ${spacing.s3} 0 ${spacing.s9} 0;
`;

export const ModalBody = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: ${spacing.s4};
`;

export const Header = styled.h3`
  text-align: center;
  font-size: ${fontSizes.h3};
  font-weight: 500;
  color: ${colors.grey.grey10};
  margin-bottom: ${spacing.s4};
`;

export const Paragraph = styled.p`
  text-align: center;
  font-size: ${fontSizes.base};
  color: ${colors.grey.grey08};
  margin-bottom: ${spacing.s6};
`;

export const ButtonWrapper = styled(Wrapper)`
  display: flex;
  flex-direction: column;
  gap: ${spacing.s2};
  width: 100%;

  @media ${device.largeTablet} {
    flex-direction: row;
  }
`;

export const ModalHeadlineWrapper = styled.span`
  display: flex;
  align-items: center;
`;

export const ModalHeadline = styled.span`
  font-size: ${fontSizes.h4};
  font-weight: 500;
  line-height: 30px;
  color: ${colors.primaryIndigo.indigo08};
  padding: ${spacing.none} ${spacing.s3};

  @media ${device.largeTablet} {
    font-size: ${fontSizes.h3};
    line-height: 54px;
    padding: ${spacing.none} ${spacing.s6};
  }
`;

export const TextWrapper = styled(Wrapper)`
  display: flex;
  flex-direction: column;
  gap: ${spacing.s2};
  text-align: center;
  margin-bottom: ${spacing.s2};
`;

export const ModalStrapline = styled.span`
  display: inline-block;
  font-weight: 400;
  text-align: center;
  color: ${colors.grey.grey08};
  padding-bottom: ${spacing.s4};
`;
