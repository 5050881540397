import type { AdmissionActions, AdmissionState } from './types';
import type {
  AdmissionDataMatches,
  StartAdmissionFormData,
} from 'components/StartAdmissionForm/types';
import type { SearchPersonAttributes } from 'services/graphql';

export const initAdmissionState = {
  admission: {} as StartAdmissionFormData,
  matchedData: {} as AdmissionDataMatches,
  person: {} as SearchPersonAttributes,
  isStandalone: false,
};

export const admissionReducer = (
  state: AdmissionState,
  action: AdmissionActions
): AdmissionState => {
  switch (action.type) {
    case 'setAdmission': {
      return { ...state, admission: action.admission };
    }
    case 'setMatchedData': {
      return { ...state, matchedData: action.matchedData };
    }
    case 'setPerson': {
      return { ...state, person: action.person };
    }
    case 'setIsStandalone': {
      return { ...state, isStandalone: action.isStandalone };
    }
    case 'resetAll': {
      return initAdmissionState;
    }
    default: {
      return state;
    }
  }
};
