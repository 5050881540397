import { Timestamp, Wrapper } from '@bt-healthcare/ui-toolkit';
import type { ReviewDateTimeProps } from './types';

export const ReviewDateTime = ({
  reviewDateTime = new Date(),
}: ReviewDateTimeProps) => (
  <Wrapper as="span" data-testid="review-date-time-wrapper">
    <strong>
      <Timestamp
        date={reviewDateTime}
        type="date"
        data-testid="review-date"
        as="span"
      />
    </strong>{' '}
    at{' '}
    <strong>
      <Timestamp
        date={reviewDateTime}
        type="time"
        data-testid="review-time"
        as="span"
      />
    </strong>
    .
  </Wrapper>
);
