import { Avatar } from '@bt-healthcare/ui-toolkit';
import type { ProfileProps } from './types';
import { ProfileWrapper } from './styles';
import { useAuthUserProfile } from 'auth/useAuthUserProfile';

export const Profile = ({
  showName = false,
  avatarSize,
  color,
  fgColor,
}: ProfileProps) => {
  const { profile } = useAuthUserProfile();

  return (
    <ProfileWrapper data-testid="profile">
      <Avatar
        name={profile.displayName}
        size={avatarSize}
        color={color}
        fgColor={fgColor}
      />
      {showName && <span>{profile.displayName}</span>}
    </ProfileWrapper>
  );
};
