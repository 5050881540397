import styled, { css } from 'styled-components';
import {
  Wrapper,
  colors,
  device,
  fontSizes,
  spacing,
} from '@bt-healthcare/ui-toolkit';
import {
  mobileFooterHeight,
  desktopFooterHeight,
} from 'components/FormFooter/styles';

// TODO: Check colors applied and add to ui-toolkit
const textGradientMixin = css`
  background: linear-gradient(
    63deg,
    #448aff -0.36%,
    #472ecd 54.21%,
    #5514b4 100.81%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const NotificationWrapper = styled(Wrapper)`
  cursor: pointer;
`;

export const RecommendationAvailableText = styled.span`
  cursor: pointer;
  ${textGradientMixin};
`;

export const TextLink = styled.a`
  color: ${colors.secondaryBlue.blue07};
  border-bottom: 1px solid ${colors.secondaryBlue.blue07};
`;

const mobileMarginBottom = `calc(${mobileFooterHeight} + ${spacing.s6})`;
const desktopMarginBottom = `calc(${desktopFooterHeight} + ${spacing.s8})`;

export const AssessmentTypeSelectorWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 1295px;
  margin: ${spacing.s6} ${spacing.none} ${mobileMarginBottom} ${spacing.none};
  gap: ${spacing.s6};

  svg {
    max-width: 100%;
    height: auto;
  }

  @media ${device.largeTablet} {
    width: 80%;
    flex-direction: row;
    gap: ${spacing.l4};
    margin: ${spacing.s16} ${spacing.s8} ${desktopMarginBottom} ${spacing.s8};
  }
`;

export const AssessmentTypeSelectorTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.s2};
  text-align: center;
  max-width: 640px;

  @media ${device.largeTablet} {
    text-align: left;
    gap: ${spacing.s4};
  }
`;

export const AssessmentTypeSelectorTitle = styled.span`
  color: ${colors.grey.grey09};
  font-size: ${fontSizes.base};
  font-weight: 500;

  @media ${device.largeTablet} {
    font-size: ${fontSizes.h3};
  }
`;

export const AssessmentTypeSelectorText = styled.span`
  color: ${colors.grey.grey08};
  font-size: ${fontSizes.sm};

  @media ${device.largeTablet} {
    font-size: ${fontSizes.h4};
  }
`;

export const HypertensionAssessmentFormHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.s2};
  margin-top: ${spacing.s4};
  padding-right: ${spacing.s4};

  @media ${device.largeTablet} {
    align-items: flex-start;
    flex-direction: row;
    > div {
      width: 50%;
    }
  }
`;
