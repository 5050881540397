import * as yup from 'yup';
import type { AdmissionDataMatches, StartAdmissionFormData } from './types';
import {
  firstNameRule,
  surnameRule,
  requiredNumber,
  requiredYear,
  REQUIRED_TEXT,
  DOB_REQUIRED_TEXT,
} from 'components/Form/validationHelper';
import type { PersonPersonalInformation } from 'services/graphql';
import { appendZeroForDate } from 'utils/date.utils';

export const INVALID_DATE_MESSAGE = 'Date is invalid.';
export const FUTURE_DATE_MESSAGE = 'Date must be earlier than todays date.';
export const INVALID_PATIENT_AGE =
  'Patient cannot be admitted as they are under 18.';

const startAdmissionFormRules = {
  firstName: firstNameRule(
    'First name must be between 1-50 characters in length.'
  ),
  surname: surnameRule('Surname must be between 1-50 characters in length.'),
  email: yup
    .string()
    .matches(
      /^([a-zA-Z0-9_\-\\.+]+)@([a-zA-Z0-9_\-\\.]+)\.([a-zA-Z]{2,15})$/,
      'Please enter a valid email address.'
    )
    .required(REQUIRED_TEXT),
  dayOfBirth: requiredNumber(DOB_REQUIRED_TEXT),
  monthOfBirth: requiredNumber(DOB_REQUIRED_TEXT),
  yearOfBirth: requiredYear(),
} as { [key in Partial<keyof StartAdmissionFormData>]: any };

export const startAdmissionSchema = yup.object(startAdmissionFormRules);

export const validatePartialMatch = (
  formData: StartAdmissionFormData,
  responseData: PersonPersonalInformation
) => {
  const dob = `${formData.yearOfBirth}-${appendZeroForDate(
    formData.monthOfBirth
  )}-${appendZeroForDate(formData.dayOfBirth)}`;

  const matches = {
    firstName:
      formData.firstName.toLowerCase() === responseData.firstName.toLowerCase(),
    surname:
      formData.surname.toLowerCase() === responseData.surname.toLowerCase(),
    dateOfBirth: dob === responseData.dateOfBirth,
  };

  return {
    matches,
    isPartial: Object.values(matches).some((x) => x === false),
  };
};

export const validateAllNotMatch = (data: AdmissionDataMatches) =>
  Object.values(data).reduce(
    (count: number, item) => count + (item === false ? 1 : 0),
    0
  ) === 3;
