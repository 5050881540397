import { either, isEmpty, isNil, propOr } from 'ramda';
import { EMPTY_VALUE } from 'App.constants';

export const isEmptyOrNil = either(isNil, isEmpty);
export const valueEmptyOrNil = (
  value?: string | null,
  defaultEmptyValue = EMPTY_VALUE
) => (isEmptyOrNil(value) ? defaultEmptyValue : (value as string));

/**
 * Checks if property on an object is an empty string, null or undefined
 */
export const propEmptyOr = <T>(
  propertyKey: string,
  obj: T,
  defaultEmptyValue = EMPTY_VALUE
) => {
  const value: string | undefined | null = propOr(undefined, propertyKey, obj);
  return valueEmptyOrNil(value, defaultEmptyValue);
};

export const isFunctionType = (type: any): type is Function =>
  typeof type === 'function';

export const isEnumType = <T extends {}>(
  enumType: T,
  value: any
): value is T[keyof T] => Object.values(enumType).includes(value as T);
