import { Text, colors, fontSizes } from '@bt-healthcare/ui-toolkit';
import { NumOfFiltersWrapper } from './styles';
import type { NumOfFiltersProps } from './types';

export const NumOfFilters = ({ numOfFilters }: NumOfFiltersProps) => (
  <NumOfFiltersWrapper>
    <Text color={colors.base.white} fontSize={fontSizes.xs}>
      {numOfFilters}
    </Text>
  </NumOfFiltersWrapper>
);
