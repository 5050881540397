import { differenceInDays } from 'date-fns';
import { Maybe } from 'purify-ts';

import { getDischargeDate } from './discharge';
import { pipeNilable } from './utils';
import type { CareSettingPatient, Ward, WardPatient } from 'services/graphql';
import { parseDateTime } from 'utils/date.utils';

export const getCareSettingPatient = (
  wardPatient: WardPatient
): CareSettingPatient | null => wardPatient.careSettingPatient;

export const getDischarge = (wardPatient: WardPatient) =>
  wardPatient.wardDischarge;

export const getAdmissionDate = (wardPatient: WardPatient): Date | undefined =>
  Maybe.fromNullable(wardPatient.attributes.admissionDateTime)
    .chain(parseDateTime)
    .extract();

export const getWard = (wardPatient: WardPatient): Ward | null =>
  wardPatient.ward;

export const getLengthOfStayInDays = (wardPatient: WardPatient) => {
  const admissionDate = getAdmissionDate(wardPatient);
  const dischargeDate = pipeNilable([getDischarge, getDischargeDate])(
    wardPatient
  );
  if (!admissionDate || !dischargeDate) {
    return null;
  }
  return differenceInDays(admissionDate, dischargeDate);
};
