import { colors, Text, BTHealthIcon } from '@bt-healthcare/ui-toolkit';

import { ReadingTitle, Header } from '../ReadingItem/styles';
import { FilterDropdown } from '../FilterDropdown';
import type { ReadingHeaderProps } from './types';

export const ReadingHeader = ({
  icon,
  title,
  handleFilterDropdownClick,
  filterType,
}: ReadingHeaderProps) => (
  <Header>
    <ReadingTitle>
      <BTHealthIcon
        icon={icon}
        color={colors.primaryIndigo.indigo08}
        size={16}
      />
      <Text color={colors.primaryIndigo.indigo08} fontWeight={500}>
        {title}
      </Text>
    </ReadingTitle>
    <FilterDropdown
      handleItemClick={handleFilterDropdownClick}
      filterType={filterType}
    />
  </Header>
);
