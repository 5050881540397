import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Spacer } from '@bt-healthcare/ui-toolkit';
import { createSearchParams, useNavigate } from 'react-router-dom';

import { useEffect, useState } from 'react';
import { omit } from 'ramda';
import { mapFormDataOnSubmit } from '../mapping';
import { PatientAdmissionFormWrapper } from '../styles';
import type { PatientAdmissionPersonalFormData } from '../types';
import {
  invalidDateOfBirthResponseHandler,
  patientAdmissionSchema,
  validateDateOfBirth,
} from './validation';
import { PersonalAdmissionFormFields } from './PersonalAdmissionFormFields';
import { ROUTE } from 'config/routes';
import { Step } from 'pages/PatientAdmission/types';
import { FormFooter } from 'components/FormFooter';
import { CancelAdmissionModal } from 'components/Modal/CancelAdmission';
import { usePatient, usePatientDispatch } from 'context/patient/PatientContext';
import {
  useAdmissionForm,
  useAdmissionFormDispatch,
} from 'context/admission/AdmissionContext';
import { useLeavePageModal } from 'hooks/useLeavePageModal';

export const PersonalAdmission = () => {
  const {
    admissionFormData: { personal },
  } = usePatient();
  const { admission } = useAdmissionForm();
  const patientDispatch = usePatientDispatch();
  const admissionDispatch = useAdmissionFormDispatch();

  const {
    register,
    control,
    setError,
    clearErrors,
    setFocus,
    formState,
    handleSubmit,
  } = useForm<PatientAdmissionPersonalFormData>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(patientAdmissionSchema),
    defaultValues: {
      ...personal,
      ...omit(['dateOfBirth'], admission),
    },
  });
  const { isValid, errors, isDirty } = formState;

  const navigate = useNavigate();
  const onContinue = (data: PatientAdmissionPersonalFormData) => {
    const validationResponse = validateDateOfBirth(data);
    if (!validationResponse.isValid) {
      invalidDateOfBirthResponseHandler(
        validationResponse.message!,
        setError,
        setFocus
      );
    } else {
      patientDispatch({
        type: 'setPersonalFormData',
        personalFormData: mapFormDataOnSubmit(data),
      });
      navigate({
        pathname: ROUTE.ADMISSION,
        search: createSearchParams({ step: Step.MEDICAL }).toString(),
      });
    }
  };

  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const { formDirtied, setFormDirtied } = useLeavePageModal();

  useEffect(() => {
    if (isDirty) {
      if (!formDirtied) {
        setFormDirtied();
      }
    }
  }, [isDirty, formDirtied]);

  return (
    <PatientAdmissionFormWrapper data-testid="personal-admission-wrapper">
      <PersonalAdmissionFormFields
        register={register}
        control={control}
        errors={errors}
        clearErrors={clearErrors}
      />
      <Spacer size="s4" />
      <FormFooter
        primaryButtonText="Continue"
        primaryButtonDisabled={!isValid}
        primaryButtonClick={handleSubmit(onContinue)}
        cancelButtonClick={() => setCancelModalOpen(true)}
      />
      {cancelModalOpen && (
        <CancelAdmissionModal
          isModalOpen={cancelModalOpen}
          handleClose={() => setCancelModalOpen(false)}
          handleProceed={() => {
            setCancelModalOpen(false);
            patientDispatch({ type: 'resetPatientAdmissionFormData' });
            admissionDispatch({ type: 'resetAll' });
            navigate(ROUTE.HOME);
          }}
        />
      )}
    </PatientAdmissionFormWrapper>
  );
};
