import type { User } from '@auth0/auth0-react';
import type { Profile } from '@bt-healthcare/ui-toolkit';
import { pipe, pathOr, filter, map } from 'ramda';
import jwt_decode from 'jwt-decode';
import { captureException } from '@sentry/react';

import type { Operation } from '@apollo/client';
import type {
  Maybe,
  ModuleRole,
  GetUserProfileBySafIdQuery,
} from 'services/graphql';
import type {
  CareSetting,
  GeneralPracticesWithIdType,
  UserProfileCareSettingAttributes,
} from 'types';
import { HTTP_HEADER } from 'services/httpHeaders';

export const getCareSetting = (
  defaultWardId: Maybe<string>,
  id: Maybe<string>,
  careSettingAttributes?: UserProfileCareSettingAttributes
): CareSetting => ({
  id,
  organisation: careSettingAttributes?.name ?? '',
  organisationShortName: careSettingAttributes?.shortName ?? '',
  defaultWardId,
  generalPractices: pipe(
    pathOr([], ['relatedOrganisations']),
    filter((item: any) => item.type === 'GENERAL_PRACTICE'),
    map((item: any) => item.name),
    (arr: string[]) => (arr.length > 0 ? arr : null)
  )(careSettingAttributes),
  generalPracticesWithId: pipe(
    pathOr([], ['relatedOrganisations']),
    filter((item: any) => item.type === 'GENERAL_PRACTICE'),
    map((item: any) => ({ name: item.name, value: item.externalReferenceId })),
    (arr: GeneralPracticesWithIdType[]) => (arr.length > 0 ? arr : null)
  )(careSettingAttributes),
});

export const getProfile = (careSetting: CareSetting, user?: User): Profile => ({
  mail: user?.email ?? '',
  organisation: careSetting.organisation,
  organisationShortName: careSetting.organisationShortName,
  displayName: user?.name ?? '',
});

export const getUserIdFromJwtToken = (
  correlationId: string,
  authToken?: string
) => {
  if (!authToken) return undefined;
  try {
    const decodedToken: any = jwt_decode(authToken);
    return decodedToken['https://aim.bt.com/aim_user_id'];
  } catch (error) {
    captureException(error, {
      tags: {
        'x-correlation-id': correlationId,
      },
    });
    return undefined;
  }
};

export const getCorrelationId = (operation: Operation) => {
  const correlationId =
    operation.getContext().headers?.[HTTP_HEADER.apigwTracking];
  return {
    'x-correlation-id': correlationId ?? 'Not set',
  };
};

export const getUserId = (operation: Operation, correlationId: string) => {
  const authToken = operation.getContext().headers?.authorization;
  return getUserIdFromJwtToken(correlationId, authToken);
};

export const getUserRoles = (
  data?: GetUserProfileBySafIdQuery
): ModuleRole[] => {
  if (data?.userProfile?.userModuleRoles == null) return [];
  return data.userProfile.userModuleRoles.flatMap((role) =>
    role?.attributes.moduleRoleId == null ? [] : role.attributes.moduleRoleId
  );
};
