import { ModalTitle } from '../styles';
import { ModalButtons } from '../ModalButtons';
import { StartAdmissionModal } from '..';
import {
  ModalBody,
  ModalStrapline,
  TextWrapper,
} from 'components/Modal/styles';

export type EmailAlreadyExistModalProps = {
  isModalOpen: boolean;
  handleClose: () => void;
  handleProceed: () => void;
};

export const EmailAlreadyExistModal = ({
  isModalOpen,
  handleProceed,
  handleClose,
}: EmailAlreadyExistModalProps) => (
  <StartAdmissionModal
    onClose={handleClose}
    isModalOpen={isModalOpen}
    id="email-already-exist-modal"
  >
    <ModalBody>
      <TextWrapper>
        <ModalTitle title="Email address already in use" />
        <ModalStrapline>
          The details you have entered do not match those of the existing
          account holder with this email address. Review the information
          provided and try again. If the problem persists contact support.
        </ModalStrapline>
      </TextWrapper>
      <ModalButtons handleClose={handleClose} handleProceed={handleProceed} />
    </ModalBody>
  </StartAdmissionModal>
);
