import type { MutableRefObject } from 'react';
import { useRef, useState } from 'react';
import { useClickOutside } from '@bt-healthcare/ui-toolkit';
import { Profile } from '../../Profile';
import { NavItem, Label } from '../../styles';
import {
  activeBackgroundColor,
  activeColor,
  defaultBackgroundColor,
  defaultColor,
} from '../../styles.constants';
import type { MyAccountProps } from './types';
import {
  MyAccountWrapper,
  ContextMenu,
  ProfileWrapper,
  Divider,
  LogoutButton,
} from './styles';
import { useLogout } from 'hooks/useLogout';

export const MyAccount = ({ sideNavOpen }: MyAccountProps) => {
  const logout = useLogout();

  const [contextMenuOpen, setContextMenuOpen] = useState(false);
  const [hovered, setHovered] = useState(false);

  const wrapperRef = useRef() as MutableRefObject<HTMLDivElement>;
  useClickOutside(wrapperRef, () => {
    setContextMenuOpen(false);
  });

  return (
    <MyAccountWrapper data-testid="my-account" ref={wrapperRef}>
      <NavItem
        sideNavOpen={sideNavOpen}
        onClick={() => setContextMenuOpen(!contextMenuOpen)}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        data-testid="my-account-button"
      >
        <Profile
          color={
            contextMenuOpen || hovered
              ? activeBackgroundColor
              : defaultBackgroundColor
          }
          fgColor={contextMenuOpen ? activeColor : defaultColor}
        />
        <Label sideNavOpen={sideNavOpen} active={contextMenuOpen}>
          My account
        </Label>
      </NavItem>

      {contextMenuOpen && (
        <ContextMenu id="profile-context-menu" sideNavOpen={sideNavOpen}>
          <ProfileWrapper>
            <Profile showName avatarSize="50px" />
          </ProfileWrapper>
          <Divider />
          <LogoutButton data-testid="logout-button" onClick={logout}>
            Logout
          </LogoutButton>
        </ContextMenu>
      )}
    </MyAccountWrapper>
  );
};
