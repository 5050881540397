import { differenceInDays, format } from 'date-fns';
import { viewportWidths } from '@bt-healthcare/ui-toolkit';

import type { DateFilterRange, NavigationDirection } from './types';
import { DateFilter } from './types';
import type { DateRange } from './LineChart/LineChart.types';
import { LINE_CHART_WIDTH } from './LineChart/lineChart.config';
import { DATE_FILTER_MAP } from 'utils/dateFilter.utils';

export const getXAxisLabel = (dateFilter: DateFilter, dateRange: DateRange) => {
  switch (dateFilter) {
    case DateFilter.Monthly:
    case DateFilter.Biweekly:
    case DateFilter.Week:
      return '';
    case DateFilter.Custom: {
      const dayRange = differenceInDays(dateRange.endDate, dateRange.startDate);
      return dayRange === 0
        ? format(dateRange.startDate, 'eeee do MMMM yyyy')
        : '';
    }
    default:
      return format(dateRange.startDate, 'eeee do MMMM yyyy');
  }
};

export const getDateIncrement = (
  direction: NavigationDirection,
  filter: DateFilter
) => {
  const noOfDays = DATE_FILTER_MAP[filter]!;
  return direction === 'next' ? noOfDays : -noOfDays;
};

/** Set chart width based on viewport width - based on the current structure the below works well
 *  but will need to be reviewed if the layout changes
 * */
export const getChartWidth = (width: number) => {
  if (
    width > parseFloat(viewportWidths.largeTablet) &&
    width < parseFloat(viewportWidths.desktop)
  )
    return 770;
  if (
    width >= parseFloat(viewportWidths.desktop) &&
    width < parseFloat(viewportWidths.largeDesktop)
  )
    return 540;
  return LINE_CHART_WIDTH;
};

/** Get Custom filter based on the date range  */
export const getCustomFilter = (dateRange: DateRange) => {
  const noOfDays = differenceInDays(dateRange.endDate, dateRange.startDate);
  let dateRangeFilter: DateFilterRange = {
    dateFilter: DateFilter.Monthly,
    noOfDays,
    isCustom: true,
  };
  if (noOfDays === 0)
    dateRangeFilter = { ...dateRangeFilter, dateFilter: DateFilter.Today };
  if (noOfDays > 0 && noOfDays <= DATE_FILTER_MAP.Month!)
    dateRangeFilter = { ...dateRangeFilter, dateFilter: DateFilter.Week };
  return dateRangeFilter;
};
