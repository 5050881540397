import { colors, fontCalcMixin, fontSizes } from '@bt-healthcare/ui-toolkit';
import styled from 'styled-components';

export const LineChartHeadingWrapper = styled.div.attrs(
  ({ ariaLevel }: { ariaLevel: string }) => ({
    'aria-level': ariaLevel,
  })
)`
  ${({ ariaLevel }) =>
    fontCalcMixin(ariaLevel === '4' ? fontSizes.sm : fontSizes.base)}
`;

export const TitleWrapper = styled.span`
  color: ${colors.grey.grey10};
  font-weight: 500;
`;

export const UnitsWrapper = styled.span`
  color: ${colors.grey.grey08};
`;

export const LineChartWrapper = styled.div`
  position: relative;
`;
