import { Text, colors } from '@bt-healthcare/ui-toolkit';
import { DateFilter } from 'components/Visualisations/types';
import type {
  Maybe,
  GetObservationAbsoluteAverageQuery,
} from 'services/graphql';

export const getAverageText = (
  isOneDayReading: boolean,
  dateFilter: DateFilter,
  isCustom: boolean
) => {
  if (dateFilter === DateFilter.Today || isOneDayReading) return 'from';
  return isCustom ? 'over' : 'over the last';
};

export const isSingular = (condition: boolean) => (condition ? '' : 's');

export const getAverageDaysText = (
  dateFilter: DateFilter,
  readingsData: Maybe<GetObservationAbsoluteAverageQuery>,
  isOneDayReading: boolean,
  isNavigation: boolean
) => {
  if (dateFilter === DateFilter.Today && !isNavigation) return 'today.';
  return (
    <Text color={colors.grey.grey08} fontWeight={500}>
      {readingsData!.observationsAbsoluteAverage!.attributes.totalNoOfDays} day
      {isSingular(isOneDayReading)}.
    </Text>
  );
};
