import { Wrapper, device, spacing } from '@bt-healthcare/ui-toolkit';
import styled from 'styled-components';
import { iconMarginLeftCalc } from '../../styles';

export const BurgerMenuWrapper = styled(Wrapper)`
  position: absolute;
  right: ${spacing.s6};
  width: auto;

  @media ${device.largeTablet} {
    display: none;
  }
`;

export const Spacer = styled(Wrapper)`
  height: ${spacing.s2};
`;

export const ProfileWrapper = styled(Wrapper)`
  margin-left: ${iconMarginLeftCalc};
`;
