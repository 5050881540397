import { ValueBarList, ValueBar, Stack } from '@bt-healthcare/ui-toolkit';

import type { AvgValueBarProps } from './types';
import { ObservationTypeMap } from 'transforms/observationTypeSearchTransform';

export const AvgValueBar = ({
  observationTypeData,
  observationType,
}: AvgValueBarProps) => {
  const observationTypeConfig = ObservationTypeMap[observationType];
  return (
    <Stack id="value-bar" space="s2">
      {observationTypeConfig &&
        observationTypeConfig.readings.map((value) => {
          const label = value.label!;
          const reading =
            observationTypeData.observationsAbsoluteAverage!.attributes.observationsAbsoluteAverages!.find(
              (item) => item.name.includes(label)
            );
          let average;
          if (reading != null) {
            const { absoluteAverages } = reading;
            average =
              absoluteAverages != null
                ? absoluteAverages[0].res_averageValueInteger ?? undefined
                : undefined;
          }
          return (
            <ValueBarList id={label} time="" key={label}>
              <ValueBar
                id={label}
                min={value.min}
                max={value.max}
                value={average}
                label={label}
              />
            </ValueBarList>
          );
        })}
    </Stack>
  );
};
