import { StatusDropDown } from '@bt-healthcare/ui-toolkit';
import { MonitoredConditionStatusError } from './MonitoredConditionStatusError';
import { MonitoredConditionStatusLastUpdated } from './MonitoredConditionStatusLastUpdated';
import type { MonitoredConditionStatusUpdateProps } from './types';
import { EMPTY_OPTION_VALUE } from 'App.constants';
import { MonitoringConditionDropDownSkeleton } from 'components/Skeletons/MonitoringConditionDropDownSkeleton';

export const MonitoredConditionStatusUpdate = ({
  loading,
  label,
  id,
  handleChange,
  statusOptions,
  currentStatus,
  valueContainerColor,
  error,
  condition,
  status,
  handleReload,
}: MonitoredConditionStatusUpdateProps) => (
  <>
    {loading ? (
      <MonitoringConditionDropDownSkeleton />
    ) : (
      <StatusDropDown
        label={`${label}:`}
        name={id}
        options={statusOptions}
        id={id}
        onChange={handleChange}
        value={currentStatus ?? EMPTY_OPTION_VALUE}
        valueContainerColor={valueContainerColor}
      />
    )}

    {status?.statusUpdateDateTime && (
      <MonitoredConditionStatusLastUpdated
        statusUpdateDateTime={status.statusUpdateDateTime}
        statusUpdatedBy={status.res_statusUpdatedBy}
        condition={condition}
      />
    )}
    {error && (
      <MonitoredConditionStatusError
        condition={condition}
        error={error.graphQLErrors}
        handleReload={handleReload}
      />
    )}
  </>
);
