import { Wrapper, colors, spacing } from '@bt-healthcare/ui-toolkit';
import styled from 'styled-components';
import { activeBackgroundColor } from '../../styles.constants';
import { iconMarginLeftCalc } from '../../styles';
import type { ContextMenuProps } from './types';

const contextMenuWidth = '214px';
const contextMenuPadding = spacing.s6;

export const MyAccountWrapper = styled(Wrapper)`
  position: relative;
  margin-left: ${iconMarginLeftCalc};
`;

export const ContextMenu = styled(Wrapper)<ContextMenuProps>`
  position: absolute;
  width: ${contextMenuWidth};
  min-height: 153px;
  border-radius: ${spacing.s2};
  background-color: ${colors.base.white};
  box-shadow: 0px 4px 10px 1px rgba(112, 144, 176, 0.2);
  bottom: 0;
  left: ${({ sideNavOpen }) => (sideNavOpen ? '48px' : '100%')};
`;

export const ProfileWrapper = styled(Wrapper)`
  padding: ${contextMenuPadding} ${contextMenuPadding} ${spacing.none}
    ${contextMenuPadding};
  span {
    overflow-wrap: anywhere;
  }
`;

export const Divider = styled(Wrapper)`
  height: 1px;
  width: ${contextMenuWidth};
  background-color: ${colors.grey.grey04};
  margin-top: ${spacing.s4};
  margin-bottom: ${spacing.s2};
`;

export const LogoutButton = styled(Wrapper)`
  padding: ${spacing.s3} ${spacing.s4};
  cursor: pointer;
  margin: ${spacing.s2};

  &:hover {
    background: ${activeBackgroundColor};
    border-radius: ${spacing.s2};
    transition: background 0.2s ease-in;
  }
`;
