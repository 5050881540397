import { addDays } from 'date-fns';

import type { DateRange } from 'components/Visualisations/LineChart/LineChart.types';
import type {
  Maybe,
  ObservationType,
  ObservationVisualsSearchInput,
  ObservationsAverageInput,
} from 'services/graphql';
import { SortDirection } from 'services/graphql';
import { toISOStringAsDate } from 'utils/date.utils';

export const getObservationTypeSearchInputVariables = (
  dateRange: DateRange,
  personId: string,
  observationType: Maybe<ObservationType> = null
) =>
  ({
    size: null,
    filters: {
      personId,
      observationType,
      startDate: toISOStringAsDate(dateRange.startDate),
      endDate: toISOStringAsDate(addDays(dateRange.endDate, 1)),
    },
    sort: { recordedDateTime: SortDirection.Ascending },
  } as ObservationVisualsSearchInput);

export const getObservationAbsoluteAverageInputVariables = (
  dateRange: DateRange,
  personId: string,
  observationType: Maybe<ObservationType> = null
) =>
  ({
    personId,
    observationType,
    from: toISOStringAsDate(dateRange.startDate),
    to: toISOStringAsDate(addDays(dateRange.endDate, 1)),
  } as ObservationsAverageInput);

export const initPatientMenuOptions = () => [
  { label: 'Health Vitals', active: true },
  {
    label: 'Overview',
    active: false,
  },
  { label: 'Assessments', active: false },
  { label: 'Health Information', active: false },
  { label: 'Lifestyle', active: false },
];

export const patientColumnMap: { [key: number]: string } = {
  0: '1fr 2fr 1fr',
  1: '3fr 1fr',
  2: '3fr 1fr',
  3: '3fr 1fr',
  4: '3fr 1fr',
};
