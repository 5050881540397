import { LinePath } from '@visx/shape';
import { curveMonotoneX } from '@visx/curve';

import { DateFilter, type SeriesDataValue } from '../types';
import { seriesChartConfigMap } from './lineChart.config';
import type { DataLineProps } from './LineChart.types';
import { DataPoint } from './DataPoint';

export const DataLine = ({
  dateFilter,
  chartData,
  xScale,
  yScale,
  idx,
  parseDate,
  handleTooltip,
  tooltipOpen,
  tooltipData,
  tooltipLeft,
  tooltipTop,
  hideTooltip,
  setTooltipValue,
  observationType,
}: DataLineProps) => (
  <>
    {chartData.length > 1 && (
      <LinePath
        data={chartData}
        x={(d: SeriesDataValue) => xScale(parseDate(d.date))}
        y={(d: SeriesDataValue) => yScale(d.series[idx])}
        strokeWidth={2}
        stroke={seriesChartConfigMap[idx].line}
        curve={dateFilter === DateFilter.Monthly ? curveMonotoneX : undefined}
      />
    )}
    <DataPoint
      dateFilter={dateFilter}
      chartData={chartData}
      xScale={xScale}
      yScale={yScale}
      idx={idx}
      parseDate={parseDate}
      handleTooltip={handleTooltip}
      tooltipOpen={tooltipOpen}
      tooltipData={tooltipData}
      tooltipLeft={tooltipLeft}
      tooltipTop={tooltipTop}
      hideTooltip={hideTooltip}
      setTooltipValue={setTooltipValue}
      observationType={observationType}
    />
  </>
);
