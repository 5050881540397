import type { Dispatch } from 'react';
import { createContext } from 'react';

import { useAppContext } from '../useAppContext';
import type { SettingsActions, SettingsState } from 'reducers/settings/types';

export const SettingsContext = createContext<SettingsState | null>(null);
export const SettingsDispatchContext =
  createContext<Dispatch<SettingsActions> | null>(null);

export const useSettings = () => useAppContext(SettingsContext);
export const useSettingsDispatch = () => useAppContext(SettingsDispatchContext);
