import type { PropsWithChildren, ReactNode, SyntheticEvent } from 'react';
import type { DateRange } from './LineChart/LineChart.types';
import type { Maybe, ObservationType } from 'services/graphql';
import type { SupportedObservationType } from 'types';
import type { ClinicianReviewUI } from 'models/wardPatient/types';

export type ObservationTypeUnit = 'bpm' | '%' | 'mmHg';

export type ObservationTypeLineChartProps = {
  lastReview?: ClinicianReviewUI;
  personId: string;
  admissionDateTime: Maybe<string>;
  observationTypes: SupportedObservationType[];
  title?: string | ReactNode;
  showLineChartHeading?: boolean;
  hasSubmittedReadings: boolean;
};

export type ObservationTypeLineChartFilterProps = {
  setActiveFilter: (item: ObservationChartDateFilter) => void;
  activeFilter: ObservationChartDateFilter;
  handleOptionChange: (
    item: ObservationChartDateFilter,
    dateRange?: Maybe<DateRange>
  ) => void;
  previousFilter: ObservationChartDateFilter | null;
};

export type ObservationTypeLineChartNavigationProps = PropsWithChildren<{
  handleNavigation: (
    evt: SyntheticEvent,
    direction: NavigationDirection
  ) => void;
  showNext: boolean;
  showPrev: boolean;
  title: string;
}>;

export type ObservationTypeData = {
  name: string;
  type: ObservationType;
  unit?: ObservationTypeUnit;
  mean: Maybe<number[]>;
  hits: number;
  data: SeriesDataValue[];
};

export type ObservationChartDateFilter = {
  id: string;
  value: DateFilter;
  data: any;
};

export type SeriesDataValue = {
  date: string;
  series: number[];
};

export enum DateFilter {
  Biweekly = 'Biweekly',
  Monthly = 'Month',
  Today = 'Today',
  Week = 'Week',
  Custom = 'Custom',
}

export type NavigationDirection = 'next' | 'prev';

export type DateFilterRange = {
  dateFilter: DateFilter;
  noOfDays: number;
  isCustom?: boolean;
};
