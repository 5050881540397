import { device, spacing } from '@bt-healthcare/ui-toolkit';
import styled from 'styled-components';

export const AccordionItemWrapper = styled.div`
  padding: ${spacing.none} ${spacing.s2};

  @media ${device.largeTablet} {
    padding: ${spacing.none} ${spacing.s4};
  }
`;

export const PatientDetailsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: ${spacing.s4};
  padding: ${spacing.s2} ${spacing.none};

  @media ${device.largeTablet} {
    grid-template-columns: repeat(5, 1fr);
    padding: ${spacing.s6} ${spacing.none};
  }
`;
