import {
  Stack,
  Col,
  colors,
  Grid,
  GRID_BREAKPOINT,
  Button,
  SubHeader,
  Paragraph,
} from '@bt-healthcare/ui-toolkit';
import { useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import {
  DischargeConfirmedSVG,
  HeaderWrapper,
} from './PatientDischargedConfirmed.styles';
import { type DischargeConfirmedState } from './PatientDischargedConfirmed.types';
import { DischargeSummary } from 'components/DischargeSummary';
import { ROUTE } from 'config/routes';
import { useTracking } from 'hooks/useTracking';
import { useBackButtonHijacker } from 'hooks/useBackButtonHijacker';
import { PageName } from 'config/pageNames';

export const DischargeConfirmed = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { trackPage } = useTracking();
  useEffect(() => {
    trackPage(PageName.DISCHARGE_CONFIRMED);
  }, []);
  const {
    name,
    admissionDate,
    dischargeDate,
    lengthOfStayInDays,
    reasonForDischarge,
    comments,
  } = state as DischargeConfirmedState;

  useBackButtonHijacker(() => {
    navigate(ROUTE.HOME, { replace: true });
  });

  return (
    <Grid style={{ gridAutoFlow: 'dense' }}>
      <Col
        col={{
          [GRID_BREAKPOINT.small]: '1 / 5',
          [GRID_BREAKPOINT.medium]: '3 / 7',
          [GRID_BREAKPOINT.large]: '2 / 6',
        }}
        order={1}
      >
        <Stack id="summary" space="s8">
          <HeaderWrapper>
            <SubHeader color="indigo08">Discharge confirmed</SubHeader>
            <Paragraph textAlign="center">
              Your patient was successfully discharged. Find your discharge
              summary below.
            </Paragraph>
          </HeaderWrapper>
          <DischargeSummary
            backgroundColor={colors.base.white}
            name={name}
            admissionDate={admissionDate}
            dischargeDate={dischargeDate}
            lengthOfStayInDays={lengthOfStayInDays}
            reasonForDischarge={reasonForDischarge}
            comments={comments}
          />
          <Link to={ROUTE.HOME}>
            <Button id="view-patient-list">View patient list</Button>
          </Link>
        </Stack>
      </Col>
      <Col
        col={{
          [GRID_BREAKPOINT.small]: '1 / 5',
          [GRID_BREAKPOINT.medium]: '3 / 7',
          [GRID_BREAKPOINT.large]: '8 / 12',
        }}
        display="flex"
        justifyContent="center"
      >
        <Stack
          id="hero-wrapper"
          flex={1}
          space="none"
          justifyContent="center"
          flexDirection="column"
        >
          <DischargeConfirmedSVG />
        </Stack>
      </Col>
    </Grid>
  );
};
