import { format, getHours, getMinutes, parse } from 'date-fns';
import { DateFilter, type SeriesDataValue } from '../types';
import { ObservationType } from 'services/graphql';
import { ObservationTypeMap } from 'transforms/observationTypeSearchTransform';
import { formatNumber } from 'utils/number.utils';

export const getTooltipDate = (data: any, dateFilter: DateFilter) => {
  if (dateFilter === DateFilter.Monthly) {
    const monthlyDate = parse(data.date, 'yyyy-MM-dd', new Date());
    return format(monthlyDate, 'MMM dd/yy');
  }

  const returnedHours = getHours(new Date(data.date));
  const returnedMinutes = getMinutes(new Date(data.date));
  const hours = returnedHours < 10 ? `0${returnedHours}` : returnedHours;
  const minutes =
    returnedMinutes < 10 ? `0${returnedMinutes}` : returnedMinutes;

  return `${hours}:${minutes}`;
};

export const getTooltipValue = (
  data: SeriesDataValue,
  observationType: ObservationType
) => {
  if (observationType === ObservationType.BloodPressure)
    return `${formatNumber(data.series[0], 0)}/${formatNumber(
      data.series[1],
      0
    )}`;
  return `${formatNumber(data.series[0])} ${
    ObservationTypeMap[observationType]?.unit
  }`;
};
