import { getFormYesNoValue, getFormValue } from './utils';
import { EMPTY_VALUE } from 'App.constants';
import { RecommendationStatusChip } from 'components/HypertensionAssessmentForm/RecommendationStatusChip';
import type { HypertensionAssessmentFormData } from 'components/HypertensionAssessmentForm/types';
import {
  AssessmentTypeMap,
  ConsultationTimeframeMap,
  GuidelineMap,
  YesNoMap,
} from 'mappings/enums';
import {
  TestType,
  YesNoType,
  MedicationOutcome,
  GuidelineDeclinedReason,
  ConsultationTimeframe,
} from 'services/graphql';
import type { ContentMap } from 'types';
import { parseDateString } from 'utils/date.utils';
import { isEmptyOrNil, propEmptyOr, valueEmptyOrNil } from 'utils/object.utils';
import {
  bloodPressureFormatter,
  bloodPressureReadingsFormatter,
} from 'utils/string.utils';

export const assessmentConfirmationMapping = (
  formData: HypertensionAssessmentFormData,
  patient?: string
) => [
  { name: 'Patient', value: valueEmptyOrNil(patient) },
  { name: 'Condition assessed', value: 'Hypertension' }, // Default value is currently only Hypertension
  {
    name: 'Type of assessment',
    value: AssessmentTypeMap[formData.assessmentType.value],
  },
  {
    name: 'Date of review',
    value: parseDateString(formData.assessmentStartDate as string),
  },
  {
    name: 'Average BP during review',
    value: bloodPressureFormatter(
      formData.averageDiastolic,
      formData.averageSystolic
    ),
  },
  {
    name: 'Average based on',
    value: bloodPressureReadingsFormatter(
      formData.totalReadings,
      formData.totalDays
    ),
  },
  {
    name: 'Tests required',
    value: getFormYesNoValue(formData.testsRequiredYN),
  },
  {
    name: 'Tests requested',
    value: isEmptyOrNil(formData.conditionTestTypes)
      ? EMPTY_VALUE
      : formData
          .conditionTestTypes!.map((item) =>
            item.value === TestType.Other
              ? formData.conditionTestTypeOther
              : item.label
          )
          .join(', '),
  },
  {
    name: 'Urgent Clinical Intervention',
    value: getFormYesNoValue(formData.urgentClinicalInterventionRequiredYN),
  },
  {
    name: 'Urgent Clinical Intervention done',
    value: getFormYesNoValue(formData.urgentClinicalInterventionTakenPlaceYN),
  },
  {
    name: 'Reason why',
    value: getFormValue(formData.reasonForNoUrgentClinicalIntervention),
  },
  {
    name: 'Consultation required',
    value: getFormYesNoValue(formData.consultationRequiredYN),
  },
  {
    name: 'Consultation to take place',
    value: () => {
      const value = propEmptyOr('value', formData.consultationTimeframe);
      return value === ConsultationTimeframe.Other
        ? formData.customConsultationTimeframe!
        : ConsultationTimeframeMap[value as ConsultationTimeframe] ??
            EMPTY_VALUE;
    },
  },
  {
    name: 'QRisk3',
    value: isEmptyOrNil(formData.qriskScore)
      ? EMPTY_VALUE
      : `${formData.qriskScore}%`,
  },
  {
    name: 'Comments',
    value: getFormValue(formData.assessmentComments),
  },
  {
    name: 'Is the patient taking BP medication',
    value: getFormYesNoValue(formData.isTakingBloodPressureMedicationYN),
  },
  {
    name: 'Medication protocol',
    value: propEmptyOr('label', formData.medicationProtocol),
  },
  {
    name: 'Has taken BP medication in the past',
    value: isEmptyOrNil(formData.hasEverTakenBloodPressureMedicationYN)
      ? EMPTY_VALUE
      : YesNoMap[formData.hasEverTakenBloodPressureMedicationYN as YesNoType],
  },
];

export const getReasonForDeclining = (
  formData: HypertensionAssessmentFormData,
  idx: number
) => {
  if (
    isEmptyOrNil(formData.guidelineRecommendationDecisions) ||
    isEmptyOrNil(
      formData.guidelineRecommendationDecisions?.[idx]
        ?.recommendationDeclinedReason
    )
  ) {
    return EMPTY_VALUE;
  }
  return formData.guidelineRecommendationDecisions![idx]!
    .recommendationDeclinedReason!.value === GuidelineDeclinedReason.Other
    ? formData.guidelineRecommendationDecisions![idx]!
        .otherRecommendationDeclinedReasonComments!
    : formData.guidelineRecommendationDecisions![idx]!
        .recommendationDeclinedReason!.label;
};

export const carePlanConfirmationMapping = (
  formData: HypertensionAssessmentFormData,
  idx = 0
) => {
  let reasonForDeclining: ContentMap | undefined;

  if (
    formData.guidelineRecommendationDecisions?.[idx]?.guidelineAccepted ===
    YesNoType.No
  ) {
    reasonForDeclining = {
      name: 'Reason for declining',
      value: () => getReasonForDeclining(formData, idx),
    };
  }
  const carePlanningDetails = [
    {
      name: 'Medication recommendation',
      value:
        isEmptyOrNil(formData.guidelineRecommendationDecisions) ||
        isEmptyOrNil(
          formData.guidelineRecommendationDecisions?.[idx]?.guidelineRecommended
        )
          ? EMPTY_VALUE
          : GuidelineMap[
              formData.guidelineRecommendationDecisions![idx]!
                .guidelineRecommended
            ],
    },
    {
      name: 'Recommendation was',
      value:
        isEmptyOrNil(formData.guidelineRecommendationDecisions) ||
        isEmptyOrNil(
          formData.guidelineRecommendationDecisions?.[idx]?.guidelineAccepted
        ) ? (
          EMPTY_VALUE
        ) : (
          <RecommendationStatusChip
            guidelineAccepted={
              formData.guidelineRecommendationDecisions![idx]!
                .guidelineAccepted!
            }
          />
        ),
    },
    { ...reasonForDeclining },
    {
      name: 'Medication outcome',
      value: () => {
        if (isEmptyOrNil(formData.medicationOutcome)) {
          return EMPTY_VALUE;
        }
        return formData.medicationOutcome!.value === MedicationOutcome.Stopped
          ? formData.stoppedMedicationOutcomeComments!
          : formData.medicationOutcome!.label;
      },
    },
    {
      name: 'New medication protocol',
      value: isEmptyOrNil(formData.newMedicationProtocol)
        ? EMPTY_VALUE
        : formData.newMedicationProtocol!.label,
    },
    {
      name: 'Comments',
      value: isEmptyOrNil(formData.comments) ? EMPTY_VALUE : formData.comments!,
    },
  ];

  return carePlanningDetails.filter(
    (item) => item.name && item.value
  ) as ContentMap[];
};
