import * as yup from 'yup';
import type { TypedOptionType } from '@bt-healthcare/ui-toolkit';
import { REQUIRED_TEXT } from 'components/Form/validationHelper';
import { isNo, isYes } from 'components/Form/conditionalRules';
import type { ConditionParams } from 'components/Form/types';

export const isValidOption = (
  arg: TypedOptionType<string> | string | TypedOptionType<string>[],
  value: string
) => {
  if (!arg) return false;
  if (value.includes('|')) {
    const args = value.split('|');
    if (typeof arg === 'string') return args.includes(arg);
    if (Array.isArray(arg))
      return arg.every((item) => args.includes(item.value));
    return args.includes(arg.value);
  }
  if (typeof arg === 'string') return arg === value;
  if (Array.isArray(arg)) return arg.some((item) => item.value === value);
  return arg.value === value;
};

export const isRequired = (
  isNullable = false,
  yupSchema: any = yup.string(),
  message = REQUIRED_TEXT
) =>
  isNullable
    ? yupSchema.nullable().required(message)
    : yupSchema.required(message);

export const isRequiredNullable = (
  yupSchema = yup.string(),
  message?: string
) => isRequired(true, yupSchema, message);

export const isWithinNumberRange = (max = 1000000, min = 1) =>
  yup
    .number()
    .integer()
    .typeError(REQUIRED_TEXT)
    .min(min, `Value entered must be greater than ${min}.`)
    .max(max, `Value entered must be less than ${max}.`);

export const isRequiredWhenYes = ({
  field,
  fn = isRequired,
  yupSchema = yup.string(),
}: ConditionParams) =>
  yupSchema.nullable().when(field, {
    is: (arg: string) => isYes(arg),
    then: fn(),
  });

export const isRequiredWhenNo = ({
  field,
  fn = isRequired,
  yupSchema = yup.string(),
}: ConditionParams) =>
  yupSchema.nullable().when(field, {
    is: (arg: string) => isNo(arg),
    then: fn(),
  });

export const isRequiredDropDownListOption = () =>
  yup
    .object()
    .shape({
      label: yup.string().required(),
      value: yup.string().required(),
    })
    .default(null)
    .nullable()
    .required(REQUIRED_TEXT);

export const isRequiredDropDownListMultiOption = () =>
  yup
    .array()
    .required(REQUIRED_TEXT)
    .min(1, 'Please select at least 1 item.')
    .of(isRequiredDropDownListOption());

export const isRequiredWhenSelection = ({
  field,
  value,
  fn = isRequired,
  yupSchema = yup.string(),
}: ConditionParams) =>
  yupSchema.nullable().when(field, {
    is: (arg: any) =>
      value == null ? arg === '' || arg == null : isValidOption(arg, value),
    then: fn(),
  });
