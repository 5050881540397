import {
  AccordionItem,
  Text,
  colors,
  fontSizes,
} from '@bt-healthcare/ui-toolkit';
import type { RecommendationAccordionItemProps } from './types';
import { AccordionItemWrapper } from './styles';
import { getRecommendationList } from './util';
import { RecommendationAccordionItemContent } from './RecommendationAccordionItemContent';

export const RecommendationAccordionItem = ({
  recommendations,
  medicationAssessment,
}: RecommendationAccordionItemProps) => {
  const defaultRecommendationDetails = getRecommendationList(
    null,
    medicationAssessment
  );
  return (
    <AccordionItemWrapper>
      <AccordionItem
        title={
          <Text color={colors.secondaryBlue.blue09} fontSize={fontSizes.base}>
            Hypertension care plan
          </Text>
        }
        id="assessment-recommendation"
        variant="secondary"
      >
        {recommendations != null && recommendations.length > 0 ? (
          recommendations.map((recommendation) => {
            const recommendationDetails = getRecommendationList(
              recommendation,
              medicationAssessment
            );
            return (
              <RecommendationAccordionItemContent
                recommendationDetails={recommendationDetails}
                key={recommendation.guidelineRecommended}
              />
            );
          })
        ) : (
          <RecommendationAccordionItemContent
            recommendationDetails={defaultRecommendationDetails}
          />
        )}
      </AccordionItem>
    </AccordionItemWrapper>
  );
};
