import {
  Text,
  colors,
  Spacer,
  fontSizes,
  spacing,
} from '@bt-healthcare/ui-toolkit';
import Hero from './assets/empty.svg?react';
import { Wrapper, TextWrapper } from './styles';

export const PatientListEmptyView = () => (
  <Wrapper data-testid="patient-list-empty-view">
    <Hero
      width="100%"
      preserveAspectRatio="xMidYMid meet"
      style={{
        minHeight: '0',
        minWidth: '0',
        maxWidth: '396px',
        margin: `${spacing.s5} 0`,
      }}
    />
    <Spacer size="s8" />
    <TextWrapper>
      <Text color={colors.grey.grey08} fontWeight={400} fontSize={fontSizes.h4}>
        No patients have been admitted to this care setting. To admit a patient
        use the Admission tab above and follow the admission process.
      </Text>
    </TextWrapper>
  </Wrapper>
);
