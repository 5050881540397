import { omit } from 'ramda';
import type { PatientAdmissionPersonalFormData } from './types';

export const mapFormDataOnSubmit = (
  data: PatientAdmissionPersonalFormData
): PatientAdmissionPersonalFormData =>
  omit(['dateOfBirth'], {
    ...data,
    postcode: data.postcode.toUpperCase(),
  });
