import { useRef, useEffect } from 'react';
import type { TypedOptionType } from '@bt-healthcare/ui-toolkit';

import type { HypertensionConsultationRecommendedFormData } from 'components/HypertensionAssessmentForm/types';
import { validateRecommendationFieldValues } from 'pages/Assessment/utils';
import { AssessmentType } from 'services/graphql';
import { isEmptyOrNil } from 'utils/object.utils';

export const usePreviousHypertensionConsultationRecommendation = (
  assessmentType: TypedOptionType<AssessmentType>,
  averageDiastolic: number,
  averageSystolic: number,
  totalDays: number,
  totalReadings: number,
  callback: () => void
) => {
  const previousRecommendationRef = useRef<
    HypertensionConsultationRecommendedFormData | undefined
  >(undefined);
  useEffect(() => {
    if (
      validateRecommendationFieldValues(
        averageDiastolic,
        averageSystolic,
        totalDays,
        totalReadings
      ) &&
      assessmentType?.value === AssessmentType.Consultation
    ) {
      if (
        !isEmptyOrNil(previousRecommendationRef.current) &&
        (previousRecommendationRef.current!.averageDiastolic !==
          averageDiastolic ||
          previousRecommendationRef.current!.averageSystolic !==
            averageSystolic ||
          previousRecommendationRef.current!.totalDays !== totalDays ||
          previousRecommendationRef.current!.totalReadings !== totalReadings)
      ) {
        callback();
      } else {
        previousRecommendationRef.current = {
          averageDiastolic,
          averageSystolic,
          totalDays,
          totalReadings,
        };
      }
    }
  }, [totalDays, averageDiastolic, averageSystolic, totalReadings]);

  return previousRecommendationRef;
};
