import {
  Wrapper,
  Text,
  colors,
  fontSizes,
  BTHealthIcon,
  Divider,
} from '@bt-healthcare/ui-toolkit';
import {
  FilterItemWrapper,
  TextWrapper,
  DividerWrapper,
  FilterPanel,
  FilterPanelTitleWrapper,
  FilterWrapper,
} from './styles';
import type { FilterItemProps } from './types';
import { MedicalCentreFilters } from 'components/PatientListFilter/Filters/MedicalCentreFilters';

export const MedicalCentreFilterItem = ({
  statusFilter,
  handleTargetChecked,
  panelOpen,
  handlePanelOpen,
}: FilterItemProps) => (
  <Wrapper data-testid="medical-centre-filter-item">
    <FilterItemWrapper
      onClick={() => handlePanelOpen(true)}
      data-testid="open-panel"
    >
      <TextWrapper>
        <Text color={colors.grey.grey10} fontSize={fontSizes.base}>
          Medical Centre
        </Text>
        <BTHealthIcon
          icon="ChevronRight"
          size={14}
          color={colors.primaryIndigo.indigo08}
        />
      </TextWrapper>

      {statusFilter.length > 0 && (
        <Text color={colors.grey.grey08} fontSize={fontSizes.xs}>
          {statusFilter
            .filter((item) => item.type === 'MedicalCentre')
            .map((option) => option.value)
            .join(', ')}
        </Text>
      )}

      <DividerWrapper>
        <Divider flexColor="grey.grey02" />
      </DividerWrapper>
    </FilterItemWrapper>

    <FilterPanel panelOpen={panelOpen.MedicalCentre}>
      <FilterPanelTitleWrapper>
        <Wrapper onClick={() => handlePanelOpen(false)} data-testid="back">
          <BTHealthIcon icon="BackArrow" size={16} />
        </Wrapper>
        <Text
          color={colors.grey.grey10}
          fontSize={fontSizes.sm}
          fontWeight={500}
        >
          MEDICAL CENTRE
        </Text>
      </FilterPanelTitleWrapper>

      <FilterWrapper>
        <MedicalCentreFilters
          statusFilter={statusFilter}
          handleTargetChecked={handleTargetChecked}
        />
      </FilterWrapper>
    </FilterPanel>
  </Wrapper>
);
