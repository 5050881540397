import { colors, fontSizes } from '@bt-healthcare/ui-toolkit';

import type { FieldProps } from './AdmissionConfirmation.types';
import { Text } from './AdmissionConfirmation.styles';

export const Field = ({
  children,
  'data-testid': dataTestId,
  fieldType = 'key',
}: FieldProps) => (
  <Text
    color={colors.grey.grey10}
    fontWeight={fieldType === 'key' ? 500 : 400}
    fontSize={fontSizes.base}
    data-testid={dataTestId}
  >
    {children}
  </Text>
);
