import { SearchBar } from '@bt-healthcare/ui-toolkit';
import debounce from 'lodash.debounce';
import type { ChangeEvent } from 'react';
import { useEffect, useRef } from 'react';
import type { ClientSearchProps } from './types';

export const ClientSearch = <T,>({
  data,
  setSearchFilter,
  searchFilter,
  placeholder = 'Search',
  variant = 'primary',
}: ClientSearchProps<T>) => {
  const searchValue = useRef('');
  const handleSearchFilter = (search: string) => {
    if (search === '') {
      searchValue.current = '';
      setSearchFilter(data);
    } else {
      searchValue.current = search;
      const filter = searchFilter(data, search);
      setSearchFilter(filter);
    }
  };

  useEffect(() => {
    handleSearchFilter(searchValue.current);
  }, [data]);

  const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const { value } = target;
    handleSearchFilter(value);
  };

  const debouncedHandleChange = debounce((evt) => handleChange(evt), 1000);

  return (
    <SearchBar
      id="search"
      placeholder={placeholder}
      onChange={debouncedHandleChange}
      variant={variant}
    />
  );
};
