import { sort } from 'ramda';

import type { WardPatientComments } from 'services/graphql';

export const sortWardPatientComments = (arr: WardPatientComments[]) =>
  sort(
    (a, b) =>
      +new Date(b.attributes.recordedDateTime) -
      +new Date(a.attributes.recordedDateTime),
    arr
  );
