import { useEffect } from 'react';
import {
  Text,
  colors,
  fontSizes,
  useScrollToTop,
} from '@bt-healthcare/ui-toolkit';
import { useSearchParams } from 'react-router-dom';

import { Step } from './types';
import { FormWrapper } from './styles';
import { StartAdmissionFormStep } from './StartAdmissionFormStep';
import { enumFromStringValue } from 'mappings/enums';
import { useTracking } from 'hooks/useTracking';
import { PageName } from 'config/pageNames';
import { HeaderCard } from 'components/Card/HeaderCard';
import { useAdmissionForm } from 'context/admission/AdmissionContext';

export const StartAdmissionForm = () => {
  useScrollToTop();
  const { trackPage } = useTracking();
  const [searchParams] = useSearchParams();
  const { isStandalone } = useAdmissionForm();
  const step: Step =
    enumFromStringValue(Step, searchParams.get('step') || Step.FORM) ||
    Step.CONFIRM;

  const isStepConfirm = step === Step.CONFIRM;

  useEffect(() => {
    if (isStepConfirm) {
      return trackPage(`${PageName.START_ADMISSION} - Confirm`);
    }

    return trackPage(PageName.START_ADMISSION);
  }, [step]);

  const getStepDescription = () => {
    if (isStepConfirm) {
      if (!isStandalone) {
        return `These details are associated with a patient already admitted to this remote care service.`;
      }

      return `These details are associated with an existing HealthyYou account. Review
      the patient's details are correct and click 'step up to remote care' in
      order to invite the patient to join the remote monitoring service.`;
    }

    return 'Complete the fields below to start the patient admission process.';
  };

  const getStepTitle = () => {
    if (isStepConfirm && !isStandalone) {
      return 'Patient already exists';
    }

    if (isStepConfirm && isStandalone) {
      return 'A HealthyYou account already exists';
    }

    return 'Patient admission';
  };

  return (
    <FormWrapper>
      <HeaderCard
        id="start-patient-admission-card"
        data-testid="start-patient-admission-card"
      >
        <Text
          color={colors.primaryIndigo.indigo08}
          as="h4"
          fontWeight={500}
          fontSize={fontSizes.h4}
        >
          {getStepTitle()}
        </Text>
        <Text
          color={isStandalone ? colors.grey.grey10 : colors.grey.grey08}
          as="div"
          fontSize={fontSizes.base}
          style={{ marginTop: '10px', marginBottom: '25px' }}
        >
          {getStepDescription()}
        </Text>
        <StartAdmissionFormStep step={step} />
      </HeaderCard>
    </FormWrapper>
  );
};
