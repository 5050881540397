import { toHyphenCase } from '@bt-healthcare/ui-toolkit';

import type { RecommendationAccordionItemContentProps } from './types';
import {
  PatientDetailsWrapper,
  PatientDetail,
  LabelText,
  ValueText,
} from 'pages/Patient/styles';
import { isFunctionType } from 'utils/object.utils';

export const RecommendationAccordionItemContent = ({
  recommendationDetails,
}: RecommendationAccordionItemContentProps) => (
  <PatientDetailsWrapper>
    {recommendationDetails.map((detail: any) => (
      <PatientDetail
        key={detail.label}
        data-testid={toHyphenCase(detail.label)}
      >
        <LabelText>{detail.label}:</LabelText>
        <ValueText>
          {isFunctionType(detail.value) ? detail.value() : detail.value}
        </ValueText>
      </PatientDetail>
    ))}
  </PatientDetailsWrapper>
);
