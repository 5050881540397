import type { HypertensionStatusItems, HypertensionStatusType } from './types';
import type { HypertensionAssessmentFormData } from 'components/HypertensionAssessmentForm/types';
import type { GetWardPatientStatusesQuery } from 'services/graphql';
import {
  MonitoredConditionConsultationStatusType,
  MonitoredConditionTestStatusType,
  MonitoringStatusType,
  PatientDiagnosis,
  YesNoType,
} from 'services/graphql';
import { isEnumType } from 'utils/object.utils';
import {
  ConsultationStatusTypeColorMap,
  MonitoringStatusTypeColorMap,
  TestStatusTypeColorMap,
} from 'pages/PatientList/PatientList.util';
import {
  ConsultationStatusMap,
  MonitoringStatusMap,
  TestStatusMap,
} from 'mappings/enums';

export const getAssessmentConsultationStatus = (
  formData: HypertensionAssessmentFormData
) => {
  if (formData.consultationRequiredYN === YesNoType.No)
    return MonitoredConditionConsultationStatusType.NotRequired;
  return MonitoredConditionConsultationStatusType.Required;
};

export const getAssessmentTestStatus = (
  formData: HypertensionAssessmentFormData
) => {
  if (formData.testsRequiredYN === YesNoType.No)
    return MonitoredConditionTestStatusType.NotRequired;
  return MonitoredConditionTestStatusType.Required;
};
export const getAssessmentMonitoringStatus = (
  formData: HypertensionAssessmentFormData
) => {
  const {
    fitToSelfMonitorYN,
    urgentClinicalInterventionRequiredYN,
    urgentClinicalInterventionTakenPlaceYN,
  } = formData;

  if (fitToSelfMonitorYN === YesNoType.No) return MonitoringStatusType.Reviewed;
  if (fitToSelfMonitorYN === YesNoType.Yes)
    return MonitoringStatusType.SelfMonitoring;

  if (
    urgentClinicalInterventionRequiredYN === YesNoType.Yes &&
    (urgentClinicalInterventionTakenPlaceYN === YesNoType.No ||
      urgentClinicalInterventionTakenPlaceYN == null)
  )
    return MonitoringStatusType.UrgentClinicalInterventionRequired;

  return MonitoringStatusType.Reviewed;
};

export const getHypertensionStatusValue = (
  value: HypertensionStatusType,
  statusOption: 'colour' | 'status'
) => {
  if (isEnumType(MonitoringStatusType, value)) {
    return statusOption === 'colour'
      ? MonitoringStatusTypeColorMap[value as MonitoringStatusType]
      : MonitoringStatusMap[value as MonitoringStatusType];
  }

  if (isEnumType(MonitoredConditionConsultationStatusType, value)) {
    return statusOption === 'colour'
      ? ConsultationStatusTypeColorMap[
          value as MonitoredConditionConsultationStatusType
        ]
      : ConsultationStatusMap[
          value as MonitoredConditionConsultationStatusType
        ];
  }

  return statusOption === 'colour'
    ? TestStatusTypeColorMap[value as MonitoredConditionTestStatusType]
    : TestStatusMap[value as MonitoredConditionTestStatusType];
};

export const getAssessmentStatuses = (
  formData: HypertensionAssessmentFormData,
  data?: GetWardPatientStatusesQuery
) => {
  const statuses = data?.wardPatient?.attributes.monitoredConditions?.find(
    (item) => item.condition === PatientDiagnosis.Hypertension
  );
  if (!statuses || statuses.monitoringStatus?.status == null) return [];
  const assessmentStatuses: HypertensionStatusItems[] = [
    {
      label: 'Condition',
      status: getAssessmentMonitoringStatus(formData),
    },
  ];
  if (formData.consultationRequiredYN != null) {
    assessmentStatuses.push({
      label: 'Consultation',
      status: getAssessmentConsultationStatus(formData),
    });
  }

  if (formData.testsRequiredYN != null) {
    assessmentStatuses.push({
      label: 'Tests',
      status: getAssessmentTestStatus(formData),
    });
  }

  return assessmentStatuses;
};
