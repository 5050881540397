import { createSearchParams, useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import {
  Text,
  colors,
  fontSizes,
  Card,
  SpinnerLoader,
  ErrorModal,
  Grid,
  Col,
  Row,
} from '@bt-healthcare/ui-toolkit';

import { PatientAdmittedModal } from '../Modal/PatientAdmitted';
import {
  AdmissionConfirmationGrid,
  List,
} from './AdmissionConfirmation.styles';
import {
  medicalMapping,
  personalMapping,
} from './AdmissionConfirmation.mapping';
import { useAdmission } from './useAdmission';
import { ListRow } from './ListRow';
import { getAdmissionFormErrorConfig } from './error.config';
import { Step } from 'pages/PatientAdmission/types';
import { ROUTE } from 'config/routes';
import { useBackButtonHijacker } from 'hooks/useBackButtonHijacker';
import { userAlreadyExistsError } from 'services/errors/client';
import { ErrorCode } from 'services/graphql';
import { FormFooter } from 'components/FormFooter';
import { CancelAdmissionModal } from 'components/Modal/CancelAdmission';
import { usePatient, usePatientDispatch } from 'context/patient/PatientContext';
import {
  useAdmissionForm,
  useAdmissionFormDispatch,
} from 'context/admission/AdmissionContext';
import { useAdmissionTracking } from 'hooks/useAdmissionTracking';

export const AdmissionConfirmation = () => {
  const {
    admissionFormData: { personal, medical },
  } = usePatient();
  const patientDispatch = usePatientDispatch();
  const personIdRef = useRef<string | undefined>();
  const navigate = useNavigate();
  const { isStandalone } = useAdmissionForm();
  const admissionDispatch = useAdmissionFormDispatch();

  useEffect(() => {
    if (personIdRef.current == null) {
      personIdRef.current = uuidv4();
    }
  }, []);

  const [admitPatient, { loading, data, error }] = useAdmission(
    personIdRef.current!,
    personal,
    medical
  );
  useEffect(() => {
    if (!personal && !data) {
      navigate({
        pathname: ROUTE.ADMISSION,
        search: createSearchParams({ step: Step.PERSONAL }).toString(),
      });
    }
  }, [personal]);

  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [errorType, setErrorType] = useState<ErrorCode | undefined>();
  const { trackAdmissionType } = useAdmissionTracking();

  const errorConfig = getAdmissionFormErrorConfig(errorType);

  useEffect(() => {
    if (error) {
      if (userAlreadyExistsError(error.graphQLErrors)) {
        setErrorType(ErrorCode.UserAlreadyExists);
      } else {
        setErrorType(ErrorCode.Generic);
      }
      setErrorModalOpen(true);
    }
  }, [error]);

  useBackButtonHijacker(() => {
    if (showSuccessDialog) {
      navigate(ROUTE.HOME, { replace: true });
    }
  });

  useEffect(() => {
    if (!loading && data) {
      trackAdmissionType(isStandalone ? 'Patient stepped up' : 'New patient');
      setShowSuccessDialog(true);
    }
  }, [data, loading, setShowSuccessDialog, isStandalone]);
  return (
    <Grid>
      {loading && (
        <SpinnerLoader
          id="loader"
          data-testid="loader"
          asModal
          text="Admitting patient"
        />
      )}
      <Row>
        <Col from={1}>
          <Text
            color={colors.grey.grey09}
            fontWeight={400}
            fontSize={fontSizes.base}
          >
            Please review all the information submited below and confirm you are
            about to admit this patient to a virtual ward and send them an
            invite to HealthyYou.
          </Text>
        </Col>
      </Row>
      <Row>
        <Col from={1}>
          <Card id="admission-summary">
            <AdmissionConfirmationGrid>
              <List>
                {personal &&
                  personalMapping(personal).map(({ name, value }) => (
                    <ListRow
                      name={name}
                      value={value}
                      key={`personal-${name}`}
                    />
                  ))}
              </List>
              <List>
                {medical &&
                  medicalMapping(medical).map(({ name, value }) => (
                    <ListRow
                      name={name}
                      value={value}
                      key={`medical-${name}`}
                    />
                  ))}
              </List>
            </AdmissionConfirmationGrid>
          </Card>
        </Col>
      </Row>
      <FormFooter
        primaryButtonText="Confirm and send invite"
        primaryButtonClick={() => {
          if (!personal || !medical) {
            navigate(ROUTE.ADMISSION);
            return;
          }
          admitPatient();
        }}
        cancelButtonClick={() => setCancelModalOpen(true)}
      />
      <CancelAdmissionModal
        isModalOpen={cancelModalOpen}
        handleClose={() => setCancelModalOpen(false)}
        handleProceed={() => {
          setCancelModalOpen(false);
          patientDispatch({ type: 'resetPatientAdmissionFormData' });
          navigate(ROUTE.HOME);
        }}
      />
      <PatientAdmittedModal
        modalOpen={showSuccessDialog}
        handleClose={() => {
          setShowSuccessDialog(false);
          patientDispatch({ type: 'resetPatientAdmissionFormData' });
          admissionDispatch({ type: 'resetAll' });
          navigate(ROUTE.HOME);
        }}
        isStandalone={isStandalone}
      />
      <ErrorModal
        isModalOpen={errorModalOpen}
        secondaryButtonText="Cancel"
        onSecondaryAction={() => {
          setErrorModalOpen(false);
          setErrorType(undefined);
        }}
        onPrimaryAction={() => {
          setErrorModalOpen(false);
          if (errorType === ErrorCode.Generic) admitPatient();
          if (errorType === ErrorCode.UserAlreadyExists) {
            navigate(ROUTE.ADMISSION);
          }
          setErrorType(undefined);
        }}
        primaryButtonText="Try again"
        title={errorConfig?.errorTitle ?? ''}
      >
        {errorConfig?.errorMessage} If the problem persists, please contact us
        for assistance.
      </ErrorModal>
    </Grid>
  );
};
