export const modifiers = [
  {
    name: 'flip',
    options: {
      rootBoundary: 'viewport',
      flipVariations: false,
      fallbackPlacements: 'bottom',
    },
  },
  {
    name: 'offset',
    options: {
      offset: [-178, -25],
    },
  },
];
