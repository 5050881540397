import { Skeleton, Spacer, Stack, Wrapper } from '@bt-healthcare/ui-toolkit';
import { SkeletonWrapper } from './SkeletonWrapper';

export const AvgBloodPressureSkeleton = () => (
  <Wrapper>
    <Stack space="s4" id="avg-bp-stack-header" includeContainer>
      <Skeleton
        id="avg-bp-heading-skeleton"
        data-testid="avg-bp-heading-skeleton"
        variant="text"
        fontSize="sm"
        width="50%"
        rounded
      />
      <Spacer size="s1" />
    </Stack>
    <Stack space="s4" id="avg-bp-stack-content">
      <SkeletonWrapper count={2}>
        <Skeleton
          id="avg-bp-readings-skeleton"
          data-testid="avg-bp-readings-skeleton"
          variant="text"
          fontSize="base"
          rounded
        />
      </SkeletonWrapper>
    </Stack>
    <Stack space="s6" id="avg-bp-stack-header" includeContainer>
      <Skeleton
        id="avg-bp-note-skeleton"
        data-testid="avg-bp-skeleton"
        variant="text"
        fontSize="sm"
        width="80%"
        rounded
      />
    </Stack>
  </Wrapper>
);
