import {
  Accordion,
  Card,
  ErrorNotification,
  SubHeader,
} from '@bt-healthcare/ui-toolkit';
import { SubHeaderWrapper } from './styles';
import type { PatientHistoricAssessmentsProps } from './types';
import {
  sortWardPatientConditionAssessments,
  patientConditionAssessmentList,
} from './util';
import { PatientAssessmentDetailsAccordionItem } from './PatientAssessmentDetailsAccordionItem';
import { NoHistoricReviews } from 'components/NoHistoricReviews';
import type {
  WardPatientConditionAssessment,
  WardPatientConditionAssessmentAttribute,
} from 'services/graphql';
import { useGetWardPatientConditionAssessmentsQuery } from 'services/graphql';
import { FETCH_POLICY } from 'App.constants';
import { isEmptyOrNil } from 'utils/object.utils';
import { useApp } from 'context/app/AppContext';
import { PatientTabWrapper } from 'pages/Patient/styles';
import { PatientViewSkeleton } from 'components/Skeletons/PatientViewSkeleton';

export const PatientHistoricAssessments = ({
  wardPatientId,
  startAssessment,
  canStartAssessment,
}: PatientHistoricAssessmentsProps) => {
  const { careSetting } = useApp();

  const { data, loading, error, refetch } =
    useGetWardPatientConditionAssessmentsQuery({
      variables: {
        wardPatientId,
        careSettingId: careSetting.id!,
      },
      fetchPolicy: FETCH_POLICY.CACHE_AND_NETWORK,
      notifyOnNetworkStatusChange: true,
    });

  const hasReviews = !isEmptyOrNil(data?.wardPatientConditionAssessment);

  if (loading)
    return (
      <PatientTabWrapper>
        <Card id="patient-historic-reviews-loading-card">
          <PatientViewSkeleton />
        </Card>
      </PatientTabWrapper>
    );

  if (error)
    return (
      <ErrorNotification
        id="hypertension-assessments-view"
        action="trying to load assessments"
        onTryAgainClick={() => refetch()}
      />
    );

  return (
    <PatientTabWrapper>
      {hasReviews ? (
        <Card id="patient-historic-reviews-card">
          <SubHeaderWrapper>
            <SubHeader>Assessments</SubHeader>
          </SubHeaderWrapper>
          <Accordion id="patient-historic-reviews-accordion">
            {sortWardPatientConditionAssessments(
              data?.wardPatientConditionAssessment as WardPatientConditionAssessment[]
            ).map((assessment) => {
              const details = patientConditionAssessmentList(
                assessment.attributes as WardPatientConditionAssessmentAttribute
              );
              return (
                <PatientAssessmentDetailsAccordionItem
                  assessment={assessment}
                  details={details}
                  key={assessment.id}
                />
              );
            })}
          </Accordion>
        </Card>
      ) : (
        <NoHistoricReviews
          startAssessment={startAssessment}
          canStartAssessment={canStartAssessment}
        />
      )}
    </PatientTabWrapper>
  );
};
