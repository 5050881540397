import { BTHealthIcon, colors } from '@bt-healthcare/ui-toolkit';
import HealthyYouLogo from 'assets/healthyyou-logo.svg?react';
import { useNavigate } from 'react-router-dom';

import { navItems } from '../config';
import {
  LogoWrapper,
  LogoText,
  NavItems,
  NavItem,
  IconWrapper,
  Label,
  FooterNavItems,
} from '../styles';
import { SideMenuItems, ToggleMenu } from './styles';
import type { DesktopContentProps } from './types';
import { MyAccount } from './MyAccount';
import { UserSettingsFeature } from 'features/userSettings/UserSettingsFeature';

export const DesktopContent = ({
  onCollapse,
  sideNavOpen,
  activeRoute,
}: DesktopContentProps) => {
  const onToggleClick = () => {
    onCollapse(!sideNavOpen);
  };

  const navigate = useNavigate();

  return (
    <SideMenuItems data-testid="side-menu-items">
      <LogoWrapper>
        <HealthyYouLogo />
        <LogoText sideNavOpen={sideNavOpen}>HealthyYou</LogoText>
      </LogoWrapper>

      <ToggleMenu
        onClick={onToggleClick}
        onKeyUp={onToggleClick}
        role="button"
        tabIndex={0}
        data-testid="toggle-side-menu-open"
      >
        <BTHealthIcon
          icon={sideNavOpen ? 'ChevronLeft' : 'ChevronRight'}
          color={colors.base.white}
          size={12}
          title={sideNavOpen ? 'Close' : 'Expand'}
        />
      </ToggleMenu>

      <NavItems>
        {navItems.map((navItem) => (
          <NavItem
            sideNavOpen={sideNavOpen}
            key={navItem.id}
            onClick={() => navigate(navItem.link)}
            active={navItem.link === activeRoute}
          >
            <IconWrapper
              sideNavOpen={sideNavOpen}
              active={navItem.link === activeRoute}
            >
              <BTHealthIcon icon={navItem.icon} size={24} />
            </IconWrapper>

            <Label
              sideNavOpen={sideNavOpen}
              active={navItem.link === activeRoute}
              data-testid="side-menu-item-label"
            >
              {navItem.label}
            </Label>
          </NavItem>
        ))}
      </NavItems>

      <FooterNavItems>
        <UserSettingsFeature sideNavOpen={sideNavOpen} />
        <MyAccount sideNavOpen={sideNavOpen} />
      </FooterNavItems>
    </SideMenuItems>
  );
};
