import { ErrorMessage } from '@bt-healthcare/ui-toolkit';
import { Outlet, useNavigate } from 'react-router-dom';

import { useEffect } from 'react';
import { useAuthUserProfile } from 'auth/useAuthUserProfile';
import { hasModuleAccess } from 'modules';
import { ROUTE } from 'config/routes';
import { useLeavePageModal } from 'hooks/useLeavePageModal';

export const ProtectedRoute = () => {
  const { data, error, loading } = useAuthUserProfile();
  const { reset } = useLeavePageModal();
  const navigate = useNavigate();

  useEffect(() => {
    reset();
  }, [navigate]);

  if (loading) {
    return null;
  }
  if (error) {
    return <ErrorMessage />;
  }
  if (!hasModuleAccess(data)) {
    navigate(ROUTE.UNAUTHORISED);
  }
  return <Outlet />;
};
