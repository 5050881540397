import styled from 'styled-components';
import { colors, fontSizes, spacing } from '@bt-healthcare/ui-toolkit';
import { defaultStyles } from '@visx/tooltip';

import type { TooltipTextProps } from './Tooltip.types';

export const tooltipStyles = {
  ...defaultStyles,
  paddingTop: spacing.s2,
  paddingBottom: spacing.s2,
  paddingLeft: spacing.s5,
  paddingRight: spacing.s5,
};

export const TooltipText = styled.span<TooltipTextProps>`
  text-align: center;
  display: block;
  position: relative;
  color: ${({ primary }) =>
    primary ? colors.primaryIndigo.indigo08 : colors.grey.grey08};
  font-weight: ${({ primary }) => (primary ? 500 : 400)};
  font-size: ${({ primary }) => !primary && fontSizes.xs};
  margin-bottom: ${spacing.s1};

  &:last-child {
    margin-bottom: ${spacing.none};
  }
`;
