import { colors, spacing } from '@bt-healthcare/ui-toolkit';
import styled, { css } from 'styled-components';

export const BreadcrumbsWrapper = styled.ol`
  display: flex;
  list-style: none;
  margin: ${spacing.none};
  padding: ${spacing.none};
  gap: ${spacing.none};
  li {
    padding: ${spacing.s2};
  }
`;

const crumbDefaultStyle = css`
  color: ${colors.grey.grey08};
  font-weight: 400;
  cursor: pointer;
  &:hover {
    background: ${colors.grey.grey03};
    border-radius: 8px;
    transition: background 0.2s ease-in;
  }
`;

const crumbLastStyle = css`
  color: ${colors.base.dark};
  font-weight: 500;
`;

export const Crumb = styled.li<{ lastCrumb: boolean }>`
  ${({ lastCrumb }) => (lastCrumb ? crumbLastStyle : crumbDefaultStyle)};
`;
