import { toHyphenCase } from '@bt-healthcare/ui-toolkit';
import { PatientOverviewDetail, PatientOverviewDetailsWrapper } from './styles';
import { patientInformationUi } from './utils';
import type { PatientOverviewDetailsProps } from './types';
import { LabelText, ValueText } from 'pages/Patient/styles';

export const PatientOverviewDetails = ({
  wardPatient,
}: PatientOverviewDetailsProps) => {
  const patientDetailsList = patientInformationUi(wardPatient);

  return (
    <PatientOverviewDetailsWrapper>
      {patientDetailsList.map((item) => (
        <PatientOverviewDetail
          key={item.label}
          data-testid={toHyphenCase(item.label)}
        >
          <LabelText>{item.label}:</LabelText>
          <ValueText>{item.value}</ValueText>
        </PatientOverviewDetail>
      ))}
    </PatientOverviewDetailsWrapper>
  );
};
