import { useEffect, useState } from 'react';
import { Wrapper, Text, colors, Divider } from '@bt-healthcare/ui-toolkit';

import { NoteItem } from '../NoteItem';
import { NoteLandmarkWrapper } from '../Notes.styles';
import { sortWardPatientComments } from '../Notes.utils';
import { ViewAllButton } from './styles';

import type { NoteListProps } from './types';
import type { Maybe, WardPatientComments } from 'services/graphql';

const INITIAL_NOTES_COUNT = 10;

export const NotesList = ({ data, error }: NoteListProps) => {
  const [articlesToShow, setArticlesToShow] =
    useState<number>(INITIAL_NOTES_COUNT);

  const [showViewMoreButton, setShowViewMoreButton] = useState<boolean>(false);

  useEffect(() => {
    if (data && data.wardPatientComments?.length > INITIAL_NOTES_COUNT) {
      setShowViewMoreButton(true);
    }
  }, [data]);

  const handleShowAllNotes = (numOfArticlesToShow: Maybe<number>) => {
    if (numOfArticlesToShow) {
      setArticlesToShow(numOfArticlesToShow);
      setShowViewMoreButton(false);
    }
  };

  return (
    <NoteLandmarkWrapper id="notes-list">
      <Text
        data-testid="title"
        color={colors.grey.grey10}
        fontWeight={500}
        as="h3"
      >
        Previous notes
      </Text>
      <Divider />
      {/* Errors */}
      {error && <h5 data-testid="error-message">Errors: output the errors</h5>}

      {/* Success - with content */}
      {data && data.wardPatientComments.length > 0 && (
        <>
          <Wrapper>
            {sortWardPatientComments(
              data.wardPatientComments as WardPatientComments[]
            )
              .slice(0, articlesToShow)
              .map((comment) => (
                <NoteItem data={comment} key={comment?.id} />
              ))}
          </Wrapper>
          {showViewMoreButton && (
            <ViewAllButton
              id="view-all-button"
              data-testid="view-all-button"
              onClick={() =>
                handleShowAllNotes(data.wardPatientComments.length)
              }
            >
              View all
            </ViewAllButton>
          )}
        </>
      )}

      {/* Success - no content */}
      {data && data.wardPatientComments.length === 0 && (
        <Wrapper data-testid="no-comments-message">
          You currently have no previous notes to display for this patient.
        </Wrapper>
      )}
    </NoteLandmarkWrapper>
  );
};
