import { colors, fontSizes, Text } from '@bt-healthcare/ui-toolkit';

import { TextLink } from './styles';
import { NICE_GUIDELINES_LINK } from 'App.constants';

export const HypertensionAssessmentRecommendationText = () => (
  <Text
    color={colors.grey.grey10}
    fontSize={fontSizes.sm}
    data-testid="recommendation-text"
  >
    Recommendations are based on{' '}
    <TextLink href={NICE_GUIDELINES_LINK} target="_blank">
      NICE guidelines
    </TextLink>{' '}
    and are NOT applicable to those with CKD or women who are pregnant/trying to
    conceive/breastfeeding.
  </Text>
);
