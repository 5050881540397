import type { WardPatientSearchRecord } from 'services/graphql';

const wardPatientSearchTypePolicy = {
  merge(existing: any, incoming: any, { args }: Record<string, any>) {
    const merged = existing?.searchResults?.slice(0) || [];
    if (args.from === 0) return incoming;
    if (incoming) {
      if (args) {
        incoming?.searchResults.forEach(
          (item: WardPatientSearchRecord, idx: number) => {
            merged[args.from + idx] = item;
          }
        );
      }
    }

    return {
      ...incoming,
      searchResults: merged,
    };
  },
  keyArgs: ['ascending', 'wardId', 'search', 'sortKey', 'monitoredConditions'],
};

export const typePolicies = {
  Query: {
    fields: {
      wardPatientsSearch: wardPatientSearchTypePolicy,
    },
  },
};
