import {
  Button,
  ErrorModal,
  Spacer,
  SpinnerLoader,
} from '@bt-healthcare/ui-toolkit';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { formatISO } from 'date-fns';

import type { BaseSyntheticEvent, SyntheticEvent } from 'react';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PatientDetailsFormFields } from './PatientDetailsFormFields';
import { getPatientDetailsFormErrorConfig } from './error.config';
import { mapFormDataOnSubmit } from './mapping';
import { EditCtaWrapper, PatientDetailsFormWrapper } from './style';
import type { PatientDetailsFormData, PatientDetailsFormProps } from './types';
import {
  invalidDateOfBirthResponseHandler,
  patientDetailsSchema,
} from './validation';
import { CancelUpdatingModal } from 'components/Modal/CancelUpdating';
import { PatientUpdatedModal } from 'components/Modal/PatientUpdated';
import { ROUTE } from 'config/routes';
import { useApp } from 'context/app/AppContext';
import { usePatient, usePatientDispatch } from 'context/patient/PatientContext';
import { getWardPatientId, updatePersonInput } from 'selectors/patient';
import {
  CommentTypes,
  ErrorCode,
  useUpdatePersonMutation,
  useUpdateWardPatientAttributeCommentMutation,
} from 'services/graphql';
import { useBackButtonHijacker } from 'hooks/useBackButtonHijacker';
import { validateDateOfBirth } from 'components/PatientAdmissionForm/Personal/validation';
import { useLeavePageModal } from 'hooks/useLeavePageModal';
import { getRefetchQueries } from 'components/Card/MonitoredConditionCard/utils';

export const PatientDetailsForm = ({
  wardPatient,
  toggleEdit,
}: PatientDetailsFormProps) => {
  const { careSetting } = useApp();
  const wardPatientId = getWardPatientId(wardPatient);

  const { patientDetailsFormData } = usePatient();
  const patientDispatch = usePatientDispatch();

  const navigate = useNavigate();

  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [errorType, setErrorType] = useState<ErrorCode | undefined>();

  const errorConfig = getPatientDetailsFormErrorConfig(errorType);

  const [updatePersonMutation, { loading, data, error }] =
    useUpdatePersonMutation({
      onQueryUpdated: () => false,
    });

  const [
    updateWardPatientAttributeCommentMutation,
    { loading: commentLoading, error: commentError },
  ] = useUpdateWardPatientAttributeCommentMutation();

  const isLoading = useMemo(
    () => commentLoading || loading,
    [commentLoading, loading]
  );

  const {
    register,
    control,
    setError,
    clearErrors,
    setFocus,
    formState,
    handleSubmit,
  } = useForm<PatientDetailsFormData>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(patientDetailsSchema),
    defaultValues: patientDetailsFormData,
  });
  const { errors, isDirty, isValid } = formState;
  const { formDirtied, setFormDirtied, reset } = useLeavePageModal();

  useEffect(() => {
    if (isDirty) {
      if (!formDirtied) {
        setFormDirtied();
      }
    }
  }, [isDirty, formDirtied]);

  const onSubmit = async (
    formData: PatientDetailsFormData,
    event?: BaseSyntheticEvent
  ) => {
    event?.preventDefault();
    const validationResponse = validateDateOfBirth(formData);
    if (!validationResponse.isValid) {
      invalidDateOfBirthResponseHandler(
        validationResponse.message!,
        setError,
        setFocus
      );
    } else {
      patientDispatch({
        type: 'setPatientDetailsFormData',
        patientDetailsFormData: mapFormDataOnSubmit(formData),
      });

      if (
        formData.comments !== '--' &&
        formData.comments !== wardPatient.attributes.comments
      ) {
        await updateWardPatientAttributeCommentMutation({
          variables: {
            input: {
              wardPatientId,
              recordedDateTime: formatISO(new Date()),
              comment: `${formData.comments}`,
              commentType: CommentTypes.Other,
            },
          },
        });
      }

      const input = updatePersonInput({
        careSettingId: wardPatient.attributes.careSettingId,
        data: formData,
        registeredGPSurgery: formData.gpSurgery.label,
      });

      await updatePersonMutation({
        variables: {
          input,
          personId: wardPatient.careSettingPatient!.person.id,
        },
        refetchQueries: getRefetchQueries(
          wardPatientId,
          careSetting.id ?? null
        ),
      });
    }
  };

  useEffect(() => {
    if (commentError || error) {
      setErrorType(ErrorCode.Generic);
      setErrorModalOpen(true);
    }
  }, [commentError, error]);

  const onCancel = (event?: SyntheticEvent) => {
    event?.preventDefault();

    if (isDirty) {
      setCancelModalOpen(true);
      return;
    }

    patientDispatch({ type: 'resetPatientDetailsFormData' });
    toggleEdit();
  };

  const handleBackButton = () => {
    setCancelModalOpen(true);
  };

  useBackButtonHijacker(() => {
    if (isDirty && !cancelModalOpen) {
      navigate(ROUTE.PATIENT_DETAIL, {
        state: { wardPatientId: wardPatient.id },
      });
      handleBackButton();
      return;
    }
    if (showSuccessDialog) {
      navigate(ROUTE.HOME, { replace: true });
    }
  });

  window.onpopstate = () => {
    if (isDirty && !cancelModalOpen) {
      navigate(ROUTE.PATIENT_DETAIL, {
        state: { wardPatientId: wardPatient.id },
      });
      handleBackButton();
    }
  };

  useEffect(() => {
    if (!isLoading && data) {
      setShowSuccessDialog(true);
    }
  }, [data, isLoading, setShowSuccessDialog]);

  return (
    <>
      {isLoading && (
        <SpinnerLoader
          id="loader"
          data-testid="loader"
          asModal
          text="Updating patient"
        />
      )}
      <PatientDetailsFormWrapper data-testid="patient-details-form-wrapper">
        <PatientDetailsFormFields
          register={register}
          control={control}
          errors={errors}
          clearErrors={clearErrors}
          generalPractices={careSetting.generalPractices ?? []}
        />
        <Spacer size="s8" />
        <EditCtaWrapper>
          <Button
            id="edit-save-changes-btn"
            onClick={handleSubmit(onSubmit)}
            disabled={!isDirty || !isValid}
            data-testid="edit-save-changes-btn"
          >
            Save Changes
          </Button>
          <Button variant="secondary" id="edit-cancel-btn" onClick={onCancel}>
            Cancel
          </Button>
        </EditCtaWrapper>
      </PatientDetailsFormWrapper>

      <CancelUpdatingModal
        isModalOpen={cancelModalOpen}
        handleClose={() => setCancelModalOpen(false)}
        handleProceed={() => {
          setCancelModalOpen(false);
          patientDispatch({ type: 'resetPatientDetailsFormData' });
          toggleEdit();
          reset();
        }}
      />
      <PatientUpdatedModal
        isModalOpen={showSuccessDialog}
        handleClose={() => {
          setShowSuccessDialog(false);
          patientDispatch({ type: 'resetPatientDetailsFormData' });
          patientDispatch({ type: 'reloadPatient', reload: true });
          toggleEdit();
        }}
        handleBack={() => {
          setShowSuccessDialog(false);
          patientDispatch({ type: 'resetPatientDetailsFormData' });
          patientDispatch({ type: 'reloadPatient', reload: true });
          navigate(ROUTE.HOME);
        }}
      />
      <ErrorModal
        isModalOpen={errorModalOpen}
        secondaryButtonText="Cancel"
        onSecondaryAction={() => {
          setErrorModalOpen(false);
          setErrorType(undefined);
        }}
        onPrimaryAction={() => {
          setErrorModalOpen(false);
          setErrorType(undefined);
        }}
        primaryButtonText="Try again"
        title={errorConfig?.errorTitle ?? ''}
      >
        {errorConfig?.errorMessage} If the problem persists, please contact us
        for assistance.
      </ErrorModal>
    </>
  );
};
