import { Button } from '@bt-healthcare/ui-toolkit';

import type { MenuActionButtonProps } from './types';

export const MenuActionButton = ({
  id,
  value,
  onClick,
}: MenuActionButtonProps) => (
  <Button id={id} variant="secondary" onClick={onClick} transparent>
    +&nbsp;{value}
  </Button>
);
