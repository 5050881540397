import { Col, GRID_BREAKPOINT } from '@bt-healthcare/ui-toolkit';
import type { HypertensionAssessmentNotificationProps } from '../types';
import { StatusNotification } from './StatusNotification';
import { ConditionalFieldRenderer } from 'components/Form/ConditionalFieldRenderer';
import { isYes, isNo } from 'components/Form/conditionalRules';
import { MonitoringStatusMap } from 'mappings/enums';
import { MonitoringStatusType } from 'services/graphql';

export const UrgentClinicalInterventionRequiredNotification = ({
  control,
  formData,
  currentStatus,
}: HypertensionAssessmentNotificationProps) => (
  <>
    <ConditionalFieldRenderer
      control={control}
      fieldName="urgentClinicalInterventionRequiredYN"
      condition={(arg) => isYes(arg)}
      defaultValue={formData.urgentClinicalInterventionRequiredYN}
    >
      <Col
        col={{
          [GRID_BREAKPOINT.large]: '1 / 14',
        }}
      >
        <ConditionalFieldRenderer
          control={control}
          fieldName="urgentClinicalInterventionTakenPlaceYN"
          condition={(arg) => isYes(arg)}
          defaultValue={formData.urgentClinicalInterventionTakenPlaceYN}
        >
          <StatusNotification
            icon="AutoRenew"
            statusIntro="Condition status"
            currentStatus={MonitoringStatusMap[
              currentStatus as MonitoringStatusType
            ].toLowerCase()}
            newStatus="reviewed"
          />
        </ConditionalFieldRenderer>
        <ConditionalFieldRenderer
          control={control}
          fieldName="urgentClinicalInterventionTakenPlaceYN"
          condition={(arg) => isNo(arg)}
          defaultValue={formData.urgentClinicalInterventionTakenPlaceYN}
        >
          <StatusNotification
            icon="AutoRenew"
            statusIntro="Condition status"
            currentStatus={MonitoringStatusMap[
              currentStatus as MonitoringStatusType
            ].toLowerCase()}
            newStatus="urgent clinical intervention"
          />
        </ConditionalFieldRenderer>
      </Col>
    </ConditionalFieldRenderer>
    <ConditionalFieldRenderer
      control={control}
      fieldName="urgentClinicalInterventionRequiredYN"
      condition={(arg) =>
        isNo(arg) && currentStatus === MonitoringStatusType.Reviewed
      }
      defaultValue={formData.urgentClinicalInterventionRequiredYN}
    >
      <Col
        col={{
          [GRID_BREAKPOINT.large]: '1 / 14',
        }}
      >
        <StatusNotification
          icon="AutoRenew"
          statusIntro="Condition status"
          currentStatus={MonitoringStatusMap[
            currentStatus as MonitoringStatusType
          ].toLowerCase()}
          newStatus="reviewed"
        />
      </Col>
    </ConditionalFieldRenderer>
  </>
);
