import { useApp, useAppDispatch } from 'context/app/AppContext';

export const useLeavePageModal = () => {
  const { formDirtied, showLeavePageModal } = useApp();
  const appDispatch = useAppDispatch();

  const setShowLeavePageModal = (value: boolean = true) => {
    appDispatch({
      type: 'setShowLeavePageModal',
      showLeavePageModal: value,
    });
  };

  const setFormDirtied = (value: boolean = true) => {
    appDispatch({
      type: 'setFormDirtied',
      formDirtied: value,
    });
  };

  const setOnCloseHandler = (value: () => void) => {
    appDispatch({
      type: 'setOnCloseHandler',
      onCloseHandler: value,
    });
  };

  const reset = () => {
    appDispatch({
      type: 'resetFormDirtied',
    });
    appDispatch({
      type: 'resetShowLeavePageModal',
    });
  };

  const checkCanLeavePage = (closeHandler?: () => void) => {
    if (formDirtied) {
      setShowLeavePageModal();

      if (closeHandler) {
        setOnCloseHandler(() => {
          closeHandler();
        });
      }
    } else {
      closeHandler?.();
    }
  };

  const closeModal = () => {
    setShowLeavePageModal(false);
  };

  return {
    formDirtied,
    setFormDirtied,
    showLeavePageModal,
    checkCanLeavePage,
    reset,
    closeModal,
    setOnCloseHandler,
  };
};
