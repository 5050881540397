import { toHyphenCase } from '@bt-healthcare/ui-toolkit';
import { ButtonFilterWrapper, StyledButtonFilter } from './styles';
import type { ButtonFiltersProps } from './types';

export const ButtonFilters = ({
  filterState,
  filterType,
  onFilterClick,
}: ButtonFiltersProps) => {
  const filterItems = filterState[filterType];
  return (
    <ButtonFilterWrapper
      data-testid={`${toHyphenCase(filterType)}-button-filters`}
    >
      {filterItems.map((filterItem) => (
        <StyledButtonFilter
          selected={filterItem.active}
          key={filterItem.filter}
          data-testid={`${toHyphenCase(filterItem.filter)}-button-filter`}
          role="button"
          onClick={() =>
            onFilterClick(filterType, filterItem.filter, !filterItem.active)
          }
        >
          {filterItem.label}
        </StyledButtonFilter>
      ))}
    </ButtonFilterWrapper>
  );
};
