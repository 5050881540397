import { Col, GRID_BREAKPOINT, Notification } from '@bt-healthcare/ui-toolkit';
import type { HypertensionAssessmentNotificationProps } from '../types';
import { toBold } from '../utils';
import { ConditionalFieldRenderer } from 'components/Form/ConditionalFieldRenderer';
import { isYes, isNo } from 'components/Form/conditionalRules';
import { TestStatusMap } from 'mappings/enums';
import { MonitoredConditionTestStatusType } from 'services/graphql';

export const TestsRequiredNotification = ({
  control,
  formData,
  currentStatus,
}: HypertensionAssessmentNotificationProps) => (
  <>
    <ConditionalFieldRenderer
      control={control}
      fieldName="testsRequiredYN"
      condition={(arg) => isYes(arg)}
      defaultValue={formData.testsRequiredYN}
    >
      <Col
        col={{
          [GRID_BREAKPOINT.large]: '1 / 14',
        }}
      >
        <Notification
          icon="Syringe"
          type="informative"
          data-testid="condition-status-required-notification"
        >
          {currentStatus &&
          currentStatus !== MonitoredConditionTestStatusType.Required ? (
            <>
              The patient’s {toBold('test status')} will be updated from{' '}
              {toBold(
                TestStatusMap[
                  currentStatus as MonitoredConditionTestStatusType
                ].toLowerCase()
              )}{' '}
              to {toBold('required.')}
            </>
          ) : (
            <>
              The patient’s {toBold('test status')} will be updated to{' '}
              {toBold('required.')}
            </>
          )}
        </Notification>
      </Col>
    </ConditionalFieldRenderer>
    <ConditionalFieldRenderer
      control={control}
      fieldName="testsRequiredYN"
      condition={(arg) => isNo(arg)}
      defaultValue={formData.testsRequiredYN}
    >
      <Col
        col={{
          [GRID_BREAKPOINT.large]: '1 / 14',
        }}
      >
        <Notification
          icon="Syringe"
          type="informative"
          data-testid="condition-status-not-required-notification"
        >
          {currentStatus &&
          currentStatus !== MonitoredConditionTestStatusType.NotRequired ? (
            <>
              The patient’s {toBold('test status')} will be updated from{' '}
              {toBold(
                TestStatusMap[
                  currentStatus as MonitoredConditionTestStatusType
                ].toLowerCase()
              )}{' '}
              to {toBold('not required.')}
            </>
          ) : (
            <>
              The patient’s {toBold('test status')} will be updated to{' '}
              {toBold('not required.')}
            </>
          )}
        </Notification>
      </Col>
    </ConditionalFieldRenderer>
  </>
);
