import { StatusChip, Wrapper, toHyphenCase } from '@bt-healthcare/ui-toolkit';
import { Fragment } from 'react';

import { MonitoredConditionRendererWrapper } from '../PatientList.styles';
import { TestStatusTypeColorMap } from '../PatientList.util';
import type { TestStatusRendererProps } from '../PatientList.types';
import { TestStatusMap } from 'mappings/enums';
import { EMPTY_VALUE } from 'App.constants';

export const TestStatusRenderer = ({ items, id }: TestStatusRendererProps) => (
  <MonitoredConditionRendererWrapper>
    {items.map((item) =>
      item.statusType == null ? (
        <Fragment key={item.condition}>{EMPTY_VALUE}</Fragment>
      ) : (
        <Wrapper key={item.condition}>
          <StatusChip
            id={`${id}-${toHyphenCase(item.condition)}-tests`}
            chipColor={TestStatusTypeColorMap[item.statusType]}
          >
            {TestStatusMap[item.statusType]}
          </StatusChip>
        </Wrapper>
      )
    )}
  </MonitoredConditionRendererWrapper>
);
