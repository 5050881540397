import {
  BTHealthIcon,
  Button,
  Text,
  Wrapper,
  colors,
  fontSizes,
} from '@bt-healthcare/ui-toolkit';
import { useState } from 'react';
import { isEmpty } from 'ramda';

import {
  FilterMenuOverlay,
  FilterMenuWrapper,
  FilterMenuTitleWrapper,
  FilterMenuButtonWrapper,
} from './styles';
import type { FilterMenuProps } from './types';
import { initPanelState } from './utils';
import { ConsultationStatusFilterItem } from './FilterItem/ConsultationStatusFilterItem';
import { TestStatusFilterItem } from './FilterItem/TestStatusFilterItem';
import { MedicalCentreFilterItem } from './FilterItem/MedicalCentreFilterItem';

export const FilterMenu = ({
  menuOpen,
  setMenuOpen,
  handleMenuClose,
  statusFilter,
  handleTargetChecked,
  handleFilterStatusSelect,
  handleClear,
}: FilterMenuProps) => {
  const [panelOpen, setPanelOpen] = useState(initPanelState);

  const numOfFilters = statusFilter.length;

  const handleClose = () => {
    setPanelOpen(initPanelState);
    setMenuOpen(false);
    handleMenuClose();
  };

  const handleApply = () => {
    handleFilterStatusSelect(statusFilter);
    setPanelOpen(initPanelState);
  };

  const handlePanelOpen = (item: any) => {
    const panelState = { ...panelOpen, ...item };
    setPanelOpen(panelState);
  };

  return (
    <>
      <FilterMenuOverlay menuOpen={menuOpen} />
      <FilterMenuWrapper menuOpen={menuOpen}>
        <FilterMenuTitleWrapper>
          <Text
            color={colors.grey.grey10}
            fontSize={fontSizes.sm}
            fontWeight={500}
          >
            FILTER BY
          </Text>
          <Wrapper onClick={handleClose} data-testid="close-button">
            <BTHealthIcon icon="Close" size={16} />
          </Wrapper>
        </FilterMenuTitleWrapper>

        <ConsultationStatusFilterItem
          statusFilter={statusFilter}
          handleTargetChecked={handleTargetChecked}
          panelOpen={panelOpen}
          handlePanelOpen={(item: boolean) =>
            handlePanelOpen({ ConsultationStatus: item })
          }
        />
        <TestStatusFilterItem
          statusFilter={statusFilter}
          handleTargetChecked={handleTargetChecked}
          panelOpen={panelOpen}
          handlePanelOpen={(item: boolean) =>
            handlePanelOpen({ TestStatus: item })
          }
        />
        <MedicalCentreFilterItem
          statusFilter={statusFilter}
          handleTargetChecked={handleTargetChecked}
          panelOpen={panelOpen}
          handlePanelOpen={(item: boolean) =>
            handlePanelOpen({ MedicalCentre: item })
          }
        />
        <FilterMenuButtonWrapper>
          <Button id="apply-filter" onClick={handleApply}>
            Apply
            {numOfFilters > 0 && <> ({numOfFilters})</>}
          </Button>
          <Button
            id="clear-filter"
            onClick={handleClear}
            variant="secondary"
            disabled={isEmpty(statusFilter)}
          >
            Clear selection
          </Button>
        </FilterMenuButtonWrapper>
      </FilterMenuWrapper>
    </>
  );
};
