import {
  Col,
  Input,
  DateInput,
  InputMessage,
  GRID_BREAKPOINT,
} from '@bt-healthcare/ui-toolkit';
import type { ChangeEvent } from 'react';
import { useRef } from 'react';
import { Grid } from './styles';
import type {
  StartAdmissionFormData,
  StartAdmissionlValidationErrors,
  StartAdmissionFormFieldsProps,
} from './types';

const validationMessage = (
  errors: StartAdmissionlValidationErrors,
  name: keyof StartAdmissionFormData
) => ({
  errorText: errors[name]?.message?.toString(),
});

export const StartAdmissionFormFields = ({
  register,
  errors,
  clearErrors,
}: StartAdmissionFormFieldsProps) => {
  const dateInputRef = useRef<any>();
  const { ref: dayRef, ...dayRest } = register('dayOfBirth');
  const { ref: monthRef, ...monthRest } = register('monthOfBirth');
  const { ref: yearRef, ...yearRest } = register('yearOfBirth');

  dateInputRef.current = { dayRef, monthRef, yearRef };
  const inputParams = {
    dayInput: {
      id: 'dayOfBirth',
      'data-testid': 'day-of-birth',
      'aria-label': 'Day of birth',
      ...dayRest,
      ...validationMessage(errors, 'dayOfBirth'),
      onChange: (e: ChangeEvent) => {
        clearErrors('dateOfBirth');
        dayRest.onChange(e);
      },
    },
    monthInput: {
      id: 'monthOfBirth',
      'data-testid': 'month-of-birth',
      'aria-label': 'Month of birth',
      ...monthRest,
      ...validationMessage(errors, 'monthOfBirth'),
      onChange: (e: ChangeEvent) => {
        clearErrors('dateOfBirth');
        monthRest.onChange(e);
      },
    },
    yearInput: {
      id: 'yearOfBirth',
      'data-testid': 'year-of-birth',
      'aria-label': 'year of birth',
      ...yearRest,
      ...validationMessage(errors, 'yearOfBirth'),
      onChange: (e: ChangeEvent) => {
        clearErrors('dateOfBirth');
        yearRest.onChange(e);
      },
    },
  };

  return (
    <Grid>
      <Col
        col={{
          [GRID_BREAKPOINT.small]: '1 / 9',
          [GRID_BREAKPOINT.large]: '1 / 5',
        }}
      >
        <Input
          id="firstName"
          label="Patient first name"
          maxLength={50}
          {...register('firstName')}
          {...validationMessage(errors, 'firstName')}
        />
      </Col>
      <Col
        col={{
          [GRID_BREAKPOINT.small]: '1 / 9',
          [GRID_BREAKPOINT.large]: '5 / 9',
        }}
      >
        <Input
          id="surname"
          label="Patient surname"
          maxLength={50}
          {...register('surname')}
          {...validationMessage(errors, 'surname')}
        />
      </Col>
      <Col
        col={{
          [GRID_BREAKPOINT.small]: '1 / 9',
          [GRID_BREAKPOINT.large]: '1 / 5',
        }}
      >
        <Input
          id="email"
          label="Email address"
          {...register('email')}
          {...validationMessage(errors, 'email')}
        />
      </Col>
      <Col
        col={{
          [GRID_BREAKPOINT.small]: '1 / 9',
          [GRID_BREAKPOINT.large]: '5 / 9',
        }}
      >
        <DateInput
          inputParams={inputParams}
          ref={dateInputRef}
          id="dateOfBirth"
          label="Date of birth"
        />
        {errors.dateOfBirth && (
          <InputMessage
            id="error-dateOfBirth"
            hasError
            errorText={errors.dateOfBirth.message}
          />
        )}
      </Col>
    </Grid>
  );
};
