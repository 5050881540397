import type { AppActions, AppState } from './types';

import type { CareSetting } from 'types';

export const initAppState = {
  careSetting: {} as CareSetting,
  userId: '',
  formDirtied: false,
  showLeavePageModal: false,
  onCloseHandler: () => {},
};

export const appReducer = (state: AppState, action: AppActions): AppState => {
  switch (action.type) {
    case 'setCareSetting': {
      return { ...state, careSetting: action.careSetting };
    }
    case 'resetCareSetting': {
      return { ...state, careSetting: initAppState.careSetting };
    }
    case 'setUserId': {
      return { ...state, userId: action.userId };
    }
    case 'setFormDirtied': {
      return { ...state, formDirtied: action.formDirtied };
    }
    case 'resetFormDirtied': {
      return { ...state, formDirtied: initAppState.formDirtied };
    }
    case 'setShowLeavePageModal': {
      return { ...state, showLeavePageModal: action.showLeavePageModal };
    }
    case 'resetShowLeavePageModal': {
      return { ...state, showLeavePageModal: initAppState.showLeavePageModal };
    }
    case 'setOnCloseHandler': {
      return { ...state, onCloseHandler: action.onCloseHandler };
    }
    case 'resetOnCloseHandler': {
      return { ...state, onCloseHandler: initAppState.onCloseHandler };
    }
    default: {
      return state;
    }
  }
};
