import type { ApolloError } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';

import { useAuthUserProfile } from 'auth/useAuthUserProfile';
import {
  careSettingPatientAdmissionInput,
  createWardPatientInput,
  patientStepupInput,
} from 'selectors/admission';
import type {
  CareSettingStepUpInvitationMutation,
  Maybe,
} from 'services/graphql';
import {
  useAdmitCareSettingPatientMutation,
  useCareSettingStepUpInvitationMutation,
  useCreateWardPatientMutation,
} from 'services/graphql';
import type {
  PatientAdmissionPersonalFormData,
  PatientAdmissionMedicalFormData,
} from 'components/PatientAdmissionForm/types';
import { useAdmissionForm } from 'context/admission/AdmissionContext';

type Result = [
  () => void,
  {
    loading: boolean;
    data:
      | { wardPatientId: string }
      | CareSettingStepUpInvitationMutation
      | undefined;
    error: ApolloError | undefined;
  }
];

export const useAdmission = (
  personId: string,
  personal?: PatientAdmissionPersonalFormData,
  medical?: PatientAdmissionMedicalFormData
): Result => {
  const [admitTrigger, setAdmitTrigger] = useState<Maybe<Date>>(null);
  const { data: userData, careSetting } = useAuthUserProfile();
  const wardId = userData?.userProfile?.attributes.defaultWardId;
  const careSettingId = userData?.userProfile?.attributes.defaultCareSettingId;
  const userId = userData?.userProfile?.id;
  const { isStandalone } = useAdmissionForm();
  const { person } = useAdmissionForm();

  const [admitCareSettingPatient, { data, loading, error }] =
    useAdmitCareSettingPatientMutation({ errorPolicy: 'all' });
  const careSettingPatientId =
    data?.admitCareSettingPatient?.attributes.careSettingPatient?.data.id;
  const [createWardPatient, createWardPatientResult] =
    useCreateWardPatientMutation();
  const wardPatientId = createWardPatientResult.data?.createWardPatient?.id;
  const [
    admitPatientStepup,
    {
      data: patientStepupData,
      error: patientStepupError,
      loading: patientStepupLoading,
    },
  ] = useCareSettingStepUpInvitationMutation();

  useEffect(() => {
    if (
      !loading &&
      careSettingPatientId &&
      careSettingId &&
      userId &&
      medical &&
      wardId
    ) {
      createWardPatient({
        variables: {
          input: createWardPatientInput({
            careSettingId,
            careSettingPatientId,
            wardId,
            medical,
            personId,
            userId,
          }),
        },
      });
    }
  }, [careSettingPatientId, loading, wardId]);

  useEffect(() => {
    if (!personal || !medical || !wardId || !careSettingId || !admitTrigger) {
      return;
    }

    if (isStandalone) {
      admitPatientStepup({
        variables: {
          input: patientStepupInput({
            careSettingId,
            personal,
            medical,
            personId: person.personId,
            generalPracticesWithId: careSetting?.generalPracticesWithId!,
          }),
        },
      });
    } else {
      admitCareSettingPatient({
        variables: {
          input: careSettingPatientAdmissionInput({
            personal,
            medical,
            wardId,
            careSettingId,
            personId,
          }),
        },
      });
    }

    setAdmitTrigger(null);
  }, [admitTrigger, wardId, careSettingId]);

  const admit = useCallback(() => {
    setAdmitTrigger(new Date());
  }, [setAdmitTrigger]);

  return [
    admit,
    {
      loading:
        loading || createWardPatientResult.loading || patientStepupLoading,
      data:
        patientStepupData ?? (wardPatientId ? { wardPatientId } : undefined),
      error: patientStepupError || createWardPatientResult.error || error,
    },
  ];
};
