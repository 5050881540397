export enum PageName {
  HOME = 'Patient list',
  PATIENT_DETAIL = 'Individual patient record',
  ADMISSION = 'Admission Form',
  START_ADMISSION = 'Patient Admission',
  ASSESSMENT_HYPERTENSION = 'Hypertension assessment',
  ASSESSMENT_HYPERTENSION_CONFIRM = 'Hypertension assessment confirm',
  DISCHARGE = 'Discharge patient',
  DISCHARGE_CONFIRMED = 'Discharge confirmed',
  SETTINGS = 'Settings',
  SETTINGS_USER = 'User settings',
  SETTINGS_USER_ADD = 'Add user',
  SETTINGS_USER_CONFIRMED = 'User update confirmed',
}
