import { device, fontSizes } from '@bt-healthcare/ui-toolkit';
import styled from 'styled-components';

export const PatientDetailWrapper = styled.div`
  span {
    font-size: ${fontSizes.sm};
  }

  @media ${device.largeTablet} {
    span {
      font-size: ${fontSizes.base};
    }
  }
`;
