import {
  BTHealthIcon,
  colors,
  Text,
  useClickOutside,
  Wrapper,
} from '@bt-healthcare/ui-toolkit';
import { type MutableRefObject, useRef, useState, useEffect } from 'react';

import {
  FilterDropdownWrapper,
  DesktopFilterButton,
  MobileFilterButton,
  DropdownWrapper,
  Spacer,
} from './styles';
import { FilterMenu } from './FilterMenu';
import { NumOfFilters } from './NumOfFilters';
import { DropdownMenu } from './DropdownMenu/DropDownMenu';
import type { SubFilter } from 'filters/types';
import { usePatient, usePatientDispatch } from 'context/patient/PatientContext';

export const PatientListFilter = () => {
  const [selected, setSelected] = useState(false);
  const [dropdownMenuOpen, setDropdownMenuOpen] = useState(false);
  const [filterMenuOpen, setFilterMenuOpen] = useState(false);

  const patientDispatch = usePatientDispatch();
  const { filters } = usePatient();
  const [statusFilters, setStatusFilters] = useState(filters.statusFilters);

  useEffect(() => {
    setStatusFilters(filters.statusFilters);
  }, [filters.statusFilters]);

  const openDropdownMenu = () => {
    setSelected(true);
    setDropdownMenuOpen(true);
  };

  const closeDropdownMenu = () => {
    setSelected(false);
    setDropdownMenuOpen(false);
  };

  const handleFilterStatusSelect = (
    statuses: SubFilter[],
    clearAll?: boolean
  ) => {
    if (!clearAll) {
      closeDropdownMenu();
      setFilterMenuOpen(false);
    }
    patientDispatch({ type: 'addStatusFilters', statusFilters: statuses });
  };

  const handleClear = () => {
    setStatusFilters([]);
  };

  const handleFilterMenuClose = () => {
    patientDispatch({
      type: 'addStatusFilters',
      statusFilters: filters.statusFilters,
    });
  };

  const handleDesktopButtonClick = () => {
    if (dropdownMenuOpen) {
      return closeDropdownMenu();
    }

    return openDropdownMenu();
  };

  const handleTargetChecked = (target: any, status: SubFilter) => {
    let updatedFilter: SubFilter[];
    if (target.checked) {
      updatedFilter = [...(statusFilters ?? []), status];
    } else {
      updatedFilter =
        statusFilters.filter((item: any) => item.value !== status.value) ?? [];
    }
    setStatusFilters(updatedFilter);
  };

  const filterRef = useRef() as MutableRefObject<HTMLDivElement>;
  useClickOutside(filterRef, () => {
    closeDropdownMenu();
    patientDispatch({
      type: 'addStatusFilters',
      statusFilters: filters.statusFilters,
    });
  });

  const numOfFilters = statusFilters.length;
  const isActive = filters.statusFilters.length > 0;

  return (
    <Wrapper ref={filterRef}>
      <FilterDropdownWrapper
        data-testid="patient-list-filter"
        selected={selected}
      >
        <DesktopFilterButton
          data-testid="patient-list-filter-desktop-button"
          onClick={handleDesktopButtonClick}
          selected={selected}
          active={isActive}
        >
          <BTHealthIcon
            icon="Filter"
            color={
              selected || isActive
                ? colors.primaryIndigo.indigo08
                : colors.grey.grey06
            }
            size={20}
          />
          <Text
            color={
              selected || isActive
                ? colors.primaryIndigo.indigo08
                : colors.grey.grey09
            }
          >
            Filter
          </Text>
        </DesktopFilterButton>
        <MobileFilterButton
          data-testid="patient-list-filter-mobile-button"
          onClick={() => setFilterMenuOpen(!filterMenuOpen)}
          active={isActive}
        >
          {numOfFilters > 0 ? (
            <NumOfFilters numOfFilters={numOfFilters} />
          ) : (
            <BTHealthIcon icon="Filter" color={colors.grey.grey06} size={20} />
          )}
          <Text
            color={
              selected || isActive
                ? colors.primaryIndigo.indigo08
                : colors.grey.grey09
            }
          >
            Filter
          </Text>
        </MobileFilterButton>

        {dropdownMenuOpen && (
          <DropdownWrapper>
            <Spacer />
            <DropdownMenu
              statusFilter={statusFilters}
              handleFilterStatusSelect={handleFilterStatusSelect}
              clearFilter={handleClear}
              handleTargetChecked={handleTargetChecked}
            />
          </DropdownWrapper>
        )}
      </FilterDropdownWrapper>

      <FilterMenu
        menuOpen={filterMenuOpen}
        setMenuOpen={setFilterMenuOpen}
        handleMenuClose={handleFilterMenuClose}
        statusFilter={statusFilters}
        handleTargetChecked={handleTargetChecked}
        handleFilterStatusSelect={handleFilterStatusSelect}
        handleClear={handleClear}
      />
    </Wrapper>
  );
};
