import {
  Card,
  Accordion,
  AccordionItem,
  Text,
  colors,
  Col,
  GRID_BREAKPOINT,
  Grid,
  Input,
} from '@bt-healthcare/ui-toolkit';

import type { HypertensionAssessmentMedicationOutcomeFormProps } from './types';
import { HypertensionAssessmentRecommendation } from './HypertensionAssessmentRecommendation';
import {
  medicationOutcomeOptions,
  medicationProtocolOptions,
} from 'mappings/enums';
import { RHFDropDown } from 'components/Form/RHFDropDown';
import { ConditionalFieldRenderer } from 'components/Form/ConditionalFieldRenderer';
import { isOtherSelected } from 'components/Form/conditionalRules';
import { usePatient } from 'context/patient/PatientContext';

export const HypertensionAssessmentMedicationOutcomeForm = ({
  register,
  control,
  errors,
  formData,
  hypertensionCarePlanRef,
  setValue,
  resetField,
  isValid,
}: HypertensionAssessmentMedicationOutcomeFormProps) => {
  const { guidelineAcceptance } = usePatient();
  return (
    <Card id="hypertension-assessment-medical-form-card">
      <Accordion id="hypertension-assessment-medical-outcome-form-accordion">
        <AccordionItem
          title={
            <Text color={colors.secondaryBlue.blue10} fontSize="20px">
              Medication outcomes
            </Text>
          }
          id="hypertension-assessment-medical-outcome-accordion-item"
          expanded
        >
          {guidelineAcceptance.map((item, idx) => (
            <HypertensionAssessmentRecommendation
              key={item.guidelineType}
              guidelineAcceptance={item}
              hypertensionCarePlanRef={hypertensionCarePlanRef!}
              control={control}
              setValue={setValue}
              register={register}
              idx={idx}
              errors={errors}
              formData={formData}
              resetField={resetField}
              isValid={isValid}
            />
          ))}
          <Grid>
            <Col
              col={{
                [GRID_BREAKPOINT.large]: '1 / 14',
              }}
            >
              <RHFDropDown
                label="Medication outcomes"
                fieldName="medicationOutcome"
                control={control}
                options={medicationOutcomeOptions}
                defaultValue={formData.medicationOutcome ?? undefined}
              />
            </Col>
            <ConditionalFieldRenderer
              control={control}
              fieldName="medicationOutcome"
              condition={(arg) => isOtherSelected(arg, 'STOPPED')}
              defaultValue={formData.medicationOutcome ?? undefined}
            >
              <Col
                col={{
                  [GRID_BREAKPOINT.large]: '1 / 14',
                }}
              >
                <Input
                  id="stoppedMedicationOutcomeComments"
                  label="Other"
                  {...register('stoppedMedicationOutcomeComments')}
                  markAsRequired
                  errorText={errors?.stoppedMedicationOutcomeComments?.message}
                  defaultValue={
                    formData.stoppedMedicationOutcomeComments ?? undefined
                  }
                />
              </Col>
            </ConditionalFieldRenderer>
            <Col
              col={{
                [GRID_BREAKPOINT.large]: '1 / 14',
              }}
            >
              <RHFDropDown
                label="New medication protocol"
                fieldName="newMedicationProtocol"
                control={control}
                options={medicationProtocolOptions}
                defaultValue={formData.newMedicationProtocol ?? undefined}
              />
            </Col>
            <Col
              col={{
                [GRID_BREAKPOINT.large]: '1 / 14',
              }}
            >
              <Input
                id="medicationOutcomeComments"
                label="Comments"
                {...register('comments')}
                defaultValue={formData.comments ?? undefined}
                maxLength={1000}
              />
            </Col>
          </Grid>
        </AccordionItem>
      </Accordion>
    </Card>
  );
};
