import { ascend, prop, sort } from 'ramda';
import { UserActivationStateMap } from './settings.mappings';
import { moduleIdentifierTransform } from './settings.utils';
import type { UsersType } from './types';
import { UserListSubMenu } from './UserListSubMenu';
import { EMPTY_VALUE } from 'App.constants';

export const columnWidthSizes = {
  desktop: '2fr 2fr 2fr 2fr 2fr 2fr 24px',
  mobile: '2fr 2fr 24px',
};

export const headerData: any = [
  { id: '1', label: 'Name' },
  { id: '2', label: 'Email', hide: 'mobile' },
  { id: '3', label: 'Mobile number', hide: 'mobile' },
  { id: '4', label: 'Access', hide: 'mobile' },
  { id: '5', label: 'Grant permissions', hide: 'mobile' },
  { id: '6', label: 'Status' },
  { id: '7', label: '' },
];

export const rowData: any = (data: UsersType[]) => {
  const sortedData = sort(ascend(prop('firstName')), data);
  return sortedData.map((rowItem) => ({
    id: rowItem.id,
    items: [
      {
        id: '1',
        item: `${rowItem.firstName} ${rowItem.surname}`,
      },
      {
        id: '2',
        item: rowItem.emailAddress ?? EMPTY_VALUE,
        hide: 'mobile',
      },
      {
        id: '3',
        item: rowItem.mobileNumber ?? EMPTY_VALUE,
        hide: 'mobile',
      },
      {
        id: '4',
        item: moduleIdentifierTransform(rowItem.modules) ?? EMPTY_VALUE,
        hide: 'mobile',
      },
      {
        id: '5',
        item: rowItem.careSettingAdministrator ? 'Yes' : 'No',
        hide: 'mobile',
      },
      {
        id: '6',
        item: UserActivationStateMap[rowItem.userActivationState ?? 'NONE'],
      },
      {
        id: '7',
        item: (
          <UserListSubMenu
            userActivationState={rowItem.userActivationState ?? 'NONE'}
            userId={rowItem.id!}
          />
        ),
        overflow: false,
      },
    ],
  }));
};
