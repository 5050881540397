import { colors } from '@bt-healthcare/ui-toolkit';

import { DateFilter, type ObservationChartDateFilter } from '../types';

export const lineChartConfigMap = {
  [DateFilter.Today]: {
    dateFormat: '%Y-%m-%dT%H:%M:%S.%LZ',
    labelFormat: '%H:%M',
  },
  [DateFilter.Week]: {
    dateFormat: '%Y-%m-%dT%H:%M:%S.%LZ',
    labelFormat: '%a %-d %b',
  },
  [DateFilter.Biweekly]: {
    dateFormat: '%Y-%m-%dT%H:%M:%S.%LZ',
    labelFormat: '%a %-d %b',
  },
  [DateFilter.Monthly]: {
    dateFormat: '%Y-%m-%d',
    labelFormat: '%a %-d %b',
  },
};

export const seriesChartConfigMap = [
  {
    line: colors.secondaryBlue.blue03,
    glyph: colors.secondaryBlue.blue06,
  },
  {
    line: colors.primaryIndigo.indigo03,
    glyph: colors.primaryIndigo.indigo08,
  },
];

export const observationChartDateFilters = [
  {
    id: 'today-filter',
    value: DateFilter.Today,
    data: DateFilter.Today,
  },
  {
    id: 'week-filter',
    value: DateFilter.Week,
    data: DateFilter.Week,
  },
  {
    id: 'biweekly-filter',
    value: '2 Weeks',
    data: DateFilter.Biweekly,
  },
  {
    id: 'month-filter',
    value: DateFilter.Monthly,
    data: DateFilter.Monthly,
  },
] as Array<ObservationChartDateFilter>;

export const observationChartDateCustomFilter = {
  id: 'custom-filter',
  value: DateFilter.Custom,
  data: DateFilter.Custom,
} as ObservationChartDateFilter;

export const MARGIN = { top: 10, right: 40, bottom: 50, left: 40 };
export const Y_SCALE_OFFSET = 10;
export const LINE_CHART_HEIGHT = 350;
export const LINE_CHART_WIDTH = 660;
