import type { UseFormSetError, UseFormSetFocus } from 'react-hook-form';
import * as yup from 'yup';

import type { PatientDetailsFormData } from './types';
import {
  DOB_REQUIRED_TEXT,
  REQUIRED_TEXT,
  firstNameRule,
  requiredDropdownRule,
  requiredNumber,
  requiredPhoneNumber,
  requiredYear,
  surnameRule,
} from 'components/Form/validationHelper';

export const INVALID_DATE_MESSAGE = 'Date is invalid.';
export const FUTURE_DATE_MESSAGE = 'Date must be earlier than todays date.';
export const INVALID_PATIENT_AGE =
  'Patient cannot be admitted as they are under 18.';

const NHS_NUMBER_VALIDATION_MESSAGE =
  'Incorrect format. Please enter as a 10 digit number or in the format XXX XXX XXXX.';

const patientDetailsFormRules = {
  firstName: firstNameRule(
    'First name must be between 1-50 characters in length.'
  ),
  surname: surnameRule('Surname must be between 1-50 characters in length.'),
  address: yup.string().required(REQUIRED_TEXT),
  postcode: yup
    .string()
    .matches(
      /^(([A-Z]{1,2}\d[A-Z\d]?|ASCN|STHL|TDCU|BBND|[BFS]IQQ|PCRN|TKCA) ?\d[A-Z]{2}|BFPO ?\d{1,4}|(KY\d|MSR|VG|AI)[ -]?\d{4}|[A-Z]{2} ?\d{2}|GE ?CX|GIR ?0A{2}|SAN ?TA1)$/i, // nosonar
      'Postcode must be alphanumeric and may contain one space. No special characters allowed.'
    )
    .required(REQUIRED_TEXT),
  genderAssignedAtBirth: requiredDropdownRule,
  ethnicity: requiredDropdownRule,
  nhsNumber: yup
    .string()
    .required(REQUIRED_TEXT)
    .matches(/^(?:\d{3} \d{3} \d{4}|\d{10})$/, NHS_NUMBER_VALIDATION_MESSAGE),
  email: yup
    .string()
    .matches(
      /^([a-zA-Z0-9_\-\\.+]+)@([a-zA-Z0-9_\-\\.]+)\.([a-zA-Z]{2,15})$/,
      'Please enter a valid email address.'
    )
    .required(REQUIRED_TEXT),
  phoneNumber: requiredPhoneNumber,
  dayOfBirth: requiredNumber(DOB_REQUIRED_TEXT),
  monthOfBirth: requiredNumber(DOB_REQUIRED_TEXT),
  yearOfBirth: requiredYear(),
  nextOfKinPhoneNumber: yup.string().when(' ', (val) => {
    if (
      val?.nextOfKinPhoneNumber?.length > 0 &&
      val?.nextOfKinPhoneNumber !== '--'
    ) {
      return yup
        .string()
        .required(REQUIRED_TEXT)
        .nullable()
        .matches(
          /^\d*$/,
          'Phone number must be a whole number, no spaces or special characters allowed.'
        )
        .min(10, 'Phone number must be at least 10 digits.')
        .max(15, 'Phone number cannot be more than 15 digits.');
    }
    return yup.string().notRequired().nullable();
  }),
  gpSurgery: requiredDropdownRule,
} as { [key in Partial<keyof PatientDetailsFormData>]: any };

export const patientDetailsSchema = yup.object(patientDetailsFormRules);

export const invalidDateOfBirthResponseHandler = (
  message: string,
  setError: UseFormSetError<PatientDetailsFormData>,
  setFocus: UseFormSetFocus<PatientDetailsFormData>
) => {
  setError('dateOfBirth', {
    type: 'custom',
    message,
  });

  const messageToFieldMap: Record<string, keyof PatientDetailsFormData> = {
    [INVALID_DATE_MESSAGE]: 'dayOfBirth',
    [INVALID_PATIENT_AGE]: 'yearOfBirth',
    default: 'monthOfBirth',
  };

  const formField = messageToFieldMap[message] ?? messageToFieldMap.default;
  setFocus(formField);
};
