import NoHistoricReviewsIllustration from 'assets/no-historic-reviews.svg?react';
import {
  NoHistoricReviewsWrapper,
  TextWrapper,
  Title,
  Text,
  NewAssessmentLink,
  NewAssessmentText,
} from './styles';
import type { NoHistoricReviewsProps } from './types';

export const NoHistoricReviews = ({
  startAssessment,
  canStartAssessment,
}: NoHistoricReviewsProps) => (
  <NoHistoricReviewsWrapper data-testid="no-historic-reviews">
    <TextWrapper>
      <Title>No assessments available.</Title>
      <Text>
        Once an assessment has been completed, the summary will be available to
        view here.{' '}
        {canStartAssessment && (
          <NewAssessmentText>
            To start a new assessment,{' '}
            <NewAssessmentLink
              as="span"
              onClick={startAssessment}
              role="button"
            >
              click here.
            </NewAssessmentLink>
          </NewAssessmentText>
        )}
      </Text>
    </TextWrapper>
    <NoHistoricReviewsIllustration />
  </NoHistoricReviewsWrapper>
);
