import { useEffect, useContext } from 'react';
import { NavigationType, UNSAFE_NavigationContext } from 'react-router-dom';
import type { History, Update } from 'history';

export const useBackButtonHijacker = (callback: (...args: any) => void) => {
  // TODO: This behaviour breaks on later versions of react-route-dom > 6.3.0
  const navigator = useContext(UNSAFE_NavigationContext).navigator as History;
  useEffect(() => {
    const listener = ({ location, action }: Update) => {
      if (action === NavigationType.Pop) {
        callback({ location, action });
      }
    };
    return navigator.listen(listener);
  }, [callback, navigator]);
};
