import type { TypedOptionType } from '@bt-healthcare/ui-toolkit';
import { customOption } from '../options';
import type { DateFilter } from 'components/Visualisations/types';

export const iconMap = {
  Biweekly: 'CalendarBiweekly',
  Month: 'CalendarMonth',
  Today: 'Calendar',
  Week: 'CalendarWeek',
  Custom: 'Calendar',
};

export const getPrevFilterValue = (
  clickedOption: TypedOptionType<DateFilter>,
  prevFilterValue: TypedOptionType<DateFilter>
) => {
  if (clickedOption === customOption) return prevFilterValue;

  return clickedOption;
};
