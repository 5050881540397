import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDebounce } from 'usehooks-ts';

import {
  Button,
  ErrorMessage,
  SearchBar,
  Stack,
  SubHeader,
  Skeleton,
  Grid,
  Row,
  Col,
} from '@bt-healthcare/ui-toolkit';

import { isEmpty } from 'ramda';
import { NetworkStatus } from '@apollo/client';
import { SearchWrapper, FilterWrapper } from './PatientList.styles';
import { PatientListRenderer } from './PatientListRenderer';
import { getFilters } from './PatientList.util';
import type { SortParams } from './PatientList.types';
import * as AppStorage from 'storage';
import { useAuthUserProfile } from 'auth/useAuthUserProfile';
import { useTracking } from 'hooks/useTracking';
import type { WardPatientsSortKey } from 'services/graphql';
import { useGetWardPatientsQuery } from 'services/graphql';
import { ROUTE } from 'config/routes';
import { PatientListEmptyView } from 'components/PatientListEmptyView';
import { FETCH_POLICY, STORAGE_KEYS } from 'App.constants';
import { ButtonFilterGroup } from 'components/ButtonFilterGroup/ButtonFilterGroup';
import { PatientListFilter } from 'components/PatientListFilter';
import { FilterStatusChipGroup } from 'components/FilterStatusChipGroup';
import { PageName } from 'config/pageNames';
import { MenuActionButton } from 'components/MenuActionButton';
import type {
  FilterTypeEnums,
  SubFilter,
  TopLevelFilterType,
} from 'filters/types';
import { updateFilters } from 'filters';
import { usePatient, usePatientDispatch } from 'context/patient/PatientContext';
import { useAdmissionFormDispatch } from 'context/admission/AdmissionContext';

export const PatientList = () => {
  const { data: userProfileData } = useAuthUserProfile();
  const initialPatientCount = useRef<number | undefined>();
  const patientDispatch = usePatientDispatch();
  const admissionDispatch = useAdmissionFormDispatch();
  const { filters } = usePatient();
  const navigate = useNavigate();
  const { trackPage } = useTracking();
  const [sortParams, setSortParams] =
    useState<SortParams<WardPatientsSortKey> | null>(null);

  useEffect(() => {
    trackPage(PageName.HOME);
    const currentSortParams = AppStorage.get<SortParams<WardPatientsSortKey>>(
      STORAGE_KEYS.PATIENT_SORT
    );
    if (currentSortParams != null) {
      setSortParams(currentSortParams);
    }
    patientDispatch({ type: 'resetPatientAdmissionFormData' });
    patientDispatch({ type: 'resetHypertensionAssessmentFormData' });
    patientDispatch({ type: 'resetGuidelineAcceptance' });
    admissionDispatch({ type: 'resetAll' });
  }, []);

  const [searchText, setSearchText] = useState('');

  const debouncedSearchText = useDebounce(searchText, 500);

  const setSortingQuery = (newSortParams: SortParams<WardPatientsSortKey>) => {
    AppStorage.set<SortParams<WardPatientsSortKey>>(
      STORAGE_KEYS.PATIENT_SORT,
      newSortParams
    );
    setSortParams(newSortParams);
  };

  const wardId = userProfileData?.userProfile?.attributes.defaultWardId;

  const handleFilterClick = (
    filterType: TopLevelFilterType,
    filter: FilterTypeEnums,
    active: boolean
  ) => {
    const filterState = updateFilters(filters, filterType, filter, active);
    patientDispatch({ type: 'updateFilters', filterState });
  };

  const handleFilterClear = () => {
    patientDispatch({ type: 'resetFilters' });
  };

  const {
    data,
    loading: wardPatientLoading,
    error: wardPatientError,
    refetch,
    fetchMore,
    networkStatus,
  } = useGetWardPatientsQuery({
    variables: {
      wardId: wardId ?? '',
      ascending: sortParams?.ascending ?? null,
      sortKey: sortParams?.key ?? null,
      from: 0,
      limit: 30,
      search: debouncedSearchText,
      ...getFilters(filters),
    },
    errorPolicy: 'all',
    fetchPolicy: FETCH_POLICY.CACHE_AND_NETWORK,
    skip: !wardId,
    notifyOnNetworkStatusChange: true,
  });

  const loading = wardPatientLoading;

  const error = wardPatientError;

  const totalLength = data?.wardPatientsSearch?.hits;
  const patientListCount = data?.wardPatientsSearch?.searchResults?.length ?? 0;
  const hasMore = patientListCount < (totalLength ?? 0);

  useEffect(() => {
    if (initialPatientCount.current) {
      initialPatientCount.current = totalLength;
    }
  }, [totalLength]);

  const loadNext = useCallback(() => {
    fetchMore({ variables: { from: patientListCount } });
  }, [fetchMore, patientListCount]);

  const handleCloseFilter = (value: SubFilter) => {
    const updatedStatusFilters = filters.statusFilters.filter(
      (status: any) =>
        !(status.value === value.value && status.type === value.type)
    );
    patientDispatch({ type: 'removeStatusFilter', statusFilter: value });
    AppStorage.set(STORAGE_KEYS.PATIENT_FILTER, {
      ...filters,
      statusFilters: updatedStatusFilters,
    });
  };

  if (
    !error &&
    !loading &&
    networkStatus === NetworkStatus.ready &&
    initialPatientCount.current === 0
  ) {
    return <PatientListEmptyView />;
  }

  if (error) {
    return (
      <ErrorMessage
        title="Oops, we’re experiencing an error"
        message="Looks like something went wrong, please go back or use the button below to try again."
      >
        <Stack id="patient-list-error-btns" flexDirection="column" space="s4">
          <Button id="retry" onClick={() => refetch()}>
            Try again
          </Button>
          <Button
            id="back-home"
            onClick={() => navigate(ROUTE.HOME)}
            variant="secondary"
          >
            Back to home
          </Button>
        </Stack>
      </ErrorMessage>
    );
  }

  return (
    <Grid>
      <Row>
        <Col lg={12}>
          <ButtonFilterGroup
            filterState={filters}
            onFilterClick={handleFilterClick}
            onFilterClear={handleFilterClear}
          />
        </Col>
        <Col xs={12} display={{ xs: 'block', lg: 'none' }}>
          <MenuActionButton
            id="admit-patient-mobile-btn"
            onClick={() => navigate(ROUTE.START_ADMISSION)}
            value="Admit patient"
          />
        </Col>
      </Row>
      <Row>
        <Col from={1}>
          <SearchWrapper>
            <SubHeader color="grey10">
              {loading ? (
                <Skeleton
                  id="patients-total-skeleton"
                  data-testid="patients-total-skeleton"
                  variant="text"
                  fontSize="h3"
                  rounded
                />
              ) : (
                <>Patients ({totalLength || '0'})</>
              )}
            </SubHeader>
            <FilterWrapper>
              <SearchBar
                id="patient-list-searchbar"
                onChange={({ target: { value } }) => {
                  setSearchText(value);
                }}
              />
              <PatientListFilter />
            </FilterWrapper>
          </SearchWrapper>
          {!isEmpty(filters.statusFilters) && (
            <FilterStatusChipGroup
              statusFilters={filters.statusFilters}
              onCloseFilter={handleCloseFilter}
            />
          )}
        </Col>
      </Row>
      <Row>
        <Col from={1}>
          <PatientListRenderer
            loading={wardPatientLoading}
            hasMore={hasMore}
            loadNext={loadNext}
            patientListCount={patientListCount}
            data={data}
            sortParams={sortParams}
            setSortingQuery={setSortingQuery}
          />
        </Col>
      </Row>
    </Grid>
  );
};
