import * as yup from 'yup';

export const schema = (minChars: number, maxChars: number) =>
  yup.object().shape({
    notevalue: yup
      .string()
      .min(minChars, 'This is a required field')
      .max(maxChars)
      .required(),
    category: yup.object().nullable().required('Category is a required Field'),
  });
