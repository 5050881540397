import { Col, GRID_BREAKPOINT, Input } from '@bt-healthcare/ui-toolkit';

import type { HypertensionAssessmentDeclineRecommendationProps } from './types';
import { ConditionalFieldRenderer } from 'components/Form/ConditionalFieldRenderer';
import { RHFDropDown } from 'components/Form/RHFDropDown';
import { isOtherSelected } from 'components/Form/conditionalRules';
import { guidelineDeclinedReasonOptions } from 'mappings/enums';

export const HypertensionAssessmentDeclineRecommendation = ({
  control,
  register,
  errors,
  formData,
  idx,
  disabled = false,
}: HypertensionAssessmentDeclineRecommendationProps) => (
  <>
    <Col
      col={{
        [GRID_BREAKPOINT.large]: '1 / 14',
      }}
    >
      <RHFDropDown
        label="Reason for declining medication recommendation"
        fieldName={`guidelineRecommendationDecisions.${idx}.recommendationDeclinedReason`}
        control={control}
        options={guidelineDeclinedReasonOptions}
        markAsRequired
        disabled={disabled}
        errorText={
          errors?.guidelineRecommendationDecisions?.[idx]
            ?.recommendationDeclinedReason?.message
            ? String(
                errors.guidelineRecommendationDecisions[idx]
                  ?.recommendationDeclinedReason?.message
              )
            : undefined
        }
        defaultValue={
          formData.guidelineRecommendationDecisions?.[idx]
            ?.recommendationDeclinedReason ?? undefined
        }
      />
    </Col>
    <ConditionalFieldRenderer
      control={control}
      fieldName={`guidelineRecommendationDecisions.${idx}.recommendationDeclinedReason`}
      condition={(arg) => isOtherSelected(arg)}
      defaultValue={
        formData.guidelineRecommendationDecisions?.[idx]
          ?.recommendationDeclinedReason
      }
    >
      <Col
        col={{
          [GRID_BREAKPOINT.large]: '1 / 14',
        }}
      >
        <Input
          id="otherRecommendationDeclinedReasonComments"
          label="Other"
          {...register(
            `guidelineRecommendationDecisions.${idx}.otherRecommendationDeclinedReasonComments`
          )}
          markAsRequired
          disabled={disabled}
          errorText={
            errors?.guidelineRecommendationDecisions?.[idx]
              ?.otherRecommendationDeclinedReasonComments?.message
          }
          defaultValue={
            formData?.guidelineRecommendationDecisions?.[idx]
              ?.otherRecommendationDeclinedReasonComments ?? undefined
          }
        />
      </Col>
    </ConditionalFieldRenderer>
  </>
);
