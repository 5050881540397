import { colors, spacing } from '@bt-healthcare/ui-toolkit';
import styled from 'styled-components';

export const FilterItemWrapper = styled.div`
  margin-top: ${spacing.s8};
  padding: ${spacing.none} ${spacing.s4} ${spacing.s6} ${spacing.s4};
`;

export const TextWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const DividerWrapper = styled.div`
  margin-top: ${spacing.s6};
`;

export const FilterPanel = styled.div<{ panelOpen: boolean }>`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${colors.base.white};
  z-index: 2;
  padding: ${spacing.s4} ${spacing.s2};
  transform: ${({ panelOpen }) =>
    panelOpen ? `translateX(0)` : `translateX(100%)`};
`;

export const FilterPanelTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: ${spacing.s4};
  gap: ${spacing.s2};
  margin-bottom: ${spacing.s4};

  > div {
    display: flex;
  }
`;

export const FilterWrapper = styled.div`
  padding: ${spacing.none} ${spacing.s4};
`;
