import { Wrapper, device, spacing } from '@bt-healthcare/ui-toolkit';
import styled from 'styled-components';

export const PatientDetailsFormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  padding: ${spacing.s2} ${spacing.none};

  @media ${device.largeTablet} {
    padding: ${spacing.s6} ${spacing.none};
  }

  & input#dayOfBirth,
  input#monthOfBirth,
  input#yearOfBirth {
    text-align: center;
    padding-inline: 12px;
  }

  & textarea#comments {
    min-height: 56px;
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-gap: ${spacing.s4};

  @media ${device.largeTablet} {
    grid-template-columns: repeat(12, 1fr);
  }
`;

export const EditCtaWrapper = styled(Wrapper)`
  display: flex;
  gap: ${spacing.s2};
  max-width: 592px;
`;
