import styled from 'styled-components';
import { colors, spacing, fontSizes } from '@bt-healthcare/ui-toolkit';

export const Wrapper = styled.div`
  border-bottom: 0.5px solid ${colors.grey.grey06};
  padding: ${spacing.s4} 0;
`;

export const TitleContainer = styled.div<{ showPointer: boolean }>`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  cursor: ${({ showPointer }) => (showPointer ? 'pointer' : 'text')};
  pointer-events: ${({ showPointer }) => (showPointer ? 'auto' : 'none')};
`;

export const IconContainer = styled.div<{ rotateIcon: boolean }>`
  transition: transform 0.25s ease-in-out;
  transform: ${({ rotateIcon }) =>
    rotateIcon ? 'rotate(180deg)' : 'rotate(0deg)'};
`;

export const Title = styled.h3`
  color: ${colors.grey.grey10};
  font-size: ${fontSizes.base};
  font-weight: 500;
  line-height: 24px;
  margin-bottom: ${spacing.s2};
`;

export const Body = styled.div`
  color: ${colors.grey.grey10};
  font-size: ${fontSizes.base};
  font-weight: 400;
`;
