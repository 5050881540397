import type { TypedOptionType } from '@bt-healthcare/ui-toolkit';
import { getColorValue, colors } from '@bt-healthcare/ui-toolkit';
import { useState } from 'react';

import { ConsultationStatusOptions } from 'mappings/enums';
import type {
  MonitoredConditionConsultationStatus,
  MonitoredConditionConsultationStatusType,
} from 'services/graphql';
import { useUpdateWardPatientConsultationStatusMutation } from 'services/graphql';
import { ConsultationStatusTypeColorMap } from 'pages/PatientList/PatientList.util';

export const useConsultationStatusUpdate = (
  consultationStatus?: MonitoredConditionConsultationStatus
) => {
  const initConsultationStatus = ConsultationStatusOptions.find(
    (item) => item.value === consultationStatus?.status
  );

  const initConsultationStatusColor = consultationStatus?.status
    ? getColorValue(ConsultationStatusTypeColorMap[consultationStatus.status])
    : colors.grey.grey03;

  const [currentColor, setCurrentColor] = useState(initConsultationStatusColor);

  const [values, setValues] = useState(ConsultationStatusOptions);

  const [current, setCurrent] = useState<
    TypedOptionType<MonitoredConditionConsultationStatusType> | undefined
  >(initConsultationStatus);

  const [updateWardPatientConsultationStatus, { error, loading }] =
    useUpdateWardPatientConsultationStatusMutation();

  return {
    consultationStatusMutation: {
      mutation: updateWardPatientConsultationStatus,
      error,
      loading,
    },
    consultationStatusConfig: {
      current,
      setCurrent,
      options: { values, setValues },
      color: {
        currentColor,
        setCurrentColor,
      },
    },
  };
};
