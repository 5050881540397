import IconCheck from 'assets/icon-check.svg?react';
import IconCross from 'assets/icon-cross.svg?react';

export const returnCheckOrCross = (matched: boolean) => {
  if (matched) {
    return <IconCheck />;
  }

  return <IconCross />;
};
