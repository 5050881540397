import {
  Text,
  colors,
  fontSizes,
  zoneDateTimeToDateTimeConvertor,
} from '@bt-healthcare/ui-toolkit';
import { useState } from 'react';
import { AccordionTitleWrapper } from './styles';
import type { AccordionTitleProps } from './types';
import { AssessmentTypeMap, MonitoredConditionMap } from 'mappings/enums';

export const AccordionTitle = ({ assessment }: AccordionTitleProps) => {
  const [active, setActive] = useState(false);

  return (
    <AccordionTitleWrapper
      onClick={() => setActive(!active)}
      data-testid={`${assessment.id}-title-wrapper`}
    >
      <Text
        color={active ? colors.secondaryBlue.blue09 : colors.grey.grey08}
        fontSize={fontSizes.sm}
      >
        {
          zoneDateTimeToDateTimeConvertor(
            assessment.attributes.assessmentStartDateZoned,
            'dd/MM/yyyy'
          ) as string
        }
      </Text>
      <Text
        color={active ? colors.secondaryBlue.blue09 : colors.grey.grey10}
        fontSize={fontSizes.base}
        fontWeight={500}
      >
        {MonitoredConditionMap[assessment.attributes.monitoredCondition]} -{' '}
        {AssessmentTypeMap[assessment.attributes.assessmentType]}
      </Text>
    </AccordionTitleWrapper>
  );
};
