import { replace, toUpper } from 'ramda';
import { EMPTY_VALUE } from 'App.constants';
import type { FormatterConversionType } from 'types';

export const capitalize = replace(/^./, toUpper);

export const nhsIdFormatter = (
  inputStr?: string | null,
  conversionType: FormatterConversionType = 'format'
) => {
  if (inputStr == null) {
    return conversionType === 'format' ? EMPTY_VALUE : null;
  }
  if (conversionType === 'unformat') {
    return inputStr.replace(/\s/g, '');
  }
  return inputStr.replace(/(\d{3})(\d{3})(\d{4})/, '$1 $2 $3');
};

export const bloodPressureFormatter = (
  diastolic: string | number,
  systolic: string | number
) => `${systolic}/${diastolic}`;

export const bloodPressureReadingsFormatter = (
  totalReadings: string | number,
  totalDays: string | number
) =>
  `${totalReadings} reading${
    +totalReadings > 1 ? 's' : ''
  } over ${totalDays} days`;
