import { device } from '@bt-healthcare/ui-toolkit';
import styled from 'styled-components';

export const Form = styled.form`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  flex: 1;

  & > div:first-child {
    grid-template-columns: initial;

    @media ${device.largeTablet} {
      grid-template-columns: repeat(12, 1fr);
    }
  }
`;
