import { format } from 'date-fns';
import { colors, Text } from '@bt-healthcare/ui-toolkit';

import { DateRange } from './styles';
import type { AvgReadingDateRangeProps } from './types';

export const AvgReadingDateRange = ({
  dateRange,
  isOneDayReading,
  isCustom,
}: AvgReadingDateRangeProps) => (
  <DateRange data-testid="avg-reading-date-range-text">
    {format(new Date(dateRange.startDate), 'eee do MMM')}
    {!isOneDayReading && (
      <>
        {' '}
        -{' '}
        <Text
          color={isCustom ? colors.grey.grey10 : colors.primaryIndigo.indigo08}
          as="span"
        >
          {format(new Date(dateRange.endDate), 'eee do MMM')}
        </Text>
      </>
    )}
  </DateRange>
);
