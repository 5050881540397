import type { PatientDetailsFormData } from './types';
import { EMPTY_OPTION_VALUE, EMPTY_VALUE } from 'App.constants';
import { getContactPartyDetails } from 'components/PatientOverview/utils';
import {
  EthnicityMap,
  GenderAssignedAtBirthMap,
  GenderIdentifiedAsMap,
  PronounsMap,
} from 'mappings/enums';
import type { WardPatient } from 'services/graphql';
import { getDate } from 'utils/date.utils';
import { propEmptyOr } from 'utils/object.utils';
import { nhsIdFormatter } from 'utils/string.utils';

const getOptionType = <T extends string | number | symbol>(
  map: Record<T, string>,
  value?: T | null
) => {
  if (value) {
    const label = map[value];
    return {
      label,
      value,
    };
  }
  return EMPTY_OPTION_VALUE as {
    label: string;
    value: T;
  };
};

export const patientDetailsMapping = (
  wardPatient: WardPatient
): PatientDetailsFormData => {
  const {
    contactInformation,
    personalInformation,
    contactParty,
    registeredGPSurgery,
  } = wardPatient?.careSettingPatient?.person.attributes || {};
  const { comments } = wardPatient?.attributes || {};

  const contactPartDetails = getContactPartyDetails(contactParty);

  const [day, month, year] = personalInformation?.dateOfBirth
    ? getDate(personalInformation.dateOfBirth).split('/')
    : [];

  const patientDetails: PatientDetailsFormData = {
    firstName: propEmptyOr('firstName', personalInformation),
    surname: propEmptyOr('surname', personalInformation),
    dateOfBirth: getDate(personalInformation?.dateOfBirth),
    dayOfBirth: Number(day),
    monthOfBirth: Number(month),
    yearOfBirth: Number(year),
    genderAssignedAtBirth: getOptionType(
      GenderAssignedAtBirthMap,
      personalInformation?.genderAssignedAtBirth
    ),
    genderIdentifiedAs: getOptionType(
      GenderIdentifiedAsMap,
      personalInformation?.genderIdentifiedAs
    ),
    pronouns: getOptionType(PronounsMap, personalInformation?.pronouns),
    ethnicity: getOptionType(EthnicityMap, personalInformation?.ethnicity),
    address: propEmptyOr('line1', personalInformation?.primaryAddress),
    postcode: propEmptyOr('postalCode', personalInformation?.primaryAddress),
    nhsNumber: nhsIdFormatter(personalInformation?.nhsNumber)!,
    email: propEmptyOr('primaryEmailAddress', contactInformation),
    phoneNumber: propEmptyOr('primaryContactNumber', contactInformation),
    nextOfKinFirstName: contactPartDetails[0]?.name || EMPTY_VALUE,
    nextOfKinSurname: contactPartDetails[0]?.surname || EMPTY_VALUE,
    nextOfKinPhoneNumber: contactPartDetails[0]?.phone || EMPTY_VALUE,
    gpSurgery: registeredGPSurgery
      ? { label: registeredGPSurgery, value: registeredGPSurgery }
      : EMPTY_OPTION_VALUE,
    comments: comments || EMPTY_VALUE,
  };

  return patientDetails ?? {};
};
