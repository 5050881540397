import {
  Text,
  Timestamp,
  colors,
  toHyphenCase,
} from '@bt-healthcare/ui-toolkit';

import { ConditionStatusLastUpdatedWrapper } from './styles';
import type { MonitoredConditionStatusLastUpdatedProps } from './types';
import { parseDateTime } from 'utils/date.utils';

export const MonitoredConditionStatusLastUpdated = ({
  statusUpdateDateTime,
  statusUpdatedBy,
  condition,
}: MonitoredConditionStatusLastUpdatedProps) => {
  const statusLastUpdated = parseDateTime(statusUpdateDateTime).extract();
  return (
    <ConditionStatusLastUpdatedWrapper
      data-testid={`${toHyphenCase(condition)}-status-last-updated-wrapper`}
    >
      <Text color={colors.grey.grey10} fontSize="12px">
        Last updated:&nbsp;
      </Text>
      <Timestamp
        date={statusLastUpdated}
        type="dateTimeWithName"
        variant="small"
        name={statusUpdatedBy ?? undefined}
        as="span"
      />
    </ConditionStatusLastUpdatedWrapper>
  );
};
