import { spacing, device } from '@bt-healthcare/ui-toolkit';
import styled from 'styled-components';

export const Container = styled.section`
  display: flex;
  flex-direction: column;
`;

export const SearchWrapper = styled.section`
  display: flex;
  flex-direction: column;
  gap: ${spacing.s4};
  width: inherit;

  @media ${device.largeTablet} {
    flex-direction: row;
    align-items: center;

    h3 {
      white-space: nowrap;
      min-width: 124px;
    }
  }
`;

export const FilterWrapper = styled.div`
  display: flex;
  gap: ${spacing.s4};
  flex-basis: 100%;
`;

export const MonitoredConditionRendererWrapper = styled.div`
  & > div {
    margin-bottom: ${spacing.s4};
    &:last-child {
      margin-bottom: ${spacing.none};
    }
  }
`;

export const MedicalPracticeTextWrapper = styled.span`
  white-space: normal;
`;
