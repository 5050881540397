import type { PatientAdmissionFormStepProps } from './types';
import { Step } from './types';
import { MedicalAdmission } from 'components/PatientAdmissionForm/Medical/MedicalAdmission';
import { PersonalAdmission } from 'components/PatientAdmissionForm/Personal/PersonalAdmission';
import { AdmissionConfirmation } from 'components/PatientAdmissionConfirmation/AdmissionConfirmation';

export const PatientAdmissionFormStep = ({
  step,
}: PatientAdmissionFormStepProps) => {
  switch (step) {
    case Step.CONFIRM:
      return <AdmissionConfirmation />;
    case Step.MEDICAL:
      return <MedicalAdmission />;
    default:
      return <PersonalAdmission />;
  }
};
