import { Skeleton, Wrapper, spacing } from '@bt-healthcare/ui-toolkit';

import { SkeletonWrapper } from './SkeletonWrapper';
import type { SkeletonWrapperProps } from './types';

export const ListViewSkeleton = ({ count = 1 }: SkeletonWrapperProps) => (
  <SkeletonWrapper count={count}>
    <Skeleton
      id="listview-skeleton"
      data-testid="listview-skeleton"
      variant="text"
      fontSize="h1"
      width="100%"
      rounded
    />
    <Wrapper style={{ marginBottom: spacing.s4 }} />
  </SkeletonWrapper>
);
