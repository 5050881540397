import {
  Card,
  StatusChip,
  Text,
  Timestamp,
  colors,
} from '@bt-healthcare/ui-toolkit';
import {
  AdmissionStatusLastUpdatedWrapper,
  AdmissionStatusTitle,
  StatusChipWrapper,
} from './styles';
import type { PatientAdmissionProps } from './types';
import { AdmissionStatusMap } from 'mappings/enums';
import { EMPTY_VALUE } from 'App.constants';
import { AdmissionStatusColorMap } from 'pages/PatientList/PatientList.util';

export const PatientAdmissionCard = ({
  admissionStatus,
  admissionStatusLastUpdated,
}: PatientAdmissionProps) => (
  <Card id="patient-admission-card">
    <AdmissionStatusTitle>Patient status:</AdmissionStatusTitle>
    {admissionStatus == null ? (
      EMPTY_VALUE
    ) : (
      <StatusChipWrapper>
        <StatusChip
          id="patient-admission"
          chipColor={AdmissionStatusColorMap[admissionStatus]}
        >
          {AdmissionStatusMap[admissionStatus]}
        </StatusChip>
      </StatusChipWrapper>
    )}
    {admissionStatusLastUpdated && (
      <AdmissionStatusLastUpdatedWrapper>
        <Text color={colors.grey.grey10} fontSize="12px">
          Last updated:&nbsp;
        </Text>
        <Timestamp
          date={admissionStatusLastUpdated}
          type="dateTimeWithName"
          variant="small"
          as="span"
        />
      </AdmissionStatusLastUpdatedWrapper>
    )}
  </Card>
);
