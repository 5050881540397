import SelectAssessmentIllustration from 'assets/no-historic-reviews.svg?react';
import {
  AssessmentTypeSelectorText,
  AssessmentTypeSelectorTextWrapper,
  AssessmentTypeSelectorTitle,
  AssessmentTypeSelectorWrapper,
} from './styles';

export const AssessmentTypeSelector = () => (
  <AssessmentTypeSelectorWrapper data-testid="select-assessment">
    <AssessmentTypeSelectorTextWrapper>
      <AssessmentTypeSelectorTitle>
        Select type of assessment.
      </AssessmentTypeSelectorTitle>
      <AssessmentTypeSelectorText>
        Please select the type of assessment you’d like to start in order to
        continue. Once selected, you will see the form here.
      </AssessmentTypeSelectorText>
    </AssessmentTypeSelectorTextWrapper>
    <SelectAssessmentIllustration />
  </AssessmentTypeSelectorWrapper>
);
