import { Button, Modal, noop } from '@bt-healthcare/ui-toolkit';

import { useNavigate } from 'react-router-dom';
import type { CancelAssessmentProps } from '../types';
import {
  ButtonWrapper,
  ModalBody,
  ModalStrapline,
  TextWrapper,
} from '../styles';
import { ModalTitle } from '../ModalTitle';
import { ROUTE } from 'config/routes';
import { useAssessmentTracking } from 'hooks/useAssessmentTracking';

export const CancelAssessment = ({
  modalOpen,
  setModalOpen,
  wardPatientId,
}: CancelAssessmentProps) => {
  const navigate = useNavigate();
  const { trackTappedAssessment } = useAssessmentTracking();

  const handleCancelAssessment = () => {
    trackTappedAssessment('assessment/hypertension/cancel_assessment');
    navigate(ROUTE.PATIENT_DETAIL, { state: { wardPatientId } });
  };

  return (
    <Modal onClose={noop} isModalOpen={modalOpen} id="cancel-assessment-modal">
      <ModalBody>
        <TextWrapper>
          <ModalTitle title="Cancel assessment" />
          <ModalStrapline>
            Cancelling the assessment will delete all progress made and any
            information that you have entered so far will be lost.
          </ModalStrapline>
        </TextWrapper>
        <ButtonWrapper>
          <Button
            id="continue-hypertension-assessment"
            onClick={() => setModalOpen(false)}
          >
            No, continue
          </Button>
          <Button
            id="cancel-hypertension-assessment"
            onClick={handleCancelAssessment}
            variant="primaryRed"
          >
            Yes, cancel
          </Button>
        </ButtonWrapper>
      </ModalBody>
    </Modal>
  );
};
