import {
  Card,
  Text,
  Accordion,
  AccordionItem,
  colors,
  Input,
  MultipleChoiceButton,
  Grid,
  Col,
  GRID_BREAKPOINT,
  fontSizes,
  Timestamp,
  disallowExponentialInputs,
} from '@bt-healthcare/ui-toolkit';
import type { HypertensionAssessmentFormProps } from './types';
import { TestsRequiredNotification } from './Notifications/TestsRequiredNotification';
import { ConsultationRequiredNotification } from './Notifications/ConsultationRequiredNotification';
import { UrgentClinicalInterventionRequiredNotification } from './Notifications/UrgentClinicalInterventionRequiredNotification';
import { isSelfMonitoring } from './utils';
import { RecommendationAvailableNotification } from './Notifications/RecommendationAvailableNotification';
import {
  consultationTimeframeOptions,
  testRequiredOptions,
  yesNoOptions,
} from 'mappings/enums';
import {
  ConditionalFieldRenderer,
  ConditionalMultipleFieldRenderer,
} from 'components/Form/ConditionalFieldRenderer';
import {
  isAllNoSelected,
  isNo,
  isOtherSelected,
  isOtherSelectedMulti,
  isYes,
} from 'components/Form/conditionalRules';
import { RHFDropDown } from 'components/Form/RHFDropDown';
import { CheckboxCard } from 'components/Card/CheckboxCard';
import { ConsultationTimeframe, TestType } from 'services/graphql';
import { usePatient } from 'context/patient/PatientContext';

export const HypertensionAssessmentReviewForm = ({
  register,
  control,
  errors,
  handleSelfMonitoringConfirmationChange,
  formData,
  resetField,
  monitoringStatus,
  consultationStatus,
  testStatus,
  hypertensionCarePlanRef,
}: HypertensionAssessmentFormProps) => {
  const { guidelineAcceptance } = usePatient();
  return (
    <Card id="hypertension-assessment-review-form-card">
      <Accordion id="hypertension-assessment-review-form-accordion">
        <AccordionItem
          title={
            <Text color={colors.secondaryBlue.blue10} fontSize="20px">
              Hypertension form
            </Text>
          }
          id="hypertension-assessment-review-accordion-item"
          expanded
        >
          <Grid>
            <Col
              col={{
                [GRID_BREAKPOINT.small]: '1 / 14',
                [GRID_BREAKPOINT.large]: '1 / 7',
              }}
            >
              <Input
                id="averageSystolic"
                label="Average Systolic"
                {...register('averageSystolic', {
                  valueAsNumber: true,
                  deps: ['averageDiastolic'],
                })}
                markAsRequired
                type="number"
                {...disallowExponentialInputs()}
                errorText={errors?.averageSystolic?.message}
                defaultValue={formData.averageSystolic}
              />
            </Col>
            <Col
              col={{
                [GRID_BREAKPOINT.small]: '1 / 14',
                [GRID_BREAKPOINT.large]: '7 / 14',
              }}
            >
              <Input
                id="averageDiastolic"
                label="Average Diastolic"
                {...register('averageDiastolic', {
                  valueAsNumber: true,
                  deps: ['averageSystolic'],
                })}
                markAsRequired
                type="number"
                {...disallowExponentialInputs()}
                errorText={errors?.averageDiastolic?.message}
                defaultValue={formData.averageDiastolic}
              />
            </Col>
            <Col
              col={{
                [GRID_BREAKPOINT.small]: '1 / 14',
                [GRID_BREAKPOINT.large]: '1 / 7',
              }}
            >
              <Input
                id="totalReadings"
                label="Total readings"
                {...register('totalReadings', { valueAsNumber: true })}
                markAsRequired
                type="number"
                {...disallowExponentialInputs()}
                errorText={errors?.totalReadings?.message}
                defaultValue={formData.totalReadings}
              />
            </Col>
            <Col
              col={{
                [GRID_BREAKPOINT.small]: '1 / 14',
                [GRID_BREAKPOINT.large]: '7 / 14',
              }}
            >
              <Input
                id="totalDays"
                label="Total days"
                {...register('totalDays', { valueAsNumber: true })}
                markAsRequired
                type="number"
                {...disallowExponentialInputs()}
                errorText={errors?.totalDays?.message}
                defaultValue={formData.totalDays}
              />
            </Col>
            {guidelineAcceptance.length > 0 && (
              <Col
                col={{
                  [GRID_BREAKPOINT.large]: '1 / 14',
                }}
              >
                <RecommendationAvailableNotification
                  hypertensionCarePlanRef={hypertensionCarePlanRef!}
                />
              </Col>
            )}
            <Col
              col={{
                [GRID_BREAKPOINT.large]: '1 / 14',
              }}
            >
              <MultipleChoiceButton
                label={
                  <>
                    Does the patient require any tests?
                    <br />
                    <Text color={colors.grey.grey08} fontSize={fontSizes.sm}>
                      If yes, please complete the request on ICE.
                    </Text>
                  </>
                }
                options={yesNoOptions}
                {...register('testsRequiredYN', {
                  onChange: () => {
                    resetField('conditionTestTypes', { defaultValue: null });
                    resetField('conditionTestTypeOther', {
                      defaultValue: null,
                    });
                  },
                })}
                border
                defaultValue={formData.testsRequiredYN ?? undefined}
              />
            </Col>
            <TestsRequiredNotification
              control={control}
              formData={formData}
              currentStatus={testStatus}
            />
            <ConditionalFieldRenderer
              control={control}
              fieldName="testsRequiredYN"
              condition={(arg) => isYes(arg)}
              defaultValue={formData.testsRequiredYN}
            >
              <Col
                col={{
                  [GRID_BREAKPOINT.large]: '1 / 14',
                }}
              >
                <RHFDropDown
                  label="Tests required"
                  fieldName="conditionTestTypes"
                  control={control}
                  isMulti
                  options={testRequiredOptions}
                  markAsRequired
                  errorText={
                    errors?.conditionTestTypes?.message
                      ? String(errors.conditionTestTypes.message)
                      : undefined
                  }
                  defaultValue={
                    (formData.conditionTestTypes as any) ?? undefined
                  }
                  handleChange={(option) => {
                    const otherValueExists = option?.find(
                      (item: any) => item.value === TestType.Other
                    );
                    if (!otherValueExists) {
                      resetField('conditionTestTypeOther', {
                        defaultValue: null,
                      });
                    }
                  }}
                />
              </Col>
              <ConditionalFieldRenderer
                control={control}
                fieldName="conditionTestTypes"
                condition={(arg) => isOtherSelectedMulti(arg)}
                defaultValue={formData.conditionTestTypes}
              >
                <Col
                  col={{
                    [GRID_BREAKPOINT.large]: '1 / 14',
                  }}
                >
                  <Input
                    id="conditionTestTypeOther"
                    label="Other"
                    {...register('conditionTestTypeOther')}
                    markAsRequired
                    errorText={errors?.conditionTestTypeOther?.message}
                    defaultValue={formData.conditionTestTypeOther ?? undefined}
                  />
                </Col>
              </ConditionalFieldRenderer>
            </ConditionalFieldRenderer>
            <Col
              col={{
                [GRID_BREAKPOINT.large]: '1 / 14',
              }}
            >
              <MultipleChoiceButton
                label="Urgent clinical intervention required?"
                options={yesNoOptions}
                {...register('urgentClinicalInterventionRequiredYN', {
                  onChange: () => {
                    resetField('urgentClinicalInterventionTakenPlaceYN', {
                      defaultValue: null,
                    });
                    resetField('reasonForNoUrgentClinicalIntervention', {
                      defaultValue: null,
                    });
                  },
                })}
                border
                defaultValue={
                  formData.urgentClinicalInterventionRequiredYN ?? undefined
                }
              />
            </Col>
            <ConditionalFieldRenderer
              control={control}
              fieldName="urgentClinicalInterventionRequiredYN"
              condition={(arg) => isYes(arg)}
              defaultValue={formData.urgentClinicalInterventionRequiredYN}
            >
              <Col
                col={{
                  [GRID_BREAKPOINT.large]: '1 / 14',
                }}
              >
                <MultipleChoiceButton
                  label="Has the urgent clinical intervention taken place?"
                  options={yesNoOptions}
                  {...register('urgentClinicalInterventionTakenPlaceYN', {
                    onChange: () => {
                      resetField('reasonForNoUrgentClinicalIntervention', {
                        defaultValue: null,
                      });
                    },
                  })}
                  border
                  defaultValue={
                    formData.urgentClinicalInterventionTakenPlaceYN ?? undefined
                  }
                />
              </Col>
              <ConditionalFieldRenderer
                control={control}
                fieldName="urgentClinicalInterventionTakenPlaceYN"
                condition={(arg) => isNo(arg)}
                defaultValue={formData.urgentClinicalInterventionTakenPlaceYN}
              >
                <Col
                  col={{
                    [GRID_BREAKPOINT.large]: '1 / 14',
                  }}
                >
                  <Input
                    id="reasonForNoUrgentClinicalIntervention"
                    label="Please explain why?"
                    {...register('reasonForNoUrgentClinicalIntervention')}
                    markAsRequired
                    errorText={
                      errors?.reasonForNoUrgentClinicalIntervention?.message
                    }
                    defaultValue={
                      formData.reasonForNoUrgentClinicalIntervention ??
                      undefined
                    }
                  />
                </Col>
              </ConditionalFieldRenderer>
            </ConditionalFieldRenderer>
            <UrgentClinicalInterventionRequiredNotification
              control={control}
              formData={formData}
              currentStatus={monitoringStatus}
            />
            <Col
              col={{
                [GRID_BREAKPOINT.large]: '1 / 14',
              }}
            >
              <MultipleChoiceButton
                label="Does the patient need a consultation?"
                options={yesNoOptions}
                {...register('consultationRequiredYN', {
                  onChange: () => {
                    resetField('consultationTimeframe', { defaultValue: null });
                    resetField('customConsultationTimeframe', {
                      defaultValue: null,
                    });
                  },
                })}
                border
                defaultValue={formData.consultationRequiredYN ?? undefined}
              />
            </Col>
            <ConsultationRequiredNotification
              control={control}
              formData={formData}
              currentStatus={consultationStatus}
            />
            <ConditionalFieldRenderer
              control={control}
              fieldName="consultationRequiredYN"
              condition={(arg) => isYes(arg)}
              defaultValue={formData.consultationRequiredYN}
            >
              <Col
                col={{
                  [GRID_BREAKPOINT.large]: '1 / 14',
                }}
              >
                <RHFDropDown
                  label="When should the consultation take place?"
                  fieldName="consultationTimeframe"
                  control={control}
                  options={consultationTimeframeOptions}
                  markAsRequired
                  errorText={
                    errors?.consultationTimeframe?.message
                      ? String(errors.consultationTimeframe.message)
                      : undefined
                  }
                  defaultValue={formData.consultationTimeframe ?? undefined}
                  handleChange={(option) => {
                    if (option.value === ConsultationTimeframe.Other) {
                      resetField('customConsultationTimeframe', {
                        defaultValue: null,
                      });
                    }
                  }}
                />
              </Col>
              <ConditionalFieldRenderer
                control={control}
                fieldName="consultationTimeframe"
                condition={(arg) => isOtherSelected(arg)}
                defaultValue={formData.consultationTimeframe}
              >
                <Col
                  col={{
                    [GRID_BREAKPOINT.large]: '1 / 14',
                  }}
                >
                  <Input
                    id="customConsultationTimeframe"
                    label="Other"
                    {...register('customConsultationTimeframe')}
                    markAsRequired
                    errorText={errors?.customConsultationTimeframe?.message}
                    defaultValue={
                      formData.customConsultationTimeframe ?? undefined
                    }
                  />
                </Col>
              </ConditionalFieldRenderer>
            </ConditionalFieldRenderer>
            <Col
              col={{
                [GRID_BREAKPOINT.large]: '1 / 14',
              }}
            >
              <Input
                id="qRiskScore"
                label="QRisk3 (%)"
                {...register('qriskScore')}
                type="number"
                {...disallowExponentialInputs()}
                errorText={errors?.qriskScore?.message}
                defaultValue={formData.qriskScore ?? undefined}
              />
            </Col>
            <ConditionalMultipleFieldRenderer
              control={control}
              fieldNames={[
                'testsRequiredYN',
                'urgentClinicalInterventionRequiredYN',
                'consultationRequiredYN',
              ]}
              condition={(args) =>
                isAllNoSelected(args) || isSelfMonitoring(formData)
              }
            >
              <Col
                col={{
                  [GRID_BREAKPOINT.large]: '1 / 14',
                }}
              >
                <MultipleChoiceButton
                  label="Is the patient fit to self monitor their hypertension?"
                  options={yesNoOptions}
                  {...register('fitToSelfMonitorYN')}
                  border
                  defaultValue={formData.fitToSelfMonitorYN ?? undefined}
                />
              </Col>
              <ConditionalFieldRenderer
                control={control}
                fieldName="fitToSelfMonitorYN"
                condition={(arg) => isYes(arg)}
                defaultValue={formData.fitToSelfMonitorYN}
              >
                <Col
                  col={{
                    [GRID_BREAKPOINT.large]: '1 / 14',
                  }}
                >
                  <Text color={colors.grey.grey10} as="span" fontWeight={500}>
                    The patient will no longer be actively monitored for their
                    hypertension
                  </Text>
                  , meaning the remote care team will not be reviewing their
                  condition moving forwards. Please ensure you inform the
                  patient of this decision, as they will not automatically be
                  notified of this status change.
                </Col>
                <Col
                  col={{
                    [GRID_BREAKPOINT.large]: '1 / 14',
                  }}
                >
                  <CheckboxCard
                    id="self-monitoring-confirmation"
                    label={
                      <Text color={colors.grey.grey10}>
                        I have informed the patient and can confirm they are
                        stable to self-monitor their condition from{' '}
                        <strong>
                          <Timestamp
                            date={new Date()}
                            type="date"
                            data-testid="self-monitoring-date"
                            as="span"
                          />
                        </strong>{' '}
                        at{' '}
                        <strong>
                          <Timestamp
                            date={new Date()}
                            type="time"
                            data-testid="self-monitoring-time"
                            as="span"
                          />
                          .
                        </strong>
                      </Text>
                    }
                    handleChange={handleSelfMonitoringConfirmationChange!}
                    backgroundColour={colors.secondaryBlue.blue01}
                  />
                </Col>
              </ConditionalFieldRenderer>
            </ConditionalMultipleFieldRenderer>
            <Col
              col={{
                [GRID_BREAKPOINT.large]: '1 / 14',
              }}
            >
              <Input
                id="assessmentComments"
                label="Comments"
                {...register('assessmentComments')}
                defaultValue={formData.assessmentComments ?? undefined}
                maxLength={1000}
              />
            </Col>
          </Grid>
        </AccordionItem>
      </Accordion>
    </Card>
  );
};
