import styled from 'styled-components';
import { colors, device, fontSizes, spacing } from '@bt-healthcare/ui-toolkit';
import type { BackNavItemProps } from './types';

export const BackNavItem = styled.a<BackNavItemProps>`
  cursor: ${({ navPanelOpen }) => (navPanelOpen ? 'none' : 'pointer')};
  pointer-events: ${({ navPanelOpen }) => (navPanelOpen ? 'none' : 'auto')};
  display: ${({ navPanelOpen }) => (navPanelOpen ? 'none' : 'block')};

  @media ${device.largeTablet} {
    display: none;
  }
`;

export const MenuHeader = styled.div`
  display: inline-block;
  font-size: ${fontSizes.sm};
  text-transform: uppercase;
  position: absolute;
  top: ${spacing.s6};
  left: 50%;
  transform: translate(-50%, 0);
  color: ${colors.grey.grey08};

  @media ${device.largeTablet} {
    display: none;
  }
`;
