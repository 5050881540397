import { Wrapper, fontSizes, spacing } from '@bt-healthcare/ui-toolkit';
import styled from 'styled-components';

export const NoFilterResultsWrapper = styled(Wrapper)`
  font-size: ${fontSizes.sm};
  & > div {
    margin-top: ${spacing.s2};
  }

  ul {
    margin-top: 0;
    margin-bottom: 0;
  }
`;
