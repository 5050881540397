import { useFlags } from 'flagsmith/react';
import type { Flags, FeatureFlags } from 'features/types';
import { featureFlagKeys } from 'features/types';

export const useFeatureFlags = () => {
  const flags: Flags = useFlags(featureFlagKeys.map((key) => key.toString()));
  const featureFlags: FeatureFlags = Object.entries(flags).reduce(
    (result, [key, flag]) => {
      const keyParts = key.split('_');
      const newKey = keyParts
        .slice(2)
        .map((part, index) =>
          index === 0 ? part : part[0].toUpperCase() + part.slice(1)
        )
        .join('');
      const dynamicKey = `is${newKey[0].toUpperCase()}${newKey.slice(
        1
      )}FeatureFlagEnabled` as keyof FeatureFlags;
      // eslint-disable-next-line no-param-reassign
      result[dynamicKey] = flag.enabled;
      return result;
    },
    {} as FeatureFlags
  );

  return featureFlags;
};
