import { map, pipe, values } from 'ramda';
import type {
  ObservationTypeData,
  ObservationTypeUnit,
} from 'components/Visualisations/types';
import { DateFilter } from 'components/Visualisations/types';
import type { ObservationType } from 'services/graphql';
import type { SupportedObservationType } from 'types';
import { READING_RANGE } from 'models/wardPatientObservation';

type Accumulator = {
  [observationType: string]: ObservationTypeData;
};

export const ObservationTypeMap: Partial<
  Record<
    ObservationType,
    {
      name: string;
      unit: ObservationTypeUnit;
      sort: number;
      defaultRange: { min: number; max: number };
      readings: { label?: string; min: number; max: number }[];
    }
  >
> = {
  BLOOD_PRESSURE: {
    name: 'Blood pressure',
    unit: 'mmHg',
    sort: 1,
    defaultRange: { min: 60, max: 130 },
    readings: [
      { label: 'Systolic', ...READING_RANGE.systolic },
      { label: 'Diastolic', ...READING_RANGE.diastolic },
    ],
  },
  PULSE: {
    name: 'Pulse',
    unit: 'bpm',
    sort: 3,
    defaultRange: { min: 60, max: 110 },
    readings: [{ ...READING_RANGE.bpm }],
  },
  SPO2: {
    name: 'SPO2',
    unit: '%',
    sort: 2,
    defaultRange: { min: 90, max: 90 },
    readings: [{ ...READING_RANGE.oxygenSaturation }],
  },
};

export const initObservationTypeData = (observationType: ObservationType) => {
  const observationTypeMap = ObservationTypeMap[observationType];
  return {
    name: observationTypeMap?.name ?? 'unknown',
    type: observationType,
    unit: observationTypeMap?.unit,
    mean: [],
    hits: 0,
    data: [],
  };
};

// Sets default chart data when observations are not submitted
export const getDefaultChartData = (
  supportedObservationTypes: SupportedObservationType[]
) => supportedObservationTypes.map((item) => initObservationTypeData(item));

// Get all supported observations for a Patient
const getAllSupportedObservations = (
  observationTypeData: ObservationTypeData[],
  observationTypes: SupportedObservationType[]
) =>
  observationTypes.map((observationType) => {
    const supportedType = observationTypeData.find(
      (item) => item.type === observationType
    );
    return supportedType ?? initObservationTypeData(observationType);
  });

// Determine averages based on the dataset for each observation type
const getObservationTypeAverages = (
  observationTypeData: ObservationTypeData[]
) =>
  observationTypeData.map((item) => {
    const mean: number[] = item.data[0]?.series.map((_, index) => {
      const sum = item.data.reduce(
        (acc, dataItem) => acc + dataItem.series[index],
        0
      );
      const average = sum / item.data.length;
      return +average;
    });
    return mean ? { ...item, mean } : item;
  });

// Group by day and get daily averages */
export const getDailyAverages = (observationTypeData: ObservationTypeData[]) =>
  observationTypeData.map((item) => {
    const groupedData = item.data.reduce((result, dataItem) => {
      const date = dataItem.date.split('T')[0];
      if (!result[date]) {
        // eslint-disable-next-line no-param-reassign
        result = {
          ...result,
          [date]: {
            date,
            series: [...dataItem.series],
            count: 1,
          },
        };
      } else {
        // eslint-disable-next-line no-param-reassign
        result = {
          ...result,
          [date]: {
            ...result[date],
            series: result[date].series.map((value: number, index: number) =>
              Number(
                (value * result[date].count + dataItem.series[index]) /
                  (result[date].count + 1)
              )
            ),
            count: result[date].count + 1,
          },
        };
      }
      return result;
    }, {} as { [key: string]: any });
    const transformedSeries = Object.values(groupedData);
    return {
      ...item,
      data: transformedSeries,
    };
  });

// Transform source data for usage on charts
export const observationTypeSearchTransform = (
  observationVisualQuery: any,
  dateFilter: DateFilter,
  observationTypes?: SupportedObservationType[]
) => {
  const { searchResults } = observationVisualQuery.observationVisualsSearch;
  const groupedData = searchResults.reduce((acc: Accumulator, item: any) => {
    const { observationType } = item;
    const resObservationValues = item.res_observationValues;
    const observationValues: number[] = resObservationValues.values.map(
      (observationValue: any) => observationValue.value
    );
    if (!acc[observationType]) {
      acc[observationType] = initObservationTypeData(observationType);
    }
    acc[observationType] = {
      ...acc[observationType],
      hits: (acc[observationType].hits || 0) + 1,
    };
    acc[observationType].data.push({
      date: item.recordedDateTime,
      series: observationValues,
    });

    return acc;
  }, {});

  return pipe(
    values,
    map((data) => data as ObservationTypeData),
    (data) =>
      dateFilter === DateFilter.Monthly ? getDailyAverages(data) : data,
    (data) =>
      observationTypes != null
        ? getAllSupportedObservations(data, observationTypes)
        : data,
    (data) => getObservationTypeAverages(data)
  )(groupedData);
};
