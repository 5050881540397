import { Button, Grid, Row, Col } from '@bt-healthcare/ui-toolkit';
import { ButtonWrapper, FormFooterWapper } from './styles';
import type { FormFooterProps } from './types';

export const FormFooter = ({
  primaryButtonClick,
  primaryButtonDisabled = false,
  primaryButtonText = 'Continue',
  cancelButtonClick,
  cancelButtonText = 'Cancel',
  isFormWidth = true,
}: FormFooterProps) => (
  <FormFooterWapper id="sticky-footer" data-testid="sticky-footer">
    <Grid>
      <Row>
        <Col from={1}>
          <ButtonWrapper isFormWidth={isFormWidth}>
            <Button
              id="primary-btn"
              onClick={primaryButtonClick}
              disabled={primaryButtonDisabled}
            >
              {primaryButtonText}
            </Button>
            <Button
              id="cancel-btn"
              variant="tertiary"
              onClick={cancelButtonClick}
              type="button"
            >
              {cancelButtonText}
            </Button>
          </ButtonWrapper>
        </Col>
      </Row>
    </Grid>
  </FormFooterWapper>
);
