import { DatePicker, Wrapper, colors } from '@bt-healthcare/ui-toolkit';
import { Controller } from 'react-hook-form';
import { set } from 'date-fns';

import type { HypertensionAssessmentFormHeaderProps } from './types';
import { HypertensionAssessmentFormHeaderWrapper } from './styles';
import { RHFDropDown } from 'components/Form/RHFDropDown';
import { AssessmentType } from 'services/graphql';
import { allAssessmentTypeOptions } from 'mappings/enums';
import { resetConsultationFormFields } from 'pages/Assessment/utils';
import { usePatientDispatch } from 'context/patient/PatientContext';

export const HypertensionAssessmentFormHeader = ({
  control,
  errors,
  resetField,
  assessmentType,
  hypertensionAssessmentFormData,
  recommendationTriggeredRef,
}: HypertensionAssessmentFormHeaderProps) => {
  const patientDispatch = usePatientDispatch();
  const handleAssessmentTypeChange = () => {
    patientDispatch({
      type: 'resetHypertensionAssessmentFormData',
    });
    patientDispatch({ type: 'resetGuidelineAcceptance' });
    if (assessmentType?.value === AssessmentType.Review) {
      resetConsultationFormFields(resetField);
    }
    if (assessmentType?.value === AssessmentType.Consultation) {
      // eslint-disable-next-line no-param-reassign
      recommendationTriggeredRef.current = false;
    }
  };

  return (
    <HypertensionAssessmentFormHeaderWrapper data-testid="hypertension-assessment-form-header">
      <Wrapper>
        <RHFDropDown
          fieldName="assessmentType"
          control={control}
          options={allAssessmentTypeOptions}
          aria-label="Assessment type"
          transparentBackground
          isClearable={false}
          errorText={
            errors?.assessmentType?.message
              ? String(errors.assessmentType.message)
              : undefined
          }
          defaultValue={hypertensionAssessmentFormData.assessmentType}
          handleChange={handleAssessmentTypeChange}
        />
      </Wrapper>
      <Wrapper>
        <Controller
          name="assessmentStartDate"
          control={control}
          defaultValue={
            hypertensionAssessmentFormData.assessmentStartDate
              ? new Date(hypertensionAssessmentFormData.assessmentStartDate)
              : set(new Date(), {
                  hours: 0,
                  minutes: 0,
                  seconds: 0,
                  milliseconds: 0,
                })
          }
          render={({ field: { value, onChange, onBlur, name } }) => (
            <DatePicker
              id={name}
              onBlur={onBlur}
              dateFormat="dd/MM/yy"
              selected={value}
              onChange={onChange}
              name={name}
              maxDate={new Date()}
              aria-label="Date input"
              placeholder="DD/MM/YY"
              disabledKeyboardNavigation
              showPreviousMonthsDays={false}
              transparentBackground
              iconColor={colors.primaryIndigo.indigo08}
              errorText={errors?.assessmentStartDate?.message}
            />
          )}
        />
      </Wrapper>
    </HypertensionAssessmentFormHeaderWrapper>
  );
};
