import type { TypedOptionType } from '@bt-healthcare/ui-toolkit';
import { getColorValue, colors } from '@bt-healthcare/ui-toolkit';
import { useState } from 'react';

import { usePrevious } from 'hooks/usePrevious';
import { MonitoringStatusOptions, SelfMonitoringOptions } from 'mappings/enums';
import { MonitoringStatusTypeColorMap } from 'pages/PatientList/PatientList.util';
import type { AdmissionStatus, MonitoringStatus } from 'services/graphql';
import {
  MonitoringStatusType,
  useUpdateWardPatientMonitoringStatusMutation,
} from 'services/graphql';

export const useMonitoringStatusUpdate = (
  monitoringStatus?: MonitoringStatus
) => {
  const initMonitoringStatus = MonitoringStatusOptions.find(
    (item) => item.value === monitoringStatus?.status
  );
  const initMonitoringStatusColor = monitoringStatus?.status
    ? getColorValue(MonitoringStatusTypeColorMap[monitoringStatus.status])
    : colors.notifications.light.successLight;

  const initMonitoringStatusOptions =
    initMonitoringStatus?.value === MonitoringStatusType.SelfMonitoring
      ? SelfMonitoringOptions
      : MonitoringStatusOptions;

  const [currentColor, setCurrentColor] = useState(initMonitoringStatusColor);

  const previous = usePrevious(initMonitoringStatus);
  const previousColor = usePrevious(initMonitoringStatusColor);

  const [patientSelfMonitoringOpen, setPatientSelfMonitoringOpen] =
    useState(false);
  const [monitoredConditionReviewedOpen, setMonitoredConditionReviewedOpen] =
    useState(false);
  const [urgentClinicalIntervention, setUrgentClinicalIntervention] =
    useState(false);
  const [patientMonitoringOpen, setPatientMonitoringOpen] = useState(false);

  const [values, setValues] = useState(initMonitoringStatusOptions);
  const [current, setCurrent] = useState<
    | TypedOptionType<MonitoringStatusType | AdmissionStatus.Monitoring>
    | undefined
  >(initMonitoringStatus);

  const [updateWardPatientMonitoringStatus, { error, loading }] =
    useUpdateWardPatientMonitoringStatusMutation();

  return {
    monitoringStatusMutation: {
      mutation: updateWardPatientMonitoringStatus,
      error,
      loading,
    },
    monitoringStatusModal: {
      patientSelfMonitoringOpen,
      setPatientSelfMonitoringOpen,
      patientMonitoringOpen,
      setPatientMonitoringOpen,
      monitoredConditionReviewedOpen,
      setMonitoredConditionReviewedOpen,
      urgentClinicalIntervention,
      setUrgentClinicalIntervention,
    },
    monitoringStatusConfig: {
      previous,
      current,
      setCurrent,
      options: { values, setValues },
      color: {
        previousColor,
        currentColor,
        setCurrentColor,
      },
    },
  };
};
