import { Timestamp } from '@bt-healthcare/ui-toolkit';
import type { DischargeSummaryProps } from './types';
import { CommentsWrapper } from './styles';
import { propEmptyOr } from 'utils/object.utils';

export const dischargeConfirmationMapping = (data: DischargeSummaryProps) => [
  { label: 'Patient', value: data.name },
  {
    label: 'Admitted',
    value: <Timestamp date={data.admissionDate} type="date" />,
  },
  {
    label: 'Discharged',
    value: <Timestamp date={data.dischargeDate} type="date" />,
  },
  {
    label: 'Actual length of stay (days)',
    value: propEmptyOr('lengthOfStayInDays', data),
  },
  { label: 'Reason for discharge', value: data.reasonForDischarge },
  {
    label: 'Comments',
    value: <CommentsWrapper>{propEmptyOr('comments', data)}</CommentsWrapper>,
  },
];
