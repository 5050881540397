import type { NavigateFunction } from 'react-router-dom';
import { noop } from '@bt-healthcare/ui-toolkit';
import { ROUTE } from './routes';
import { StartAdmissionFeature } from 'features/startAdmission';

export const mapBackRoute = (
  pathname: string,
  navigate: NavigateFunction,
  wardPatientId?: string
) => {
  switch (pathname) {
    case ROUTE.PATIENT_DETAIL:
    case ROUTE.ADMISSION:
      return () => navigate(ROUTE.HOME);
    case ROUTE.DISCHARGE:
    case ROUTE.ASSESSMENT_HYPERTENSION:
      return () => navigate(ROUTE.PATIENT_DETAIL, { state: { wardPatientId } });
    default:
      return undefined;
  }
};

export const getMenuHeader = (pathname: string) => {
  switch (pathname) {
    case ROUTE.PATIENT_DETAIL:
      return 'Patient profile';
    case ROUTE.START_ADMISSION:
      return 'Patient Admission';
    case ROUTE.ADMISSION:
      return 'Admission Form';
    case ROUTE.DISCHARGE:
      return 'Discharge patient';
    case ROUTE.ASSESSMENT_HYPERTENSION:
      return 'Assessment';
    case ROUTE.SETTINGS:
      return 'Settings';
    case ROUTE.SETTINGS_USER:
      return 'Users';
    case ROUTE.SETTINGS_USER_EDIT:
      return 'Edit user';
    case ROUTE.SETTINGS_USER_CREATE:
      return 'Add user';
    case ROUTE.SETTINGS_USER_REACTIVATE:
      return 'Reactivate user';
    case ROUTE.SETTINGS_USER_CONFIRMED:
      return 'Confirm user';
    default:
      return 'Overview';
  }
};

const getPrevPathName = (navigate: NavigateFunction, prevPathName: string) => {
  switch (prevPathName) {
    case ROUTE.SETTINGS_USER_EDIT:
      return {
        label: 'Edit User',
        action: () => navigate(ROUTE.SETTINGS_USER_EDIT),
      };
    case ROUTE.SETTINGS_USER_REACTIVATE:
      return {
        label: 'Reactivate User',
        action: () => navigate(ROUTE.SETTINGS_USER_REACTIVATE),
      };
    default:
      return {
        label: 'Add New User',
        action: () => navigate(ROUTE.SETTINGS_USER_CREATE),
      };
  }
};

export const getBreadcrumbs = (
  pathname: string,
  navigate: NavigateFunction,
  wardPatientId?: string,
  patient?: string,
  prevPathName?: string
) => {
  const patientList = {
    label: 'Patient List',
    action: () => navigate(ROUTE.HOME),
  };

  const patientProfile = {
    label: 'Patient Profile',
    action: () => navigate(ROUTE.PATIENT_DETAIL, { state: { wardPatientId } }),
  };

  const dischargePatient = {
    label: 'Discharge Patient',
    action: noop,
  };

  const patientHypertensionAssessment = {
    label: 'Assessment',
    action: () =>
      navigate(ROUTE.ASSESSMENT_HYPERTENSION, {
        state: { wardPatientId, patient },
      }),
  };

  const settings = {
    label: 'Settings',
    action: () => navigate(ROUTE.SETTINGS),
  };

  const users = { label: 'Users', action: () => navigate(ROUTE.SETTINGS_USER) };

  switch (pathname) {
    case ROUTE.START_ADMISSION:
      return [
        { ...patientList },
        {
          label: 'Patient Admission',
          action: () => navigate(ROUTE.START_ADMISSION),
        },
      ];
    case ROUTE.ADMISSION:
      return [
        { ...patientList },
        ...StartAdmissionFeature(navigate),
        {
          label: 'Admission Form',
          action: () => navigate(ROUTE.ADMISSION),
        },
      ];
    case ROUTE.PATIENT_DETAIL:
      return [{ ...patientList }, { ...patientProfile }];
    case ROUTE.DISCHARGE:
      return [
        { ...patientList },
        { ...patientProfile },
        { ...dischargePatient },
      ];
    case ROUTE.DISCHARGE_CONFIRMED:
      return [
        { ...patientList },
        { ...patientProfile },
        { ...dischargePatient },
        {
          label: 'Discharge Patient Confirmed',
          action: noop,
        },
      ];
    case ROUTE.ASSESSMENT_HYPERTENSION:
      return [
        { ...patientList },
        { ...patientProfile },
        { ...patientHypertensionAssessment },
      ];
    case ROUTE.ASSESSMENT_HYPERTENSION_CONFIRM:
      return [
        { ...patientList },
        { ...patientProfile },
        { ...patientHypertensionAssessment },
        {
          label: 'Assessment Confirmed',
          action: () =>
            navigate(ROUTE.ASSESSMENT_HYPERTENSION_CONFIRM, {
              state: { wardPatientId },
            }),
        },
      ];
    case ROUTE.SETTINGS_USER:
      return [{ ...settings }, { label: 'Users', action: noop }];
    case ROUTE.SETTINGS_USER_CREATE:
      return [
        { ...settings },
        { ...users },
        { label: 'Add New User', action: noop },
      ];
    case ROUTE.SETTINGS_USER_EDIT:
      return [
        { ...settings },
        { ...users },
        { label: 'Edit User', action: noop },
      ];
    case ROUTE.SETTINGS_USER_REACTIVATE:
      return [
        { ...settings },
        { ...users },
        { label: 'Reactivate User', action: noop },
      ];
    case ROUTE.SETTINGS_USER_CONFIRMED:
      return [
        { ...settings },
        { ...users },
        { ...getPrevPathName(navigate, prevPathName!) },
        { label: 'Confirm details', action: noop },
      ];
    default:
      return '';
  }
};
