import { Text, colors } from '@bt-healthcare/ui-toolkit';

import type { PatientDetailProps } from './types';
import { PatientDetailWrapper } from './styles';

export const PatientDetail = ({ label, id, children }: PatientDetailProps) => (
  <PatientDetailWrapper>
    <Text color={colors.grey.grey08}>{label}: </Text>
    <Text color={colors.grey.grey09} fontWeight={500} data-testid={id}>
      {children}
    </Text>
  </PatientDetailWrapper>
);
