import { omit } from 'ramda';
import type { TypedOptionType } from '@bt-healthcare/ui-toolkit';
import { EMPTY_VALUE } from '../../App.constants';
import {
  AdmissionStatus,
  AssessmentType,
  CommentTypes,
  ConsultationTimeframe,
  DischargeReasonType,
  Ethnicity,
  GenderAssignedAtBirth,
  GenderIdentifiedAs,
  GuidelineType,
  MedicationProtocol,
  Mobility,
  MonitoredConditionConsultationStatusType,
  MonitoredConditionTestStatusType,
  MonitoringStatusType,
  PatientDiagnosis,
  Pronouns,
  TestType,
  YesNoType,
  MedicationOutcome,
  GuidelineDeclinedReason,
  SmokingStatus,
  DiabetesStatus,
  CvdFamilyInformation,
} from '../../services/graphql';

export type EnumMapType<T extends string> = { [key in T]: string };

export const enumFromStringValue = <T>(
  enm: { [s: string]: T },
  value: string
): T | undefined =>
  (Object.values(enm) as unknown as string[]).includes(value)
    ? (value as unknown as T)
    : undefined;

export const getOptionsForEnum = <T extends object>(
  value: T,
  arg?: string
): TypedOptionType<keyof T>[] => {
  const entries = Object.entries(value);
  const options = arg ? entries.filter((item) => item[0] !== arg) : entries;
  return options.map((item) => ({
    label: item[1],
    value: item[0] as keyof T,
  }));
};

export const formatEnum = <T>(value: TypedOptionType<T>) => value.value;

export const GenderAssignedAtBirthMap = {
  [GenderAssignedAtBirth.Male]: 'Male',
  [GenderAssignedAtBirth.Female]: 'Female',
};

export const GenderAssignedAtBirthOptions = getOptionsForEnum(
  GenderAssignedAtBirthMap
);

export const GenderIdentifiedAsMap = {
  [GenderIdentifiedAs.Male]: 'Male',
  [GenderIdentifiedAs.Female]: 'Female',
  [GenderIdentifiedAs.NonBinary]: 'Non-binary',
  [GenderIdentifiedAs.Transman]: 'Transman',
  [GenderIdentifiedAs.Transwoman]: 'Transwoman',
  [GenderIdentifiedAs.PreferNone]: 'Prefer not to say',
  [GenderIdentifiedAs.Other]: 'Other',
  [GenderIdentifiedAs.None]: EMPTY_VALUE,
};

export const GenderIdentifiedAsOptions = getOptionsForEnum(
  omit([GenderIdentifiedAs.None], GenderIdentifiedAsMap)
);

export const CommentTypeMap = {
  [CommentTypes.ChangedStatus]: 'Changed status',
  [CommentTypes.ContactedFamilyCarer]: 'Contacted family / carer',
  [CommentTypes.EmergencyAction]: 'Emergency action',
  [CommentTypes.Medication]: 'Medication',
  [CommentTypes.Observations]: 'Observations',
  [CommentTypes.PatientContactedNoClinicianRequired]:
    'Contacted Patient - No Clinician Required',
  [CommentTypes.PatientContactedClinicianRequired]:
    'Contacted Patient - Clinician Required',
  [CommentTypes.PatientContactedF2FRequired]:
    'Contacted Patient - F2F Required',
  [CommentTypes.Other]: 'Other',
};

export const EthnicityMap = {
  [Ethnicity.WhiteBritish]: 'White - British',
  [Ethnicity.WhiteIrish]: 'White - Irish',
  [Ethnicity.WhiteAnyOtherWhiteBackground]:
    'White - Any other White background',
  [Ethnicity.MixedWhiteAndBlackCaribbean]: 'Mixed - White and Black Caribbean',
  [Ethnicity.MixedWhiteAndBlackAfrican]: 'Mixed - White and Black African',
  [Ethnicity.MixedWhiteAndAsian]: 'Mixed - White and Asian',
  [Ethnicity.MixedAnyOtherMixed]: 'Mixed - Any other mixed background',
  [Ethnicity.AsianOrAsianBritishIndian]: 'Asian or Asian British - Indian',
  [Ethnicity.AsianOrAsianBritishPakistani]:
    'Asian or Asian British - Pakistani',
  [Ethnicity.AsianOrAsianBritishBangladeshi]:
    'Asian or Asian British - Bangladeshi',
  [Ethnicity.AsianOrAsianBritishAnyOtherAsianBackground]:
    'Asian or Asian British - Any other Asian background',
  [Ethnicity.BlackOrBlackBritishCaribbean]:
    'Black or Black British - Caribbean',
  [Ethnicity.BlackOrBlackBritishAfrican]: 'Black or Black British - African',
  [Ethnicity.BlackOrBlackBritishAnyOtherBlackBackground]:
    'Black or Black British - Any other Black background',
  [Ethnicity.OtherEthnicGroupsChinese]: 'Other Ethnic Groups - Chinese',
  [Ethnicity.OtherEthnicGroupsAnyOtherEthnicGroup]:
    'Other Ethnic Groups - Any other ethnic group',
  [Ethnicity.NotStated]: 'Not stated',
  [Ethnicity.NotKnown]: 'Not known',
};

export const EthnicityOptions = getOptionsForEnum(EthnicityMap);

export const PronounsMap = {
  [Pronouns.SheHer]: 'She/Her',
  [Pronouns.HeHim]: 'He/Him',
  [Pronouns.TheyThem]: 'They/Them',
};

export const PronounsOptions = getOptionsForEnum(PronounsMap);

export const MobilityMap = {
  [Mobility.NoAssistance]: 'No assistance',
  [Mobility.MinimumAssistance]: 'Minimum assistance',
  [Mobility.ModerateAssistance]: 'Moderate assistance',
  [Mobility.MaximumAssistance]: 'Maximum assistance',
};

export const MobilityOptions = getOptionsForEnum(MobilityMap);

export const MonitoringStatusMap: Record<MonitoringStatusType, string> = {
  [MonitoringStatusType.ReadyForReview]: 'Ready for review',
  [MonitoringStatusType.Reviewed]: 'Reviewed',
  [MonitoringStatusType.UrgentClinicalInterventionRequired]:
    'Urgent clinical intervention',
  [MonitoringStatusType.SelfMonitoring]: 'Self monitoring',
  [MonitoringStatusType.AwaitingReadings]: 'Awaiting readings',
  [MonitoringStatusType.ReadingsAvailable]: 'Readings available',
};

export const MonitoringStatusOptions = getOptionsForEnum(MonitoringStatusMap);

export const ConsultationStatusMap = {
  [MonitoredConditionConsultationStatusType.NotRequired]: 'Not required',
  [MonitoredConditionConsultationStatusType.Required]: 'Required',
  [MonitoredConditionConsultationStatusType.Invited]: 'Invited',
  [MonitoredConditionConsultationStatusType.Booked]: 'Booked',
  [MonitoredConditionConsultationStatusType.Missed]: 'Missed',
  [MonitoredConditionConsultationStatusType.Cancelled]: 'Cancelled',
  [MonitoredConditionConsultationStatusType.Completed]: 'Completed',
};

export const ConsultationStatusOptions = getOptionsForEnum(
  ConsultationStatusMap
);

export const TestStatusMap = {
  [MonitoredConditionTestStatusType.NotRequired]: 'Not required',
  [MonitoredConditionTestStatusType.Required]: 'Required',
  [MonitoredConditionTestStatusType.Invited]: 'Invited',
  [MonitoredConditionTestStatusType.Booked]: 'Booked',
  [MonitoredConditionTestStatusType.Missed]: 'Missed',
  [MonitoredConditionTestStatusType.Cancelled]: 'Cancelled',
  [MonitoredConditionTestStatusType.AwaitingResults]: 'Awaiting results',
  [MonitoredConditionTestStatusType.Overdue]: 'Overdue',
  [MonitoredConditionTestStatusType.Returned]: 'Returned',
  [MonitoredConditionTestStatusType.Reviewed]: 'Reviewed',
};

export const TestStatusOptions = getOptionsForEnum(TestStatusMap);

export const MonitoredConditionMap: Record<PatientDiagnosis, string> = {
  [PatientDiagnosis.Hypertension]: 'Hypertension',
  [PatientDiagnosis.Copd]: 'COPD',
  [PatientDiagnosis.None]: 'None',
} as const;

export const MonitoredConditionOptions = getOptionsForEnum(
  omit([PatientDiagnosis.None], MonitoredConditionMap)
);

export const ReasonForDischargeOptions = [
  { label: 'None', value: DischargeReasonType.None },
  {
    label: 'Patient admitted to hospital',
    value: DischargeReasonType.PatientAdmittedToHospital,
  },
  {
    label: 'Remote monitoring completed',
    value: DischargeReasonType.RemoteMonitoringCompleted,
  },
  {
    label: 'Patient refused remote monitoring',
    value: DischargeReasonType.PatientRefusedRemoteMonitoring,
  },
  { label: 'Other', value: DischargeReasonType.Other },
];

export const AdmissionStatusMap: Record<AdmissionStatus, string> = {
  [AdmissionStatus.Invited]: 'Invited',
  [AdmissionStatus.Accepted]: 'Account activated',
  [AdmissionStatus.Monitoring]: 'Monitored',
  [AdmissionStatus.InvitedStepup]: 'Awaiting consent',
};

export const SelfMonitoringStatusMap = {
  [MonitoringStatusType.SelfMonitoring]: 'Self monitoring',
  [AdmissionStatus.Monitoring]: 'Monitoring',
};

export const SelfMonitoringOptions = getOptionsForEnum(SelfMonitoringStatusMap);

export const YesNoMap = {
  [YesNoType.Yes]: 'Yes',
  [YesNoType.No]: 'No',
  [YesNoType.None]: EMPTY_VALUE,
};

export const yesNoOptions = getOptionsForEnum(omit([YesNoType.None], YesNoMap));

export const AssessmentTypeMap = {
  [AssessmentType.Review]: 'Review',
  [AssessmentType.Consultation]: 'Consultation',
};

export const assessmentTypeOptions = getOptionsForEnum(
  omit([AssessmentType.Consultation], AssessmentTypeMap)
);

export const allAssessmentTypeOptions = getOptionsForEnum(AssessmentTypeMap);

export const TestsRequiredMap = {
  [TestType.Blood]: 'Blood',
  [TestType.Urine]: 'Urine',
  [TestType.Ecg]: 'ECG',
  [TestType.Other]: 'Other',
};

export const testRequiredOptions = getOptionsForEnum(TestsRequiredMap);

export const ConsultationTimeframeMap = {
  [ConsultationTimeframe.WithinTwoDays]: 'Within 1 or 2 days',
  [ConsultationTimeframe.OneWeek]: '1 week',
  [ConsultationTimeframe.TwoWeeks]: '2 weeks',
  [ConsultationTimeframe.OneMonth]: '1 month',
  [ConsultationTimeframe.ThreeMonths]: '3 months',
  [ConsultationTimeframe.SixMonths]: '6 months',
  [ConsultationTimeframe.TwelveMonths]: '12 months',
  [ConsultationTimeframe.Other]: 'Other (please specify)',
};

export const consultationTimeframeOptions = getOptionsForEnum(
  ConsultationTimeframeMap
);

export const MedicationProtocolMap = {
  [MedicationProtocol.Single]: 'Single',
  [MedicationProtocol.Double]: 'Double',
  [MedicationProtocol.Triple]: 'Triple',
  [MedicationProtocol.Quadruple]: 'Quadruple',
  [MedicationProtocol.None]: 'None',
};

export const medicationProtocolOptions = getOptionsForEnum(
  MedicationProtocolMap
);

export const GuidelineMap = {
  [GuidelineType.ConsiderMedication]: 'Consider medication',
  [GuidelineType.DiscussMedication]: 'Discuss medication',
  [GuidelineType.LifestyleOnly]: 'Lifestyle only',
  [GuidelineType.OfferMedication]: 'Offer drugs',
};

export const guidelineOptions = getOptionsForEnum(GuidelineMap);

export const MedicationOutcomeMap = {
  [MedicationOutcome.OnMaximalToleratedTherapy]:
    'Patient on maximal tolerated antihypertensive therapy',
  [MedicationOutcome.Started]: 'Started',
  [MedicationOutcome.SatisfactoryNoChanges]: 'Satisfactory/ No changes',
  [MedicationOutcome.Changed]: 'Changed',
  [MedicationOutcome.Decreased]: 'Decreased',
  [MedicationOutcome.Increased]: 'Increased',
  [MedicationOutcome.Stopped]: 'Stopped (please specify why)',
  [MedicationOutcome.Refused]: 'Treatment refused',
};

export const medicationOutcomeOptions = getOptionsForEnum(MedicationOutcomeMap);

export const GuidelineDeclinedReasonMap = {
  [GuidelineDeclinedReason.Contraindicated]: 'Contraindicated',
  [GuidelineDeclinedReason.DrugAllergies]: 'Drug allergies',
  [GuidelineDeclinedReason.DrugDrugInteraction]: 'Drug-drug interaction',
  [GuidelineDeclinedReason.PastMedicationTrialsUnsuccessful]:
    'Past trials of medication were unsuccessful',
  [GuidelineDeclinedReason.OnMaximalToleratedTherapy]:
    'Patient on maximal tolerated antihypertensive therapy',
  [GuidelineDeclinedReason.Other]: 'Other (please specify)',
};

export const guidelineDeclinedReasonOptions = getOptionsForEnum(
  GuidelineDeclinedReasonMap
);

export const SmokingStatusMap = {
  [SmokingStatus.NoNeverSmoked]: 'No, never smoked',
  [SmokingStatus.NoQuit]: 'No, quit',
  [SmokingStatus.PreferNotSay]: 'Prefer not say',
  [SmokingStatus.YesLessThan_10ADay]: 'Yes, smokes less than 10 a day',
  [SmokingStatus.YesLessThan_20ADay]: 'Yes, smokes less than 20 a day',
  [SmokingStatus.YesMoreThan_20ADay]: 'Yes, smokes 20+ a day',
};

export const DiabetesStatusMap = {
  [DiabetesStatus.No]: 'None',
  [DiabetesStatus.PreferNotToSay]: 'Prefer not to say',
  [DiabetesStatus.Type_1]: 'Type 1',
  [DiabetesStatus.Type_2]: 'Type 2',
};

export const CvdFamilyInformationMap = {
  [CvdFamilyInformation.DontKnow]: 'Unknown family history',
  [CvdFamilyInformation.No]: 'No family history',
  [CvdFamilyInformation.PreferNotToSay]: 'Prefer not to say family history',
  [CvdFamilyInformation.Yes]: 'Family history of CVD',
};
