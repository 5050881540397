import type { ConsultationStatusChipProps } from '../PatientList.types';
import { sortByCondition } from '../PatientList.util';
import { ConsultationStatusRenderer } from './ConsultationStatusRenderer';
import { EMPTY_VALUE } from 'App.constants';
import { isEmptyOrNil } from 'utils/object.utils';
import type { PatientDiagnosis } from 'services/graphql';
import { MonitoringStatusType } from 'services/graphql';

export const ConsultationStatusChip = ({
  consultationStatusByCondition,
  monitoringStatusByCondition,
  id,
}: ConsultationStatusChipProps) => {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  if (isEmptyOrNil(consultationStatusByCondition)) return <>{EMPTY_VALUE}</>;

  const monitoringConditionStatuses = sortByCondition(
    Object.entries(consultationStatusByCondition).map(
      ([condition, statusType]) => ({
        condition,
        statusType:
          monitoringStatusByCondition[condition as PatientDiagnosis] ===
          MonitoringStatusType.SelfMonitoring
            ? null
            : statusType,
      })
    )
  );

  return (
    <ConsultationStatusRenderer items={monitoringConditionStatuses} id={id} />
  );
};
