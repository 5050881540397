import { colors, device, fontSizes, spacing } from '@bt-healthcare/ui-toolkit';
import styled from 'styled-components';

export const NoHistoricReviewsWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 90%;
  max-width: 1000px;
  margin: ${spacing.s6} auto;
  gap: ${spacing.s6};

  svg {
    max-width: 100%;
    height: auto;
  }

  @media ${device.largeTablet} {
    width: 80%;
    flex-direction: row;
    gap: ${spacing.l4};
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.s2};
  text-align: left;

  @media ${device.largeTablet} {
    gap: ${spacing.s4};
  }
`;

export const Title = styled.span`
  color: ${colors.grey.grey09};
  font-size: ${fontSizes.base};
  font-weight: 500;

  @media ${device.largeTablet} {
    font-size: ${fontSizes.h3};
  }
`;

export const Text = styled.span`
  color: ${colors.grey.grey08};
  font-size: ${fontSizes.sm};

  @media ${device.largeTablet} {
    font-size: ${fontSizes.h4};
  }
`;

export const NewAssessmentLink = styled(Text)`
  color: ${colors.primaryIndigo.indigo08};
  cursor: pointer;
  font-weight: 500;
`;

export const NewAssessmentText = styled.span`
  display: none;
  @media ${device.largeTablet} {
    display: inline-block;
  }
`;
