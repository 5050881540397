import { Col, GRID_BREAKPOINT, Notification } from '@bt-healthcare/ui-toolkit';

import type { HypertensionAssessmentNotificationProps } from '../types';
import { ConditionalFieldRenderer } from 'components/Form/ConditionalFieldRenderer';
import { isYes, isNo } from 'components/Form/conditionalRules';
import { MonitoredConditionConsultationStatusType } from 'services/graphql';
import { ConsultationStatusMap } from 'mappings/enums';
import { BoldText } from 'components/BoldText';

// TODO: Simplify the content and create a simple component for this and RequiredTestNotification
export const ConsultationRequiredNotification = ({
  control,
  formData,
  currentStatus,
}: HypertensionAssessmentNotificationProps) => (
  <>
    <ConditionalFieldRenderer
      control={control}
      fieldName="consultationRequiredYN"
      condition={(arg) => isYes(arg)}
      defaultValue={formData.consultationRequiredYN}
    >
      <Col
        col={{
          [GRID_BREAKPOINT.large]: '1 / 14',
        }}
      >
        <Notification
          icon="Stethoscope"
          type="informative"
          data-testid="consultation-status-required-notification"
        >
          {currentStatus &&
          currentStatus !==
            MonitoredConditionConsultationStatusType.Required ? (
            <>
              The patient’s <BoldText>consultation status</BoldText> will be
              updated from{' '}
              <BoldText>
                {ConsultationStatusMap[
                  currentStatus as MonitoredConditionConsultationStatusType
                ].toLowerCase()}
              </BoldText>{' '}
              to <BoldText>required.</BoldText>
            </>
          ) : (
            <>
              The patient’s <BoldText>consultation status</BoldText> will be
              updated to <BoldText>required.</BoldText>
            </>
          )}
        </Notification>
      </Col>
    </ConditionalFieldRenderer>
    <ConditionalFieldRenderer
      control={control}
      fieldName="consultationRequiredYN"
      condition={(arg) => isNo(arg)}
      defaultValue={formData.consultationRequiredYN}
    >
      <Col
        col={{
          [GRID_BREAKPOINT.large]: '1 / 14',
        }}
      >
        <Notification
          icon="Stethoscope"
          type="informative"
          data-testid="consultation-status-not-required-notification"
        >
          {currentStatus &&
          currentStatus !==
            MonitoredConditionConsultationStatusType.NotRequired ? (
            <>
              The patient’s <BoldText>consultation status</BoldText> will be
              updated from{' '}
              <BoldText>
                {ConsultationStatusMap[
                  currentStatus as MonitoredConditionConsultationStatusType
                ].toLowerCase()}
              </BoldText>{' '}
              to <BoldText>not required.</BoldText>
            </>
          ) : (
            <>
              The patient’s <BoldText>consultation status</BoldText> will be
              updated to <BoldText>not required.</BoldText>
            </>
          )}
        </Notification>
      </Col>
    </ConditionalFieldRenderer>
  </>
);
