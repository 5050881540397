import { colors, device, spacing } from '@bt-healthcare/ui-toolkit';
import styled from 'styled-components';

const toggleMenuHeightCalc = `${24 + 30}px`; // NavigationWrapper padding + (logo height / 2)

export const SideMenuItems = styled.div`
  display: none;

  @media ${device.largeTablet} {
    display: block;
    width: 100%;
  }
`;

export const ToggleMenu = styled.div`
  position: absolute;
  top: ${toggleMenuHeightCalc};
  right: ${spacing.none};
  transform: translate(50%, -50%);
  background-color: ${colors.primaryIndigo.indigo08};
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
