import { colors, device, spacing } from '@bt-healthcare/ui-toolkit';
import styled from 'styled-components';
import {
  activeStyles,
  filterButtonStyles,
  selectedStyles,
} from './styles.utils';
import type { StateProps } from './types';

export const FilterDropdownWrapper = styled.div<StateProps>`
  z-index: ${({ selected }) => selected && 2};

  .dropdown {
    background-color: ${colors.base.white};
    border: 1px solid ${colors.grey.grey05};
    border-radius: ${spacing.s4};
    padding: ${spacing.s6};
  }

  @media ${device.largeTablet} {
    position: relative;
  }
`;

export const DesktopFilterButton = styled.div<StateProps>`
  display: none;

  @media ${device.largeTablet} {
    display: block;
    ${filterButtonStyles}
    ${({ active }) => active && activeStyles}
    ${({ selected }) => selected && selectedStyles}
  }
`;

export const MobileFilterButton = styled.div<StateProps>`
  ${filterButtonStyles}
  ${({ active }) => active && activeStyles}

  @media ${device.largeTablet} {
    display: none;
  }
`;

export const DropdownWrapper = styled.div`
  @media ${device.largeTablet} {
    position: absolute;
    right: 0;
    width: 338px;
  }
`;

export const Spacer = styled.hr`
  border: 0;
  display: block;
  margin: ${spacing.s1};
`;
