import type { PropsWithChildren } from 'react';
import { useReducer } from 'react';

import { AppContext, AppDispatchContext } from './AppContext';
import type { AppState } from 'reducers/app/types';
import { appReducer, initAppState } from 'reducers/app/appReducer';

export const AppProvider = ({
  children,
  value = initAppState,
}: PropsWithChildren<{ value?: AppState }>) => {
  const [state, dispatch] = useReducer(appReducer, value);
  return (
    <AppContext.Provider value={state}>
      <AppDispatchContext.Provider value={dispatch}>
        {children}
      </AppDispatchContext.Provider>
    </AppContext.Provider>
  );
};
