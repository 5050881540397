import { useClickOutside } from '@bt-healthcare/ui-toolkit';
import type { MutableRefObject } from 'react';
import { useRef, useState } from 'react';

import {
  UserListSubMenuDropdownListItem,
  UserListSubMenuButton,
  UserListSubMenuDropdown,
  UserListSubMenuWrapper,
} from './styles';
import type { UserActionType, UserListSubMenuProps } from './types';
import { UserState } from 'services/graphql';
import { useSettingsDispatch } from 'context/settings/SettingsContext';

export const UserListSubMenu = ({
  userActivationState,
  userId,
}: UserListSubMenuProps) => {
  const [open, setOpen] = useState(false);

  const dispatch = useSettingsDispatch();

  const dropdownRef = useRef() as MutableRefObject<HTMLDivElement>;
  useClickOutside(dropdownRef, () => setOpen(false));

  const handleClick = () => {
    setOpen(!open);
  };

  const handleAction = (action: UserActionType) => {
    dispatch({
      type: 'setUserAction',
      userAction: {
        userId,
        userAction: action,
      },
    });
    setOpen(false);
  };

  const handleStateChange = (userState: UserState) => {
    dispatch({
      type: 'setUserAction',
      userAction: {
        userId,
        userAction: userState === UserState.Enabled ? 'activate' : 'deactivate',
      },
    });
    setOpen(false);
  };

  return (
    <UserListSubMenuWrapper ref={dropdownRef}>
      <UserListSubMenuButton type="button" onClick={handleClick} open={open}>
        <p>...</p>
      </UserListSubMenuButton>
      {open && (
        <UserListSubMenuDropdown>
          <ul>
            {userActivationState === 'ACTIVE' ? (
              <>
                <UserListSubMenuDropdownListItem
                  onClick={() => handleAction('edit')}
                  onKeyPress={() => handleAction('edit')}
                >
                  Edit user
                </UserListSubMenuDropdownListItem>
                <UserListSubMenuDropdownListItem
                  onClick={() => handleStateChange(UserState.Disabled)}
                  onKeyPress={() => handleStateChange(UserState.Disabled)}
                  warning
                >
                  Deactivate
                </UserListSubMenuDropdownListItem>
              </>
            ) : null}
            {userActivationState === 'DEACTIVATED' ? (
              <UserListSubMenuDropdownListItem
                onClick={() => handleStateChange(UserState.Enabled)}
                onKeyPress={() => handleStateChange(UserState.Enabled)}
              >
                Reactivate
              </UserListSubMenuDropdownListItem>
            ) : null}
          </ul>
        </UserListSubMenuDropdown>
      )}
    </UserListSubMenuWrapper>
  );
};
