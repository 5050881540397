import { pick } from 'ramda';

import type { UserActivationState, UserFormData, UsersType } from './types';
import type { EnumMapType } from 'mappings/enums';
import type {
  AddRegisterCareSettingClinicianInput,
  UpdateRegisterCareSettingClinicianInput,
} from 'services/graphql';
import { UserState, ModuleIdentifier } from 'services/graphql';

export const ModuleIdentifierMap = {
  HEALTHYYOU_CLINICIAN: 'HealthyYou',
  SMART_MESSAGING: 'Smart messaging',
} as EnumMapType<ModuleIdentifier>;

export const UserActivationStateMap = {
  ACTIVE: 'Active',
  DEACTIVATED: 'Deactivated',
  NONE: 'None',
} as EnumMapType<UserActivationState>;

export const userMapping = (data: UsersType) => ({
  emailAddress: {
    key: 'Email',
    value: data.emailAddress,
  },
  mobileNumber: {
    key: 'Mobile number',
    value: data.mobileNumber,
  },
  careSettingAdministrator: {
    key: 'Grant permissions',
    value: data.careSettingAdministrator ? 'Yes' : 'No',
  },
  firstName: {
    key: 'First name',
    value: data.firstName,
  },
  surname: {
    key: 'Surname',
    value: data.surname,
  },
  appAccess: {
    key: 'App access',
    value:
      data.modules!.map((item) => ModuleIdentifierMap[item]).join(', ') ?? '',
  },
  status: {
    key: 'Status',
    value: UserActivationStateMap[data.userActivationState ?? 'NONE'],
  },
});

export const userFormMapping = (data: UserFormData) =>
  ({
    firstName: data.firstName,
    surname: data.surname,
    mobileNumber: data.mobileNumber,
    emailAddress: data.emailAddress,
    careSettingAdministrator: data.careSettingAdministrator,
  } as UsersType);

export const mapUserObjectKeys = (
  data: UsersType,
  keys: Array<keyof ReturnType<typeof userMapping>>
) => Object.values(pick(keys, userMapping(data)));

export const userSelectionMapping = (data: UsersType) =>
  mapUserObjectKeys(data, [
    'emailAddress',
    'mobileNumber',
    'appAccess',
    'careSettingAdministrator',
    'status',
  ]);

export const userConfirmationMapping = (data: UsersType) =>
  mapUserObjectKeys(data, [
    'firstName',
    'surname',
    'emailAddress',
    'mobileNumber',
    'appAccess',
    'careSettingAdministrator',
  ]);

const registerCoreCareSettingClinicianMap = (
  mappedUserAdmissionData: UsersType
) => ({
  careSettingAdministrator: mappedUserAdmissionData.careSettingAdministrator,
  firstName: mappedUserAdmissionData.firstName,
  surname: mappedUserAdmissionData.surname,
  mobileNumber: mappedUserAdmissionData.mobileNumber,
  modules: [ModuleIdentifier.HealthyyouClinician],
});

export const addRegisterCareSettingClinicianInputMap = (
  mappedUserAdmissionData: UsersType,
  careSettingId: string,
  defaultWardId: string
): AddRegisterCareSettingClinicianInput => ({
  ...registerCoreCareSettingClinicianMap(mappedUserAdmissionData),
  careSettingId,
  defaultWardId,
  emailAddress: mappedUserAdmissionData.emailAddress,
});

export const updateRegisterCareSettingClinicianInputMap = (
  mappedUserAdmissionData: UsersType,
  careSettingId: string,
  userId: string,
  isReactivate: boolean,
  defaultWardId: string
): UpdateRegisterCareSettingClinicianInput => {
  const output = {
    ...registerCoreCareSettingClinicianMap(mappedUserAdmissionData),
    careSettingId,
    defaultWardId,
    userId,
  };
  return isReactivate
    ? { ...output, userState: UserState.Enabled }
    : (output as any);
};

export const userAdmissionFormMapping = (data: UserFormData) =>
  ({
    firstName: data.firstName,
    surname: data.surname,
    mobileNumber: data.mobileNumber,
    emailAddress: data.emailAddress,
    modules: [ModuleIdentifier.HealthyyouClinician],
    careSettingAdministrator: data.careSettingAdministrator,
  } as UsersType);
