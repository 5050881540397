import { reject, isNil } from 'ramda';
import type { NavItem, MenuConfig } from '@bt-healthcare/ui-toolkit';

import { MODULE_ID } from '../App.constants';

import type {
  GetUserProfileBySafIdQuery,
  ModuleIdentifier,
  UserModuleAvailability,
} from 'services/graphql';
import { UserModuleAvailabilityStatus } from 'services/graphql';

export const getMenuConfig = (navItems: NavItem[], module: string) =>
  ({
    navItems,
    hostname: window.location.hostname,
    module,
  } as MenuConfig);

export const getNavItems = (data: GetUserProfileBySafIdQuery | undefined) =>
  reject(isNil, data?.userProfile?.userModuleAvailability || []).map(
    ({ attributes }: UserModuleAvailability) =>
      ({
        module: attributes.moduleShortName,
        label: attributes.moduleName,
        isActive:
          attributes.availabilityStatus ===
          UserModuleAvailabilityStatus.Accessible,
      } as NavItem)
  );

export const hasModuleAccess = (
  data: GetUserProfileBySafIdQuery | undefined,
  module: ModuleIdentifier = MODULE_ID
): boolean =>
  !!data?.userProfile?.userModuleAvailability?.find(
    (moduleAvailability) =>
      moduleAvailability?.attributes.moduleId === module &&
      moduleAvailability?.attributes.availabilityStatus ===
        UserModuleAvailabilityStatus.Accessible
  );
