import { StatusChip } from '@bt-healthcare/ui-toolkit';

import type { AdmissionStatusChipProps } from '../PatientList.types';
import { AdmissionStatusColorMap } from '../PatientList.util';
import { EMPTY_VALUE } from 'App.constants';
import { AdmissionStatusMap } from 'mappings/enums';

export const AdmissionStatusChip = ({
  admissionStatus,
  id,
}: AdmissionStatusChipProps) =>
  admissionStatus ? (
    <StatusChip
      id={`${id}-admission`}
      chipColor={AdmissionStatusColorMap[admissionStatus]}
    >
      {AdmissionStatusMap[admissionStatus]}
    </StatusChip>
  ) : (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{EMPTY_VALUE}</>
  );
