import type { TypedOptionType } from '@bt-healthcare/ui-toolkit';
import { DropDown } from '@bt-healthcare/ui-toolkit';
import type { FieldValues } from 'react-hook-form';
import { Controller } from 'react-hook-form';

import type { RHFDropDownProps } from './types';

/** React hook form wrapper for DropDown component  */
export const RHFDropDown = <T extends FieldValues>({
  label,
  options,
  fieldName,
  control,
  defaultValue,
  handleChange,
  errorText,
  isClearable = true,
  isMulti = false,
  markAsRequired = false,
  arialLabel,
  transparentBackground = false,
  ...props
}: RHFDropDownProps<T>) => (
  <Controller
    name={fieldName}
    control={control}
    defaultValue={defaultValue}
    render={({ field: { onChange, onBlur, name, value } }) => (
      <DropDown
        id={name}
        value={value as TypedOptionType<string>}
        name={name}
        label={label}
        options={options}
        transparentBackground={transparentBackground}
        errorText={errorText}
        onBlur={onBlur}
        isClearable={isClearable}
        ariaLabel={arialLabel}
        onChange={(option: any) => {
          onChange(option);
          if (handleChange && option != null) handleChange(option);
        }}
        isMulti={isMulti}
        markAsRequired={markAsRequired}
        {...props}
      />
    )}
  />
);
