import { ListView } from '@bt-healthcare/ui-toolkit';
import { columnWidthSizes, headerData, rowData } from './UserListConfig';
import type { UserListRendererProps } from './types';
import { ListViewSkeleton } from 'components/Skeletons/ListViewSkeleton';

export const UserListRenderer = ({ loading, data }: UserListRendererProps) =>
  loading && data.length === 0 ? (
    <ListViewSkeleton count={10} />
  ) : (
    <ListView
      headerData={headerData}
      rowData={rowData(data)}
      id="user-settings"
      columnWidths={columnWidthSizes}
    />
  );
