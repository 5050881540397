import type { ComponentIdentifier } from '@bt-healthcare/ui-toolkit';
import { Card } from '@bt-healthcare/ui-toolkit';
import type { PropsWithChildren } from 'react';

export const HeaderCard = ({
  children,
  id,
  'data-testid': dataTestId,
}: PropsWithChildren<ComponentIdentifier>) => (
  <Card
    id={id}
    background="linear-gradient(44deg, rgba(85, 20, 180, 0.10) 0%, rgba(71, 46, 205, 0.10) 30.20%, rgba(68, 138, 255, 0.10) 76.70%)"
    data-testid={dataTestId}
  >
    {children}
  </Card>
);
