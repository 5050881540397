import type {
  FilterTypeEnums,
  TopLevelFilterItem,
  TopLevelFilterType,
} from './types';
import {
  AdmissionStatusMap,
  MonitoredConditionMap,
  MonitoringStatusMap,
} from 'mappings/enums';
import type { FilterState } from 'reducers/patient/types';
import {
  AdmissionStatus,
  MonitoringStatusType,
  PatientDiagnosis,
} from 'services/graphql';

const filterMonitoringStatusOrder: MonitoringStatusType[] = [
  MonitoringStatusType.AwaitingReadings,
  MonitoringStatusType.ReadingsAvailable,
  MonitoringStatusType.ReadyForReview,
  MonitoringStatusType.UrgentClinicalInterventionRequired,
  MonitoringStatusType.Reviewed,
  MonitoringStatusType.SelfMonitoring,
];

const mapFilter = <T extends FilterTypeEnums>(
  filterMap: Record<T, string>
): TopLevelFilterItem<T>[] =>
  Object.keys(filterMap).map((filter) => ({
    filter: filter as T,
    label: filterMap[filter as T],
    active: false,
  }));

export const getConditionFilters = () =>
  mapFilter(MonitoredConditionMap).filter(
    (filter) => filter.filter !== PatientDiagnosis.None
  );

export const getPatientStatusFilters = () => {
  const admissionFilters = mapFilter(AdmissionStatusMap).filter(
    (filter) => filter.filter !== AdmissionStatus.Monitoring
  );

  const monitoringFilters = mapFilter(MonitoringStatusMap);
  const sortedMonitoringFilters = filterMonitoringStatusOrder.map((key) =>
    monitoringFilters.find((item) => item.filter === key)
  );

  return [...admissionFilters, ...sortedMonitoringFilters];
};

export const updateFilters = <T extends FilterTypeEnums>(
  filterState: FilterState,
  filterType: TopLevelFilterType,
  filter: T,
  active: boolean
) => {
  const topLevelFilter = filterState[filterType];
  const newTopLevelFilterState = topLevelFilter.map((item) =>
    item.filter === filter ? { ...item, active } : item
  );
  return { ...filterState, [filterType]: newTopLevelFilterState };
};

export const hasActiveFilters = (filterState: FilterState) =>
  filterState.Condition.some((item) => item.active) ||
  filterState.PatientStatus.some((item) => item.active);
