import styled from 'styled-components';
import { Wrapper, device, spacing } from '@bt-healthcare/ui-toolkit';
import type { WrapperProps } from './types';

const sideNavOpenMargin = '284px';
const sideNavClosedMargin = '140px';

export const HeaderWrapper = styled.nav<WrapperProps>`
  align-items: center;
  display: ${({ sideNavOpen }) => (sideNavOpen ? 'flex' : 'none')};
  transition: opacity 0.2s ease-in;

  @media ${device.largeTablet} {
    position: relative;
    justify-content: space-between;
    /* Review padding calc here to align title and side menu chevron on desktop */
    padding: calc(${spacing.s6} + 2px) 0;
    display: flex;
  }
`;

export const AppMenuHeader = styled(Wrapper)<WrapperProps>`
  display: none;

  @media ${device.largeTablet} {
    height: 56px;
    width: ${({ sideNavOpen }) =>
      sideNavOpen
        ? `calc(100% - ${sideNavOpenMargin} - ${spacing.s6})`
        : `calc(100% - ${sideNavClosedMargin} - ${spacing.s6})`};
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: ${({ sideNavOpen }) =>
      sideNavOpen ? sideNavOpenMargin : sideNavClosedMargin};
    transition-property: margin-left, width;
    transition-duration: 0.2s;
    transition-timing-function: ease-in;
  }
`;

export const LeftContent = styled(Wrapper)`
  @media ${device.largeTablet} {
    display: flex;
    align-items: center;

    h3 {
      margin-right: ${spacing.s8};
      white-space: nowrap;
    }
  }
`;
