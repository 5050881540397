import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { NavigationWrapper } from './styles';
import type { NavigationProps } from './types';
import { MobileContent } from './MobileContent';
import { DesktopContent } from './DesktopContent';

export const Navigation = ({
  onCollapse,
  menuHeader,
  sideNavOpen = false,
  onBackClick,
}: NavigationProps) => {
  const location = useLocation();
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const addBackground = () => setScrolled(window.scrollY > 60);
    window.addEventListener('scroll', addBackground);
    return () => window.removeEventListener('scroll', addBackground);
  }, []);

  const activeRoute = location.pathname;

  return (
    <NavigationWrapper
      sideNavOpen={sideNavOpen}
      scrolled={scrolled}
      data-testid="navigation"
      aria-label="Navigation"
    >
      <MobileContent
        onBackClick={onBackClick}
        menuHeader={menuHeader}
        activeRoute={activeRoute}
      />
      <DesktopContent
        onCollapse={onCollapse}
        sideNavOpen={sideNavOpen}
        activeRoute={activeRoute}
      />
    </NavigationWrapper>
  );
};
