import { spacing, fontSizes, colors } from '@bt-healthcare/ui-toolkit';
import styled from 'styled-components';

export const DateRange = styled.span`
  display: block;
  margin: ${spacing.s4} auto;
  font-size: ${fontSizes.sm};
  color: ${colors.grey.grey10};
`;

export const Note = styled.span`
  display: block;
  margin-top: ${spacing.s4};
  font-size: ${fontSizes.xs};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`;
