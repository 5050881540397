import { formatISO } from 'date-fns';
import { all, isEmpty } from 'ramda';
import { v4 as uuidv4 } from 'uuid';
import type {
  CareSettingPatientAdmissionInput,
  StepUpGender,
  WardPatientInput,
} from 'services/graphql';
import {
  IsolationType,
  OriginType,
  PorterStatusType,
  SpecialtyType,
  YesNoType,
  GenderIdentifiedAs,
  PersonRelationshipType,
  Covid19Status,
} from 'services/graphql';
import { nhsIdFormatter } from 'utils/string.utils';
import type {
  PatientAdmissionMedicalFormData,
  PatientAdmissionPersonalFormData,
} from 'components/PatientAdmissionForm/types';
import { getObservationTypesForMonitoredConditionOptions } from 'mappings/monitoredConditions.formatter';
import type { GeneralPracticesWithIdType } from 'types';

export const createWardPatientInput = ({
  careSettingId,
  careSettingPatientId,
  medical,
  wardId,
  personId,
  userId,
  date = new Date(),
}: {
  careSettingId: string;
  careSettingPatientId: string;
  medical: PatientAdmissionMedicalFormData;
  wardId: string;
  personId: string;
  userId: string;
  date?: Date;
}): WardPatientInput => ({
  admissionDateTime: date.toISOString(),
  arrivalExpectedDateTime: null,
  careSettingId,
  careSettingPatientId,
  comments: medical.comments ?? null,
  covid19Status: Covid19Status.None,
  isolationType: IsolationType.None,
  isolationTypeOther: null,
  origin: OriginType.None,
  outlierYN: YesNoType.None,
  porterStatus: PorterStatusType.NotRequired,
  predictedLengthOfStay: null,
  monitoredConditions: medical.monitoredConditions.map(({ value }) => ({
    condition: value,
    period: { start: date.toISOString(), end: null },
  })),
  specialty: SpecialtyType.None,
  wardBedId: uuidv4(),
  wardId,
  userId,
  observationTypes: getObservationTypesForMonitoredConditionOptions(
    medical.monitoredConditions
  ),
  requiresIsolationYN: YesNoType.None,
  primaryDiagnosis: null,
  secondaryDiagnosis: null,
  personId,
});

export const careSettingPatientAdmissionInput = ({
  careSettingId,
  wardId,
  personal,
  medical,
  personId,
}: {
  wardId: string;
  careSettingId: string;
  personal: PatientAdmissionPersonalFormData;
  medical: PatientAdmissionMedicalFormData;
  personId: string;
}): CareSettingPatientAdmissionInput => {
  const dob = new Date(
    personal.yearOfBirth,
    personal.monthOfBirth - 1,
    personal.dayOfBirth
  );
  const contactParty = all(isEmpty, [
    personal.nextOfKinPhoneNumber,
    personal.nextOfKinFirstName,
    personal.nextOfKinSurname,
  ])
    ? []
    : [
        {
          humanNames: [
            {
              family: personal.nextOfKinSurname ?? '',
              given: personal.nextOfKinFirstName ?? '',
            },
          ],
          primaryContactNumber: personal.nextOfKinPhoneNumber ?? null,
          personRelationshipType: PersonRelationshipType.NextOfKin,
        },
      ];

  return {
    personId,
    careSettingPatient: {
      requiresIsolationYN: YesNoType.None,
      admissionComments: medical.comments ?? null,
      careSettingId,
      decisionToAdmitDateTime: formatISO(new Date()),
      furtherWardPreferences: null,
      isolationType: IsolationType.None,
      isolationTypeOther: null,
      origin: OriginType.None,
      patientWardPreference: null,
      specialty: SpecialtyType.None,
      socialCareYN: YesNoType.None,
    },
    person: {
      contactInformation: {
        primaryContactNumber: personal.phoneNumber,
        primaryEmailAddress: personal.email,
      },
      personalInformation: {
        firstName: personal.firstName.trim(),
        surname: personal.surname.trim(),
        title: 'Ind',
        nhsNumber: nhsIdFormatter(personal.nhsNumber, 'unformat'),
        dateOfBirth: formatISO(dob, { representation: 'date' }),
        genderAssignedAtBirth: personal.genderAssignedAtBirth,
        genderIdentifiedAs:
          personal.genderIdentifiedAs?.value ?? GenderIdentifiedAs.None,
        pronouns: personal.pronouns?.value ?? null,
        mobility: personal.mobility?.value ?? null,
        ethnicity: personal.ethnicity?.value ?? null,
        primaryAddress: {
          line1: personal.address,
          postalCode: personal.postcode,
        },
      },
      contactParty,
      careSettingId,
      registeredGPSurgery: medical.gpSurgery.label,
    },
    user: {
      defaultCareSettingId: careSettingId,
      defaultWardId: wardId,
    },
  };
};

export const patientStepupInput = ({
  careSettingId,
  personal,
  medical,
  personId,
  generalPracticesWithId,
  date = new Date(),
}: {
  careSettingId: string;
  personal: PatientAdmissionPersonalFormData;
  medical: PatientAdmissionMedicalFormData;
  personId: string;
  generalPracticesWithId?: GeneralPracticesWithIdType[];
  date?: Date;
}) => ({
  personId,
  careSettingId,
  monitoredConditions: medical.monitoredConditions.map(({ value }) => ({
    condition: value,
    period: { start: date.toISOString(), end: null },
  })),
  observationTypes: getObservationTypesForMonitoredConditionOptions(
    medical.monitoredConditions
  ),
  nhsNumber: nhsIdFormatter(personal.nhsNumber, 'unformat')!,
  ethnicity: personal.ethnicity?.value!,
  medicalCentreReferenceId: generalPracticesWithId?.find(
    (x) => x.name === medical?.gpSurgery?.value
  )?.value!,
  gender: personal.genderAssignedAtBirth as unknown as StepUpGender,
  address: personal.address,
  postcode: personal.postcode,
  phoneNumber: personal.phoneNumber,
});
