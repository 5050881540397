import { Wrapper, colors, fontSizes, spacing } from '@bt-healthcare/ui-toolkit';
import styled from 'styled-components';

export const Title = styled.p`
  color: ${colors.grey.grey10};
  font-size: ${fontSizes.base};
  font-weight: 500;
  padding-bottom: ${spacing.s2};
`;

export const Grid = styled(Wrapper)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: ${spacing.s4};
  padding-top: ${spacing.s4};
`;
