import { useLocation, useNavigate } from 'react-router-dom';
import { noop, toHyphenCase } from '@bt-healthcare/ui-toolkit';
import { Fragment } from 'react';
import { BreadcrumbsWrapper, Crumb } from './styles';
import { getBreadcrumbs } from 'config/routes.utils';
import { useLeavePageModal } from 'hooks/useLeavePageModal';

export const Breadcrumbs = () => {
  const navigate = useNavigate();
  const { pathname, state: locationState } = useLocation();
  const { checkCanLeavePage } = useLeavePageModal();

  const crumbs = getBreadcrumbs(
    pathname,
    navigate,
    (locationState as any)?.wardPatientId,
    (locationState as any)?.patient,
    (locationState as any)?.from?.pathname
  );

  const onClickHandler = (lastCrumb: boolean, crumbAction: () => void) => {
    if (lastCrumb) {
      noop();
    } else {
      checkCanLeavePage(crumbAction);
    }
  };

  if (!crumbs) return null;

  return (
    <BreadcrumbsWrapper>
      {crumbs.map((crumb, index) => {
        const lastCrumb = index + 1 === crumbs.length;
        return (
          <Fragment key={toHyphenCase(crumb.label)}>
            <Crumb
              data-testid={`breadcrumb-${toHyphenCase(crumb.label)}`}
              onClick={() => onClickHandler(lastCrumb, crumb.action)}
              lastCrumb={lastCrumb}
            >
              {crumb.label}
            </Crumb>
            {!lastCrumb && <li>/</li>}
          </Fragment>
        );
      })}
    </BreadcrumbsWrapper>
  );
};
