/* eslint-disable react/jsx-no-useless-fragment */
import { SpinnerLoader } from '@bt-healthcare/ui-toolkit';
import type { PropsWithChildren } from 'react';
import { useEffect, useState } from 'react';

import { UserConfirmation } from 'pages/Settings/UserConfirmation';

export const DelayedComponent = ({
  children,
  delay = 1000,
}: PropsWithChildren<{ delay?: number }>) => {
  const [show, setShow] = useState(false);
  useEffect(() => {
    const timeout = setTimeout(() => setShow(true), delay);
    return () => {
      clearTimeout(timeout);
    };
  }, []);
  return (
    <>{!show ? <SpinnerLoader id="loader" data-testid="loader" /> : children}</>
  );
};

export const UserConfirmationFeatureRoute = () => (
  <DelayedComponent delay={750}>
    <UserConfirmation />
  </DelayedComponent>
);
