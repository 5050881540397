import { device, spacing } from '@bt-healthcare/ui-toolkit';
import styled from 'styled-components';

export const Grid = styled.div`
  display: grid;
  grid-template-rows: max-content;
  gap: ${spacing.s4};

  @media ${device.largeMobile} {
    grid-template-columns: repeat(4, 1fr);
  }

  @media ${device.tablet} {
    grid-template-columns: repeat(8, 1fr);
  }

  @media ${device.largeTablet} {
    gap: ${spacing.s6};
  }
`;

export const PatientAdmissionFormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
`;
