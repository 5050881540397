import { Wrapper } from '@bt-healthcare/ui-toolkit';

import type { HypertensionAssessmentHealthVitalsProps } from './types';
import { ObservationTypeLineChart } from 'components/Visualisations/ObservationTypeLineChart';
import { AvgBloodPressure } from 'components/Readings';
import { VitalTrendsWrapper } from 'components/PatientHealthVitals/styles';
import { ObservationType } from 'services/graphql';
import { LineChartHeading } from 'components/Visualisations/LineChart/LineChartHeading';

export const HypertensionAssessmentHealthVitals = ({
  wardPatientUI,
}: HypertensionAssessmentHealthVitalsProps) => {
  /** Only show Blood pressure observation types for a Hypertension assessment */
  const observationTypes = wardPatientUI.supportedObservationTypes.filter(
    (item) => item === ObservationType.BloodPressure
  );
  return (
    <>
      <Wrapper>
        <AvgBloodPressure
          personId={wardPatientUI.personId!}
          admissionDateTime={wardPatientUI.admissionDateTime}
          hasSubmittedReadings={wardPatientUI.hasSubmittedReadings}
        />
      </Wrapper>
      <VitalTrendsWrapper>
        <ObservationTypeLineChart
          personId={wardPatientUI.personId!}
          observationTypes={observationTypes}
          admissionDateTime={wardPatientUI.admissionDateTime}
          title={
            <LineChartHeading
              unit="mmHg"
              title="Blood pressure"
              ariaLevel={3}
              observationType={ObservationType.BloodPressure}
            />
          }
          showLineChartHeading={false}
          hasSubmittedReadings={wardPatientUI.hasSubmittedReadings}
        />
      </VitalTrendsWrapper>
    </>
  );
};
