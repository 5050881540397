import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { type SubmitHandler, useForm } from 'react-hook-form';
import { useBoolean } from 'usehooks-ts';
import {
  Button,
  Col,
  DateInput,
  Grid,
  Input,
  Stack,
  SubHeader,
  GRID_BREAKPOINT,
  ErrorMessage,
  SpinnerLoader,
  TextArea,
  Spacer,
} from '@bt-healthcare/ui-toolkit';

import type { DischargeFormData } from './PatientDischarge.types';
import { schema } from './PatientDischarge.schema';
import { Form } from './PatientDischarge.styles';
import { RHFDropDown } from 'components/Form/RHFDropDown';
import { useTracking } from 'hooks/useTracking';
import { ReasonForDischargeOptions } from 'mappings/enums';
import type { WardPatient } from 'services/graphql';
import { useGetWardPatientQuery } from 'services/graphql';
import { dischargeDefaultFormData } from 'selectors/patient';
import { CancelDischarge } from 'components/Modal/CancelDischarge';
import { ConfirmDischarge } from 'components/Modal/ConfirmDischarge';
import { getAdmissionDate } from 'selectors/wardPatient';

import { useRedirectAndReturnState } from 'hooks/useRedirectAndReturnState';
import { useApp } from 'context/app/AppContext';
import { PageName } from 'config/pageNames';

export const Discharge = () => {
  const wardPatientId = useRedirectAndReturnState('wardPatientId');
  const { careSetting } = useApp();
  const { data, loading } = useGetWardPatientQuery({
    variables: { wardPatientId, careSettingId: careSetting.id ?? null },
  });
  const navigate = useNavigate();
  const { trackPage } = useTracking();
  useEffect(() => {
    trackPage(PageName.DISCHARGE);
  }, []);

  const {
    value: showCancelModal,
    setFalse: closeCancel,
    setTrue: showCancel,
  } = useBoolean(false);

  const {
    value: showConfirmModal,
    setFalse: closeConfirm,
    setTrue: showConfirm,
  } = useBoolean(false);

  const {
    register,
    control,
    formState: { isValid, errors },
    handleSubmit,
    getValues,
  } = useForm<DischargeFormData>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
  });

  const formSubmit: SubmitHandler<DischargeFormData> = (_, event) =>
    event?.preventDefault();

  if (loading) {
    return <SpinnerLoader id="loader" data-testid="loader" />;
  }
  if (!data?.wardPatient) {
    return <ErrorMessage />;
  }
  const wardPatient = data.wardPatient as WardPatient;
  const admission = getAdmissionDate(wardPatient);

  const { admissionDate, dischargeDate, lengthOfStayInDays } =
    dischargeDefaultFormData({
      admissionDate: admission,
    });

  return (
    <Form data-testid="discharge-form" onSubmit={handleSubmit(formSubmit)}>
      <Grid>
        <Col
          col={{
            [GRID_BREAKPOINT.small]: '1 / span 4',
            [GRID_BREAKPOINT.large]: '3 / span 4',
          }}
        >
          <SubHeader color="indigo08">Discharge patient </SubHeader>
        </Col>
        <Col
          col={{
            [GRID_BREAKPOINT.small]: '1 / span 4',
            [GRID_BREAKPOINT.medium]: '1 / span 4',
            [GRID_BREAKPOINT.large]: '3 / span 4',
          }}
        >
          <DateInput
            id="admitted-date-input"
            inputParams={{
              dayInput: {
                id: 'admitted-date-input',
                value: admissionDate?.day,
              },
              yearInput: {
                id: 'admitted-date-input',
                value: admissionDate?.year,
              },
              monthInput: {
                id: 'admitted-date-input',
                value: admissionDate?.month,
              },
            }}
            label="Admitted"
            disabled
          />
        </Col>
        <Col
          col={{
            [GRID_BREAKPOINT.small]: '1 / span 4',
            [GRID_BREAKPOINT.medium]: '5 / span 4',
            [GRID_BREAKPOINT.large]: '7 / span 4',
          }}
        >
          <DateInput
            id="discharged-date-input"
            inputParams={{
              dayInput: {
                id: 'discharged-date-input',
                value: dischargeDate.day,
              },
              yearInput: {
                id: 'discharged-date-input',
                value: dischargeDate.year,
              },
              monthInput: {
                id: 'discharged-date-input',
                value: dischargeDate.month,
              },
            }}
            label="Discharged"
            disabled
          />
        </Col>
        <Col
          col={{
            [GRID_BREAKPOINT.small]: '1 / span 4',
            [GRID_BREAKPOINT.medium]: '1 / span 8',
            [GRID_BREAKPOINT.large]: '3 / span 4',
          }}
        >
          <Input
            id="lengthOfStay"
            label="Actual length of stay"
            disabled
            value={lengthOfStayInDays}
          />
        </Col>
        <Col
          col={{
            [GRID_BREAKPOINT.small]: '1 / span 4',
            [GRID_BREAKPOINT.medium]: '1 / span 8',
            [GRID_BREAKPOINT.large]: '7 / span 4',
          }}
        >
          <RHFDropDown
            label="Reason for discharge"
            fieldName="reasonForDischarge"
            control={control}
            options={ReasonForDischargeOptions}
            errorText={errors.reasonForDischarge?.message?.toString()}
          />
        </Col>
        <Col
          col={{
            [GRID_BREAKPOINT.small]: '1 / span 4',
            [GRID_BREAKPOINT.medium]: '1 / span 8',
            [GRID_BREAKPOINT.large]: '3 / span 8',
          }}
        >
          {/* TODO: Enquire about Comments maxLength  */}
          <TextArea
            id="comments"
            label="Comments"
            optional
            {...register('comments')}
          />
        </Col>
      </Grid>
      <Spacer size="s2" />
      <Grid>
        <Col
          col={{
            [GRID_BREAKPOINT.small]: '1 / span 4',
            [GRID_BREAKPOINT.medium]: '1 / span 8',
            [GRID_BREAKPOINT.large]: '3 / span 8',
          }}
        >
          {/* TODO: Review issue with Stack component in latest Toolkit version */}
          <Stack
            id="discharge-btn"
            space="s1"
            flexDirection={{ xs: 'column', md: 'row' } as any}
          >
            <Button id="continue" disabled={!isValid} onClick={showConfirm}>
              Continue
            </Button>
            <Button
              id="cancel-discharge"
              variant="tertiary"
              onClick={showCancel}
            >
              Cancel
            </Button>
          </Stack>
        </Col>
      </Grid>
      <CancelDischarge
        modalOpen={showCancelModal}
        handleCancelDischarge={() => {
          navigate(-1);
        }}
        handleContinueDischarge={closeCancel}
      />
      <ConfirmDischarge
        modalOpen={showConfirmModal}
        closeConfirm={closeConfirm}
        admissionDate={admission}
        dischargeDate={new Date()}
        lengthOfStayInDays={lengthOfStayInDays}
        wardPatient={data?.wardPatient as WardPatient}
        formData={getValues()}
      />
    </Form>
  );
};
