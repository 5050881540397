import { Card, Checkbox } from '@bt-healthcare/ui-toolkit';
import type { CheckboxCardProps } from './types';

export const CheckboxCard = ({
  label,
  backgroundColour,
  handleChange,
  id,
  variant = 'primary',
}: CheckboxCardProps) => (
  <Card id={`${id}=checkbox-card`} background={backgroundColour}>
    <Checkbox
      id={`${id}-checkbox`}
      onChange={handleChange}
      variant={variant}
      position="leftTop"
      label={label}
    />
  </Card>
);
