import {
  Text,
  Wrapper,
  colors,
  fontSizes,
  spacing,
} from '@bt-healthcare/ui-toolkit';
import styled from 'styled-components';

export const ModalTitle = ({ title }: { title: string }) => (
  <Text
    as="h3"
    fontSize={fontSizes.h3}
    fontWeight={500}
    color={colors.rag.light.red}
    style={{ textAlign: 'center' }}
  >
    {title}
  </Text>
);

export const TextWrapper = styled(Wrapper)`
  display: flex;
  flex-direction: column;
  gap: ${spacing.s2};
  text-align: center;
  margin-bottom: ${spacing.s2};
  align-items: center;
`;

export const FieldWrapper = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 24px;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid ${colors.grey.grey05};
`;

export const FieldRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;
