import { Stack, toHyphenCase } from '@bt-healthcare/ui-toolkit';
import { ColumnSortToggle } from './styles';
import type { ColumnSortProps } from './types';
import type { WardPatientsSortKey } from 'services/graphql';

export const ColumnSort = <T extends WardPatientsSortKey>({
  setSortingQuery,
  sortParams,
  sortKey,
  label,
}: ColumnSortProps<T>) => {
  const identifier = `${toHyphenCase(sortKey).replace(/_/g, '-')}-column-sort`;

  return (
    <Stack space="none" id={identifier} flexDirection="row">
      <ColumnSortToggle
        role="button"
        data-testid={identifier}
        onClick={() => {
          setSortingQuery({
            key: sortKey,
            ascending:
              sortParams?.key === sortKey ? !sortParams.ascending : true,
          });
        }}
      >
        {label}
      </ColumnSortToggle>
    </Stack>
  );
};
