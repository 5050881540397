import {
  Wrapper,
  Text,
  colors,
  fontSizes,
  BTHealthIcon,
  Divider,
} from '@bt-healthcare/ui-toolkit';
import {
  FilterItemWrapper,
  TextWrapper,
  DividerWrapper,
  FilterPanel,
  FilterPanelTitleWrapper,
  FilterWrapper,
} from './styles';
import type { FilterItemProps } from './types';
import { ConsultationStatusMap } from 'mappings/enums';
import type { MonitoredConditionConsultationStatusType } from 'services/graphql';
import { ConsultationStatusFilters } from 'components/PatientListFilter/Filters/ConsultationStatusFilters';

export const ConsultationStatusFilterItem = ({
  statusFilter,
  handleTargetChecked,
  panelOpen,
  handlePanelOpen,
}: FilterItemProps) => (
  <Wrapper data-testid="filter-item">
    <FilterItemWrapper
      onClick={() => handlePanelOpen(true)}
      data-testid="open-panel"
    >
      <TextWrapper>
        <Text color={colors.grey.grey10} fontSize={fontSizes.base}>
          Consultation status
        </Text>
        <BTHealthIcon
          icon="ChevronRight"
          size={14}
          color={colors.primaryIndigo.indigo08}
        />
      </TextWrapper>

      {statusFilter.length > 0 && (
        <Text color={colors.grey.grey08} fontSize={fontSizes.xs}>
          {statusFilter
            .filter((item) => item.type === 'ConsultationStatus')
            .map(
              (option) =>
                ConsultationStatusMap[
                  option.value as MonitoredConditionConsultationStatusType
                ]
            )
            .join(', ')}
        </Text>
      )}

      <DividerWrapper>
        <Divider flexColor="grey.grey02" />
      </DividerWrapper>
    </FilterItemWrapper>

    <FilterPanel panelOpen={panelOpen.ConsultationStatus}>
      <FilterPanelTitleWrapper>
        <Wrapper onClick={() => handlePanelOpen(false)} data-testid="back">
          <BTHealthIcon icon="BackArrow" size={16} />
        </Wrapper>
        <Text
          color={colors.grey.grey10}
          fontSize={fontSizes.sm}
          fontWeight={500}
        >
          CONSULTATION STATUS
        </Text>
      </FilterPanelTitleWrapper>

      <FilterWrapper>
        <ConsultationStatusFilters
          statusFilter={statusFilter}
          handleTargetChecked={handleTargetChecked}
        />
      </FilterWrapper>
    </FilterPanel>
  </Wrapper>
);
