import debounce from 'lodash.debounce';
import type { Dispatch, SetStateAction } from 'react';
import { useEffect, useState } from 'react';
import type { Control, FieldValues, Path } from 'react-hook-form';
import { useWatch } from 'react-hook-form';

export const useDebounceFormValue = <T extends FieldValues, K>(
  control: Control<T>,
  fieldName: Path<T>,
  fieldValue: K,
  setFormValue: Dispatch<SetStateAction<K>>,
  duration = 500
) => {
  const internal = useWatch({ control, name: fieldName });
  const [debouncing, setDebouncing] = useState(false);
  useEffect(() => {
    setDebouncing(true);
    const debouncedFunction = debounce(() => {
      if (fieldValue !== internal) {
        setFormValue(internal);
      }
      setDebouncing(false);
    }, duration);
    debouncedFunction();
    return () => {
      debouncedFunction.cancel();
    };
  }, [fieldValue, setFormValue, internal, duration]);

  return debouncing;
};
