import { Skeleton, Stack } from '@bt-healthcare/ui-toolkit';

export const MonitoringConditionDropDownSkeleton = () => (
  <Stack space="s1" id="monitored-condition-dropdown-skeleton">
    <Skeleton
      id="monitoring-condition-text-skeleton"
      data-testid="monitoring-text-dropdown-skeleton"
      variant="text"
      fontSize="h4"
      width="50%"
      rounded
    />
    <Skeleton
      id="monitoring-condition-dropdown-skeleton"
      data-testid="monitoring-condition-dropdown-skeleton"
      variant="text"
      fontSize="h2"
      width="100%"
      rounded
    />
  </Stack>
);
