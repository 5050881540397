import { isEmpty, filter, includes, values } from 'ramda';

import type { MonitoredConditionView } from './types';
import { MonitoredConditionMap } from 'mappings/enums';
import { getObservationTypesForMonitoredConditions } from 'mappings/monitoredConditions.formatter';
import { SupportedObservationTypeConstant } from 'models/wardPatientObservation';
import type { ObservationType } from 'services/graphql';
import { MonitoringStatusType, PatientDiagnosis } from 'services/graphql';
import type { SupportedObservationType } from 'types';

export const getPatientsMonitoredConditionsText = (
  monitoredConditions?: MonitoredConditionView
) =>
  monitoredConditions
    ?.map((item) => MonitoredConditionMap[item.condition])
    .join(', ') ?? '';

export const getPatientsMonitoredConditions = (
  monitoredConditions?: MonitoredConditionView
) => monitoredConditions?.map((item) => item.condition) ?? [];

export const getSupportedObservationTypes = (
  submittedObservationTypes: ObservationType[],
  monitoredConditions: PatientDiagnosis[]
): SupportedObservationType[] => {
  let patientObservationTypes;
  const supportedObservationTypes =
    getObservationTypesForMonitoredConditions(monitoredConditions);

  patientObservationTypes = [...supportedObservationTypes];

  if (!isEmpty(submittedObservationTypes)) {
    const supportedSubmittedObservationTypes = filter(
      (observationType: any) =>
        includes(observationType, values(SupportedObservationTypeConstant)),
      submittedObservationTypes
    ) as SupportedObservationType[];

    const observationTypesSubmitted = supportedSubmittedObservationTypes.filter(
      (item) => !supportedObservationTypes.includes(item)
    );
    patientObservationTypes = [
      ...supportedObservationTypes,
      ...observationTypesSubmitted,
    ];
  }
  return patientObservationTypes.sort();
};

export const checkIfAssessmentStart = (
  monitoredConditions?: MonitoredConditionView
) => {
  const hypertension = monitoredConditions?.find(
    (item) => item.condition === PatientDiagnosis.Hypertension
  );
  if (hypertension === undefined || hypertension.monitoringStatus == null)
    return false;
  return (
    hypertension.monitoringStatus.status !== MonitoringStatusType.SelfMonitoring
  );
};
