import { Button, Wrapper, toHyphenCase } from '@bt-healthcare/ui-toolkit';

import { ButtonFilterGroupGrid, ButtonFilterGroupLabel } from './styles';
import type { ButtonFilterGroupProps } from './types';
import { ButtonFilters } from './ButtonFilters';
import { topLevelFilters } from 'filters/config';
import { HeaderCard } from 'components/Card/HeaderCard';
import { hasActiveFilters } from 'filters';

export const ButtonFilterGroup = ({
  filterState,
  onFilterClick,
  onFilterClear,
}: ButtonFilterGroupProps) => (
  <HeaderCard id="button-filter-group" data-testid="button-filter-group-card">
    <ButtonFilterGroupGrid>
      {topLevelFilters.map((item) => (
        <Wrapper
          key={item.filterType}
          data-testid={`${toHyphenCase(item.filterType)}-button-filter-group`}
        >
          <ButtonFilterGroupLabel>{item.label}</ButtonFilterGroupLabel>
          <ButtonFilters
            filterState={filterState}
            filterType={item.filterType}
            onFilterClick={onFilterClick}
          />
        </Wrapper>
      ))}
      {hasActiveFilters(filterState) && (
        <Wrapper data-testid="top-level-filter-clear-all">
          <Button
            id="top-level-filter-clear-all-btn"
            onClick={onFilterClear}
            variant="secondary"
            height="sm"
            transparent
          >
            Clear all
          </Button>
        </Wrapper>
      )}
    </ButtonFilterGroupGrid>
  </HeaderCard>
);
