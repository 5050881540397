import { Wrapper, colors } from '@bt-healthcare/ui-toolkit';
import styled from 'styled-components';

export const ProgressIndicatorWrapper = styled(Wrapper)`
  display: flex;
  position: relative;
  width: 100%;
  height: 41px;
`;

export const Background = styled.div`
  position: absolute;
  width: 100%;
  background: ${colors.secondaryBlue.blue01};
  height: 12px;
  border-radius: 6px;
`;

type IndicatorProps = {
  width: number;
};
export const Indicator = styled.div<IndicatorProps>`
  position: absolute;
  width: ${(props) => props.width}%;
  background: ${colors.secondaryBlue.blue06};
  height: 12px;
  border-radius: 6px;
`;

export const Steps = styled.div`
  position: absolute;
  display: flex;
  width: 100%;
`;
